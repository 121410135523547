<template>
  <div>
    <p>
      <span class="badge badge-info"
        >{{ $t("library.audios") }}: {{ files.length }}</span
      >
    </p>
    <div class="row">
      <div
        class="col-sm-3"
        v-for="(file, index) in files"
        :key="`audio_to_upload_${index}`"
      >
        <div class="text-right">
          <a href="#" class="m-0 p-0" @click.prevent="removeAudio(index)"
            ><i class="bi-trash"></i
          ></a>
        </div>
        <audio
          style="width: 100%; max-height: 90px"
          :id="`audio_${index}`"
          :src="URL.createObjectURL(file.data) + '#t=2'"
          controls
        >
          Your browser does not support the audio tag.
        </audio>
        <p class="text-center mb-0">
          <small class="text-secondary" :id="`audioDuration_${index}`"
            ><div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div></small
          ><small> - {{ file.data.name }}</small>
        </p>
        <div v-if="!selectFiles">
          <label
            ><small>{{ $t("library.language-of-the-audio") }}</small></label
          >
          <select class="form-control form-control-sm mb-3" v-model="file.locale">
            <option value=""></option>
            <option value="no-speech">{{ $t('library.no-speech') }}</option>
            <option value="en-US">English</option>
            <option value="pt-BR">Português</option>
            <option value="de-DE">Deutsch</option>
            <option value="es-ES">Español</option>
            <option value="fr-FR">Français</option>
            <option value="it-IT">Italiano</option>
            <option value="ar-SA">عربى</option>
            <option value="fil-PH">Filipino</option>
            <option value="hi-IN">Hindi</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "library-audios-component",

  props: ["files", "removeAudio", "selectFiles"],

  data() {
    return {
      URL: URL,
    };
  },

  mounted() {
    setTimeout(() => {
      if (this.files.length) {
        for (let index in this.files) {
          let audio = document.querySelector(`#audio_${index}`);
          let duration = audio.duration;
          document.getElementById(`audioDuration_${index}`).innerHTML =
            this.timestampToString(duration);
        }
      }
    }, 1000);
  },

  methods: {
    timestampToString(seconds) {
      return new Date(seconds * 1000).toISOString().substr(11, 8);
    },
  },

  watch: {
    files(val) {
      setTimeout(() => {
        if (this.files.length) {
          for (let index in this.files) {
            let audio = document.querySelector(`#audio_${index}`);
            let duration = audio.duration;
            document.getElementById(`audioDuration_${index}`).innerHTML =
              this.timestampToString(duration);
          }
        }
      }, 1000);
    },
  },
};
</script>
