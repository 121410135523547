<template>
  <div id="project-edit-music">
    <div class="custom-control custom-switch">
      <input type="checkbox" class="custom-control-input" id="automatic-music" v-model="model.autobgm" @change="tryUpdate" />
      <label class="custom-control-label" for="automatic-music">
        <p>{{ $t('project-edit.automatic-music') }}</p>
      </label>
    </div>

    <MusicPicker :bgm_id="bgm_id" :pickSong="selectSong" v-if="model.autobgm == 1" />
  </div>
</template>

<script>
import MusicPicker from '@/components/MusicPicker'

export default {
  name: 'music',

  props: ['model', 'tryUpdate'],

  components: {
    MusicPicker,
  },

  data() {
    return {
      bgm_id: null,
      customizeIntroOutro: false,
    }
  },

  mounted() {
    this.bgm_id = this.model.bgm_id
    this.customizeIntroOutro = this.model.intro_mode != null || this.model.outro_mode != null ? true : false
  },

  methods: {
    selectSong(song) {
      this.model.bgm_id = song.id
      this.bgm_id = this.model.bgm_id
      this.tryUpdate()
      window.analytics.track('New Project Song Selected', {
        bgmId: song.id,
        bgmTitle: song.title,
      })
    },

    changeIntro(param) {
      this.model.intro_mode = param
      this.tryUpdate()
    },

    changeOutro(param) {
      this.model.outro_mode = param
      this.tryUpdate()
    },
  },
}
</script>
