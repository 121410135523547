<template>
  <div>
    <div
      class="alert alert-danger text-center"
      role="alert"
      v-if="isVlog && model.locale == ''"
    >
      {{ $t("new-project.select-language") }}
    </div>
    <div
      class="alert alert-dark text-center text-success d-flex justify-content-between"
      role="alert"
      v-else-if="video_style"
    >
      <b><i class="bi-check-circle-fill"></i> {{ $t('new-project.good-work') }}</b>
      <button type="button" class="btn btn-default m-0 p-0" @click.prevent="changeNav('music')">
        {{ $t("new-project.next") }} <i class="bi-arrow-right-circle"></i>
      </button>
    </div>
    <h5 class="text-center">
      {{ $t("new-project.audio-type") }}<br /><small>{{
        $t("new-project.select-one")
      }}</small>
    </h5>
    <div class="row">
      <div class="col-sm-4 text-center">
        <button
          type="button"
          class="btn btn-block border-primary"
          :class="{
            'btn-primary': video_style == 'vlog',
            'btn-outline-primary': video_style != 'vlog',
          }"
          @click.prevent="changeStyle('vlog')"
        >
          <h1 class="p-0 m-0">
            <i class="bi-mic-fill" style="font-size: 6rem"></i>
          </h1>
          <h4 class="text-light">{{ $t("new-project.someone-is-talking") }}</h4>
        </button>
        <div class="form-group mt-3" v-if="isVlog">
          <label>{{ $t("new-project.spoken-language") }}</label>
          <select class="form-control" v-model="model.locale">
            <option value=""></option>
            <option value="en-US">English</option>
            <option value="pt-BR">Português</option>
            <option value="de-DE">Deutsch</option>
            <option value="es-ES">Español</option>
            <option value="fr-FR">Français</option>
            <option value="it-IT">Italiano</option>
            <option value="ar-SA">عربى</option>
            <option value="fil-PH">Filipino</option>
            <option value="hi-IN">Hindi</option>
          </select>
        </div>
      </div>
      <div class="col-sm-4 text-center">
        <button
          type="button"
          class="btn btn-block border-primary"
          :class="{
            'btn-primary': video_style == 'no_speech',
            'btn-outline-primary': video_style != 'no_speech',
          }"
          @click.prevent="changeStyle('no_speech')"
        >
          <h1 class="p-0 m-0">
            <i class="bi-volume-up" style="font-size: 6rem"></i>
          </h1>
          <h4 class="text-light">{{ $t("new-project.no-voice") }}</h4>
        </button>
      </div>
      <div class="col-sm-4 text-center">
        <button
          type="button"
          class="btn btn-block border-primary"
          :class="{
            'btn-primary': video_style == 'mute_videos',
            'btn-outline-primary': video_style != 'mute_videos',
          }"
          @click.prevent="changeStyle('mute_videos')"
        >
          <h1 class="p-0 m-0">
            <i class="bi-volume-mute" style="font-size: 6rem"></i>
          </h1>
          <h4 class="text-light">{{ $t("new-project.no-sound") }}</h4>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "new-project-style",

  props: ["video_style", "model", "changeStyle", "isVlog", "setIsVlog", "changeNav"],

  mounted() {
    if (this.video_style == "vlog") {
      this.setIsVlog(true);
    } else {
      this.setIsVlog(false);
    }
  },

  watch: {
    video_style: function (val) {
      if (val == "vlog") {
        this.setIsVlog(true);
      } else {
        this.setIsVlog(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  &.btn-block {
    height: 25rem;
  }
}
</style>
