<template>
  <div class="card-media mb-4">
    <div class="text-right">
      <a href="#" class="m-0 p-0" @click.prevent="removeFile(file, index)">
        <SvgIcon name="trash" stroke="#ffffff" width="18px" />
      </a>
    </div>

    <div class="card-media__background">
      <video
        v-if="file.progress == 0 || file.progress >= 100"
        class="rounded border border-dark"
        style="width: 100%; max-height: 120px"
        :id="`videoToUpload_${index}`"
        :src="file.utype == 'library' ? file.data.export_raw_url + '#t=2' : URL.createObjectURL(file.data) + '#t=2'"
        muted
      >
        Your browser does not support the video tag.
      </video>
    </div>

    <div class="card-media__title">
      <h4 v-if="file.progress > 0 || file.progress < 100" class="text-primary rounded border border-dark mt-3 mb-2 text-center">{{ file.progress }}%</h4>
      <p class="text-truncate text-default" :class="{ 'text-success': file.progress >= 100 }">
        <b v-if="file.progress >= 100">{{ file.progress }}% - </b>{{ file.data.name }}
      </p>
    </div>

    <div class="progress" style="height: 3px">
      <div
        class="progress-bar progress-bar-striped"
        :class="{ 'bg-success': file.progress >= 100 }"
        role="progressbar"
        :style="`width: ${file.progress}%`"
        :aria-valuenow="file.progress"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/icon/SvgIcon.vue'

export default {
  name: 'new-project-video-component',

  props: ['file', 'index', 'removeFile'],

  components: {
    SvgIcon,
  },

  data() {
    return {
      URL: URL,
    }
  },

  methods: {
    timestampToString(seconds) {
      return new Date(seconds * 1000).toISOString().substr(11, 8)
    },
  },
}
</script>
