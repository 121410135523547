<template>
  <div class="video-player">
    <video
      ref="videoPlayer"
      class="video-js"
      type="video/*"
      controls="controls"
      controlslist="nodownload"
      :src="video"
      preload="auto"
      :style="`height: ${height}px`"
      loop="false"
    >
      <track
        v-if="subtitles"
        :src="subtitles"
        kind="subtitles"
        default="true"
        label="Subtitles"
      />
    </video>
  </div>
</template>

<script>
import videojs from "video.js";

export default {
  name: "video-player",

  props: [
    "thumbnail",
    "video",
    "subtitles",
    "start",
    "end",
    "autoplay",
    "height",
    "playing",
  ],

  data() {
    return {
      player: null,
      currentTime: 0,
    };
  },

  mounted() {
    const self = this;
    this.player = videojs(
      this.$refs.videoPlayer,
      {
        autoplay: false,
        controls: true,
        poster: this.thumbnail,
        loop: false,
        sources: [
          {
            src: this.video,
            type: "video/mp4",
          },
        ],
      },
      function onPlayerReady() {
        let settings = this.textTrackSettings;
        settings.setValues({
          backgroundColor: "#000",
          backgroundOpacity: "0",
          edgeStyle: "uniform",
          fontPercent: 1.3,
        });
        settings.updateDisplay();

        this.on("timeupdate", function () {
          self.currentTime = this.currentTime();
        });
      }
    );
  },

  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<style lang="scss">
@import "~video.js/dist/video-js.css";
.project-preview-view {
  .video-js {
    max-height: 24.625rem !important;
  }
}

.video-js {
  width: 100%;
}

.video-js .vjs-big-play-button {
  left: calc(50% - 45px);
  top: calc(50% - 24.5px);
}

@media only screen and (max-width: 480px) {
  .video-fixed-height .video-js {
    height: 160px;
  }
}

.video-window {
  padding: 10px;
  cursor: default;
  margin: 20px;
  border-radius: 8px;
  color: var(--theme-primary-50) !important;
  background-color: var(--theme-fusion-800);

  .video-player,
  .video-player .mejs__container {
    background-color: #000;
  }

  .video-options {
    margin-top: 30px;
  }

  .btn-group {
    margin: 0px !important;

    button {
      width: 50%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .video-window {
    padding: 5px;
    margin: 10px;
  }
}
</style>
