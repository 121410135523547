<template>
  <div id="music-picker">
    <hr class="border-dark" />
    <div v-if="loading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-if="!loading">
      <div class="row align-items-center">
        <div class="col-sm-8">
          <h5>
            <span v-if="!bgm">{{ $t('new-project.pick-a-song') }}</span>
            <span v-if="bgm">
              <small class="text-primary">{{ $t('new-project.selected-song') }}:</small>
              {{ bgm.title }}
              <small class="text-default">{{ bgm.author }}</small>
            </span>
          </h5>
        </div>

        <div class="col-sm-4">
          <div class="input-group wrapper-input-search">
            <input type="text" class="form-control" @keyup="filterSongs" v-model="pattern" :placeholder="$t('project-edit.search-music')" />
            <div class="input-group-append">
              <button class="btn btn-secondary px-0" type="button" @click.prevent="clearSearch" v-if="pattern != ''">
                <span class="material-icons-round md-18">clear</span>
              </button>
              <button class="btn btn-secondary" type="button" @click.prevent="filterSongs">
                <span class="material-icons-round md-18">search</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <hr class="border-dark" />

      <ul class="list-group list-group-flush">
        <li class="list-group-item" v-for="song in songs" :key="song.id">
          <div class="row">
            <div class="col-2 col-sm-1 text-center">
              <button type="button" class="btn btn-link p-0 m-0" @click.prevent="pickSong(song)">
                <h4 class="p-0 mr-2 ml-2 mb-0 mt-0">
                  <i
                    :class="{
                      'bi-square': song.id != bgm_id,
                      'bi-check-square-fill text-success': song.id == bgm_id,
                    }"
                  ></i>
                </h4>
              </button>
            </div>
            <div class="col-2 col-sm-1 text-center">
              <button
                type="button"
                class="btn btn-link p-0 m-0"
                @click.prevent="playSong(song)"
                :disabled="showAudioPlayer && currentSong.id == song.id"
                :class="{
                  'text-default': showAudioPlayer && currentSong.id == song.id,
                }"
              >
                <h4 class="p-0 mr-2 ml-2 mb-0 mt-0">
                  <SvgIcon name="play" stroke="#ffffff" width="24px" v-if="!showAudioPlayer || currentSong.id != song.id" />
                  <SvgIcon name="sound" stroke="#ffffff" width="24px" v-if="showAudioPlayer && currentSong.id == song.id" />
                </h4>
              </button>
            </div>

            <div class="col-7 col-sm-9">
              <p class="p-0 m-0">
                {{ song.title }}
                <br /><small class="text-default">{{ song.author }}</small>
              </p>
            </div>
            <div class="col-1 col-sm-1 text-right" v-if="menu">
              <h4>
                <SvgIcon name="info-octogon" stroke="#5E52F5" width="24px" :id="`popover-music-target-${song.id}`" />
                <b-popover :target="`popover-music-target-${song.id}`" triggers="hover" placement="top">
                  <template #title>{{ $t('project-edit.music-info') }}</template>
                  <div>
                    <b>{{ $t('project-edit.genres') }}</b
                    ><br />
                    {{ song.genre_tags }}
                  </div>
                  <hr class="border-light" />
                  <div>
                    <b>{{ $t('project-edit.themes') }}</b>
                    <br />
                    {{ song.video_theme_tags }}
                  </div>
                  <hr class="border-light" />
                  <div>
                    <b>{{ $t('project-edit.instruments') }}</b>
                    <br />
                    {{ song.instrument_tags }}
                  </div>
                </b-popover>
              </h4>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import SvgIcon from '@/components/icon/SvgIcon.vue'

export default {
  name: 'music-picker',

  props: ['bgm_id', 'pickSong'],

  components: {
    SvgIcon,
  },

  data() {
    return {
      loading: true,
      bgm: null,
      pattern: '',
      songs: [],
    }
  },

  computed: {
    ...mapGetters({
      all: 'bgm/all',
      currentSong: 'common/song',
      showAudioPlayer: 'common/showAudioPlayer',
      menu: 'common/menu',
    }),
  },

  async mounted() {
    if (!this.all) {
      await this.getAll()
    }
    this.loading = false
    if (this.bgm_id) {
      this.bgm = this.all.find((x) => x.id === this.bgm_id)
    }
    this.filterSongs()
  },

  methods: {
    ...mapMutations({
      toggleAudioPlayer: 'common/TOGGLE_AUDIO_PLAYER',
      setSong: 'common/SET_SONG',
    }),

    ...mapActions({
      getAll: 'bgm/GET_ALL',
    }),

    filterSongs() {
      if (this.pattern == '') {
        this.songs = this.all
      } else {
        this.songs = []
        for (let i = 0; i < this.all.length; i++) {
          if (this.all[i].title.toLowerCase().indexOf(this.pattern.toLowerCase()) > -1) {
            this.songs.push(this.all[i])
          } else if (this.all[i].author.toLowerCase().indexOf(this.pattern.toLowerCase()) > -1) {
            this.songs.push(this.all[i])
          } else if (this.all[i].genre_tags.toLowerCase().indexOf(this.pattern.toLowerCase()) > -1) {
            this.songs.push(this.all[i])
          } else if (this.all[i].instrument_tags.toLowerCase().indexOf(this.pattern.toLowerCase()) > -1) {
            this.songs.push(this.all[i])
          } else if (this.all[i].video_theme_tags.toLowerCase().indexOf(this.pattern.toLowerCase()) > -1) {
            this.songs.push(this.all[i])
          }
        }
      }
    },

    playSong(song) {
      this.toggleAudioPlayer(false)
      this.setSong(null)
      setTimeout(() => {
        this.setSong(song)
        this.toggleAudioPlayer(true)
      }, 200)
    },

    clearSearch() {
      this.pattern = ''
      this.filterSongs()
    },
  },

  watch: {
    bgm_id: function (val) {
      if (val && this.all) {
        this.bgm = this.all.find((x) => x.id === this.bgm_id)
      } else {
        this.bgm = null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';
.list-group-item {
  border-bottom: 1px solid $gray-900;
}
</style>
