import { responseHandle } from "./handle"
import fetch from "./fetch"

export default {
  getAll() {
    const endpoint = `/bgms`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  getById(id) {
    const endpoint = `/bgms/${id}`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  updateById(id, payload) {
    const endpoint = `/bgms/${id}`
    return fetch
      .put(endpoint, payload)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },
}
