<template>
  <nav class="navbar navbar-expand-lg navbar-dark">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img src="@/assets/images/logo.svg" height="28" />
      </a>

      <ul class="navbar-nav ml-auto"></ul>
      <router-link :to="registerUrl" class="btn btn-sm btn-primary" v-if="this.server != 'beta'">{{$t('login.create-account')}}</router-link>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'login-topbar',

  data() {
    return {
      server: '',
      registerUrl: ''
    }
  },

  mounted() {
    this.registerUrl = '/register';

    switch(window.location.host.split('.')[0]) {
      case 'localhost:8080':
        this.server = 'local';
        break;
      case 'beta':
        this.server = 'beta';
        break;
      case 'app':
        this.server = 'production';
        break;
    }

    window.ire('identify', {customerId: '', customerEmail: ''});
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/colors.scss";
  .navbar {
    box-shadow: 0 0 50px 7px $black
  }
</style>
