import api from "@/api"

const getters = {}

const mutations = {}

const actions = {
  UPDATE_BY_ID: ({ dispatch }, scene) => {
    return new Promise(resolve => {
      return api.scene
        .updateById(scene.id, scene)
        .then(result => {
          dispatch('project/GET_STORYBOARD', {
            id: scene.project_id,
            scenesIds: [scene.id]
          }, { root: true })
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  CHANGE_TYPE: ({ dispatch }, { scene, type }) => {
    return new Promise(resolve => {
      return api.scene
        .changeType(scene.id, type)
        .then(async result => {
          await dispatch('project/GET_STORYBOARD', {
            id: scene.project_id,
            scenesIds: [scene.id]
          }, { root: true })
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  CHANGE_ORDER: ({ dispatch }, { scene, order }) => {
    return new Promise(resolve => {
      return api.scene
        .changeOrder(scene.id, order)
        .then(async result => {
          await dispatch('project/GET_BY_ID', scene.project_id, { root: true })
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  SPLIT_BY_LINE: ({ dispatch }, { scene, line }) => {
    return new Promise(resolve => {
      return api.scene
        .splitByLine(scene.id, line)
        .then(async result => {
          await dispatch('project/GET_BY_ID', scene.project_id, { root: true })
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  SPLIT_BY_POSITION: ({ dispatch }, { scene, position }) => {
    return new Promise(resolve => {
      return api.scene
        .splitByPosition(scene.id, position)
        .then(async result => {
          await dispatch('project/GET_BY_ID', scene.project_id, { root: true })
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  MERGE_NEXT_SCENE: ({ dispatch }, scene) => {
    return new Promise(resolve => {
      return api.scene
        .mergeNextScene(scene.id)
        .then(async result => {
          await dispatch('project/GET_BY_ID', scene.project_id, { root: true })
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  SHOW: ({ dispatch }, scene) => {
    return new Promise(resolve => {
      return api.scene
        .show(scene.id)
        .then(async result => {
          await dispatch('project/GET_STORYBOARD', {
            id: scene.project_id,
            scenesIds: [scene.id]
          }, { root: true })
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  HIDE: ({ dispatch }, scene) => {
    return new Promise(resolve => {
      return api.scene
        .hide(scene.id)
        .then(async result => {
          await dispatch('project/GET_STORYBOARD', {
            id: scene.project_id,
            scenesIds: [scene.id]
          }, { root: true })
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  TRIM: ({ dispatch }, scene) => {
    return new Promise(resolve => {
      return api.scene
        .trim(scene.id, scene)
        .then(async result => {
          await dispatch('project/GET_STORYBOARD', {
            id: scene.project_id,
            scenesIds: [scene.id]
          }, { root: true })
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  DUPLICATE: ({ dispatch }, scene) => {
    return new Promise(resolve => {
      return api.scene
        .duplicate(scene.id)
        .then(async result => {
          await dispatch('project/GET_BY_ID', scene.project_id, { root: true })
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  }
}

const state = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
