<template>
  <div>
    <nav class="top-bar navbar navbar-expand-lg navbar-dark d-flex fixed-top">
      <div class="d-flex align-items-start" v-if="!menu">
        <a href="#" v-b-toggle.sidebar-border class="text-white m-0 mr-3">
          <h4 class="m-0">
            <i class="bi-list"></i>
          </h4>
        </a>
        <router-link class="navbar-brand m-0 p-0" to="/">
          <img src="@/assets/images/logo.svg" height="30" />
        </router-link>
      </div>

      <div class="d-none d-md-block">
        <div class="buttons-group">
          <router-link to="/open" class="btn btn-secondary">
            <SvgIcon name="list-details" />
            {{ $t('home.project-list') }}
          </router-link>

          <router-link to="/newproject" class="btn btn-primary">
            <SvgIcon name="file-plus" />
            {{ $t('home.new-project') }}
          </router-link>
        </div>
      </div>

      <ul class="navbar-nav ml-auto d-flex align-items-center flex-row">
        <li class="nav-item badge-beta badge-beta--hidden-xs mr-3" v-if="server == 'beta'">
          <a class="nav-link" :href="appUrl" target="_blank">
            <span class="badge badge-info">{{ $t('topbar.see-prod-version') }}</span>
          </a>
        </li>

        <li v-if="me" class="nav-item upgrade-storage">
          <span class="badge badge-dark">
            <SvgIcon name="upload" stroke="#9999B5" />
            <span class="badge__used">{{ (me.total_used_storage / 1024 ** 3).toFixed(1) }} GB </span>
            <span class="badge__max">/ {{ (me.max_storage / 1024 ** 3).toFixed(1) }} GB </span>
          </span>
        </li>
        <li v-if="me && me.video_tier < 1" class="nav-item upgrade-link">
          <router-link to="/account" class="btn btn-sm">
            {{ $t('project-edit.upgrade-now') }}
          </router-link>
        </li>
        <b-nav-item-dropdown right v-if="me" no-caret class="text-right p-0">
          <template #button-content>
            <v-gravatar :email="me.email" height="26" class="p-0 m-0 border border-light rounded-circle mr-1" />
            <b class="text-light d-none d-lg-block">{{ me.name }}</b>
            <i class="bi-chevron-down"></i>
          </template>
          <b-dropdown-item href="#" disabled>
            <b class="text-light">{{ me.name }}</b>
            <small class="text-default">
              <strong> {{ me.email }}</strong>
            </small>
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <div class="p-2">
            <select class="form-control form-control-sm" @change="localeChange">
              <option value="en" :selected="me.locale == 'en'">English</option>
              <option value="pt-BR" :selected="me.locale == 'pt-BR'">Português</option>
              <option value="es" :selected="me.locale == 'es'">Español</option>
              <option value="it" :selected="me.locale == 'it'">Italiano</option>
              <option value="fr" :selected="me.locale == 'fr'">Français</option>
              <option value="de" :selected="me.locale == 'de'">Deutsch</option>
            </select>
          </div>

          <b-dropdown-divider></b-dropdown-divider>

          <b-dropdown-item to="/account">
            <SvgIcon name="user" stroke="#fff" />
            {{ $t('topbar.my-plan') }}
          </b-dropdown-item>

          <b-dropdown-item href="#" @click.prevent="promptPWA" v-if="pwa && !appInstalled">
            <SvgIcon name="download" stroke="#fff" />
            {{ $t('topbar.install-app') }}
          </b-dropdown-item>

          <b-dropdown-divider></b-dropdown-divider>

          <b-dropdown-item href="#" @click.prevent="tryLogout">
            <SvgIcon name="logout" stroke="#fff" />
            {{ $t('topbar.exit') }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </ul>

      <b-sidebar class="sidebar" v-if="!menu" id="sidebar-border" backdrop no-header bg-variant="dark" text-variant="light">
        <template #default="{ hide }">
          <div class="px-3 py-4 relative">
            <img src="@/assets/images/logo.svg" height="24" />
            <button class="btn btn-link btn-close" @click="hide()">
              <SvgIcon name="cancel" stroke="#ffffff" />
            </button>
          </div>

          <div class="list-group">
            <router-link id="menu-home" to="/" exact class="list-group-item list-group-item-action" active-class="active">
              <SvgIcon name="home" />
              <div class="list-group-item__title">
                {{ $t('menu.home') }}
              </div>
            </router-link>

            <router-link id="menu-new-project" to="/newproject" class="list-group-item list-group-item-action" active-class="active">
              <SvgIcon name="file-plus" />
              <div class="list-group-item__title">
                {{ $t('menu.new-project') }}
              </div>
            </router-link>

            <router-link id="home-project-list" to="/open" class="list-group-item list-group-item-action" active-class="active">
              <SvgIcon name="list-details" />
              <div class="list-group-item__title">
                {{ $t('menu.project-list') }}
              </div>
            </router-link>

            <router-link id="home-library" to="/library" class="list-group-item list-group-item-action" active-class="active">
              <SvgIcon name="camera" />
              <div class="list-group-item__title">
                {{ $t('menu.media') }}
              </div>
            </router-link>

            <a href="https://help.wisecut.video/" target="_blank" id="home-help" class="list-group-item list-group-item-action" active-class="active">
              <SvgIcon name="help" />
              <div class="list-group-item__title">
                {{ $t('menu.help') }}
              </div>
            </a>
          </div>
        </template>
      </b-sidebar>

      <div class="row mx-0 mt-4 w-100 d-xs-block d-sm-block d-md-none">
        <div class="buttons-group justify-content-end">
          <router-link to="/open" class="btn btn-secondary">
            <SvgIcon name="list-details" />
            {{ $t('home.project-list') }}
          </router-link>

          <router-link to="/newproject" class="btn btn-primary">
            <SvgIcon name="file-plus" />
            {{ $t('home.new-project') }}
          </router-link>
        </div>
        <div class="badge-beta badge-beta--visible-xs ml-auto">
          <a class="nav-link" :href="appUrl" target="_blank" v-if="server == 'beta'">
            <span class="badge badge-info">{{ $t('topbar.see-prod-version') }}</span>
          </a>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import SvgIcon from '@/components/icon/SvgIcon.vue'

export default {
  name: 'top-bar',

  data() {
    return {
      server: '',
      appUrl: '',
      pwa: null,
      appInstalled: false,
    }
  },

  components: {
    SvgIcon,
  },

  computed: {
    ...mapGetters({
      me: 'user/me',
      menu: 'common/menu',
      selected: 'project/selected',
      tawktoHash: 'user/tawktoHash',
      impactHash: 'user/impactHash',
    }),
  },

  async mounted() {
    this.triggerPWAListeners()
    this.appUrl = process.env.VUE_APP_MAIN_URL
    switch (window.location.host.split('.')[0]) {
      case 'localhost:8080':
        this.server = 'local'
        break
      case 'beta':
        this.server = 'beta'
        break
      case 'app':
        this.server = 'production'
        break
    }
    await this.getMe()
    this.$i18n.locale = this.me.locale
    await this.getTawktoHash()
    if (Tawk_API && Tawk_API.setAttributes) {
      Tawk_API.setAttributes({
        name: this.me.name,
        email: this.me.email,
        tier: this.me.video_tier ? 'Premium' : 'Free',
        locale: this.me.locale,
        hash: this.tawktoHash,
      })
    }
    window.analytics.identify(this.me.id, {
      name: this.me.name,
      email: this.me.email,
      isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
      createdAt: this.me.created_at,
    })

    await this.getImpactHash()
    window.ire('identify', {
      customerId: this.me.id,
      customerEmail: this.impactHash,
    })
  },

  methods: {
    ...mapActions({
      logout: 'user/LOGOUT',
      changeLocale: 'user/CHANGE_LOCALE',
      getMe: 'user/GET_ME',
      getTawktoHash: 'user/GET_TAWKTO_HASH',
      getImpactHash: 'user/GET_IMPACT_HASH',
    }),

    localeChange(e) {
      this.tryChangeLocale(e.target.value)
    },

    triggerPWAListeners() {
      window.addEventListener('appinstalled', (e) => {
        this.appInstalled = true
      })
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault()
        this.pwa = e
      })
    },

    promptPWA() {
      this.pwa.prompt()
      this.pwa.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          this.appInstalled = true
        } else {
          this.appInstalled = false
        }
      })
    },

    async tryChangeLocale(locale) {
      await this.changeLocale(locale)
      this.$i18n.locale = locale
    },

    tryLogout() {
      localStorage.setItem('user', null)
      this.$router.push('/login')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';

.badge-beta {
  @media screen and (max-width: 1114px) {
    .badge {
      white-space: normal;
    }
    width: 103px;
  }
  &--hidden-xs {
    display: block;
    @media screen and (max-width: 760px) {
      display: none;
    }
  }
  &--visible-xs {
    display: none;
    @media screen and (max-width: 760px) {
      display: block;
      width: fit-content;
    }
    @media screen and (max-width: 560px) {
      display: none;
    }
  }
}

.sidebar {
  .btn-close {
    position: absolute;
    right: 0;
    top: 12px;
  }
  .b-sidebar-body {
    display: flex;
    flex-direction: column;
  }
  .list-group-item {
    background: transparent;
    display: flex;
    gap: 17px;
    border: 0;
    padding: 1.1rem 1.25rem;
    color: $gray-450;
    svg {
      stroke: $white;
    }
    &.active,
    &:hover {
      border: none;
      background: $gray-800;
      color: $white;
      svg {
        stroke: $pink;
      }
    }
  }
}
.top-bar {
  background-color: $black;
  padding-left: calc(5.625rem + 60px);
  padding-right: 40px;
  padding-top: 39px;
  transition: 0.3s;
  width: 100%;
  min-height: 114px;
  @media screen and (min-width: 1025px) and (max-width: 1290px) {
    padding-right: 30px;
  }
  @media screen and (max-width: 768px) {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.upgrade-storage {
  margin-right: 20px;
  @media screen and (max-width: 600px) {
    display: none;
  }
  .badge {
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    &__used {
      font-size: 14px;
      line-height: 21px;
      color: $white;
    }
    &__max {
      color: $gray-450;
      font-size: 12px;
    }
  }
}

.buttons-group {
  display: flex;
  gap: 1.25rem;
  @media screen and (max-width: 1090px) {
    gap: 0.75rem;
    .btn {
      padding: 11px 12px;
      gap: 5px;
    }
  }
}

::v-deep {
  .dropdown {
    position: relative;
    display: inline-block;
    .dropdown-toggle-no-caret {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .dropdown-toggle {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 0;
      img {
        width: 44px;
        height: 44px;
      }
      b {
        font-size: 0.875rem;
      }
    }
  }
  .dropdown-menu {
    background-color: $gray-900;
    .dropdown-divider {
      border-color: $gray-800;
    }
    .dropdown-item {
      display: flex;
      gap: 8px;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}
</style>
