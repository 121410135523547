export const getters = {
  logged: (state) => {
    return state.logged
  },

  menu: (state) => {
    return state.menu
  },

  showAudioPlayer: (state) => {
    return state.showAudioPlayer
  },

  song: (state) => {
    return state.song
  },
}

export const mutations = {
  TOGGLE_LOGGED(state) {
    state.logged = !state.logged
  },

  TOGGLE_AUDIO_PLAYER(state, param) {
    state.showAudioPlayer = param
  },

  SET_SONG(state, song) {
    state.song = song
  },

  SHOW_MENU(state) {
    state.menu = true
  },

  HIDE_MENU(state) {
    state.menu = false
  },

  SET_LOGGED(state) {
    state.logged = true
  },

  SET_UNLOGGED(state) {
    state.logged = false
  },

  SET_INTERVAL(state, interval) {
    state.interval = interval
  },

  CLEAR_INTERVAL(state) {
    clearInterval(state.interval)
  }
}

const state = {
  logged: false,
  menu: true,
  showAudioPlayer: false,
  song: null,
  interval: null,
}

const namespaced = true

export default {
  namespaced,
  state,
  getters,
  mutations,
}
