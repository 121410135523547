<template>
  <section>
    <div v-if="loading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-if="!loading">
      <div
        class="alert alert-info text-center"
        role="alert"
        v-if="
          needsRender == 'true' &&
          ['new', 'Working', 'Rendering', 'Final Rendering', 'Rdg Request'].findIndex((x) => x == selected.project.status) < 0
        "
      >
        {{ $t('project-edit.needs-render') }}
        <button type="button" class="btn btn-sm btn-outline mr-auto ml-auto mt-3" @click.prevent="tryRender">
          <i class="bi-arrow-repeat"></i> {{ $t('project-edit.render-now') }}
        </button>
      </div>
      <div class="row justify-content-start">
        <div class="col-sm-4" v-if="selected.project.status == 'Preview Ready' || selected.project.status == 'Render Ready'">
          <div class="share-video-player">
            <VideoPlayer
              :thumbnail="selected.project.thumbnailPath"
              :video="selected.project.export_url"
              :subtitles="null"
              v-if="showVideo"
            />
          </div>
          <p v-if="selected.project.status == 'Render Ready'" class="text-center project-info">
            <button type="button" class="btn btn-secondary" @click.prevent="tryReopen">
              <SvgIcon name="pencil" stroke="#ffffff" />
              {{ $t('project-edit.reopen-project') }}
            </button>
          </p>
        </div>
        <div class="col-sm-6 text-center">
          <Rendering v-if="['new', 'Working', 'Rendering', 'Final Rendering', 'Rdg Request'].findIndex((x) => x == selected.project.status) > -1" />
          <Preview
            v-else-if="selected.project.status == 'Preview Ready'"
            :again="false"
            :downloadSubtitles="downloadSubtitles"
            :tryExport="tryExport"
            :selected="selected"
            :model="model"
            :tryUpdate="tryUpdate"
          />
          <div v-else>
            <h4 class="text-left">
              {{ $t('project-edit.your-project-is-done') }}
            </h4>
            <Youtube v-if="selected.project.status == 'Render Ready'" :selected="selected" />
            <Export
              v-if="selected.project.status == 'Render Ready'"
              :selected="selected"
              :tryReopen="tryReopen"
              :downloadSubtitles="downloadSubtitles"
            />
            <Preview
              :again="true"
              :downloadSubtitles="downloadSubtitles"
              :tryExport="tryExport"
              :selected="selected"
              :model="selected.project"
              :tryUpdate="tryUpdate"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Rendering from '../components/Rendering'
import Preview from '../components/Preview'
import Export from '../components/ExportOld'
import Youtube from '../components/YoutubeOld'
import VideoPlayer from '@/components/VideoPlayer'
import SvgIcon from '@/components/icon/SvgIcon.vue'

export default {
  name: 'edit-project-share',

  props: ['needsRender', 'tryRender', 'model', 'tryUpdate'],

  components: {
    Rendering,
    Preview,
    Export,
    Youtube,
    VideoPlayer,
    SvgIcon
  },

  data() {
    return {
      loading: false,
      showVideo: false,
      subtitles: null,
    }
  },

  computed: {
    ...mapGetters({
      selected: 'project/selected',
    }),
  },

  mounted() {
    if (this.selected) {
      this.subtitles = this.selected.project.final_render != 0 ? null : this.selected.project.subtitles.vtt
      this.showVideo = true
    }
  },

  methods: {
    ...mapActions({
      render: 'project/RENDER',
      reopen: 'project/REOPEN',
    }),

    downloadSubtitles() {
      window.analytics.track('Project Subtitles Downloaded', {
        projectId: this.selected.project.id,
        name: this.selected.project.name,
      })
      window.open(`${process.env.VUE_APP_WEB_URL}/projects/${this.selected.project.id}/subtitles`, '_blank')
    },

    async tryExport({ aspectRatio, localeSubtitles, resolution, exportVideo }) {
      this.loading = true
      if (!exportVideo || exportVideo == null) {
        exportVideo = 0
      }

      await this.render({
        id: this.selected.project.id,
        payload: {
          final: true,
          locale: localeSubtitles,
          aspect: aspectRatio == '' ? null : aspectRatio,
          tier: parseInt(resolution),
          exportvideo: parseInt(exportVideo),
        },
      })
      localStorage.setItem(`needsRender_${this.selected.project.id}`, 'false')
      this.$router.push(`/projects/${this.selected.project.id}/preview`)
    },

    async tryReopen() {
      this.loading = true
      await this.reopen(this.selected.project.id)
      this.$router.push(`/projects/${this.selected.project.id}/storyboard`)
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';

.share-video-player {
  position: sticky;
  height: 285px;
  overflow: hidden;
  border-radius: 10px;
  top: 112px;
  margin-bottom: 20px;
}
::v-deep {
  .share-video-player,
  .video-player,
  .video-js {
    height: 285px;
  }
}

.project-info {
  position: sticky;
  top: 416px;
}
</style>
