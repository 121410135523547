<template>
  <div class="project-tools">
    <div class="custom-control custom-switch">
      <input type="checkbox" class="custom-control-input" id="enableHideRemovedScenes" v-model="enableHideRemovedScenesLocal" @change="emitChanges" />
      <label class="custom-control-label" for="enableHideRemovedScenes">
        <p>{{ $t('project-edit.enable-hide-removed-scenes') }}</p>
      </label>
    </div>
    <div class="custom-control custom-switch">
      <input type="checkbox" class="custom-control-input" id="enableDrag" v-model="enableDragLocal" @change="handleDragChange" />
      <label class="custom-control-label" for="enableDrag">
        <p>{{ $t('project-edit.drag-to-reorder') }}</p>
      </label>
    </div>
    <div class="custom-control custom-switch">
      <input type="checkbox" class="custom-control-input" id="enableSelect" v-model="enableSelectLocal" @change="handleSelectChange" />
      <label class="custom-control-label" for="enableSelect">
        <p>{{ $t('project-edit.enable-select') }}</p>
      </label>
    </div>
    <!-- <button type="button" class="btn btn-lg btn-link p-0 ml-auto" style="font-size: 2rem" @click="$emit('showModalInfo')">
      <SvgIcon name="question" stroke="#9999B5" />
    </button> -->
  </div>
</template>

<script>
import SvgIcon from '@/components/icon/SvgIcon.vue'

export default {
  props: ['enableDrag', 'enableSelect', 'enableHideRemovedScenes'],
  data() {
    return {
      enableDragLocal: this.enableDrag,
      enableSelectLocal: this.enableSelect,
      enableHideRemovedScenesLocal: this.enableHideRemovedScenes,
    }
  },
  components: {
    SvgIcon,
  },
  methods: {
    emitChanges() {
      this.$emit('update:enableHideRemovedScenes', this.enableHideRemovedScenesLocal)
    },
    handleDragChange() {
      this.$emit('update:enableDrag', this.enableDragLocal)
      this.enableSelectLocal = false
    },
    handleSelectChange() {
      this.$emit('update:enableSelect', this.enableSelectLocal)
      this.enableDragLocal = false
    },
  },
  watch: {
    enableDrag(val) {
      this.enableDragLocal = val
    },
    enableSelect(val) {
      this.enableSelectLocal = val
    },
    enableHideRemovedScenes(val) {
      this.enableHideRemovedScenesLocal = val
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';
.project-tools {
  position: sticky;
  top: 114px;
  z-index: 999;
  background: $black;
  display: flex;
  gap: 50px;
  padding-bottom: 15px;
  @media screen and (max-width: 1720px) {
    gap: 20px;
  }
  @media screen and (max-width: 1022px) {
    position: relative;
    top: 0;
    z-index: 0;
  }
  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
}
</style>
