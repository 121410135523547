<template>
  <div class="mb-3">
    <hr class="border-danger" />
    <div class="text-center" v-if="loading">
      <div class="spinner-border text-danger" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-if="!loading">
      <h5 class="text-left">
        {{ $t('project-edit.share-on') }}
        <i class="bi-youtube"></i> YouTube
      </h5>
      <p class="text-left">
        <a href="https://www.wisecut.video/terms" target="_blank">{{ $t('project-edit.terms') }}</a>
      </p>
      <div class="text-left" v-if="selected.project.youtube_export === null">
        <button type="button" class="btn btn-danger mb-3" @click.prevent="connectToYoutube" v-if="!connected">
          {{ $t('project-edit.connect-to') }} <i class="bi-youtube"></i>
          <b> YouTube</b>
        </button>
        <p v-if="!connected">
          Connecting to YouTube is temporarily unavailable. <span class="text-warning">We're making improvements to bring you a smoother and better integration soon.</span> Thank you for your patience!
        </p>
        <p>
          <button type="button" class="btn btn-danger" v-if="connected && !enabled" @click.prevent="enabled = true">
            {{ $t('project-edit.export-to') }} <i class="bi-youtube"></i>
            <b> YouTube</b>
          </button>
        </p>
        <p v-if="connected">
          Exporting to YouTube is temporarily unavailable. <span class="text-warning">We're making improvements to bring you a smoother and better integration soon.</span> Thank you for your patience!
        </p>
        <p>
          <button type="button" class="btn btn-default" @click.prevent="disconnectFromYoutube" v-if="connected">
            {{ $t('project-edit.disconnect-from') }} <i class="bi-youtube"></i>
            <b> YouTube</b>
          </button>
        </p>
      </div>
      <div class="text-center" v-else-if="selected.project.youtube_export === 'exporting'">
        <div>
          <div class="spinner-border text-danger" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <h6>
          {{ $t('project-edit.exporting-to') }}
          <i class="bi-youtube text-danger"></i> YouTube
        </h6>
      </div>
      <div v-else-if="!enabled && selected.project.youtube_export != 'exporting'" class="text-center">
        <p>{{ $t('project-edit.youtube-already-exported') }}.</p>
        <a target="_blank" :href="selected.project.youtubeUrl" class="btn btn-danger"
          >{{ $t('project-edit.view-at') }} <i class="bi-youtube"></i><b> YouTube</b></a
        >
        <br /><button type="button" class="btn btn-sm btn-link mt-2" @click.prevent="enabled = true">
          {{ $t('project-edit.youtube-export-again') }}
        </button>
      </div>
      <div v-if="enabled && selected.project.youtube_export != 'exporting' && connected">
        <div class="form-group text-left">
          <label>{{ $t('project-edit.video-name') }}</label>
          <input type="text" class="form-control form-control-sm" :class="{ 'is-invalid': $v.model.title.$error }" v-model="model.title" />
        </div>
        <div class="form-group text-left">
          <label>{{ $t('project-edit.video-description') }}</label>
          <textarea
            class="form-control form-control-sm"
            :class="{ 'is-invalid': $v.model.description.$error }"
            v-model="model.description"
          ></textarea>
        </div>
        <div class="form-group text-left">
          <label>{{ $t('project-edit.video-privacy') }}</label>
          <select class="form-control form-control-sm" :class="{ 'is-invalid': $v.model.privacy.$error }" v-model="model.privacy">
            <option value="public">{{ $t('project-edit.public') }}</option>
            <option value="private">{{ $t('project-edit.private') }}</option>
            <option value="unlisted">
              {{ $t('project-edit.unlisted') }}
            </option>
          </select>
        </div>
        <button type="button" class="btn btn-block btn-danger" @click.prevent="tryExportToYoutube">
          <i class="bi-youtube"></i> {{ $t('project-edit.share') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

export default {
  name: 'share-youtube-old',

  props: ['selected'],

  data() {
    return {
      connected: false,
      count: 0,
      enabled: false,
      loading: false,
      model: {
        title: '',
        description: '',
        privacy: '',
      },
    }
  },

  computed: {
    ...mapGetters({
      youtubeUrl: 'user/youtubeUrl',
    }),
  },

  mixins: [validationMixin],

  validations: {
    model: {
      title: {
        required,
      },
      description: {
        required,
      },
      privacy: {
        required,
      },
    },
  },

  async mounted() {
    await this.getYoutubeUrl()
    if (this.youtubeUrl.url) {
      this.connected = false
    } else {
      this.connected = true
    }
  },

  methods: {
    ...mapActions({
      getById: 'project/GET_BY_ID',
      getYoutubeUrl: 'user/GET_YOUTUBE_URL',
      disconnectYoutube: 'user/DISCONNECT_FROM_YOUTUBE',
      shareYoutube: 'project/SHARE_YOUTUBE',
    }),

    connectToYoutube() {
      window.analytics.track('YouTube Connection Started', {})
      window.open(this.youtubeUrl.url, '_blank')
      this.checkConnected()
    },

    async disconnectFromYoutube() {
      window.analytics.track('YouTube Disconnection Started', {})
      await this.disconnectYoutube()
      await this.getYoutubeUrl()
      this.connected = false
      this.count = 0
    },

    checkConnected() {
      if (this.count < 180 && !this.connected) {
        this.count++
        setTimeout(async () => {
          await this.getYoutubeUrl()
          if (this.youtubeUrl.url) {
            this.connected = false
            this.checkConnected()
          } else {
            this.connected = true
          }
        }, 1000)
      }
    },

    async tryExportToYoutube() {
      this.$v.model.$touch()
      if (this.$v.model.$error) return
      this.loading = true
      await this.shareYoutube({
        id: this.selected.project.id,
        payload: this.model,
      })
      this.enabled = false
      this.loading = false
    },
  },

  watch: {
    selected(val) {
      if (val.project.youtube_export == 'exporting') {
        setTimeout(() => {
          this.getById(val.project.id)
        }, 1000 * 30)
      }
    },
  },
}
</script>
