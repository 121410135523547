<template>
  <div class="current-plan">
    <div class="card card--minor">
      <h5 class="card-title mb-3">
        {{ $t('payment.subscription') }}
      </h5>

      <div class="text-center mt-2 mb-3" v-if="loading">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div v-else>
        <p v-if="me && me.ltd_tier" class="mb-0">
          <span v-if="me.appsumo_license_uuid" class="text-white mb-0">
            {{ $t('appsumo.appsumo') }} {{ $t('appsumo.license-tier') }} {{ me.ltd_tier.split('wisecut_tier')[1] }}
          </span>
          <span v-else class="text-white mb-0">LTD: {{ $t('ltd.lifetime-deal') }} {{ me.ltd_tier.split('ltd_tier')[1] }}</span>
          <small class="ml-2 text-default">
            {{ $t('payment.minutes-renews-at') }}
            {{ me.ltd_renew_at | moment(me.locale == 'en' ? 'MM/DD/YYYY' : 'DD/MM/YYYY') }}
          </small>
        </p>

        <p v-if="subscribedPlan && !(me && me.ltd_tier)" class="mb-0">
          <span class="text-white">{{ $t('pricing.your-current-plan') }}:</span>
          {{ $t(`pricing.${subscribedPlan.nickname.split('-')[0]}`) }}
          <span v-if="!subscribedPlan.active && subscribedPlan.amount > 0" class="text-warning">{{ $t('pricing.deprecated-plan') }}</span>
        </p>

        <div v-if="subscribedPlan">
          <div class="text-left">
            <div v-if="subscription.stripe_subscription.cancel_at" class="mt-2">
              <span class="text-warning">{{ $t('payment.subscription-wont-renew') }}</span>
              <br />
              <span class="mt-2 inline-block">
                {{ $t('payment.expires-in') }}:
                {{ (subscription.stripe_subscription.cancel_at * 1000) | moment(me.locale == 'en' ? 'MM/DD/YYYY' : 'DD/MM/YYYY') }}
              </span>
            </div>

            <div v-else class="text-default">
              <span
                class="mt-2"
                v-if="
                  subscription.stripe_subscription.current_period_end &&
                  subscribedPlan.amount > 0 &&
                  subscription.stripe_subscription.status != 'trialing'
                "
              >
                {{ $t('payment.renews-at') }}:
                {{ (subscription.stripe_subscription.current_period_end * 1000) | moment(me.locale == 'en' ? 'MM/DD/YYYY' : 'DD/MM/YYYY') }},
              </span>
              <span v-if="subscribedPlan.amount > 0 && !(me && me.ltd_tier)" class="mt-3 inline-block">
                US$ {{ (subscribedPlan.amount / 100).toFixed(2) }} /
                <span v-if="subscribedPlan.interval == 'year'">{{ $t('pricing.year') }}.</span>
                <span v-else-if="subscribedPlan.interval == 'month'">{{ $t('pricing.month') }}.</span>
              </span>
            </div>

            <p v-if="!subscribedPlan.active && subscribedPlan.amount > 0" class="text-warning">{{ $t('pricing.deprecated-plan') }}</p>

            <div class="mt-4" v-if="subscribedPlan.amount > 0 && !subscription.stripe_subscription.cancel_at">
              <button v-if="subscription.stripe_subscription.status == 'trialing'" @click="selectPlan(freePlan)" class="btn btn-sm btn-error">
                {{ $t('pricing.cancel-subscription') }}
              </button>
              <button v-else @click="selectPlan(freePlan)" class="btn btn-sm btn-error">{{ $t('payment.cancel-renewal') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-downgrading" centered hide-footer>
      <template #modal-header="{ close }">
        {{ $t('payment.downgrading') }}

        <button class="btn btn-link p-0" @click="close()">
          <SvgIcon name="cancel" stroke="#ffffff" />
        </button>
      </template>

      <div class="alert alert-danger">
        {{ $t('payment.confirm-downgrade') }}
      </div>

      <PlanDetails :selectedPlan="selectedPlan" v-if="selectedPlan" />

      <div class="d-flex justify-content-between mt-5">
        <button type="button" class="btn btn-block btn-primary" @click.prevent="updatePlan">
          {{ $t('payment.confirm') }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SvgIcon from '@/components/icon/SvgIcon.vue'
import PlanDetails from './PlanDetails'

export default {
  name: 'account-subscription',

  props: ['tryUpdateSubscription', 'payment_method', 'isLoading'],

  components: {
    PlanDetails,
    SvgIcon,
  },

  data() {
    return {
      loading: false,
      subscribedPlan: null,
      loadingCoupon: false,
      selectedPlan: null,
      couponInput: '',
      couponIsValid: null,
    }
  },

  computed: {
    ...mapGetters({
      me: 'user/me',
      subscription: 'user/subscription',
      freePlan: 'payment/freePlan',
    }),
  },

  mounted() {
    this.getAllRequests()
  },

  methods: {
    ...mapActions({
      getSubscription: 'user/GET_SUBSCRIPTION',
      getSubscription: 'user/GET_SUBSCRIPTION',
      getYearlyPlans: 'payment/GET_YEARLY_PLANS',
      getMonthlyPlans: 'payment/GET_MONTHLY_PLANS',
      getFreePlan: 'payment/GET_FREE_PLAN',
      getUpgradeLink: 'user/GET_UPGRADE_LINK',
      getCoupon: 'payment/GET_COUPON',
    }),

    async getAllRequests() {
      try {
        this.loading = true
        await this.getFreePlan()
        await this.getSubscription()
        await this.getYearlyPlans()
        await this.getMonthlyPlans()
        if (this.me.ltd_tier) {
          await this.getUpgradeLink()
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }

      this.setPlans()

      if (this.subscription) {
        this.subscribedPlan = this.subscription.stripe_subscription.plan
      }
    },

    async loadCoupon(code, planId) {
      if (code == '') {
        this.couponIsValid = null
        this.selectedPlan.coupon = null
        this.selectedPlan.amount = parseInt(this.selectedPlan.amount_decimal)
      } else {
        this.loadingCoupon = true
        await this.getCoupon({
          code: code,
          planId: planId,
        })
        this.loadingCoupon = false
      }
    },

    setPlans() {
      if (this.isAnnual) this.plans = this.yearlyPlans
      else this.plans = this.monthlyPlans
    },

    selectPlan(plan) {
      if (this.subscription.stripe_plan != plan.id) {
        this.selectedPlan = plan
        this.loadCoupon('', '')
        this.couponInput = ''
        if (process.env.NODE_ENV !== 'development' && !this.payment_method && plan.amount > 0) {
          this.$bvToast.toast(this.$t('payment.select-payment-method'), {
            variant: 'info',
            title: this.$t('payment.no-payment-method'),
            toaster: 'b-toaster-top-center',
            solid: true,
          })
        } else {
          if (plan.amount <= this.subscription.stripe_subscription.plan.amount) {
            this.$bvModal.show('modal-downgrading')
          } else {
            this.$bvModal.show('modal-upgrading')
          }
        }
      }
    },

    updatePlan() {
      this.$bvModal.hide('modal-downgrading')
      this.$bvModal.hide('modal-upgrading')
      this.tryUpdateSubscription(this.selectedPlan)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';

::v-deep .modal-body {
  padding-top: 0;
}
.current-plan {
  .card {
    p {
      font-size: 18px;
      color: #c917cd;
      font-weight: 600;
    }
  }
}
</style>
