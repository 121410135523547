<template>
  <div>
    <b-modal v-for="(_, idx) in confirmDelete" :key="idx" :id="`modal-delete-${idx}`" v-model="confirmDelete[idx]" centered hide-header hide-footer>
      <div class="text-center">
        <h6 class="mb-2 font-weight-bold">
          {{ $t('project-open.remove-project-confirmation') }}
        </h6>
        <p class="text-default mb-4">
          {{ $t('project-open.remove-project-tip') }}
        </p>

        <div class="d-flex justify-content-center mt-3">
          <button type="button" class="btn btn-sm btn-secondary mr-3" @click.prevent="closeModal(idx)">
            {{ $t('project-open.cancel') }}
          </button>
          <button type="button" class="btn btn-sm btn-danger" @click.prevent="tryRemove(project)">
            {{ $t('project-open.confirm') }}
          </button>
        </div>
      </div>
    </b-modal>

    <div class="list-group-item__list" :class="{ deleted: project.deleted_at }">
      <router-link :to="`/projects/${project.id}/preview`">
        <img class="img-thumbnail" :src="project.thumbnailPath || '/img/thumbnail.png'" />
      </router-link>

      <div class="list-group-item__content">
        <h5 class="list-group-item__title">{{ project.name }}</h5>

        <p v-if="project.deleted_at">
          {{ $t('project-open.project-deleted') }}
        </p>

        <div v-else class="list-group-item__infos">
          <div>
            <span class="badge badge-transparent">
              <SvgIcon name="clock" stroke="#9999B5" />
              {{ timestampToString(project.duration_out_seconds) }}
            </span>
            <span class="badge badge-transparent">
              <SvgIcon name="computer" stroke="#9999B5" />
              {{ (project.total_filesize / 1024 ** 2).toFixed(1) }} MB
            </span>
          </div>

          <div class="list-group-item__infos--actions">
            <router-link :to="`/projects/${project.id}/preview`" class="badge badge-transparent text-white" v-if="project.status != 'Render Ready'">
              <SvgIcon name="pencil" stroke="#ffffff" width="18px" />
              <span>
                {{ $t('open-project.edit') }}
              </span>
            </router-link>
            <router-link :to="`/projects/${project.id}/share`" class="badge badge-transparent text-white" v-if="project.status == 'Render Ready'">
              <SvgIcon name="share" stroke="#ffffff" width="18px" />
              <span>
                {{ $t('open-project.export') }}
              </span>
            </router-link>
            <button type="button" class="badge badge-transparent text-white" @click.prevent="openModal(idx)">
              <SvgIcon name="trash" stroke="#ffffff" width="18px" />
              <span>
                {{ $t('open-project.delete') }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SvgIcon from '@/components/icon/SvgIcon.vue'

export default {
  name: 'preview-ready',

  props: ['project', 'idx', 'tryRemove'],

  components: {
    SvgIcon,
  },

  data() {
    return {
      confirmDelete: Array.from({ length: this.idx + 1 }, () => false),
    }
  },

  computed: {
    ...mapGetters({
      menu: 'common/menu',
    }),
  },

  methods: {
    timestampToString(seconds) {
      return new Date(seconds * 1000).toISOString().substr(11, 8)
    },
    openModal(idx) {
      this.$set(this.confirmDelete, idx, true)
    },
    closeModal(idx) {
      this.$set(this.confirmDelete, idx, false)
    },
  },
}
</script>

<style lang="scss">
.modal .modal-body {
  padding-top: 30px;
}
.deleted {
  opacity: 0.5;
}
</style>
