<template>
  <div id="library-scenes">
    <div class="d-flex">
      <h5 class="title-page">{{ $t('library.scenes-of') }} {{ project?.name }}</h5>

      <button @click="handleBackToList" class="btn btn-outline text-nowrap ml-auto">
        <SvgIcon name="arrow-left" stroke="#ffffff" width="9px" />
        {{ $t('library.back-to-list') }}
      </button>
    </div>

    <div class="text-center" v-if="loading">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="scenes-selected" v-if="selectedScenesOfCurrentProject">
      <span>{{ countOfSelectedScenesInCurrentProject }} {{ $t('library.scenes-selected') }}</span>
    </div>

    <div class="scenes-selected py-2" v-if="$route.params.order">
      <span v-if="$route.params.type && $route.params.type == 'overlay'">
        {{ $t('library.insert-scene-over') }}
        <span class="text-tertiary">{{ $t('library.scene') }} {{ $route.params.order }}</span>
      </span>
      <span v-else-if="$route.params.order != 1">
        {{ $t('library.insert-scenes-after') }}
        <span class="text-tertiary">{{ $t('library.scene') }} {{ $route.params.order - 1 }}</span>
      </span>
      <span v-else>
        <span class="text-tertiary">{{ $t('library.insert-scenes-at-start') }}</span>
      </span>
    </div>

    <div v-if="!loading">
      <div class="col-sm-12 text-center" v-if="!scenes.length">
        <b class="text-primary" v-if="!searching && query == ''">{{ $t('library.no-results') }}</b>
        <b class="text-primary" v-if="query != '' && searching">{{ `${$t('library.no-results-found-for')} "${query}"` }}</b>
      </div>
      <div class="col-sm-12 text-center" v-if="scenes.length && query != '' && searching">
        <b class="text-primary">{{ $t('library.showing-results-for') }} "{{ query }}"</b>
      </div>

      <div class="wrapper-list-media" v-if="scenes && scenes.length">
        <div class="wrapper-list-media__item" v-for="(scene, index) in scenes" :key="scene.id">
          <div class="card-media mb-4">
            <div class="wrapper-list-media__item--checkbox" v-if="source == 'storyboard' || $route.name != 'library'">
              <i
                :class="{
                  'bi-square text-primary': selected.findIndex((x) => x.id === scene.id) < 0 && from_library.findIndex((x) => x.id === scene.id) < 0,
                  'bi-check-square-fill text-success':
                    selected.findIndex((x) => x.id === scene.id) >= 0 || from_library.findIndex((x) => x.id === scene.id) >= 0,
                }"
                style="cursor: pointer"
                @click.prevent="selectScene(scene)"
              ></i>
            </div>
            <div
              class="card-media__background"
              :style="{ 'background-image': `url(${scene.thumbnail})` }"
              @mouseover="hoveredIndex = index"
              @mouseout="hoveredIndex = null"
              @click.prevent="previewScene(scene)"
            >
              <span class="card-media__background--overlay" :class="{ visible: hoveredIndex === index }">
                <SvgIcon name="play" stroke="#ffffff" />
              </span>
            </div>

            <div class="card-media__title">
              <span> {{ $t('library.scene') }} {{ scene.order }} </span>
              <p class="text-truncate text-default mt-2">
                <small v-for="(line, idx) in scene.text" :key="`scene_text_${scene.id}_${idx}`">
                  <div v-if="idx < 2">{{ line.text }}</div>
                </small>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center w-100" v-if="has_more">
        <button type="button" class="btn btn-sm btn btn-primary mr-auto ml-auto" @click.prevent="loadMore" :disabled="load_more_loading">
          <div class="spinner-border spinner-border-sm text-light" role="status" v-if="load_more_loading"></div>
          <span v-if="!load_more_loading">{{ $t('library.load-more') }}</span>
        </button>
      </div>
    </div>

    <div class="selected-toolbar p-3 text-center" v-if="selected.length">
      {{ selected.length }} {{ $t('library.scenes-selected') }}
      <button type="button" class="btn btn-sm btn-primary" @click.prevent="tryInsert" :disabled="insert_loading">
        <div class="spinner-border spinner-border-sm text-light" role="status" v-if="insert_loading">
          <span class="sr-only">Loading...</span>
        </div>
        <span v-if="!insert_loading">{{ $t('library.insert') }}</span>
      </button>
    </div>

    <b-modal id="modal-preview-scene" centered hide-footer>
      <template #modal-header="{ close }">
        {{ $t('library.preview-scene') }} {{ getIdScenes(scenes, thumbnail) }}
        <button class="btn btn-link p-0" size="sm" variant="outline-danger" @click="close()">
          <SvgIcon name="cancel" stroke="#ffffff" />
        </button>
      </template>

      <VideoPlayer
        v-if="previewing"
        :thumbnail="thumbnail"
        :video="video"
        :subtitles="null"
        :start="start"
        :end="end"
        :autoplay="false"
        :currentTime="currentTime"
        :height="300"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import VideoPlayer from '@/components/VideoPlayer'
import SvgIcon from '@/components/icon/SvgIcon.vue'

export default {
  name: 'library-scenes',

  props: ['source', 'changeNav', 'selectScenes', 'selectFiles', 'selectFromLibrary', 'project_query', 'from_library'],

  components: {
    VideoPlayer,
    SvgIcon,
  },

  data() {
    return {
      hoveredIndex: null,
      loading: true,
      insert_loading: false,
      load_more_loading: false,
      searching: false,
      URL: URL,
      selected: [],
      has_more: false,
      page: 1,
      query: '',
      previewing: false,
      thumbnail: null,
      start: null,
      end: null,
      currentTime: null,
      video: null,
      videoContainer: null,
      playing: 0,
    }
  },

  computed: {
    ...mapGetters({
      project: 'library/project',
      scenes: 'library/scenes',
    }),

    selectedScenesOfCurrentProject() {
      return this.countOfSelectedScenesInCurrentProject > 0
    },

    countOfSelectedScenesInCurrentProject() {
      return this.from_library.filter((scene) => scene.isScene && scene.project_id === this.project.id).length
    },
  },

  mounted() {
    window.scrollTo(0, 0)
    this.query = this.project_query
    if (this.project) {
      this.trySearch()
    }
  },

  methods: {
    ...mapActions({
      getProjectScenes: 'library/GET_PROJECT_SCENES',
      searchProject: 'library/SEARCH_PROJECT',
    }),

    selectScene(scene) {
      const index = this.selected.findIndex((x) => x.id === scene.id)
      if (this.selectFiles) {
        scene.isScene = true // aqui
        this.selectFromLibrary(scene)
      } else {
        if (index >= 0) {
          this.selected.splice(index, 1)
        } else {
          if (this.$route.params.type && this.$route.params.type == 'overlay') {
            this.selected = []
          }
          this.selected.push(scene)
        }
      }
    },

    clearSearch() {
      this.query = ''
      this.trySearch()
    },

    trySearch() {
      this.loading = true
      this.page = 1
      this.search()
    },

    loadMore() {
      this.load_more_loading = true
      this.page = this.page + 1
      this.search()
    },

    getIdScenes(scenes, thumbnail) {
      return scenes.findIndex((x) => x.thumbnail === thumbnail) + 1
    },

    previewScene(scene) {
      this.previewing = false
      this.playing = -1
      this.thumbnail = scene.thumbnail
      this.start = scene.trim_start_raw
      this.end = scene.trim_end_raw
      this.currentTime = scene.trim_start_raw
      this.video = scene.export_raw_url
      this.$bvModal.show('modal-preview-scene')
      setTimeout(() => {
        this.previewing = true
      }, 200)
      setTimeout(() => {
        this.videoContainer = document.getElementsByClassName('vjs-tech')[0]
        if (this.videoContainer) {
          this.videoContainer.ontimeupdate = () => {
            this.currentTime = this.videoContainer.currentTime
          }
          this.videoContainer.currentTime = this.start
          this.videoContainer.play()
        }
      }, 400)
    },

    async search() {
      if (this.query == '') {
        const result = await this.getProjectScenes({
          id: this.project.id,
          page: this.page,
        })
        this.searching = false
        this.has_more = result.current_page < result.last_page ? true : false
      } else {
        const result = await this.searchProject({
          id: this.project.id,
          query: this.query,
          page: this.page,
        })
        this.searching = true
        this.has_more = result.current_page < result.last_page ? true : false
      }
      this.loading = false
      this.load_more_loading = false
    },

    tryInsert() {
      this.insert_loading = true
      this.selectScenes(this.selected)
    },

    handleBackToList() {
      this.changeNav('library')
    },
  },

  watch: {
    currentTime(val) {
      if (val >= this.end) {
        this.videoContainer.pause()
      }
    },

    query(val) {
      this.searching = false
    },

    project_query(val) {
      this.query = val
    },

    project(val) {
      if (val) {
        this.trySearch()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/colors';

.scenes-selected {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: normal;
  align-items: center;
  border-radius: 8px;
  color: $gray-450;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 25px 0 15px;
  min-height: 48px;
  padding: 8px 16px;
  background: #171721;
}

.selected-toolbar {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 90px;
  z-index: 1100;
  background-color: $black;
}
</style>
