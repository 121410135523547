import Vue from "vue"
import VueGtm from '@gtm-support/vue2-gtm';
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import store from "./store"
import i18n from "./locales"
import firebase from "@firebase/app"
import PortalVue from "portal-vue"
import Gravatar from 'vue-gravatar'
import Tawk from 'vue-tawk'

import { ModalPlugin, PopoverPlugin, NavbarPlugin, SidebarPlugin, ToastPlugin, OverlayPlugin, BFormRadioGroup, FormRadioPlugin  } from "bootstrap-vue"

import { precacheAndRoute } from "workbox-precaching"
import "firebase/messaging"
import navigationPreload from "workbox-navigation-preload"
import { BackgroundSyncPlugin } from "workbox-background-sync"
import { registerRoute, NavigationRoute } from "workbox-routing"
import { StaleWhileRevalidate } from "workbox-strategies"
import { CacheFirst } from "workbox-strategies"
import { NetworkOnly } from "workbox-strategies"
import { ExpirationPlugin } from "workbox-expiration"
import { CollapsePlugin } from 'bootstrap-vue'

Vue.use(PortalVue)
Vue.use(ModalPlugin)
Vue.use(PopoverPlugin)
Vue.use(NavbarPlugin)
Vue.use(SidebarPlugin)
Vue.use(ToastPlugin)
Vue.use(FormRadioPlugin)
Vue.component('b-form-radio-group', BFormRadioGroup)
Vue.use(OverlayPlugin)
Vue.use(CollapsePlugin)

Vue.use(VueGtm, {
  id: 'GTM-58CHLGJR',
  defer: false,
  enabled: true,
  debug: false,
  loadScript: false,
  vueRouter: router,
  trackOnNextTick: true,
});

Vue.component('v-gravatar', Gravatar)

if (process.env.NODE_ENV === "production") {
  const config = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  }
  firebase.initializeApp(config)
}

const moment = require("moment")

Vue.use(require("vue-moment"), {
  moment,
})

Vue.use(Tawk, {
  tawkSrc: process.env.VUE_APP_TAWKTO_SRC
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount("#app")


if (process.env.NODE_ENV === "production") {
  const config = {
    apiKey: process.env.FIREBASE_API_KEY,
    projectId: process.env.FIREBASE_PROJECT_ID,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID,
  }
  firebase.initializeApp(config)

  window.addEventListener("install", async event => {
    event.waitUntil(
      caches.open("offline").then(cache => {
        cache.add("/offline")
        cache.add("/login")
        cache.add("/videoeditor")
      })
    )
  })

  precacheAndRoute(window.__WB_MANIFEST || [])

  registerRoute(
    ({ url }) =>
      url.pathname === "/videoeditor" ||
      url.pathname === "/music" ||
      url.pathname === "/js/app.js" ||
      url.pathname === "/js/vendor.js" ||
      url.pathname === "/css/app.css",
    new StaleWhileRevalidate()
  )

  registerRoute(
    ({ url, request }) =>
      request.destination === "style" ||
      url.pathname === "/js/template.js" ||
      url.pathname.startsWith("/template/") ||
      url.pathname === "/new-manifest.json",
    new CacheFirst()
  )

  registerRoute(new RegExp("/img/.*\\.svg"), new CacheFirst())

  registerRoute(
    ({ url }) => url.pathname.startsWith("/favicon"),
    new CacheFirst()
  )

  registerRoute(
    // Cache image files.
    /\.(?:png|jpg|jpeg|gif)$/,
    // Use the cache if it's available.
    new CacheFirst({
      // Use a custom cache name.
      cacheName: "image-cache",
      plugins: [
        new ExpirationPlugin({
          // Cache upto 50 images.
          maxEntries: 50,
          // Cache for a maximum of a week.
          maxAgeSeconds: 7 * 24 * 60 * 60,
        }),
      ],
    })
  )

  const bgSyncPlugin = new BackgroundSyncPlugin("myQueueName", {
    maxRetentionTime: 24 * 60, // Retry for max of 24 Hours (specified in minutes)
  })

  registerRoute(
    /\/api\/.*\/*.json/,
    new NetworkOnly({
      plugins: [bgSyncPlugin],
    }),
    "POST"
  )

  const messaging = firebase.messaging()

  navigationPreload.enable()

  const networkOnly = new NetworkOnly()
  const navigationHandler = async params => {
    try {
      // Attempt a network request.
      return await networkOnly.handle(params)
    } catch (error) {
      // If it fails, return the cached HTML.
      return caches.match("/offline", {
        cacheName: "offline",
      })
    }
  }

  // Register this strategy to handle all navigations.
  registerRoute(new NavigationRoute(navigationHandler))

  window.addEventListener("push", function (event) {
    var data = {}
    console.log("[Service Worker] Push Received.")
    console.log(`[Service Worker] Push had this data: "${event.data.text()}"`)

    if (!(window.Notification && window.Notification.permission === "granted")) return

    if (event.data) {
      data = JSON.parse(event.data.text())
    }

    // Customize the UI for the message box
    var icon = "https://app.wisecut.video/favicon_192.png"
    var title = data.notification.title || "Wisecut"
    var message = data.notification.body || "Update Notice"
    var image = data.notification.image || icon
    var badge = "https://app.wisecut.video/favicon_192.png"
    var options = {
      body: message,
      icon: icon,
      badge: badge,
      image: image,
      requireInteraction: true,
      data: {
        id: data.data.id,
        url: data.fcmOptions.link,
      },
    }

    navigator.setAppBadge().catch(error => {
      console.error("Error setting up icon bagde")
    })

    event.waitUntil(window.registration.showNotification(title, options))
  })

  window.addEventListener("notificationclick", function (event) {
    navigator.clearAppBadge().catch(error => {
      console.error("Error clearing up icon badge")
    })
    // Android doesn’t close the notification when you click on it
    // See: http://crbug.com/463146
    event.notification.close()

    // This looks to see if the current is already open and
    // focuses if it is
    event.waitUntil(
      window.clients
        .matchAll({
          type: "window",
        })
        .then(function (clientList) {
          for (var i = 0; i < clientList.length; i++) {
            var client = clientList[i]
            if (
              (client.url ===
                "/videoeditor#/projects/" +
                event.notification.data.id +
                "/storyboard" ||
                client.url ===
                "/videoeditor#/projects/" +
                event.notification.data.id +
                "/share") &&
              "focus" in client
            ) {
              return client.focus()
            }
          }
          if (clients.openWindow) {
            return clients.openWindow(event.notification.data.url)
          }
        })
    )
  })
}
