<template>
  <div class="promotion-banner">
    <div class="promotion-banner__content-text">
      <div class="promotion-banner__title">{{ $t('modalBlackFriday.topBannerTitle') }}</div>
      <div class="promotion-banner__text">
        {{ $t('modalBlackFriday.topBannerText1') }} <span>{{ $t('modalBlackFriday.topBannerText2') }}</span>
        {{ $t('modalBlackFriday.topBannerText3') }} <span>{{ $t('modalBlackFriday.topBannerText4') }}</span>
      </div>
    </div>
    <button class="btn btn-link btn-show-modal" @click="showModal()">
      {{ $t('modalBlackFriday.secureSavingsButton') }}
      <SvgIcon name="chevron-right" width="9px" />
    </button>

    <button class="btn btn-link p-0 promotion-banner__close" @click="closeBfBanner()">
      <SvgIcon name="cancel" stroke="#0E0E17" width="22px" />
    </button>

    <b-modal id="modal-bf-upgrading" hide-footer centered modal-class="modal-bf-upgrading" @show="setPlans" @hidden="isConfirmPayment = false">
      <template #modal-header="{ close }">
        <h2 class="modal-bf-upgrading__subtitle">{{ $t('modalBlackFriday.modalSubtitle') }}</h2>
        <h1 class="modal-bf-upgrading__title">{{ $t('modalBlackFriday.modalTitle') }}</h1>
        <p class="modal-bf-upgrading__text">{{ $t('modalBlackFriday.modalText') }}</p>

        <div class="switches-container">
          <input type="checkbox" id="switch" name="switchPlan" v-model="isAnnual" />
          <label for="switch">{{ $t('pricing.monthly') }}</label>
          <label for="switch">{{ $t('pricing.annually') }}</label>
          <div class="switch-wrapper">
            <div class="switch">
              <div>{{ $t('pricing.monthly') }}</div>
              <div>{{ $t('pricing.annually') }}</div>
            </div>
          </div>
        </div>

        <button class="btn btn-link p-0 close" @click="close()">
          <SvgIcon name="cancel" stroke="#ffffff" width="35px" />
        </button>
      </template>

      <div class="text-center mt-3 mb-3" v-if="isLoading">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div v-else class="wrapper-cards">
        <div class="wrapper-cards-content" v-if="!isConfirmPayment">
          <div
            class="wrapper-cards__card"
            v-for="plan in plans.filter((item) => item.nickname !== 'free' && item.nickname !== 'Basic')"
            :key="plan.id"
          >
            <PlanDetails :me="me" :selectedPlan="plan" :textColor="'#AD35FB'" />
            <div class="input-coupon">
              <label>{{ $t('payment.coupon') }}</label>
              <div class="position-relative">
                <SvgIcon name="coupon" stroke="#FF004F" width="30px" />
                <input type="text" v-model="couponInput" class="form-control" disabled />
              </div>
            </div>

            <button type="button" class="btn btn-confirm" @click.prevent="setUpdateSubscription(plan)">
              {{ $t('modalBlackFriday.confirmPaymentButton') }}
            </button>
          </div>
        </div>

        <div v-else class="wrapper-cards__method-payment">
          <div v-if="!planUpgradeSuccess">
            <p class="text-center">{{ $t('modalBlackFriday.confirmAddPaymentMethod') }}</p>

            <div class="text-center mt-3 mb-3" v-if="!paymentMethods">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>

            <Payment :payment_method="payment_method" :selectMethod="selectMethod" :isShowForm="isShowForm" />

            <div class="wrapper-cards__method-payment--actions">
              <button type="button" class="btn btn-secondary btn-cancel" v-if="paymentMethods" @click="isConfirmPayment = false">
                {{ $t('payment.cancel') }}
              </button>
              <button type="button" class="btn btn-confirm" @click.prevent="tryUpdateSubscription()" v-if="payment_method">
                <div class="spinner-border text-light" role="status" v-if="loading">
                  <span class="sr-only">Loading...</span>
                </div>
                <span v-if="!loading"> {{ $t('modalBlackFriday.confirmPaymentButton') }}</span>
              </button>
            </div>
          </div>

          <div v-if="planUpgradeSuccess" class="wrapper-cards__method-payment--success">
            <SvgIcon name="check" stroke="#fc3588" width="52px" />
            <span>{{ $t('modalBlackFriday.planUpgradeSuccess') }}</span>
            <button type="button" class="btn btn-sm btn-secondary btn-cancel mt-3" @click="closeModal">
              {{ $t('modalBlackFriday.close') }}
            </button>
          </div>
        </div>

        <div class="wrapper-cards__terms">
          <a href="https://www.wisecut.video/christmas-promotion" target="_blank" class="text-center">{{ $t('modalBlackFriday.termsLink') }}</a>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import SvgIcon from '@/components/icon/SvgIcon.vue'
import PlanDetails from '@/views/private/Account/components/PlanDetails.vue'
import Payment from '@/views/private/Account/components/Payment.vue'

export default {
  name: 'PromotionTopBanner',

  components: {
    SvgIcon,
    PlanDetails,
    Payment,
  },

  data() {
    return {
      yearlyPlans: null,
      monthlyPlans: null,
      plans: null,
      planId: null,
      loading: false,
      isAnnual: true,
      couponInput: 'XMAS2023',
      couponIsValid: null,
      yearlyPlans: null,
      couponDetails: {},
      payment_method: null,
      isConfirmPayment: false,
      planUpgradeSuccess: false,
      planSelected: null,
      isShowForm: false,
      allCouponsLoaded: false,
    }
  },

  computed: {
    ...mapState({
      me: (state) => state.user.me,
      paymentMethods: (state) => state.user.paymentMethods,
    }),
    isLoading() {
      return !this.plans || !this.allCouponsLoaded
    },
  },

  async mounted() {
    this.yearlyPlans = await this.getYearlyPlans()
    this.monthlyPlans = await this.getMonthlyPlans()
    this.applyDiscountsToPlans()
    this.setPlans()
  },

  methods: {
    ...mapActions({
      getYearlyPlans: 'payment/GET_YEARLY_PLANS',
      getMonthlyPlans: 'payment/GET_MONTHLY_PLANS',
      getCoupon: 'payment/GET_COUPON',
      updateSubscription: 'user/UPDATE_SUBSCRIPTION',
    }),

    ...mapMutations({
      toggleBFBanner: 'toggleBFBanner',
    }),

    closeBfBanner() {
      this.toggleBFBanner()
    },

    selectMethod(id) {
      this.payment_method = id
    },

    async applyDiscountsToPlans() {
      if (!this.yearlyPlans || !this.monthlyPlans) {
        return
      }
      const allPlans = [...this.yearlyPlans, ...this.monthlyPlans]
      await Promise.all(allPlans.map((plan) => this.loadCoupon(plan.id)))
      this.allCouponsLoaded = true
    },

    async loadCoupon(planId) {
      if (this.couponDetails[planId]) {
        return Promise.resolve()
      }

      try {
        const response = await this.getCoupon({
          code: this.couponInput,
          planId: planId,
        })

        if (!response.coupon.isValid) {
          this.couponIsValid = false
          return Promise.reject()
        }

        this.couponDetails[planId] = response.coupon
        this.applyCouponToPlan(planId, response.coupon)
      } catch (error) {
        this.couponIsValid = false
        return Promise.reject()
      }

      return Promise.resolve()
    },

    applyCouponToPlan(planId, coupon) {
      ;[this.yearlyPlans, this.monthlyPlans].forEach((planSet) => {
        const plan = planSet.find((p) => p.id === planId)
        if (plan) {
          plan.amount = (parseInt(plan.amount_decimal) * (100 - coupon.percent_off)) / 100
          plan.coupon = coupon.code
        }
      })
    },

    setPlans() {
      this.plans = this.isAnnual ? this.yearlyPlans : this.monthlyPlans
      this.isConfirmPayment = false

      this.plans.forEach((plan) => {
        if (this.couponDetails[plan.id]) {
          const coupon = this.couponDetails[plan.id]
          plan.amount = (parseInt(plan.amount_decimal) * (100 - coupon.percent_off)) / 100
          plan.coupon = coupon.code
        }
      })
    },

    showModal() {
      this.$bvModal.show('modal-bf-upgrading')
    },

    closeModal() {
      window.location.reload()
    },

    async tryUpdateSubscription() {
      if (process.env.NODE_ENV !== 'development' && !this.payment_method && this.planSelected.amount > 0) {
        this.$bvToast.toast(this.$t('payment.select-payment-method'), {
          variant: 'info',
          title: this.$t('payment.no-payment-method'),
          toaster: 'b-toaster-top-center',
          solid: true,
        })
      } else {
        this.loading = true
        const response = await this.updateSubscription({
          plan: this.planSelected.id,
          payment: this.payment_method,
          coupon: this.planSelected.coupon ?? null,
        })
        this.loading = false

        if (response && response.subscription_updated) {
          this.planUpgradeSuccess = true
        } else {
          this.$bvToast.toast(this.$t('payment.paymentError'), {
            variant: 'danger',
            title:  'Error',
            toaster: 'b-toaster-top-center',
            solid: true,
          })
        }
      }
    },

    setUpdateSubscription(plan) {
      this.planSelected = plan
      this.isConfirmPayment = true
    },
  },

  watch: {
    isAnnual(val) {
      this.setPlans()
    },
    paymentMethods(val) {
      if (this.paymentMethods.length == 1) {
        this.payment_method = this.paymentMethods[0].id
        this.isShowForm = false
      } else {
        this.isShowForm = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';

$modal-bf-upgrading-primary-color: #AD35FB;
$modal-bf-upgrading-secondary-color: #FF004F;
$modal-bf-upgrading-tertiary-color: #ffffff;
$modal-bf-gradient: linear-gradient(90deg, #AD35FC 0%, #FF004E 100%);

.promotion-banner {
  width: calc(100% - 90px);
  min-height: 35px;
  background: $modal-bf-gradient;
  position: fixed;
  top: 0;
  left: 90px;
  z-index: 1031;
  padding: 0 20px;
  transition: 0.3s;
  display: flex;
  gap: 14px;
  align-items: center;
  @media (max-width: 1600px) {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  @media (max-width: 1024px) {
    width: 100%;
    left: 0;
    padding: 10px 30px;
  }
  .btn-show-modal {
    color: $white;
    font-size: 9px;
    padding: 2px 9px;
    font-weight: bold;
    text-transform: uppercase;
    border: 1px solid $white;
    border-radius: 7px;
    gap: 7px;
    max-height: fit-content;
    @media screen and (max-width: 768px) {
      padding: 5px 9px;
      margin-right: 25px;
      min-width: 100px;
    }
    svg {
      stroke: $white;
    }
    &:hover {
      color: $black;
      background: $modal-bf-upgrading-tertiary-color;
      svg {
        stroke: $black;
      }
    }
  }
  &__title {
    color: $black;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.5em;
    text-transform: uppercase;
  }
  &__text {
    color: $black;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    span {
      font-weight: bold;
      color: $modal-bf-upgrading-tertiary-color;
    }
  }
  &__content-text {
    display: flex;
    gap: 14px;
    @media (max-width: 1024px) {
      gap: 5px;
      flex-direction: column;
    }
  }
  &__close {
    position: absolute;
    right: 15px;
    &:hover {
      background: transparent;
    }
    @media screen and (max-width: 768px) {
      top: 10px;
      right: 10px;
    }
  }
}

::v-deep .modal-bf-upgrading {
  padding-top: 65px;
  padding-bottom: 55px;
  @media screen and (max-width: 1200px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  .modal-dialog {
    @media (min-width: 760px) {
      max-width: 1214px;
    }
    @media screen and (max-width: 1024px) {
      max-width: 100%;
    }
  }
  .modal-body {
    padding-bottom: 60px;
    padding-top: 0;
  }
  .modal-header {
    text-align: center;
    flex-direction: column;
    align-items: center;
    .close {
      position: absolute;
      right: 40px;
      top: 40px;
    }
  }
  &__title,
  &__subtitle {
    text-transform: uppercase;
  }

  &__title {
    font-weight: bold;
    font-size: 20px;
    text-align: left;
    color: $modal-bf-upgrading-primary-color;
    margin-bottom: 0;
  }
  &__subtitle {
    font-size: 12px;
    letter-spacing: 0.5em;
    text-align: left;
    color: $modal-bf-upgrading-secondary-color;
    margin-top: 30px;
  }
  &__text {
    font-size: 14px;
    color: $gray-450;
    margin-top: 20px;
    margin-bottom: 25px;
    font-weight: normal;
  }
  .more-info {
    display: none;
  }
}

.wrapper-cards {
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
  .input-coupon {
    @media screen and (max-width: 768px) {
      margin: 30px 0;
      flex-direction: column;
      gap: 10px;
      margin-left: auto;
      margin-right: auto;
    }
    input {
      color: $modal-bf-upgrading-primary-color;
      padding-left: 55px;
      max-width: 225px;
    }
  }

  .btn-confirm {
    max-width: 223px;
    background: $modal-bf-gradient;
    color: $white;
    text-transform: uppercase;
    font-weight: bold;
    margin: auto;
    max-height: fit-content;
    width: 100%;
    @media screen and (max-width: 768px) {
      max-width: 100%;
    }
    &:hover,
    &:focus,
    &:active {
      color: $black;
      background: $modal-bf-upgrading-primary-color;
    }
  }

  .wrapper-cards-content {
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  &__card {
    background: #171721;
    border-radius: 10px;
    width: 100%;
    padding: 41px 20px 56px;
    max-width: 477px;
    @media screen and (max-width: 768px) {
      padding: 40px 20px 40px;
    }
  }
  &__method-payment {
    background: #171721;
    border-radius: 10px;
    width: 100%;
    max-width: 977px;
    padding: 40px 30px 40px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      color: $white;
      font-size: 16px;
      margin-bottom: 20px;
    }
    &::v-deep {
      .payment {
        width: 100%;
        .card {
          width: 100%;
          padding: 0;
          background: transparent;
          .card-title {
            color: $white;
            display: none;
          }
          .btn {
            margin: auto;
          }
          .btn-cancel,
          .btn-add-payment {
            display: none;
          }
        }
      }
    }
    &--actions {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-top: 20px;
      width: 100%;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
      }
      .btn-cancel {
        min-width: 223px;
        width: 100%;
      }
      .btn-confirm {
        min-width: 223px;
        width: 100%;
        margin: inherit;
        margin-top: 0;
      }
    }
    &--success {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin-bottom: 0;
      span {
        font-size: 18px;
      }
    }
  }
  &__terms {
    margin-top: 20px;
    text-align: center;
    a {
      color: $modal-bf-upgrading-primary-color;
      font-size: 15px;
      text-decoration: underline;
      margin-left: auto;
      margin-right: auto;
      &:hover {
        color: $modal-bf-upgrading-secondary-color;
      }
    }
  }
}

.switches-container {
  background: $white;
  label {
    color: #1a1a23;
  }
}
.switch {
  background: $modal-bf-upgrading-primary-color;
  div{
    color: #1a1a23;
  }
}
</style>
