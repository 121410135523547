<script>
export default {
  name: 'BannerTop',
}
</script>

<template>
  <div class="banner" role="banner">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';

$color: #b983ff;

.banner {
  height: max-content;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1031;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  padding: 6px;
  background-color: $color;
  p {
    margin: 0;
  }
  a {
    color: $black;
    text-decoration: underline;
    transition: color 0.3s;
    &:hover{
      color: $gray-700;
    }
  }
}
</style>
