import api from "@/api"

const getters = {
  monthlyPlans: state => {
    return state.monthlyPlans
  },
  yearlyPlans: state => {
    return state.yearlyPlans
  },
  freePlan: state => {
    return state.freePlan
  },
  coupon: state => {
    return state.coupon
  },
}

const mutations = {
  SET_MONTHLY_PLANS(state, result) {
    state.monthlyPlans = result
  },
  SET_YEARLY_PLANS(state, result) {
    state.yearlyPlans = result
  },
  SET_FREE_PLAN(state, result) {
    state.freePlan = result
  },
  SET_COUPON(state, result) {
    state.coupon = result.coupon
  },
}

const actions = {
  GET_MONTHLY_PLANS: ({ commit }) => {
    try {
      return api.payment.getPlans('month')
        .then(result => {
          if (result) {
            commit('SET_MONTHLY_PLANS', result)
          }
          return result
        })
    }
    catch (error) {
      return error
    }
  },
  GET_YEARLY_PLANS: ({ commit }) => {
    try {
      return api.payment.getPlans('year')
        .then(result => {
          commit('SET_YEARLY_PLANS', result)
          return result
        })
    }
    catch (error) {
      return error
    }
  },

  GET_FREE_PLAN: ({ commit }) => {
    return new Promise(resolve => {
      return api.payment
        .getFreePlan()
        .then(result => {
          commit('SET_FREE_PLAN', result)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },
  GET_COUPON: ({ commit }, payload) => {
    return new Promise(resolve => {
      return api.payment
        .getCoupon(payload.code, payload.planId)
        .then(result => {
          commit('SET_COUPON', result)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },
}

const state = {
  plans: null,
  freePlan: null,
  coupon: null
}

const namespaced = true

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
}
