import { responseHandle } from './handle'
import fetch from './fetch'

export default {
  getLibrary({ page, type }) {
    let endpoint
    if (type) {
      endpoint = `/library?page=${page}&type=${type}`
    } else {
      endpoint = `/library?page=${page}`
    }
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  postLibrary(payload) {
    const endpoint = '/library'
    return fetch
      .post(endpoint, payload)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  getProjectScenes(id, page) {
    const endpoint = `/library/projects/${id}/scenes?page=${page}`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  searchLibrary({ query, page, type }) {
    let endpoint
    if (type) {
      endpoint = `/library/projects/search?query=${query}&page=${page}&type=${type}`
    } else {
      endpoint = `/library/projects/search?query=${query}&page=${page}`
    }
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  searchProject({ id, query, page }) {
    const endpoint = `/library/projects/${id}/search?query=${query}&page=${page}`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  removeLibrary(id) {
    const endpoint = `/projects/${id}`
    return fetch
      .delete(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  }
}
