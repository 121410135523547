<template>
  <div>
    <div :class="['progress', size]" :style="{ '--dynamic-offset': dynamicOffset + 'px' }">
      <svg :stroke-width="backWidth" class="progress__back" viewBox="0 0 100 100" :stroke="backColor" :fill="innerColor">
        <circle cx="50" cy="50" r="45"></circle>
      </svg>
      <svg
        :stroke-linecap="rounded ? 'round' : ''"
        :stroke-width="frontWidth"
        :stroke-dashoffset="calcValue"
        class="progress__front"
        viewBox="0 0 100 100"
      >
        <defs>
          <linearGradient :id="gradientId" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" :stop-color="frontColors[0]" />
            <stop offset="100%" :stop-color="frontColors[1]" />
          </linearGradient>
        </defs>
        <circle cx="50" cy="50" r="45" :stroke="'url(#' + gradientId + ')'"></circle>
      </svg>
      <span :style="[{ color: textColor }]" class="progress__value">{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CircularProgressBar',

  props: {
    text: { type: String, default: '' },
    value: {
      type: Number,
      default: 0,
    },
    frontColors: {
      type: Array,
      default: () => [],
    },
    frontWidth: { type: Number, default: 8 },
    backColor: {
      type: String,
      default: '#f5dfd0',
    },
    backWidth: { type: Number, default: 8 },
    rounded: { type: Boolean, default: false },
    textColor: { type: String, default: '#737373' },
    innerColor: { type: String, default: 'none' },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['small', 'medium', 'large'].includes(value)
      },
    },
  },

  data() {
    return {
      gradientId: 'gradient-' + Math.random().toString(36).substr(2, 9),
      dynamicOffset: 283,
    }
  },

  mounted() {
    this.dynamicOffset = 283 * (1 - this.value / 100)
  },

  computed: {
    calcValue() {
      return 283 - (283 * this.value) / 100
    },
  },
  watch: {
    value(newVal) {
      this.dynamicOffset = 283 * (1 - newVal / 100)
    },
  },
}
</script>

<style lang="scss" scoped>
@keyframes fillUp {
  from {
    stroke-dashoffset: 283;
  }
}

.small {
  width: 5rem;
  height: 5rem;
  font-size: 1.2rem;
}

.medium {
  width: 7rem;
  height: 7rem;
  font-size: 1.6rem;
}

.large {
  width: 144px;
  height: 144px;
  font-size: 1.8rem;
}

.progress {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  background: transparent;
  margin: auto;

  svg {
    height: inherit;
    width: inherit;
  }

  .progress__back {
    position: absolute;
    top: 0;
    left: 0;
  }

  .progress__front {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(-90deg) scaleY(-1);
    animation: fillUp 1s forwards;

    circle {
      fill: none;
      stroke-dasharray: 283;
    }
  }

  .progress__value {
    position: relative;
    font-size: inherit;
    pointer-events: none;
    z-index: 2;
    transform: rotate(0);
    font-size: 20px;
  }
}
</style>
