<template>
  <b-modal id="modal-info" :title="$t('project-edit.tools-help')" centered hide-footer>
    <template #modal-header="{ close }">
      {{ $t('new-project.leave-page-title') }}
      <button class="btn btn-link p-0" size="sm" @click="close()">
        <SvgIcon name="cancel" stroke="#ffffff" />
      </button>
    </template>
    <p>
      <SvgIcon name="play" stroke="#ffffff" width="16px" class="mr-2" />
      {{ $t('project-edit.tools-play') }}
    </p>
    <p>
      <SvgIcon name="playlist" stroke="#ffffff" width="16px" class="mr-2" />
      {{ $t('project-edit.tools-play-collection') }}
    </p>
    <p>
      <SvgIcon name="pencil" stroke="#ffffff" width="16px" class="mr-2" />
      {{ $t('project-edit.tools-edit') }}
    </p>
    <p>
      <SvgIcon name="cut" stroke="#ffffff" width="16px" class="mr-2" />
      {{ $t('project-edit.tools-scissors') }}
    </p>
    <p>
      <SvgIcon name="trash" stroke="#ffffff" width="16px" class="mr-2" />
      {{ $t('project-edit.tools-trash') }}
    </p>
    <p>
      <SvgIcon name="reload" stroke="#ffffff" width="16px" class="mr-2" />
      {{ $t('project-edit.tools-restore') }}
    </p>
    <p>
      <SvgIcon name="chat" stroke="#ffffff" width="16px" class="mr-2" />
      {{ $t('project-edit.tools-type') }}
    </p>
    <p>
      <SvgIcon name="compare-arrows" stroke="#ffffff" width="16px" class="mr-2" />
      {{ $t('project-edit.tools-trim') }}
    </p>
    <p>
      <SvgIcon name="sound" stroke="#ffffff" width="16px" class="mr-2" />
      {{ $t('project-edit.tools-volume') }}
    </p>
    <p>
      <SvgIcon name="folder" stroke="#ffffff" width="16px" class="mr-2" />
      {{ $t('project-edit.duplicate-scene') }}
    </p>
    <p>
      <SvgIcon name="smile" stroke="#ffffff" width="16px" class="mr-2" />
      {{ $t('project-edit.tools-select-faces') }}
    </p>
    <p>
      <SvgIcon name="background" stroke="#ffffff" width="16px" class="mr-2" />
      {{ $t('project-edit.tools-split') }}
    </p>
    <p>
      <SvgIcon name="library-add" stroke="#ffffff" width="16px" class="mr-2" />
      {{ $t('project-edit.overscene-add') }}
    </p>
    <p>
      <SvgIcon name="settings" stroke="#ffffff" width="16px" class="mr-2" />
      {{ $t('project-edit.tools-overscene') }}
    </p>
    <p>
      <SvgIcon name="arrow-collapse" stroke="#ffffff" width="16px" class="mr-2" />
      {{ $t('project-edit.merge-next-scene') }}
    </p>
  </b-modal>
</template>

<script>
import SvgIcon from '@/components/icon/SvgIcon.vue'

export default {
  name: 'ModalInfo',

  components: {
    SvgIcon,
  },
}
</script>

<style scoped>
.icon-size {
  font-size: 1rem;
}
</style>
