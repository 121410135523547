<template>
  <div>
    <div v-if="loading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title text-primary">{{ this.tier_name }}</h5>
          <div class="text-center mb-5">
            <!-- <button type="button" class="btn btn-success" @click.prevent="checkout()">{{ $t("project-edit.buy-now") }}</button> -->
            <p>{{ $t('ltd.redirecting-payment') }}</p>
          </div>

          <!-- <div class="text-center mb-5">
            <p class="text-info mb-0"><a href="https://www.wisecut.video/ltd#comp-kx6jkb6w" target="_blank">{{ $t('ltd.more-info') }}</a></p>
          </div> -->

          <stripe-checkout
            ref="checkoutRef"
            mode="payment"
            :pk="publishableKey"
            :session-id="stripeSession"
            v-if="!loading && stripeSession"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { StripeCheckout } from "@vue-stripe/vue-stripe";

export default {
  name: 'ltd-tier',

  props: ["tier_id", "tier_name"],

	components: {
    StripeCheckout,
  },

  data() {
    return {
      loading: true,
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
			stripeEnabled: process.env.VUE_APP_STRIPE_ENABLED,
    }
  },

  computed: {
    ...mapGetters({
      stripeSession: "user/stripeSession",
    }),
  },

  async mounted() {
    await this.getStripeLtdSession(this.tier_id);
    this.loading = false;
    setTimeout(() => { this.checkout() }, 2000)
  },

  methods: {
      ...mapActions({
        getStripeLtdSession: "user/GET_STRIPE_LTD_SESSION",
    }),

    checkout() {
      window.analytics.track("LTD Buy Now Clicked", {
        stripeSession: this.stripeSession,
				tier: this.tier_id
      });
      fbq('track', 'InitiateCheckout');
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
}
</script>