<template>
  <div :style="`height: ${height}px`" class="p-1">
    <div v-if="!files.length" class="text-left upload-empty">
      <h5>{{ $t('new-project.simple-steps') }}:</h5>
      <p>{{ $t('new-project.step1') }}</p>

      <button type="button" class="btn btn-primary" @click.prevent="$bvModal.show('modal-video-uploader')">
        {{ $t('new-project.add-media') }}
      </button>
    </div>

    <div v-else>
      <div class="d-flex justify-content-end" v-if="files.length">
        <button type="button" class="btn btn-secondary" @click.prevent="changeNav('create')">
          {{ $t('new-project.next') }} <i class="bi-arrow-right-circle"></i>
        </button>
      </div>

      <div class="text-center">
        <h6 class="text-default">
          <i class="bi-grip-vertical"></i>
          {{ $t('project-edit.drag-to-reorder') }}
        </h6>
      </div>
      <draggable id="mediaRow" :list="files" class="row mt-3" ghost-class="ghost">
        <div class="col-sm-3" v-for="(file, index) in files" :key="`project_file_${index}`">
          <FileVideo v-if="file.utype == 'library' || file.data.type.substr(0, 5) === 'video'" :file="file" :index="index" :removeFile="removeFile" />
          <FileAudio v-if="file.data.type.substr(0, 5) === 'audio'" :file="file" :index="index" :removeFile="removeFile" />
          <FileImage v-if="file.data.type.substr(0, 5) === 'image'" :file="file" :index="index" :removeFile="removeFile" />
        </div>
      </draggable>
      <div class="text-center">
        <button type="button" class="btn btn-primary mt-3" @click.prevent="$bvModal.show('modal-video-uploader')">
          {{ $t('new-project.add-media') }}
        </button>
      </div>

      <div class="text-center mt-5">
        <small>
          <b>{{ $t('new-project.project-duration') }}:</b>
          <span
            :class="{
              'text-danger': (limits.minutes.total - limits.minutes.used) * 60 < projectLength,
              'text-success': (limits.minutes.total - limits.minutes.used) * 60 >= projectLength,
            }"
          >
            {{ timestampToString(projectLength) }}
          </span>

          <b class="ml-2">{{ $t('new-project.available-minutes') }}:</b>
          <span class="text-default">
            {{ timestampToString((limits.minutes.total - limits.minutes.used) * 60) }}
          </span>
        </small>
        <br />
        <br />
        <small>
          <b>{{ $t('new-project.project-size') }}:</b>
          <span
            :class="{
              'text-danger': limits.storage.total - limits.storage.used < projectSize,
              'text-success': limits.storage.total - limits.storage.used >= projectSize,
            }"
          >
            {{ (projectSize / 1024 ** 3).toFixed(2) }}
          </span>
          <b class="ml-2">{{ $t('new-project.available-size') }}:</b>
          <span class="text-default"> {{ ((limits.storage.total - limits.storage.used) / 1024 ** 3).toFixed(2) }}GB </span>
        </small>
      </div>
    </div>

    <b-modal id="modal-video-uploader" modal-class="modal-video-uploader" size="lg" centered hide-footer>
      <template #modal-header="{ close }">
        {{ $t('new-project.add-media') }}
        <button class="btn btn-link p-0" @click="close()">
          <SvgIcon name="cancel" stroke="#ffffff" />
        </button>
      </template>

      <LibraryView :selectFiles="selectFiles" :source="'upload'" />
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import SvgIcon from '@/components/icon/SvgIcon.vue'
import LibraryView from '@/views/private/Library/LibraryView'
import FileVideo from './FileVideo'
import FileAudio from './FileAudio'
import FileImage from './FileImage'

export default {
  name: 'new-project-upload',

  props: ['uppy', 'updateProgress', 'updateFiles', 'disableDrag', 'changeNav', 'changeProjectLength', 'changeProjectSize', 'files'],

  components: {
    draggable,
    LibraryView,
    FileVideo,
    FileAudio,
    FileImage,
    SvgIcon,
  },

  data() {
    return {
      plan: null,
      height: 500,
      projectSize: 0,
      projectLength: 0,
    }
  },

  computed: {
    ...mapGetters({
      limits: 'user/limits',
    }),
  },

  mounted() {
    this.uppy.cancelAll()
    this.uppy.reset()
    this.height = window.height - 90
    window.addEventListener('resize', () => {
      this.height = window.height - 90
    })
    this.uppy.on('restriction-failed', (file, error) => {
      this.$bvToast.toast(`${file.name}: ${this.$t('library.duplicated')}`, {
        variant: 'danger',
        title: this.$t('library.double-file'),
        toaster: 'b-toaster-top-center',
        solid: true,
        noAutoHide: true,
      })
    })
    this.uppy.on('progress', (progress) => {
      this.updateProgress(progress)
    })
    this.uppy.on('upload-progress', (file, progress) => {
      const index = this.files.findIndex((x) => x.data.name == file.data.name)
      this.files[index].progress = ((progress.bytesUploaded * 100) / progress.bytesTotal).toFixed(1)
    })
    this.uppy.on('upload-success', (file, response) => {
      window.analytics.track('Video Upload Finished', {
        s3File: file.meta.key,
      })
    })
    /*this.uppy.on("upload-error", (file, error, response) => {
      const index = this.videos.findIndex((x) => x.id === file.id);
      this.videos[index].error = true;
    });*/
  },

  methods: {
    openLibrary() {
      this.isLibrary = 'library'
    },

    timestampToString(seconds) {
      return new Date(seconds * 1000).toISOString().substr(11, 8)
    },

    selectFiles(from_library, videos, audios, images) {
      this.$bvModal.hide('modal-video-uploader')
      const files = from_library.concat(videos, audios, images)
      for (let file of files) {
        if (!file.id) {
          this.uppy.addFile({
            name: file.name,
            type: file.type,
            data: file,
          })
        }

        this.files.push({
          data: file,
          utype: file.id ? 'library' : 'file',
          progress: file.id ? 100 : 0,
        })
      }

      this.updateFiles(this.files)
      setTimeout(() => {
        this.uppy.upload()
        let files = this.uppy.getFiles()
        for (let file of files) {
          window.analytics.track('Video Upload Started', {
            fileName: file.name,
            fileType: file.type,
          })
        }
      }, 2000)
    },

    removeFile(file, index) {
      const uppyFiles = this.uppy.getFiles()
      const uppyFile = uppyFiles.find((x) => x.data.name == file.data.name)
      this.uppy.removeFile(uppyFile.id)
      this.files.splice(index, 1)
    },
  },

  watch: {
    files(val) {
      this.projectSize = 0
      this.projectLength = 0
      for (let file of this.files) {
        this.projectSize = this.projectSize + file.data.size
      }
      setTimeout(() => {
        let audioPlayers = document.querySelectorAll('#mediaRow audio')
        let videoPlayers = document.querySelectorAll('#mediaRow video')
        for (let player of videoPlayers) {
          this.projectLength = this.projectLength + player.duration
        }
        for (let player of audioPlayers) {
          this.projectLength = this.projectLength + player.duration
        }
      }, 1000)
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/colors.scss';
.upload-empty {
  .btn {
    display: block;
    margin-top: 20px;
  }
  h5 {
    font-size: 1.125rem;
  }
  p {
    font-size: 14px;
    margin-bottom: 0;
  }
}

.modal-video-uploader {
  .modal-header{
    padding-bottom: 0;
  }
  .card-media {
    background: #171721;
  }
  .wrapper-upload {
    .back-list {
      display: none;
    }
    .back-list-modal {
      display: block;
    }
    .title-page {
      margin-bottom: 0;
    }
  }
  .library {
    &__search {
      max-width: 100%;
      flex: auto;
    }
  }
}

.ghost {
  opacity: 0.5;
  background: $primary !important;
}
</style>
