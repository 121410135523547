<template>
  <section>
    <Projects
      :class="{ 'd-block': nav == 'library', 'd-none': nav != 'library' }"
      :source="source"
      :selectScenes="selectScenes"
      :changeNav="changeNav"
      :setProjectQuery="setProjectQuery"
      :selectFiles="selectFiles"
      :selectFromLibrary="selectFromLibrary"
      :from_library="from_library"
      @search-query-changed="updateSearchQuery"
    />

    <Scenes
      :class="{ 'd-block': nav == 'scenes', 'd-none': nav != 'scenes' }"
      :source="source"
      :selectScenes="selectScenes"
      :changeNav="changeNav"
      :project_query="project_query"
      :selectFromLibrary="selectFromLibrary"
      :selectFiles="selectFiles"
      :from_library="from_library"
    />

    <Upload
      :class="{ 'd-block': nav == 'upload', 'd-none': nav != 'upload' }"
      :source="source"
      :changeNav="changeNav"
      :from_library="from_library"
      :removeFromLibrary="removeFromLibrary"
      :selectFiles="selectFiles"
    />
  </section>
</template>

<script>
import Projects from './components/Projects'
import Scenes from './components/Scenes'
import Upload from './components/Upload'
import Import from './components/Import.vue'

export default {
  name: 'library-view',

  props: ['selectFiles', 'selectScenes', 'source', 'isLibrary'],

  components: {
    Projects,
    Scenes,
    Upload,
    Import,
  },

  data() {
    return {
      nav: 'upload',
      project_query: '',
      from_library: [],
      query: '',
    }
  },

  mounted() {
    if (!this.source) {
      this.nav = 'library'
    } else if (this.source == 'upload') {
      this.nav = 'upload'
    } else {
      this.nav = 'library'
    }
  },

  methods: {
    changeNav(nav) {
      this.nav = nav
    },

    setProjectQuery(query) {
      this.project_query = query
    },

    selectFromLibrary(item) {
      let removeScenes = false

      if (item.isScene) {
        const projectIndex = this.from_library.findIndex(
          (x) => x.id === item.project_id && !x.isScene
        )
        if (projectIndex >= 0) {
          this.from_library.splice(projectIndex, 1)
        }
      } else {
        const scenesSelected = this.from_library.some(
          (x) => x.project_id === item.id && x.isScene
        )
        if (scenesSelected) {
          this.from_library = this.from_library.filter((x) => x.project_id !== item.id)
          removeScenes = true
        }
      }

      const index = this.from_library.findIndex((x) => x.id === item.id)
      if (index >= 0) {
        this.from_library.splice(index, 1)
      } else if (!removeScenes) {
        this.from_library.push(item)
      }
    },

    removeFromLibrary(id) {
      const index = this.from_library.findIndex((x) => x.id === id)
      if (index >= 0) {
        this.from_library.splice(index, 1)
      }
    },

    updateSearchQuery(newQuery) {
      this.query = newQuery
    },
  },

  watch: {
    isLibrary(val) {
      this.changeNav(val ? 'library' : 'upload')
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/colors';

.selected-toolbar {
  position: fixed;
  width: 100%;
  transition: 0.3s;
  bottom: 0;
  left: 90px;
  z-index: 1100;
  background-color: $black;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
</style>
