import api from "@/api"

const getters = {
  library: state => {
    return state.library
  },

  scenes: state => {
    return state.scenes
  },

  project: state => {
    return state.project
  },
}

const mutations = {
  SET_LIBRARY(state, result) {
    state.library = result && result.data ? result.data : result
  },

  SET_PROJECT(state, result) {
    state.project = result
  },

  SET_PROJECT_SCENES(state, result) {
    state.scenes = result && result.data ? result.data : result
  }
}

const actions = {
  GET_LIBRARY: ({ commit }, payload) => {
    return new Promise(resolve => {
      return api.library
        .getLibrary(payload)
        .then(result => {
          if (payload.page > 1) {
            const arr = state.library.concat(result.data)
            commit('SET_LIBRARY', arr)
          } else {
            commit('SET_LIBRARY', result)
          }
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  POST_LIBRARY: ({ }, payload) => {
    return new Promise(resolve => {
      return api.library
        .postLibrary(payload)
        .then(result => {
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  GET_PROJECT_SCENES: ({ commit }, { id, page }) => {
    return new Promise(resolve => {
      return api.library
        .getProjectScenes(id, page)
        .then(result => {
          if (page > 1) {
            const arr = state.scenes.concat(result.data)
            commit('SET_PROJECT_SCENES', arr)
          } else {
            commit('SET_PROJECT_SCENES', result)
          }
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  SEARCH_LIBRARY: ({ commit }, payload) => {
    return new Promise(resolve => {
      return api.library
        .searchLibrary(payload)
        .then(result => {
          if (payload.page > 1) {
            const arr = state.library.concat(result.data)
            commit('SET_LIBRARY', arr)
          } else {
            commit('SET_LIBRARY', result)
          }
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  SEARCH_PROJECT: ({ commit }, payload) => {
    return new Promise(resolve => {
      return api.library
        .searchProject(payload)
        .then(result => {
          if (payload.page > 1) {
            const arr = state.scenes.concat(result.data)
            commit('SET_PROJECT_SCENES', arr)
          } else {
            commit('SET_PROJECT_SCENES', result)
          }
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  GET_BY_ID: ({ }, id) => {
    return new Promise(resolve => {
      return api.project
        .getById(id)
        .then(result => {
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  REMOVE_LIBRARY: ({ }, id) => {
    return new Promise(resolve => {
      return api.library
        .removeLibrary(id)
        .then(result => {
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  }
}

const state = {
  library: null,
  scenes: null,
  project: null
}

const namespaced = true

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
}
