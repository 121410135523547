<template>
  <div class="text-left">
    <div v-if="me && me.video_tier > 0">
      <div v-if="again">
        <h4>{{ $t('project-edit.export-new-version') }}</h4>
        <p class="text-primary">{{ $t('project-edit.export-new-version-tip') }}</p>
      </div>
      <div v-else>
        <h4>{{ $t('project-edit.export-video') }}</h4>
        <p class="text-default mb-4">{{ $t('project-edit.export-project-tip') }}</p>
      </div>
      <div class="form-group" v-if="!loadingSubtitles && subtitles && model.render_subtitle != 0">
        <label>{{ $t('project-edit.language-embeded') }}</label>
        <select class="form-control mb-3" v-model="localeSubtitles">
          <option v-for="subtitle in subtitles" :key="subtitle.id" :value="subtitle.locale">
            {{ $t(`project-edit.${subtitle.locale}`) }}
          </option>
        </select>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group" v-if="me && me.video_tier > 0">
            <label>{{ $t('project-edit.resolution') }}</label>
            <select class="form-control mb-3" v-model="resolution">
              <option value="1">HD (720p)</option>
              <option value="2">Full HD (1080p)</option>
              <option value="4" v-if="me && me.video_tier > 3">4k (2160p)</option>
            </select>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <label>{{ $t('project-edit.size') }}</label>
            <select class="form-control mb-3" v-model="aspectRatio">
              <option value="">{{ $t('project-edit.original') }}</option>
              <option value="landscape">{{ $t('project-edit.landscape') }} (16x9)</option>
              <option value="portrait">{{ $t('project-edit.portrait') }} (9x16)</option>
              <option value="square">{{ $t('project-edit.square') }} (1x1)</option>
            </select>
          </div>
        </div>
      </div>

      <div class="row justify-content-sm-start">
        <div class="text-justify m-3">
          <div v-if="aspectRatio" class="custom-control custom-switch mb-2">
            <input type="checkbox" class="custom-control-input" id="auto-crop" v-model="model.autocrop" @change="tryUpdate" />
            <label class="custom-control-label" for="auto-crop">
              <p>{{ $t('project-edit.auto-crop') }}</p>
            </label>
            <SvgIcon name="info-octogon" stroke="#5E52F5" width="18px" id="info-render-crop" class="ml-2" />
            <b-popover target="info-render-crop" placement="right" :title="$t('project-edit.info-autocrop')" triggers="hover"></b-popover>
          </div>
          <div class="custom-control custom-switch mb-2">
            <input type="checkbox" class="custom-control-input" id="export-video" v-model="exportVideo" />
            <label v-if="exportVideo" class="custom-control-label" for="export-video">
              <p>{{ $t('project-edit.audio-and-video') }}</p>
            </label>
            <label v-else class="custom-control-label" for="export-video">{{ $t('project-edit.audio-only') }}</label>
          </div>
          <div v-if="exportVideo" class="custom-control custom-switch mb-4">
            <input type="checkbox" class="custom-control-input" id="automatic-subtitles" v-model="model.render_subtitle" @change="tryUpdate" />
            <label class="custom-control-label" for="automatic-subtitles">
              <p>{{ $t('project-edit.automatic-subtitles') }}</p>
            </label>
            <SvgIcon name="info-octogon" stroke="#5E52F5" width="18px" id="info-render-subtitles" class="ml-2" />
            <b-popover target="info-render-subtitles" placement="right" :title="$t('project-edit.info-render-subtitle')" triggers="hover"></b-popover>
          </div>
        </div>
      </div>
      <button
        type="button"
        class="btn"
        :class="{ 'btn-secondary': again, 'btn-primary': !again }"
        @click.prevent="tryExport({ aspectRatio, localeSubtitles, resolution, exportVideo })"
        :disabled="loadingSubtitles"
      >
        <div class="spinner-border text-light" role="status" v-if="loadingSubtitles">
          <span class="sr-only">Loading...</span>
        </div>
        <span v-if="!loadingSubtitles">{{ $t('project-edit.export-video') }}</span>
      </button>
    </div>

    <div v-else>
      <div v-if="again">
        <h4 class="text-success">{{ $t('project-edit.export-new-version') }}</h4>
        <p class="text-primary">{{ $t('project-edit.export-new-version-tip') }}</p>
      </div>
      <div v-else>
        <h4 class="text-warning">
          {{ $t('project-edit.export-project-full-hd') }}
        </h4>
      </div>

      <p class="mt-2">
        {{ $t('project-edit.upgrade-your-plan') }}
      </p>
      <router-link to="/account" class="btn btn-outline border-gold">
        {{ $t('project-edit.upgrade-now') }}
      </router-link>
      <div v-if="again">
        <h4 class="text-primary">{{ $t('project-edit.export-new-version') }}</h4>
        <p class="text-info">{{ $t('project-edit.export-new-version-tip') }}</p>
      </div>
      <h4 v-else class="text-primary" style="margin-top: 60px;">{{ $t('project-edit.export-project-hd') }}</h4>
      <div class="form-group" v-if="!loadingSubtitles && subtitles && model.render_subtitle != 0">
        <label>{{ $t('project-edit.language-embeded') }}</label>
        <select class="form-control mb-3" v-model="localeSubtitles">
          <option v-for="subtitle in subtitles" :key="subtitle.id" :value="subtitle.locale">
            {{ $t(`project-edit.${subtitle.locale}`) }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label>{{ $t('project-edit.size') }}</label>
        <select class="form-control mb-3" v-model="aspectRatio">
          <option value="">{{ $t('project-edit.original') }}</option>
          <option value="landscape">{{ $t('project-edit.landscape') }} (16x9)</option>
          <option value="portrait">{{ $t('project-edit.portrait') }} (9x16)</option>
          <option value="square">{{ $t('project-edit.square') }} (1x1)</option>
        </select>
      </div>
      <div v-if="aspectRatio" class="custom-control custom-switch mb-4">
        <input type="checkbox" class="custom-control-input" id="auto-crop" v-model="model.autocrop" @change="tryUpdate" />
        <label class="custom-control-label" for="auto-crop">
          <p>{{ $t('project-edit.auto-crop') }}</p>
        </label>
        <SvgIcon name="info-octogon" stroke="#5E52F5" width="18px" id="info-render-crop" class="ml-2" />
        <b-popover target="info-render-crop" placement="right" :title="$t('project-edit.info-autocrop')" triggers="hover"></b-popover>
      </div>
      <div class="custom-control custom-switch mt-4 mb-4 text-center">
        <input type="checkbox" class="custom-control-input" id="export-video" v-model="exportVideo" />
        <label v-if="exportVideo" class="custom-control-label" for="export-video">
          <p>
            {{ $t('project-edit.audio-and-video') }}
          </p>
        </label>
        <label v-else class="custom-control-label" for="export-video">
          <p>{{ $t('project-edit.audio-only') }}</p>
        </label>
      </div>
      <div v-if="exportVideo" class="custom-control custom-switch mb-4">
        <input type="checkbox" class="custom-control-input" id="automatic-subtitles" v-model="model.render_subtitle" @change="tryUpdate" />
        <label class="custom-control-label" for="automatic-subtitles">
          <p>{{ $t('project-edit.automatic-subtitles') }}</p>
        </label>
        <SvgIcon name="info-octogon" stroke="#5E52F5" width="18px" id="info-render-subtitles" class="ml-2" />
        <b-popover target="info-render-subtitles" placement="right" :title="$t('project-edit.info-render-subtitle')" triggers="hover"></b-popover>
      </div>
      <button
        type="button"
        class="btn"
        :class="{ 'btn-secondary': again, 'btn-primary': !again }"
        @click.prevent="tryExport({ aspectRatio, localeSubtitles, resolution, exportVideo })"
        :disabled="loadingSubtitles"
      >
        <div class="spinner-border text-light" role="status" v-if="loadingSubtitles">
          <span class="sr-only">Loading...</span>
        </div>
        <span v-if="!loadingSubtitles">{{ $t('project-edit.export-video') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { StripeCheckout } from '@vue-stripe/vue-stripe'
import SvgIcon from '@/components/icon/SvgIcon.vue'

export default {
  name: 'share-preview',

  props: ['downloadSubtitles', 'tryExport', 'selected', 'model', 'tryUpdate', 'again'],

  components: {
    StripeCheckout,
    SvgIcon,
  },

  data() {
    return {
      loading: true,
      loadingSubtitles: true,
      localeSubtitles: this.selected.project.locale,
      aspectRatio: '',
      resolution: 2,
      exportVideo: 1,
    }
  },

  computed: {
    ...mapGetters({
      me: 'user/me',
      subtitles: 'project/subtitles',
    }),
  },

  async mounted() {
    if (this.model.audioonly) this.exportVideo = 0

    await this.getSubtitles({
      id: this.selected.project.id,
      ready: 1,
    })
    this.loading = false
    this.loadingSubtitles = false
  },

  methods: {
    ...mapActions({
      getSubtitles: 'project/GET_SUBTITLES',
    }),

    // checkout() {
    //   window.analytics.track("Buy Now Clicked", {
    //     projectId: this.selected.project.id,
    //     stripeSession: this.stripeSession,
    //   });
    //   this.$refs.checkoutRef.redirectToCheckout();
    // },
  },
}
</script>

<style lang="scss" scoped>
.border-gold {
  border-color: #D3A039;
}
</style>
