<template>
  <div class="create-account">
    <TopBar />
    <div class="d-flex justify-content-center mt-5">
      <div class="col-10 col-sm-6">
        <div class="card bg-light text-dark">
          <div class="card-body">
            <h3 class="d-flex justify-content-between">
              <span>{{ $t('create-account.create-account') }}</span
              ><img src="@/assets/images/favicon.png" height="40" />
            </h3>
            <div class="row">
              <div class="col-sm">
                <div class="form-group">
                  <label
                    ><small
                      ><b>{{ $t('create-account.email') }}</b></small
                    ></label
                  >
                  <input
                    type="email"
                    class="form-control form-control-sm"
                    maxlength="255"
                    v-model="model.email"
                    :class="{ 'is-invalid': $v.model.email.$error }"
                    @keyup.enter="checkInfo"
                  />
                </div>
                <div class="form-group">
                  <label
                    ><small
                      ><b>{{ $t('create-account.confirm-email') }}</b></small
                    ></label
                  >
                  <input
                    type="email"
                    class="form-control form-control-sm"
                    maxlength="255"
                    v-model="model.confirm_email"
                    :class="{ 'is-invalid': $v.model.confirm_email.$error }"
                    @keyup.enter="checkInfo"
                  />
                  <small class="form-text text-danger" v-if="$v.model.confirm_email.$error">{{ $t('create-account.emails-diverge') }}</small>
                </div>
                <div class="form-group">
                  <label
                    ><small
                      ><b>{{ $t('create-account.password') }}</b></small
                    ></label
                  >
                  <input
                    type="password"
                    class="form-control form-control-sm"
                    maxlength="255"
                    v-model="model.password"
                    :class="{ 'is-invalid': $v.model.password.$error }"
                    @keyup.enter="checkInfo"
                  />
                  <small class="form-text text-danger" v-if="$v.model.password.$error">{{ $t('create-account.password-min-length') }}</small>
                </div>
                <div class="form-group">
                  <label
                    ><small
                      ><b>{{ $t('create-account.confirm-password') }}</b></small
                    ></label
                  >
                  <input
                    type="password"
                    class="form-control form-control-sm"
                    maxlength="255"
                    v-model="model.password_confirmation"
                    :class="{
                      'is-invalid': $v.model.password_confirmation.$error,
                    }"
                    @keyup.enter="checkInfo"
                  />
                  <small class="form-text text-danger" v-if="$v.model.password_confirmation.$error">{{
                    $t('create-account.passwords-diverge')
                  }}</small>
                </div>
              </div>
              <div class="col-sm">
                <div class="form-group">
                  <label
                    ><small
                      ><b>{{ $t('create-account.language') }}</b></small
                    ></label
                  >
                  <select class="form-control form-control-sm" v-model="model.locale" @change="changeLocale">
                    <option value="en">English</option>
                    <option value="pt-BR">Português</option>
                    <option value="es">Español</option>
                    <option value="it">Italiano</option>
                    <option value="fr">Français</option>
                    <option value="de">Deutsch</option>
                  </select>
                </div>
                <div class="form-group">
                  <label
                    ><small
                      ><b>{{ $t('create-account.first-name') }}</b></small
                    ></label
                  >
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    maxlength="127"
                    v-model="model.first_name"
                    :class="{ 'is-invalid': $v.model.first_name.$error }"
                    @keyup.enter="checkInfo"
                  />
                </div>
                <div class="form-group">
                  <label
                    ><small
                      ><b>{{ $t('create-account.last-name') }}</b></small
                    ></label
                  >
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    v-model="model.last_name"
                    maxlength="127"
                    :class="{ 'is-invalid': $v.model.last_name.$error }"
                    @keyup.enter="checkInfo"
                  />
                </div>
                <div class="custom-control custom-checkbox mb-3">
                  <input
                    type="checkbox"
                    class="custom-control-input"
                    id="acceptTerms"
                    v-model="model.terms"
                    :class="{ 'is-invalid': $v.model.terms.$error }"
                  />
                  <label class="custom-control-label" for="acceptTerms"
                    >{{ $t(`create-account.read-and-accepted`) }}
                    <a href="https://www.wisecut.video/terms" target="_blank">{{ $t(`create-account.terms-of-use`) }}</a></label
                  >
                </div>
              </div>
            </div>
            <vue-recaptcha ref="recaptcha" @verify="tryRegister" @expired="onCaptchaExpired" size="invisible" :sitekey="recaptchaKey"></vue-recaptcha>
            <button type="button" class="btn btn-block btn-primary" @click="checkInfo" :disabled="loading">
              <span v-if="!loading">{{ $t('create-account.create') }}</span>
              <div class="spinner-border text-light" role="status" v-if="loading">
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-3">
      <small class="text-default">{{ $t('login.social-media') }}</small>
      <h3>
        <a href="https://www.facebook.com/wisecut/" target="_blank" class="text-light"> <i class="bi-facebook"></i> </a>&nbsp;
        <a href="https://www.instagram.com/wisecut.video/" target="_blank" class="text-light"> <i class="bi-instagram"></i> </a>&nbsp;
        <a href="https://www.youtube.com/channel/UCrBMH0uMe9Nt6BMgwytI5Sw" target="_blank" class="text-light"> <i class="bi-youtube"></i></a>
      </h3>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required, email, sameAs, minLength } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import VueRecaptcha from 'vue-recaptcha'

import TopBar from './components/TopBar'

export default {
  name: 'create-account-view',

  components: {
    TopBar,
    VueRecaptcha,
  },

  data() {
    return {
      height: 500,
      loading: false,
      recaptchaKey: process.env.VUE_APP_RECAPTCHA_KEY,
      model: {
        locale: 'en',
        first_name: '',
        last_name: '',
        email: '',
        confirm_email: '',
        password: '',
        password_confirmation: '',
        terms: false,
      },
    }
  },

  mounted() {
    this.height = window.innerHeight
    window.addEventListener('resize', () => {
      this.height = window.innerHeight
    })

    if (navigator.language == 'pt-BR') {
      this.$i18n.locale = 'pt-BR'
    } else {
      this.$i18n.locale = 'en'
    }

    this.model.locale = this.$i18n.locale
  },

  mixins: [validationMixin],

  validations: {
    model: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
        email,
      },
      confirm_email: {
        sameAsEmail: sameAs('email'),
      },
      password: {
        required,
        minLength: minLength(8),
      },
      password_confirmation: {
        sameAsPassword: sameAs('password'),
      },
      terms: {
        sameAs: (val) => val === true,
      },
    },
  },

  methods: {
    ...mapActions({
      register: 'user/REGISTER',
    }),

    changeLocale() {
      this.$i18n.locale = this.model.locale
    },

    onCaptchaExpired() {
      this.$refs.recaptcha.reset()
    },

    checkInfo() {
      this.$v.model.$touch()
      if (this.$v.model.$error) {
        return
      }
      this.executeCaptcha()
    },

    executeCaptcha() {
      this.$refs.recaptcha.execute()
    },

    async tryRegister(recaptchaToken) {
      this.loading = true
      const payload = Object.assign({}, this.model)
      delete payload['confirm_email']
      payload['g-recaptcha-response'] = recaptchaToken
      if (this.$route.params.referral) {
        payload['referral'] = this.$route.params.referral
      }
      payload['clickid'] = this.$route.query.irclickid ?? null
      const result = await this.register(payload)
      if (result.id) {
        this.$bvToast.toast(this.$t('create-account.success-desc'), {
          variant: 'success',
          title: this.$t('create-account.success-title'),
          toaster: 'b-toaster-top-center',
          solid: true,
        })

        window.analytics.identify(result.id, {
          email: result.email,
          name: result.name,
          created_at: result.created_at,
          locale: result.locale,
          utm_source: this.$route.query.utm_source ?? null,
          utm_medium: this.$route.query.utm_medium ?? null,
          utm_campaign: this.$route.query.utm_campaign ?? null,
          utm_adgroup: this.$route.query.utm_adgroup ?? null,
          utm_ad: this.$route.query.utm_ad ?? null,
          utm_term: this.$route.query.utm_term ?? null,
          utm_pagepath: this.$route.query.utm_pagepath ?? null,
          fbclid: this.$route.query.fbclid ?? null,
          gclid: this.$route.query.gclid ?? null,
        })

        fbq('track', 'CompleteRegistration', { currency: 'USD', value: 1.0 })

        ire(
          'trackConversion',
          36656,
          {
            orderId: result.id + '-registration',
            customerId: result.id,
            customerEmail: '',
          },
          {
            verifySiteDefinitionMatch: true,
          }
        )

        setTimeout(() => {
          this.$router.push('/login')
        }, 1500)
      } else {
        this.$refs.recaptcha.reset()
        this.$bvToast.toast(this.$t('create-account.error-desc'), {
          variant: 'danger',
          title: this.$t('create-account.error-title'),
          toaster: 'b-toaster-top-center',
          solid: true,
        })
      }
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';

.create-account {
  padding-top: 30px;
  height: calc(100dvh - 30px);
  background: url('/img/pattern-1.svg') no-repeat fixed;
  background-position: left bottom;
  background-size: cover;
}

.container {
  margin-top: 4rem !important;
}

.form-control,
.form-control-sm {
  color: $black;
  background-color: $light;
}
</style>
