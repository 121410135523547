<template>
  <div class="card card--minor">
    <h5 class="card-title mb-3">{{ $t('account.notifications') }}</h5>

    <div v-if="loading" class="card-loading">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-if="!loading && model && model.render_notifications" class="text-left">
      <div class="custom-control custom-switch">
        <input
          type="checkbox"
          class="custom-control-input"
          id="render-notifications"
          v-model="model.render_notifications"
          @change="tryUpdateRenderNotifications"
        />
        <label class="custom-control-label" for="render-notifications">
          <p>{{ $t('account.receiveEmailNotifications') }}</p>
        </label>
        <SvgIcon name="info-octogon" stroke="#5E52F5" width="18px" id="info-render-notifications" class="ml-2" />
        <b-popover target="info-render-notifications" placement="right" :title="$t('settings.render-notifications-info')" triggers="hover" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SvgIcon from '@/components/icon/SvgIcon.vue'

export default {
  name: 'account-settings',

  props: ['model'],

  components: {
    SvgIcon,
  },

  data() {
    return {
      loading: true,
    }
  },

  computed: {
    ...mapGetters({}),
  },

  async mounted() {
    this.loading = false
  },

  methods: {
    ...mapActions({
      changeRenderNotifications: 'user/CHANGE_RENDER_NOTIFICATIONS',
    }),

    async tryUpdateRenderNotifications() {
      this.loading = true
      await this.changeRenderNotifications(this.model.render_notifications ? 1 : 0)
      this.loading = false
    },
  },
}
</script>
