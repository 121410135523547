<template>
  <div class="row">
    <div class="col-sm-12 col-md-6 col-lg-5">
      <ul class="list-group mb-3">
        <li class="list-group-item bg-transparent">
          <h5 class="text-center">
            {{ $t('project-preview.video-engaging') }}
          </h5>
        </li>

        <li class="list-group-item bg-transparent" v-if="highlights && highlights.removedLength > 0">
          <b class="text-primary"><i class="bi-check-circle-fill"></i> {{ highlights.removedLength }}</b>
          {{ $t('project-preview.awkward-silence') }}
        </li>
        <li class="list-group-item bg-transparent" v-if="highlights && highlights.jumpCutsCreated > 0">
          <b class="text-primary"><i class="bi-check-circle-fill"></i> {{ highlights.jumpCutsCreated }}</b>
          {{ $t('project-preview.jump-cuts') }}
        </li>
        <li class="list-group-item bg-transparent" v-if="highlights && highlights.subtitlesLinesCount > 0">
          <b class="text-primary"><i class="bi-check-circle-fill"></i> {{ highlights.subtitlesLinesCount }}</b>
          {{ $t('project-preview.lines-subtitle') }}
        </li>

        <div v-else class="text-center mb-4">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <li class="list-group-item bg-transparent" v-if="selected.project.autobgm">
          <b class="text-primary"><i class="bi-music-note-beamed text-primary"></i> {{ $t('project-preview.music') }}</b>
          {{ $t('project-preview.perfectly-fits') }}
        </li>
        <li class="list-group-item bg-transparent" v-if="selected.project.autoduck">
          <b class="text-primary"><i class="bi-music-note-beamed text-primary"></i> {{ $t('project-preview.auto-duck') }}</b>
          {{ $t('project-preview.with-auto-duck') }}
        </li>
        <li class="list-group-item bg-transparent" v-if="selected.project.audio_preprocessor">
          <b class="text-primary"><i class="bi-volume-up text-primary"></i> {{ $t('project-preview.audio-enhancer') }}</b>
          {{ $t('project-preview.with-professional-techniques') }}
        </li>
        <li class="list-group-item bg-transparent" v-if="selected.project.voice_booster">
          <b class="text-primary"><i class="bi-volume-down text-primary"></i> {{ $t('project-preview.noise-canceling') }}</b>
          {{ $t('project-preview.using-ai') }}
        </li>
        <li class="list-group-item bg-transparent" v-if="selected.project.autopunchin">
          <b class="text-primary"><i class="bi-zoom-in text-primary"></i> {{ $t('project-preview.auto-punchin') }}</b>
          {{ $t('project-preview.with-auto-punchin') }}
        </li>
      </ul>
      <div class="row ml-1">
        <div class="text-left mb-2 mr-4">
          <div class="btn-group" v-if="selected.project.export_raw_url && selected.project.export_raw_url != '' && !loadingRoute">
            <div
              @click="goToProjectRoute(`/projects/${selected.project.id}/storyboard`)"
              class="btn btn-secondary"
              v-if="selected.project.status != 'Render Ready'"
            >
              <i class="bi-pencil-square"></i> {{ $t('project-preview.edit-project') }}
            </div>
            <div @click="goToProjectRoute(`/projects/${selected.project.id}/share`)" class="btn btn-secondary">
              <i class="bi-share"></i> {{ $t('project-edit.share') }}
            </div>
          </div>

          <div v-else-if="loadingRoute">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div v-else>
            <p>{{ $t('project-preview.preview-ready') }}</p>
            <div>
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <p>{{ $t('project-preview.preparing-for-editing') }}</p>
          </div>
        </div>
        <div v-if="me.video_tier <= 0" class="btn btn-outline border-gold" @click="goToProjectRoute(`/account`)">
          <svg class="crown" width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5 1L16.1667 8L22 3.33333L19.6667 15H3.33333L1 3.33333L6.83333 8L11.5 1Z" stroke="url(#paint0_linear_1_24)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <defs>
            <linearGradient id="paint0_linear_1_24" x1="11.5" y1="1" x2="11.5" y2="15" gradientUnits="userSpaceOnUse">
            <stop stop-color="#B38847"/>
            <stop offset="1" stop-color="#FFC123"/>
            </linearGradient>
            </defs>
          </svg>
          {{ $t('project-edit.remove-watermark') }}
        </div>
      </div>

    </div>
    <div class="col-sm-12 col-md-6 col-lg-7">
      <VideoPlayer
        :thumbnail="selected.project.thumbnailPath"
        :video="selected.project.export_url"
        :subtitles="selected.project.render_subtitle ? subtitles : null"
        v-if="showVideo"
      />
    </div>
  </div>
</template>

<script>
import VideoPlayer from '@/components/VideoPlayer'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'preview-ready',

  props: ['selected', 'highlights', 'preloadingStoryboard'],

  components: {
    VideoPlayer,
  },

  data() {
    return {
      showVideo: false,
      // removedLength: 0,
      // jumpCutsCreated: 0,
      // subtitlesLinesCount: 0,
      subtitles: null,
      loadingRoute: false,
      routeToLoad: null,
    }
  },

  computed: {
    ...mapGetters({
      me: 'user/me',
    }),
  },

  async mounted() {
    if (this.selected) {
      this.subtitles = this.selected.project.final_render != 0 ? null : this.selected.project.subtitles.vtt
      // this.calcHighlights();
      this.showVideo = true
    }
  },

  methods: {
    calcHighlights() {
      this.removedLength = Math.round(this.selected.project.duration_in_seconds - this.selected.project.duration_out_seconds)

      this.jumpCutsCreated = this.selected.timeline.scenes.filter((value) => value.visibility === 0).length

      this.subtitlesLinesCount = this.selected.timeline.scenes
        .filter((value) => value.visibility === 1)
        .reduce((total, scene) => {
          if (scene.text) return (total += scene.text.length)
          else return total
        }, 0)
    },
    goToProjectRoute(route) {
      this.routeToLoad = route
      if (!this.preloadingStoryboard) this.$router.push(route)
      else {
        this.loadingRoute = true
      }
    },
  },

  watch: {
    preloadingStoryboard(val) {
      if (!this.preloadingStoryboard && this.loadingRoute && this.routeToLoad) this.$router.push(this.routeToLoad)
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-secondary {
  @media screen and (max-width: 460px) {
    max-width: 180px;
  }
}
.video-js {
  height: 400px;
}
.border-gold {
  border-color: #D3A039;
}
.crown {
  padding-bottom: 2px;
}
</style>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';
.list-group {
  background: $gray-900;
  .list-group-item {
    border: 0;
    &:not(:first-child) {
      border-top: 1px solid $gray-800;
    }
  }
}
</style>
