<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <div class="card card--large">
          <h5 v-if="loading" class="card-title text-white">{{ $t('account.available-length') }}</h5>

          <div class="card-loading" v-if="loading">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <div v-else>
            <h5 class="card-title text-white mb-3">{{ $t('account.available-length') }}</h5>
            <circular-progress-bar
              :front-colors="['#5E52F5', '#20CE9E']"
              back-color="#0E0E17"
              :back-width="8"
              :front-width="4"
              :value="
                subscribedPlan ? Number(((monthlyMinutes - processingMinutes) / monthlyMinutes) * 100) : Number(100 * (1 - processingMinutes / 1200))
              "
              :text="timestampToString(subscribedPlan ? monthlyMinutes : processingMinutes)"
              rounded
              size="large"
              text-color="#ffffff"
            />

            <div class="card__infos d-flex justify-content-center">
              <span v-if="subscribedPlan">
                <b v-if="subscribedPlan.metadata.monthly_minutes >= 120">
                  {{ (subscribedPlan.metadata.monthly_minutes / 60).toFixed(0) }}
                </b>
                <b v-else>{{ subscribedPlan.metadata.monthly_minutes }}</b>
                <span v-if="subscribedPlan.metadata.monthly_minutes >= 120">
                  {{ $t('pricing.hours') }} / <span class="text-default">{{ $t('pricing.month') }}</span>
                </span>
                <span v-else>
                  {{ $t('pricing.minutes') }} / <span class="text-default">{{ $t('pricing.month') }}</span>
                </span>
              </span>

              <b v-if="processingMinutes > 0 && !subscribedPlan">
                {{ (processingMinutes / 60).toFixed(0) }}
                <span v-if="processingMinutes >= 120">
                  {{ $t('pricing.hours') }} / <span class="text-default">{{ $t('pricing.month') }}</span>
                </span>
              </b>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card card--large">
          <h5 v-if="loading && !me" class="card-title text-white">{{ $t('account.available-size') }}</h5>

          <div v-if="loading && !me" class="card-loading">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <div v-else>
            <h5 class="card-title text-white mb-3">{{ $t('account.available-size') }}</h5>
            <circular-progress-bar
              :front-colors="['#FF0099', '#FF003E']"
              back-color="#0E0E17"
              :back-width="8"
              :front-width="4"
              :value="Number((usedStorage / 1024 ** 3 / (maxStorage / 1024 ** 3)) * 100)"
              :text="String((maxStorage / 1024 ** 3).toFixed(1) + 'GB')"
              rounded
              size="large"
              text-color="#ffffff"
            />
            <div class="card__infos d-flex justify-content-center flex-column text-center">
              <span>{{ maxStorage / 1024 ** 3 }}GB</span>
              <span class="text-pink text-uppercase font-weight-bold mt-1" @click="scrollToPlans" v-if="me && me.video_tier < 1"> Upgrade </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class="card">
          <h5 v-if="loading" class="card-title text-white">{{ $t('account.file-length') }}</h5>
          <h6 v-if="loading" class="card-subtitle text-default">{{ $t('account.singleFile') }}</h6>

          <div v-if="loading" class="card-loading pt-5">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <div v-else>
            <h5 class="card-title text-white">{{ $t('account.file-length') }}</h5>
            <h6 class="card-subtitle text-default">{{ $t('account.singleFile') }}</h6>
            <div class="card-body">
              <SvgIcon name="clock" stroke="#9999B5" width="20px" />
              <p>{{ timestampToString(uploadMaxLengthMinutes) }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="card">
          <h5 v-if="loading" class="card-title text-white">{{ $t('account.file-size') }}</h5>
          <h6 v-if="loading" class="card-subtitle text-default">{{ $t('account.singleFile') }}</h6>

          <div v-if="loading" class="card-loading pt-5">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <div v-else>
            <h5 class="card-title text-white">{{ $t('account.file-size') }}</h5>
            <h6 class="card-subtitle text-default">{{ $t('account.singleFile') }}</h6>
            <div class="card-body">
              <SvgIcon name="cloud-upload" stroke="#9999B5" width="20px" />
              <p>{{ (uploadMaxSizeBytes / 1024 ** 3).toFixed(1) }} GB</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CircularProgressBar from '@/components/CircularProgressBar'
import SvgIcon from '@/components/icon/SvgIcon.vue'

export default {
  name: 'account-quotas',

  props: ['tryUpdateSubscription'],

  components: {
    CircularProgressBar,
    SvgIcon,
  },

  data() {
    return {
      loading: false,
      subscribedPlan: null,
      uploadMaxSizeBytes: 0,
      uploadMaxLengthMinutes: 0,
      processingMinutes: 0,
      storageBytes: 0,
      monthlyMinutes: 0,
      usedStorage: 0,
      maxStorage: 0,
    }
  },

  computed: {
    ...mapGetters({
      me: 'user/me',
      subscription: 'user/subscription',
      quotas: 'user/quotas',
    }),
  },

  async mounted() {
    this.getAllRequests()
  },

  methods: {
    ...mapActions({
      getSubscription: 'user/GET_SUBSCRIPTION',
      getQuotas: 'user/GET_QUOTAS',
    }),

    async getAllRequests() {
      try {
        this.loading = true
        await this.getSubscription()
        await this.getQuotas()
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }

      if (this.quotas) {
        this.uploadMaxSizeBytes = this.quotas.uploadMaxSizeBytes
        this.uploadMaxLengthMinutes = this.quotas.uploadMaxLengthMinutes
        this.processingMinutes = this.quotas.processingMinutes
        this.storageBytes = this.quotas.storageBytes
      }
      if (this.subscription) {
        this.subscribedPlan = this.subscription.stripe_subscription.plan
        this.monthlyMinutes = this.subscribedPlan.metadata.monthly_minutes
      }
      if (this.me) {
        this.usedStorage = this.me.total_used_storage
        this.maxStorage = this.me.max_storage
      }
    },

    timestampToString(totalMinutes) {
      const minutes = Math.floor(totalMinutes % 60)
      const hours = Math.floor(totalMinutes / 60)
      const seconds = Math.floor(((totalMinutes % 60) - minutes) * 60)

      return `${this.padTo2Digits(hours)}:${this.padTo2Digits(minutes)}:${this.padTo2Digits(seconds)}`
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, '0')
    },

    scrollToPlans() {
      window.scrollTo(0, 0)
    },
  },

  watch: {
    quotas(val) {
      if (val) {
        this.uploadMaxSizeBytes = val.uploadMaxSizeBytes
        this.uploadMaxLengthMinutes = val.uploadMaxLengthMinutes
        this.processingMinutes = val.processingMinutes
        this.storageBytes = val.storageBytes
      }
    },
    subscription(val) {
      if (val) {
        this.subscribedPlan = val.stripe_subscription.plan
        this.monthlyMinutes = this.subscribedPlan.metadata.monthly_minutes
      }
    },
    me(val) {
      if (val) {
        this.usedStorage = val.total_used_storage
        this.maxStorage = val.max_storage
      }
    },
  },
}
</script>
