import { responseHandle } from './handle'
import fetch from './fetch'

export default {
  register (payload) {
    const endpoint = `/register`
    return fetch
      .post(endpoint, payload)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  resend () {
    const endpoint = `/user/email/resend`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  login (payload) {
    const endpoint = `/user/login`
    return fetch
      .post(endpoint, payload)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  logout () {
    const endpoint = `/user/logout`
    return fetch.post(endpoint).then(() => {
      return
    })
  },

  getMe() {
    const endpoint = '/user'
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  setMe(payload) {
    const endpoint = '/user'
    return fetch
      .post(endpoint, payload)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  locale(locale) {
    const endpoint = `/user/locale/${locale}`
    return fetch
      .post(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  renderNotifications(status) {
    const endpoint = `/user/settings/notifications/render/${status}`
    return fetch
      .post(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  getYoutubeUrl() {
    const endpoint = `/user/youtube/connect`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  disconnectFromYoutube() {
    const endpoint = `/user/youtube/disconnect`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  getStripeLtdSession(tier) {
    const endpoint = `/stripe/checkout/ltd?ltd_tier=${tier}`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  getSubscription() {
    const endpoint = `/user/payment/subscription`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  getPaymentMethods() {
    const endpoint = `/user/payment/methods`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  getIntentToken() {
    const endpoint = `/user/payment/setupintent`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  savePaymentMethod(payload) {
    const endpoint = `/user/payment/methods`
    return fetch
      .post(endpoint, payload)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  removePaymentMethod(id) {
    const endpoint = `/user/payment/methods/${id}`
    return fetch
      .delete(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  updateSubscription(payload) {
    const endpoint = `/user/payment/subscription`
    return fetch
      .put(endpoint, payload)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  getLimits() {
    const endpoint = `/user/limits`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  getQuotas() {
    const endpoint = `/user/quotas`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  getTawktoHash() {
    const endpoint = `/user/tawkto/hash`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  getImpacttoHash() {
    const endpoint = `/user/impact/hash`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  getAppsumoUpgradeLink() {
    const endpoint = '/user/appsumo/upgradelink'
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  renameUser(payload) {
    const endpoint = '/user/rename'
    return fetch
      .put(endpoint, payload)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  toggleWizard(status) {
    const endpoint = `/user/wizard/toggle/${status}`
    return fetch
      .put(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  }
}
