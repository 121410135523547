import api from "@/api"

const actions = {
  UPDATE_BY_ID: ({ }, { id, payload }) => {
    return new Promise(resolve => {
      return api.subtitle
        .updateById(id, payload)
        .then(result => {
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  }
}

export default {
  namespaced: true,
  actions
}
