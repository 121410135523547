<template>
  <div>
    <p>
      <span class="badge badge-info">{{ $t('library.videos') }}: {{ files.length }}</span>
    </p>
    <div class="d-flex">
      <div class="col-sm-3 video-file-preview" v-for="(file, index) in files" :key="`video_to_upload_${index}`">
        <div class="text-right">
          <a href="#" class="m-0 p-0" @click.prevent="removeVideo(index)">
            <SvgIcon name="trash" stroke="#ffffff" width="18px" />
          </a>
        </div>
        <video
          class="rounded border border-dark"
          style="width: 100%; max-height: 90px"
          :id="`video_${index}`"
          :src="URL.createObjectURL(file.data) + '#t=2'"
          muted
          @mouseover.prevent="playVideo(index)"
          @mouseout.prevent="pauseVideo(index)"
        >
          Your browser does not support the video tag.
        </video>
        <p class="text-center text-truncate mb-0">
          <small class="text-default" :id="`videoDuration_${index}`">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </small>
          <small> - {{ file.data.name }}</small>
        </p>
        <div v-if="!selectFiles">
          <label>
            <small>{{ $t('library.language-of-the-video') }}</small>
          </label>
          <select class="form-control form-control-sm mb-3" v-model="file.locale">
            <option value=""></option>
            <option value="no-speech">{{ $t('library.no-speech') }}</option>
            <option value="en-US">English</option>
            <option value="pt-BR">Português</option>
            <option value="de-DE">Deutsch</option>
            <option value="es-ES">Español</option>
            <option value="fr-FR">Français</option>
            <option value="it-IT">Italiano</option>
            <option value="ar-SA">عربى</option>
            <option value="fil-PH">Filipino</option>
            <option value="hi-IN">Hindi</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/icon/SvgIcon.vue'

export default {
  name: 'library-videos-component',

  props: ['files', 'removeVideo', 'selectFiles'],

  components: {
    SvgIcon,
  },

  data() {
    return {
      URL: URL,
    }
  },

  mounted() {
    setTimeout(() => {
      if (this.files.length) {
        for (let index in this.files) {
          let video = document.querySelector(`#video_${index}`)
          let duration = video.duration
          video.currentTime = duration * 0.1
          document.getElementById(`videoDuration_${index}`).innerHTML = this.timestampToString(duration)
        }
      }
    }, 1000)
  },

  methods: {
    timestampToString(seconds) {
      return new Date(seconds * 1000).toISOString().substr(11, 8)
    },

    playVideo(index) {
      let player = document.querySelector(`#video_${index}`)
      player.play()
    },

    pauseVideo(index) {
      let player = document.querySelector(`#video_${index}`)
      player.pause()
      player.currentTime = player.duration * 0.1
    },
  },

  watch: {
    files(val) {
      setTimeout(() => {
        if (this.files.length) {
          for (let index in this.files) {
            let video = document.querySelector(`#video_${index}`)
            let duration = video.duration
            video.currentTime = duration * 0.1
            document.getElementById(`videoDuration_${index}`).innerHTML = this.timestampToString(duration)
          }
        }
      }, 1000)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';
.video-file-preview {
  border: 1px solid $gray-850;
  border-radius: 10px;
  padding: 10px;
}
</style>
