<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-black fixed-top">
    <a class="navbar-brand" :href="home_url">
      <img src="@/assets/images/logo_white_full.png" height="36" />
    </a>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <form class="form-inline my-2 my-lg-0">
          <select
            class="form-control form-control-sm mr-3"
            @change="localeChange"
            v-if="!me"
          >
            <option value="en" :selected="$i18n.locale == 'en'">English</option>
            <option value="pt-BR" :selected="$i18n.locale == 'pt-BR'">Português</option>
          </select>
        </form>
        <li class="nav-item" v-if="!me">
          <a class="nav-link" :href="home_url"
            ><b>{{ $t("pricing.home") }}</b></a
          >
        </li>
        <li class="nav-item" v-if="me">
          <a class="nav-link" :href="app_url"
            ><b>{{ $t("pricing.return-to-app") }}</b></a
          >
        </li>
        <li class="nav-item" v-if="!me">
          <a class="nav-link" href="/"
            ><b>{{ $t("pricing.sign-in") }}</b></a
          >
        </li>
        <b-nav-item-dropdown right v-if="me" no-caret class="text-right">
          <template #button-content>
            <v-gravatar
              :email="me.email"
              height="26"
              class="p-0 m-0 border border-light rounded-circle mr-1"
            />
            <i class="bi-chevron-down"></i>
          </template>
          <b-dropdown-item href="#" disabled>
            <b class="text-light">{{ me.name }}</b>
            <br />
            <small class="text-info">{{ me.email }}</small>
          </b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <div class="p-2">
            <select class="form-control form-control-sm" @change="localeChange">
              <option value="en" :selected="me.locale == 'en'">English</option>
              <option value="pt-BR" :selected="me.locale == 'pt-BR'">
                Português
              </option>
            </select>
          </div>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item href="#" @click.prevent="tryLogout">{{
            $t("topbar.exit")
          }}</b-dropdown-item>
        </b-nav-item-dropdown>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "topbar-pricing",

  data() {
    return {
      home_url: 'https://wisecut.video',
      app_url: '/'
    }
  },

  computed: {
    ...mapGetters({
      me: "user/me",
    }),
  },

  async mounted() {
    if (!this.me) {
      await this.getMe()
    }

    if (this.me) {
      this.$i18n.locale = this.me.locale;
    } else {
      if (navigator.language == "pt-BR") {
        this.$i18n.locale = "pt-BR";
      } else {
        this.$i18n.locale = "en";
      }
    }

    window.ire('identify', {customerId: '', customerEmail: ''});
  },

  methods: {
    ...mapActions({
      logout: "user/LOGOUT",
      changeLocale: "user/CHANGE_LOCALE",
      getMe: 'user/GET_ME'
    }),

    localeChange(e) {
      if(this.me) {
        this.tryChangeLocale(e.target.value);
      } else {
        this.$i18n.locale = e.target.value;
      }
      
    },

    async tryChangeLocale(locale) {
      await this.changeLocale(locale);
      this.$i18n.locale = locale;
    },

    tryLogout() {
      localStorage.setItem("user", null);
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-link {
  font-size: 1.2rem;
}
</style>