import { responseHandle } from "./handle"
import fetch from "./fetch"

export default {
  updateById(id, payload) {
    const endpoint = `/subtitles/${id}`
    return fetch
      .put(endpoint, payload)
      .then(responseHandle)
      .then(response => {
        return response
      })
  }
}

// /subtitles/${id} PUT