<template>
  <div
    class="navigation-drawer"
    :class="{ 'navigation-drawer--open': isDrawerOpen }"
    @mouseenter="toggleDrawer(true)"
    @mouseleave="toggleDrawer(false)"
  >
    <button class="navigation-drawer__button">
      <SvgIcon name="chevron-left" />
    </button>

    <div class="list-group text-center">
      <router-link to="/" class="list-group-item list-group-item-action">
        <img src="@/assets/images/logo.svg" width="152" />
      </router-link>

      <router-link id="menu-home" to="/" exact class="list-group-item list-group-item-action" active-class="active">
        <SvgIcon name="home" />
        <div class="list-group-item__title">
          {{ $t('menu.home') }}
        </div>
      </router-link>

      <router-link id="menu-new-project" to="/newproject" class="list-group-item list-group-item-action" active-class="active">
        <SvgIcon name="file-plus" />
        <div class="list-group-item__title">
          {{ $t('menu.new-project') }}
        </div>
      </router-link>

      <router-link id="home-project-list" to="/open" class="list-group-item list-group-item-action" active-class="active">
        <SvgIcon name="list-details" />
        <div class="list-group-item__title">
          {{ $t('menu.project-list') }}
        </div>
      </router-link>

      <router-link id="home-library" to="/library" class="list-group-item list-group-item-action" active-class="active">
        <SvgIcon name="camera" />
        <div class="list-group-item__title">
          {{ $t('menu.media') }}
        </div>
      </router-link>

      <a href="https://help.wisecut.video/" target="_blank" id="home-help" class="list-group-item list-group-item-action" active-class="active">
        <SvgIcon name="help" />
        <div class="list-group-item__title">
          {{ $t('menu.help') }}
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import SvgIcon from '@/components/icon/SvgIcon.vue'

export default {
  name: 'menuLeft',

  components: {
    SvgIcon,
  },

  computed: {
    ...mapGetters({
      me: 'user/me',
      isDrawerOpen: 'isDrawerOpen',
    }),
  },

  methods: {
    ...mapMutations({
      toggleDrawer: 'toggleDrawer',
    }),
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';

.navigation-drawer {
  width: 90px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1030;
  background: $gray-900;
  height: 100dvh;
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.3s;
  &--open {
    width: 16rem;
    .list-group {
      .list-group-item {
        padding: 18px 38px 18px 48px;
        &:first-child {
          padding: 42px 38px 42px 48px;
        }
      }
    }
    .navigation-drawer__button {
      background: $black;
      left: 235px;
      svg {
        transform: rotate(0deg);
      }
    }
  }
  &__button {
    position: fixed;
    top: 2.4375rem;
    left: 70px;
    width: 2.75rem;
    height: 2.75rem;
    background: $gray-900;
    z-index: 1;
    border-radius: 50%;
    border: 0;
    box-shadow: none;
    outline: none;
    z-index: 1031;
    transition: 0.3s;
    svg {
      stroke: $white;
      transform: rotate(180deg);
      width: 16px;
    }
  }
}
.list-group {
  .list-group-item {
    border: 0;
    background: transparent;
    transition: 0.3s;
    text-align: left;
    padding: 18px 38px 18px 34px;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    &__title {
      color: $gray-450;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: normal;
      word-wrap: break-word;
    }
    svg {
      min-width: 24px;
      stroke: $gray-450;
    }
    &:hover {
      .list-group-item__title {
        color: $white;
      }
      svg {
        stroke: $pink;
      }
    }

    &:first-child {
      padding: 42px 22px 42px 30px;
      img {
        margin-bottom: 0;
      }
      &:hover {
        background: transparent;
      }
    }
    &.active {
      background: $gray-800;
      .list-group-item__title {
        color: $white;
      }
      svg {
        stroke: $pink !important;
      }
    }
  }
}
</style>
