<template>
  <div>
    <div class="wrapper-storage">
      <span class="text-white"> {{ $t('project-open.used-storage') }}: </span>
      <span class="wrapper-storage--used" :class="{ 'text-success': percentageUsed < 90, 'text-danger': percentageUsed >= 90 }">
        {{ (usedStorage() / 1024 ** 3).toFixed(1) }} GB
      </span>
      <span class="text-default"> {{ (maxStorage() / 1024 ** 3).toFixed(1) }} GB</span>
    </div>
    <div class="progress">
      <div
        class="progress-bar"
        role="progressbar"
        :style="`width: ${(usedStorage() * 100) / maxStorage()}%`"
        :aria-valuenow="(usedStorage() * 100) / maxStorage()"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <!-- {{ ((usedStorage() * 100) / maxStorage()).toFixed(0) }}% -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'storage-measure',

  props: ['usedStorage', 'maxStorage'],

  computed: {
    percentageUsed() {
      return (this.usedStorage() / this.maxStorage()) * 100
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';
.progress {
  box-shadow: none;
  background: $gray-800;
  height: 11px;
  border-radius: 11px;
}
.wrapper-storage {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 5px;
}
</style>
