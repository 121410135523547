const headers = () => {
  const auth = JSON.parse(localStorage.getItem("user"))

  return {
    "Content-Type": "application/json",
    "Access-Control-Request-Headers": "Content-Type, X-PINGOTHER", 
    Accept: "application/json",
    Authorization: auth ? "Bearer " + auth.api_token : "",
  }
}

export default {
  get: (url, options) => {
    const fetchOptions = Object.assign(
      {
        method: "GET",
        headers: headers(),
      },
      options
    )
    
    return fetch(`${process.env.VUE_APP_API_URL}` + url, fetchOptions)
  },

  post: (url, body, options) => {
    const fetchOptions = Object.assign(
      {
        method: "POST",
        headers: headers(),
      },
      options
    )
    if (body !== undefined) {
      fetchOptions.body = JSON.stringify(body)
    }

    return fetch(`${process.env.VUE_APP_API_URL}` + url, fetchOptions)
  },

  put: (url, body, options) => {
    const fetchOptions = Object.assign(
      {
        method: "PUT",
        headers: headers(),
      },
      options
    )
    if (body !== undefined) {
      fetchOptions.body = JSON.stringify(body)
    }

    return fetch(`${process.env.VUE_APP_API_URL}` + url, fetchOptions)
  },

  patch: (url, body, options) => {
    const fetchOptions = Object.assign(
      {
        method: "PATCH",
        headers: headers(),
      },
      options
    )
    if (body !== undefined) {
      fetchOptions.body = JSON.stringify(body)
    }

    return fetch(`${process.env.VUE_APP_API_URL}` + url, fetchOptions)
  },

  delete: (url, options) => {
    const fetchOptions = Object.assign(
      {
        method: "DELETE",
        headers: headers(),
      },
      options
    )
    
    return fetch(`${process.env.VUE_APP_API_URL}` + url, fetchOptions)
  },
}
