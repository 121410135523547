<template>
  <div id="project-new-view">
    <div :class="{ 'd-block': loading, 'd-none': !loading }" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div
      :class="{
        'd-block': !creatingProject && !loading,
        'd-none': creatingProject || loading,
      }"
    >
      <h5 class="title-page">
        {{ $t('new-project.create-new-project') }}
      </h5>

      <div v-if="uploads.progress > 0 && files.length > 0" class="mb-3">
        <small class="text-secondary" v-if="uploads.progress < 100">
          <b>{{ $t('new-project.uploading-files') }} - {{ uploads.progress.toFixed(0) }}%</b>
        </small>
        <small class="text-success" v-if="uploads.progress >= 100">
          <b>{{ $t('new-project.videos-upload-completed') }}!</b>
        </small>
        <div class="progress" style="height: 5px">
          <div
            class="progress-bar"
            :class="{
              'bg-success': uploads.progress >= 100,
              'bg-primary': uploads.progress < 100,
            }"
            role="progressbar"
            :style="`width: ${uploads.progress}%`"
            :aria-valuenow="uploads.progress"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </div>

      <Nav :nav="nav" :files="files" :changeNav="changeNav" :scheduled="scheduled" :isVlog="isVlog" :locale="model.locale" />

      <Upload
        :class="{ 'd-block': nav == 'upload', 'd-none': nav != 'upload' }"
        :uppy="uppy"
        :updateProgress="updateProgress"
        :updateFiles="updateFiles"
        :disableDrag="disableDrag"
        :changeNav="changeNav"
        :changeProjectSize="changeProjectSize"
        :changeProjectLength="changeProjectLength"
        :files="files"
      />

      <Create
        v-if="nav == 'create'"
        :files="files"
        :progress="uploads.progress"
        :updateProgress="updateProgress"
        :model="model"
        :checkFiles="checkFiles"
        :schedule="schedule"
        :scheduled="scheduled"
        :showLimits="showLimits"
        :isVlog="isVlog"
        :video_style="video_style"
        :changeNav="changeNav"
        :changeStyle="changeStyle"
        :setIsVlog="setIsVlog"
      />
    </div>

    <div v-if="creatingProject && !loading">
      <div class="row justify-content-sm-center">
        <div class="col-sm-4">
          <div class="card">
            <div class="card-body text-center">
              <img src="@/assets/images/logo_white.png" width="96" />
              <br /><br />
              <h1><i class="bi-check-circle-fill text-success"></i></h1>
              <h2>{{ $t('new-project.now-its-with-us') }}</h2>
              <p>{{ $t('new-project.wisecut-creating-project') }}</p>
              <router-link to="/open" class="btn btn-outline mr-auto ml-auto mt-4">
                <SvgIcon name="folder" stroke="#fff" width="18px" class="mb-1" />
                {{ $t('menu.project-list') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-leave-page" hide-footer no-close-on-backdrop centered no-close-on-esc>
      <template #modal-header="{ close }">
        {{ $t('new-project.leave-page-title') }}
        <button class="btn btn-link p-0" size="sm" variant="outline-danger" @click="close()">
          <SvgIcon name="cancel" stroke="#ffffff" />
        </button>
      </template>

      <p>{{ $t('new-project.leave-page-question') }}</p>
      <p>{{ $t('new-project.leave-page-description') }}</p>

      <div class="d-flex justify-content-between mt-5">
        <button
          type="button"
          class="btn btn-secondary"
          @click.prevent="
            $bvModal.hide('modal-leave-page')
            leaveTo = null
          "
        >
          {{ $t('new-project.cancel') }}
        </button>
        <button type="button" class="btn btn-primary" @click.prevent="leaveToPage">
          {{ $t('new-project.confirm') }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

import SvgIcon from '@/components/icon/SvgIcon.vue'

import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'

import Nav from './components/Nav'
import Upload from './components/Upload'
import Style from './components/Style'
import Music from './components/Music'
import Create from './components/Create'

export default {
  name: 'project-new-view',

  components: {
    Nav,
    Upload,
    Style,
    Music,
    Create,
    SvgIcon,
  },

  data() {
    return {
      uppy: new Uppy().use(AwsS3, {
        getUploadParameters(file) {
          const url = process.env.NODE_ENV == 'development' ? '' : process.env.VUE_APP_API_URL
          const auth = JSON.parse(localStorage.getItem('user'))
          return fetch(`${url}/signeds3`, {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              'X-CSRF-TOKEN': process.env.VUE_APP_AWS_CSRF_TOKEN,
              Authorization: auth ? 'Bearer ' + auth.api_token : '',
            },
            body: JSON.stringify({
              extension: file.type.split('/').pop(),
            }),
          })
            .then((response) => {
              return response.json()
            })
            .then((data) => {
              return {
                method: 'POST',
                url: data.postEndpoint,
                fields: data.signature,
                headers: {},
              }
            })
        },
      }),
      leaveTo: null,
      loading: false,
      creatingProject: false,
      scheduled: false,
      nav: 'upload',
      showLimits: false,
      uploads: {
        progress: 0,
        total: 0,
        sent: 0,
      },
      projectSize: 0,
      projectLength: 0,
      disableDrag: true,
      files: [],
      video_style: 'vlog',
      project_id: null,
      isVlog: true,
      model: {
        project: {
          autobgm: 1,
          autocutsilences: 1,
          smoothcuts: 1,
          autoduck: 1,
          autointro: 0,
          autooutro: 0,
          autopunchin: 1,
          bgm_id: null,
          intro_duration: null,
          mute_videos: 0,
          name: '',
          no_speech: 0,
          render_subtitle: 0,
          tool_type: 'video',
          voice_booster: 0,
        },
        media: [],
        locale: '',
      },
    }
  },

  computed: {
    ...mapGetters({
      me: 'user/me',
      limits: 'user/limits',
    }),
  },

  mounted() {
    window.analytics.track('New Project Started')
    this.getLimits()
  },

  beforeRouteLeave(to, from, next) {
    if (!this.leaveTo) {
      this.$bvModal.show('modal-leave-page')
      this.leaveTo = to.path
    } else {
      next()
    }
  },

  methods: {
    ...mapMutations({
      toggleAudioPlayer: 'common/TOGGLE_AUDIO_PLAYER',
    }),

    ...mapActions({
      createProject: 'project/CREATE_PROJECT',
      getLimits: 'user/GET_LIMITS',
    }),

    leaveToPage() {
      this.$router.push(this.leaveTo)
    },

    changeNav(nav) {
      this.nav = nav
      if (this.nav != 'music') {
        this.toggleAudioPlayer(false)
      }
    },

    setIsVlog(param) {
      this.isVlog = param
    },

    updateProgress(progress) {
      this.uploads.progress = progress
    },

    updateFiles(files) {
      this.files = files
    },

    changeProjectSize(size) {
      this.projectSize = size
    },

    changeProjectLength(length) {
      this.projectLength = length
    },

    changeStyle(param) {
      this.video_style = param
      switch (param) {
        case 'vlog':
          this.model.project.no_speech = 0
          this.model.project.mute_videos = 0
          break
        case 'no_speech':
          this.model.project.no_speech = 1
          this.model.project.mute_videos = 0
          break
        case 'mute_videos':
          this.model.project.no_speech = 0
          this.model.project.mute_videos = 1
          break
      }
    },

    changeIntro(param) {
      this.model.project.intro_mode = param
    },

    changeOutro(param) {
      this.model.project.outro_mode = param
    },

    pickSong(song) {
      this.model.project.bgm_id = song.id
      window.analytics.track('New Project Song Selected', {
        bgmId: song.id,
        bgmTitle: song.title,
      })
    },

    schedule(prop) {
      this.scheduled = prop
    },

    checkFiles() {
      this.tryCreate()
    },

    async tryCreate() {
      if (this.projectLength > (this.limits.minutes.total - this.limits.minutes.used) * 60) {
        this.$bvToast.toast(this.$t(`new-project.length-limit`), {
          variant: 'danger',
          title: this.$t('new-project.limits-error'),
          toaster: 'b-toaster-top-center',
          solid: true,
          noAutoHide: true,
        })
        return
      }
      if (this.projectSize > this.limits.storage.total - this.limits.storage.used) {
        this.$bvToast.toast(this.$t(`new-project.size-limit`), {
          variant: 'danger',
          title: this.$t('new-project.limits-error'),
          toaster: 'b-toaster-top-center',
          solid: true,
          noAutoHide: true,
        })
        return
      }
      const uppyFiles = this.uppy.getFiles()
      let orderedFiles = []
      for (let x of this.files) {
        if (x.utype == 'file') {
          let file = uppyFiles.find((y) => y.name === x.data.name)
          orderedFiles.push({
            utype: 'file',
            data: file,
          })
        } else {
          orderedFiles.push(x)
        }
      }
      this.model.media = []
      for (let file of orderedFiles) {
        if (file.utype == 'file') {
          this.model.media.push({
            type: 'file',
            id: file.data.meta.key,
          })
        } else {
          this.model.media.push({
            type: 'library',
            id: file.data.id,
          })
        }
      }
      if (!this.model.project.autobgm) {
        this.model.project.bgm_id = null
      }
      if (!this.isVlog) {
        this.model.autocutsilences = 0
        this.model.smoothcuts = 0
        this.model.voice_booster = 0
        this.model.locale = ''
      }
      this.loading = true
      const result = await this.createProject(this.model)
      if (result.status == 'success') {
        this.leaveTo = '/open'
        window.onbeforeunload = null
        this.creatingProject = true
        this.project_id = result.project_id
      } else {
        if (!result.errorCode) {
          this.leaveTo = '/open'
          window.onbeforeunload = null
          this.$router.push('/open')
        } else {
          this.showLimits = true
          this.$bvToast.toast(this.$t(`new-project.error-${result.errorCode}`), {
            variant: 'danger',
            title: this.$t('new-project.create-error'),
            toaster: 'b-toaster-top-center',
            solid: true,
            noAutoHide: true,
          })
        }
      }
      this.loading = false
    },
  },
}
</script>
