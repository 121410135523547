<template>
  <div class="p-2">
    <div class="mobile-video-player" v-if="showPlayer">
      <div class="text-right bg-black">
        <button
          type="button"
          class="btn btn-link"
          @click.prevent="
            showPlayer = false
            playing = 0
          "
        >
          <i class="bi-x-circle"></i>
        </button>
      </div>
      <div class="p-3 text-center" v-if="selected.project.raw_preview_render == 0">
        <p>
          <b>{{ $t('project-edit.needs-raw-render') }}</b>
        </p>
        <button
          type="button"
          class="btn btn-sm btn-primary"
          @click.prevent="tryRenderRaw"
          :disabled="renderRawLoading || selected.project.working_progress_raw < 100"
        >
          <div class="spinner-border spinner-border-sm text-light" role="status" v-if="renderRawLoading">
            <span class="sr-only">Loading...</span>
          </div>
          <span v-if="!renderRawLoading && selected.project.working_progress_raw == 100">{{ $t('project-edit.render-now') }}</span>
        </button>
      </div>
      <VideoPlayer
        :thumbnail="selected.project.thumbnailPath"
        :video="selected.project.export_raw_url"
        :subtitles="null"
        :start="start"
        :end="end"
        :autoplay="autoplay"
        :currentTime="currentTime"
        :height="200"
        v-if="selected.project.raw_preview_render == 1"
      />
      <div class="m-2 text-center">
        <small style="opacity: 0.7"
          >{{ $t('project-edit.this-is-reference') }}
          <router-link :to="`/projects/${$route.params.id}/share`"><i class="bi-share"></i> {{ $t('project-edit.share') }}</router-link>
          {{ $t('project-edit.for-rendered-video') }}</small
        >
      </div>
    </div>
    <div class="selected-scenes-toolbar p-3 text-center" v-if="enableSelect">
      <p>{{ selectedScenes.length }} {{ $t('project-edit.scenes-selected') }} ({{ timestampToString(selectedScenesLength) }})</p>
      <button type="button" class="btn btn-primary" @click.prevent="$bvModal.show('modal-create-clips-project')">
        {{ $t('project-edit.create-clips-project') }}
      </button>
    </div>
    <div class="row m-0" :class="{ 'margin-top': showPlayer }">
      <div :class="{ 'col-10': enableDrag, 'col-12': !enableDrag }" style="margin-bottom: 150px">
        <div class="d-flex flex-column justify-content-between">
          <div class="custom-control custom-switch m-3">
            <input type="checkbox" class="custom-control-input" id="enableHideRemovedScenes" v-model="enableHideRemovedScenes" />
            <label class="custom-control-label" for="enableHideRemovedScenes"
              ><small class="text-secondary"><i class="bi-trash"></i> {{ $t('project-edit.enable-hide-removed-scenes') }}</small></label
            >
          </div>
          <div class="custom-control custom-switch m-3">
            <input type="checkbox" class="custom-control-input" id="enableDrag" v-model="enableDrag" @change.prevent="enableSelect = false" />
            <label class="custom-control-label" for="enableDrag"
              ><small class="text-secondary"><i class="bi-grip-vertical"></i> {{ $t('project-edit.drag-to-reorder') }}</small></label
            >
          </div>
          <div class="custom-control custom-switch m-3">
            <input type="checkbox" class="custom-control-input" id="enableSelect" v-model="enableSelect" @change.prevent="enableDrag = false" />
            <label class="custom-control-label" for="enableSelect"
              ><small class="text-secondary"><i class="bi-check-square"></i> {{ $t('project-edit.enable-select') }}</small></label
            >
          </div>
          <p class="length-info text-center">
            <span>{{ $t('project-edit.project-length') }}</span>
            <br />
            <i class="bi-clock"></i> {{ timestampToString(exportLength) }}
          </p>
          <div class="text-right">
            <button type="button" class="btn btn-lg btn-link m-0 p-0" style="font-size: 2rem" @click="$bvModal.show('modal-info')">
              <i class="bi-question-circle"></i>
            </button>
          </div>
        </div>
        <hr class="border-primary" />
        <a
          href="#"
          class="btn btn-lg btn-link"
          @click.prevent="addMoreFiles(1)"
          :title="`${$t('project-edit.tools-add')}`"
          style="position: absolute; top: 225px; left: 50%; transform: translateX(-50%); z-index: 1"
        >
          <i class="bi-plus-circle-fill"></i>
        </a>
        <draggable
          :list="selected.timeline.scenes"
          :disabled="!enableDrag"
          class="list-group list-group-flush"
          ghost-class="ghost"
          @end="dragEnd"
          :scroll-sensitivity="500"
          :force-fallback="true"
        >
          <div v-for="(scene, index) in selected.timeline.scenes" :key="scene.id">
            <li
              v-if="scene.visibility || !enableHideRemovedScenes"
              class="list-group-item"
              :class="{
                'active-scene': scene.trim_start_raw < currentTime && scene.trim_end_raw > currentTime && scene.visibility == 1,
              }"
            >
              <Overscene
                v-if="scene.overscene"
                :scene="scene.overscene"
                :backgroundScene="scene"
                :index="index + '_overscene'"
                :backgroundIndex="index"
                :playScene="playScene"
                :playFullVideo="playFullVideo"
                :project="selected.project"
                :currentTime="currentTime"
                :reloadPlayer="reloadPlayer"
                :enableDrag="enableDrag"
                :scissorsScene="scissorsScene"
                :enableScissors="enableScissors"
                :disableScissors="disableScissors"
                :checkNotRendered="checkNotRendered"
                :enableSelect="enableSelect"
                :selectScene="selectScene"
                :selectedScenes="selectedScenes"
                v-on:current-position="(val) => (video.currentTime = val)"
                v-on="$listeners"
              />
              <Scene
                :scene="scene"
                :index="index"
                :playScene="playScene"
                :playFullVideo="playFullVideo"
                :project="selected.project"
                :currentTime="currentTime"
                :reloadPlayer="reloadPlayer"
                :enableDrag="enableDrag"
                :scissorsScene="scissorsScene"
                :enableScissors="enableScissors"
                :disableScissors="disableScissors"
                :showPlayer="showPlayer"
                :checkNotRendered="checkNotRendered"
                :enableSelect="enableSelect"
                :selectedScenes="selectedScenes"
                :selectScene="selectScene"
                v-on="$listeners"
              />
              <div class="d-flex align-items-center" style="position: absolute; bottom: -24px; left: 50%; transform: translateX(-50%); z-index: 1">
                <a href="#" class="btn btn-lg btn-link" @click.prevent="addMoreFiles(scene.order + 1)" :title="`${$t('project-edit.tools-add')}`">
                  <i class="bi-plus-circle-fill"></i>
                </a>
                <a
                  v-if="
                    mergingScenes.findIndex((x) => x === scene.id) < 0 &&
                    typeof selected.timeline.scenes[index + 1] !== 'undefined' &&
                    (selected.timeline.scenes[index + 1].visibility || !enableHideRemovedScenes) &&
                    scene.trim_end_default == selected.timeline.scenes[index + 1].trim_start_default &&
                    scene.media_file_id == selected.timeline.scenes[index + 1].media_file_id
                  "
                  href="#"
                  class="btn btn-lg btn-link"
                  @click.prevent="mergeScenes(scene, selected.timeline.scenes[index + 1])"
                  :title="`${$t('project-edit.merge-next-scene')}`"
                >
                  <i class="bi-arrows-collapse"></i>
                </a>
                <div v-if="mergingScenes.findIndex((x) => x === scene.id) >= 0" class="spinner-border text-primary" role="status"></div>
              </div>
            </li>
          </div>
        </draggable>
      </div>
      <div class="col-2 m-0 p-0 text-center" v-if="enableDrag">
        <div class="scrollRegion" :style="`height: ${scrollRegionHeight}px`">
          <i class="bi-arrow-up text-light"></i>
          <div class="line-dashed" style="height: 80%"></div>
          <i class="bi-arrow-down text-light"></i>
        </div>
      </div>
    </div>
    <ModalClipsProject
      :selected="selected"
      :selectedScenes="selectedScenes"
      :selectedScenesLengthTimestamp="timestampToString(selectedScenesLength)"
    />
    <ModalInfo />
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import draggable from 'vuedraggable'

import VideoPlayer from '@/components/VideoPlayer'
import Scene from '../components/Scene'
import ModalInfo from '../components/ModalInfo'
import ModalClipsProject from '../components/ModalClipsProject'
import Overscene from '../components/Overscene.vue'

export default {
  name: 'storyboard-mobile',

  props: ['selected', 'needsRender', 'tryRender', 'checkNotRendered', 'tryRenderRaw', 'renderRawLoading', 'exportLength'],

  components: {
    VideoPlayer,
    Scene,
    Overscene,
    draggable,
    ModalInfo,
    ModalClipsProject,
  },

  data() {
    return {
      start: 0,
      end: null,
      autoplay: true,
      scrollRegionHeight: 100,
      currentTime: 0,
      playing: 0,
      showPlayer: false,
      enableDrag: false,
      enableSelect: false,
      enableHideRemovedScenes: false,
      scissorsScene: null,
      scissorsLine: null,
      video: null,
      scenes: null,
      selectedScenes: [],
      selectedScenesLength: 0,
      mergingScenes: [],
    }
  },

  mounted() {
    this.scrollRegionHeight = window.innerHeight - 200
    window.addEventListener('resize', () => {
      this.scrollRegionHeight = window.innerHeight - 200
    })
  },

  methods: {
    ...mapActions({
      changeOrder: 'scene/CHANGE_ORDER',
      mergeNextScene: 'scene/MERGE_NEXT_SCENE',
      render: 'project/RENDER',
    }),

    async mergeScenes(scene) {
      this.mergingScenes.push(scene.id)
      await this.mergeNextScene(scene)
      const index = this.mergingScenes.findIndex((x) => x === scene.id)
      this.mergingScenes.splice(index)
      this.$emit('update-storyboard', [scene.id, nextScene.id], true, -1)
    },

    defineStartEnd() {
      this.start = this.scenes[0].trim_start_raw
      this.end = this.scenes[this.scenes.length - 1].trim_end_raw
    },

    playScene(scene) {
      this.showPlayer = false
      this.playing = -1
      this.start = scene.trim_start_raw
      this.end = scene.trim_end_raw
      setTimeout(() => {
        this.showPlayer = true
      }, 100)
      setTimeout(() => {
        this.video = document.getElementsByClassName('vjs-tech')[0]
        if (this.video) {
          this.video.ontimeupdate = () => {
            this.currentTime = this.video.currentTime
          }
          this.video.currentTime = this.start
          this.video.play()
        }
      }, 400)
    },

    playFullVideo(order, line_start) {
      this.showPlayer = false
      this.scenes = this.selected.timeline.scenes.filter((x) => x.visibility == 1 && x.order >= order)
      this.defineStartEnd()
      this.playing = 0
      setTimeout(() => {
        this.showPlayer = true
      }, 100)
      setTimeout(() => {
        this.video = document.getElementsByClassName('vjs-tech')[0]
        if (this.video) {
          this.video.ontimeupdate = () => {
            this.currentTime = this.video.currentTime
          }
          this.video.currentTime = line_start ? line_start : this.start
          this.video.play()
        }
      }, 400)
      setTimeout(() => {
        const scene = document.getElementsByClassName('active-scene')[0]
        if (scene) {
          window.scrollTo(0, scene.offsetTop + 90)
        }
      }, 800)
    },

    reloadPlayer(scene) {
      this.showPlayer = false
      setTimeout(() => {
        this.showPlayer = true
      }, 200)
    },

    dragEnd(e) {
      const scene = this.selected.timeline.scenes[e.newIndex]
      const order = e.newIndex + 1
      this.changeOrder({ scene, order })
    },

    enableScissors(index) {
      this.scissorsScene = index
    },

    disableScissors() {
      this.scissorsScene = -1
    },

    selectScene(id, length) {
      const index = this.selectedScenes.findIndex((x) => x === id)
      if (index >= 0) {
        this.selectedScenes.splice(index, 1)
        this.selectedScenesLength -= length
      } else {
        this.selectedScenes.push(id)
        this.selectedScenesLength += length
      }
    },

    timestampToString(seconds) {
      return new Date(seconds * 1000).toISOString().substr(11, 8)
    },

    addMoreFiles(order) {
      this.$router.push(`/projects/${this.$route.params.id}/library/${order}`)
    },
  },

  watch: {
    currentTime(val) {
      if (this.playing > -1) {
        if (this.playing < this.scenes.length - 1) {
          if (val >= parseFloat(this.scenes[this.playing].trim_end_raw)) {
            this.playing++
            this.video.currentTime = this.scenes[this.playing].trim_start_raw
            this.end = this.scenes[this.playing].trim_end_raw
            setTimeout(() => {
              const scene = document.getElementsByClassName('active-scene')[0]
              if (scene) {
                window.scrollTo(0, scene.offsetTop + 90)
              }
            }, 1000)
          }
        } else {
          if (val >= this.end) {
            this.video.pause()
          }
        }
      } else {
        if (val >= this.end) {
          this.video.pause()
        }
      }
    },
  },
}
</script>

<style lang="scss">
.list-group-item img {
  margin-bottom: 10px;
}
</style>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';

.mobile-video-player {
  position: fixed;
  width: 100%;
  border-bottom: 1px solid #000;
  background-color: $black;
  top: 0;
  left: 0;
  z-index: 1100;
  box-shadow: 0 0 5px 5px $dark;
}

.selected-scenes-toolbar {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1100;
  background-color: $black;
}

.margin-top {
  margin-top: 170px;
}

.scrollRegion {
  background-color: rgba(255, 255, 255, 0.1);
  position: sticky;
  top: 90px;
  width: 40px;

  .bi-arrow-up {
    position: absolute;
    top: 10px;
    left: 13px;
  }

  .line-dashed {
    position: absolute;
    left: 20px;
    top: 46px;
    border-left: 2px dashed $light;
  }

  .bi-arrow-down {
    position: absolute;
    bottom: 10px;
    left: 13px;
  }
}

.list-group-item {
  border-left: 0;
  border-right: 0;
  border-top: 0;
}
</style>
