<template>
  <div>
    
    <div class="row mb-2 justify-content-center">
      <div class="col-sm-6 text-center">
        <div class="custom-control custom-switch">
          <input
            type="checkbox"
            class="custom-control-input"
            id="automatic-music"
            v-model="model.project.autobgm"
          />
          <label class="custom-control-label" for="automatic-music">{{
            $t("new-project.automatic-music")
          }}</label>
        </div>
      </div>
    </div>
    <div
      class="alert alert-dark text-center text-success d-flex justify-content-between"
      role="alert"
      v-if="!model.project.autobgm || model.project.bgm_id"
    >
      <b><i class="bi-check-circle-fill"></i> {{ $t('new-project.good-work') }}</b>
      <button type="button" class="btn btn-default m-0 p-0" @click.prevent="changeNav('create')">
        {{ $t("new-project.next") }} <i class="bi-arrow-right-circle"></i>
      </button>
    </div>
    <MusicPicker
      :bgm_id="model.project.bgm_id"
      :pickSong="pickSong"
      v-if="model.project.autobgm == 1"
    />
  </div>
</template>

<script>
import MusicPicker from "@/components/MusicPicker";

export default {
  name: "new-project-music",

  props: ["model", "pickSong", "changeIntro", "changeOutro", "files", "changeNav"],

  components: {
    MusicPicker,
  },

  data() {
    return {
      customizeIntroOutro: false,
    };
  },

  mounted() {
    this.customizeIntroOutro = this.model.project.intro_mode != null || this.model.project.outro_mode != null ? true : false
    if(this.files.length > 1){
      this.customizeIntroOutro = true;
    }
  },
};
</script>
