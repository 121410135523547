<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <h5>{{ $t('home.update-type') }}</h5>

      <div class="card w-fit">
        <div class="d-flex align-items-center wrapper-video">
          <div class="flex-shrink-0 wrapper-video__video">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/oak2e_xJuTM?si=YdOPH4edOmEj7CMV"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          <div class="flex-grow-1 wrapper-video__title">
            {{ $t('home.update-title') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuickTips',
}
</script>

<style lang="scss" scoped>
.card {
  @media screen and (max-width: 600px) {
    width: 100% !important;
  }
}
.wrapper-video {
  gap: 1rem;
  &__video {
    position: relative;
    width: 310px;
    height: 165px;
    @media screen and (max-width: 600px) {
      width: 220px;
      height: 120px;
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &__title {
    max-width: 125px;
    font-size: 1rem;
  }
}
</style>
