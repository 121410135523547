<template>
    <div class="congratulationsContainer">
        <h1>{{ $t('ui.congratulations') }}</h1>
        <p>{{ $t('ui.premium-user') }}<br> {{ $t('ui.welcome') }}</p>
        <button type="button" class="btn btn-primary" @click='redirectToHome'>{{ $t('ui.explore') }}</button>
    </div>
</template>


<script>
import confetti from "canvas-confetti";

export default {
    name: 'congratulations-view',
    mounted() {
        this.confettiEffect()
    },
    methods: {
        redirectToHome() {
            this.$router.push({ name: 'home' }).then(() => {
                window.location.reload();
            });
        },
        confettiEffect() {
            const firstForm = confetti.shapeFromPath({ path: 'M34.8892 0C38.8492 8.31 42.7992 16.62 46.7592 24.92C32.0492 30.78 16.0092 36.72 0.759155 32.49C0.799155 32.5 2.42916 12.63 2.59916 11.35C12.6892 19 29.8192 9.55 34.8992 0L34.8892 0Z' });
            const secondForm = confetti.shapeFromPath({ path: 'M21.417 83.1756L22.757 84.5555C22.757 84.5555 30.987 78.9155 36.087 76.4455C41.187 73.9755 44.637 72.4656 46.777 72.3156C48.917 72.1556 42.177 71.3155 36.087 73.1555C30.007 74.9955 26.637 77.0855 26.637 77.0855L21.407 83.1756H21.417Z" fill="url(#paint0_linear_110_1192)' });
            confetti({
                particleCount: 70,
                spread: 100,
                shapes: [firstForm,secondForm ],
                colors: ['#C88628', '#FEEB92', '#B15C03', '#B36109', '#C98937', '#D6A254']
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.congratulationsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 21px;

    h1 {
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
    }

    p {
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
    }
    
    button {
        width: 146px;
    }
}
</style>
