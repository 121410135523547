<template>
  <div>
    <div class="row justify-content-md-center">
      <div class="col-md-4 text-center">
        <img
          :src="selected.project.thumbnailPath || '/img/thumbnail.png'"
          class="img-thumbnail"
        />
        <div class="m-3">
          <div class="spinner-border text-primary mt-3" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div class="progress mt-3">
        <div
          class="progress-bar progress-bar-striped"
          role="progressbar"
          :style="`width: ${((selected.project.working_progress + selected.project.working_progress_raw) / 200) * 100}%`"
          :aria-valuenow="`${((selected.project.working_progress + selected.project.working_progress_raw) / 200) * 100}`"
          aria-valuemin="0"
          aria-valuemax="100"
          v-if="selected.project.working_progress_raw != 100"
        >{{ (((selected.project.working_progress + selected.project.working_progress_raw) / 200) * 100).toFixed(1) }}%</div>
        <div
          class="progress-bar progress-bar-striped"
          role="progressbar"
          :style="`width: ${selected.project.working_progress == 100 ? 1 : selected.project.working_progress}%`"
          :aria-valuenow="`${selected.project.working_progress == 100 ? 1 : selected.project.working_progress}`"
          aria-valuemin="0"
          aria-valuemax="100"
          v-if="selected.project.working_progress_raw == 100"
        >{{ selected.project.working_progress == 100 ? 1 : selected.project.working_progress }}%</div>
      </div>
        <p class="text-default mt-3">{{ $t("project-preview.creating-project") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "preview-rendering",

  props: ["selected"],
};
</script>
