<template>
  <div>
    <div v-if="isFreePlan">
      <p><b>{{ $t('pricing.auto-cut-silences') }}</b></p>
      <p><b>{{ $t('pricing.smart-background-music') }}</b></p>
      <p><b>{{ $t('pricing.auto-subtitles') }}</b></p>
      <p><b>{{ $t('pricing.ai-storyboard') }}</b></p>
      <p><b>{{ $t('pricing.youtube-integration') }}</b></p>
      <!--p><b>{{ $t('pricing.auto-cut-story') }}</b></p-->
      <p><b>{{ $t('pricing.auto-subtitles-translation') }} *</b><br><small>* {{ $t('pricing.free-limited-period') }}</small></p>
    </div>
    <div v-if="!isFreePlan">
      <p><b class="text-primary">{{ $t('pricing.everything-in-free-plus') }}</b></p>
      <p><b>{{ $t('pricing.auto-subtitles-translation') }}</b></p>
      <!--p><b>{{ $t('pricing.auto-youtube-tags') }}</b></p-->
      <p><b>{{ $t('pricing.priority-customer') }}</b></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'included',

  props: ['plan'],

  data() {
    return {
      planName: "",
    };
  },

  mounted() {
    this.planName = this.plan.nickname.split('-')[0];
  },

  computed: {
    isFreePlan() {
      if((this.planName == 'Basic') || (this.planName == 'free'))
        return true;
      else
        return false;
    }
  },
}
</script>
