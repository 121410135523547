<template>
  <section>
    <b-dropdown :text="$t(`project-edit.${nav}`)" block variant="secondary" class="m-2" menu-class="w-100" v-if="!menu">
      <b-dropdown-item :to="`/projects/${$route.params.id}/storyboard`">
        {{ $t('project-edit.storyboard') }}
      </b-dropdown-item>
      <b-dropdown-item :to="`/projects/${$route.params.id}/translate`">
        {{ $t('project-edit.translations') }}
      </b-dropdown-item>
      <b-dropdown-item :to="`/projects/${$route.params.id}/music`"> {{ $t('project-edit.music') }}</b-dropdown-item>
      <b-dropdown-item :to="`/projects/${$route.params.id}/settings`"> {{ $t('project-edit.settings') }}</b-dropdown-item>
      <b-dropdown-item :to="`/projects/${$route.params.id}/share`"> {{ $t('project-edit.share') }}</b-dropdown-item>
    </b-dropdown>
    <div v-if="menu">
      <ul class="nav nav-tabs nav-fill mb-4">
        <li class="nav-item">
          <router-link class="nav-link" :class="{ active: nav === 'storyboard' }" :to="`/projects/${$route.params.id}/storyboard`">
            {{ $t('project-edit.storyboard') }}
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :class="{ active: nav === 'translate' }" :to="`/projects/${$route.params.id}/translate`">
            {{ $t('project-edit.translations') }}
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :class="{ active: nav === 'music' }" :to="`/projects/${$route.params.id}/music`">
            {{ $t('project-edit.music') }}
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :class="{ active: nav === 'settings' }" :to="`/projects/${$route.params.id}/settings`">
            {{ $t('project-edit.settings') }}
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :class="{ active: nav === 'share' }" :to="`/projects/${$route.params.id}/share`">
            {{ $t('project-edit.share') }}
          </router-link>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'edit-project-nav',

  props: ['nav'],

  computed: {
    ...mapGetters({
      menu: 'common/menu',
    }),
  },
}
</script>
