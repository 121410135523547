import { responseHandle } from "./handle"
import fetch from "./fetch"

export default {
  getById(id) {
    const endpoint = `/scenes/${id}`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  updateById(id, payload) {
    const endpoint = `/scenes/${id}`
    return fetch
      .put(endpoint, payload)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  removeLines(id, payload) {
    const endpoint = `/scenes/${id}/removelines`
    return fetch
      .post(endpoint, payload)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  splitByLine(id, index) {
    const endpoint = `/scenes/${id}/splitbyline/${index}`
    return fetch
      .post(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  splitByPosition(id, position) {
    const endpoint = `/scenes/${id}/splitbyposition/${position}`
    return fetch
      .post(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  mergeNextScene(id) {
    const endpoint = `/scenes/${id}/mergenextscene`
    return fetch
      .post(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  show(id) {
    const endpoint = `/scenes/${id}/show`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  hide(id) {
    const endpoint = `/scenes/${id}/hide`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  changeOrder(id, order) {
    const endpoint = `/scenes/${id}/order/${order}`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  changeType(id, type) {
    const endpoint = `/scenes/${id}/type${type}`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  trim(id, { trim_start, trim_end }) {
    const payload = {
      trim_start,
      trim_end
    }
    const endpoint = `/scenes/${id}/trim`
    return fetch
      .put(endpoint, payload)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  duplicate(id) {
    const endpoint = `/scenes/${id}/duplicate`
    return fetch
      .post(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  }
}
