import HomeView from '@/views/private/Home/HomeView'
import ProjectPreviewView from '@/views/private/ProjectPreview/ProjectPreviewView'
import ProjectEditView from '@/views/private/ProjectEdit/ProjectEditView'
import ProjectOpenView from '@/views/private/ProjectOpen/ProjectOpenView'
import ProjectNewView from '@/views/private/ProjectNew/ProjectNewView'
import LibraryView from '@/views/private/Library/LibraryView'
import AccountView from '@/views/private/Account/AccountView'
import VerifyEmail from '@/views/private/Utils/VerifyEmail'
import BuyLtdView from '@/views/private/Ltd/BuyLtdView'
import Congratulations from '@/views/private/Congratulations/CongratulationsView'

const privateRoutes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/newproject',
    name: 'new',
    component: ProjectNewView,
  },
  {
    path: '/open',
    name: 'open',
    component: ProjectOpenView,
  },
  {
    path: '/projects/:id/preview',
    name: 'project-preview',
    component: ProjectPreviewView,
  },
  {
    path: '/projects/:id/storyboard',
    name: 'project-storyboard',
    component: ProjectEditView,
  },
  {
    path: '/projects/:id/translate',
    name: 'project-translate',
    component: ProjectEditView,
  },
  {
    path: '/projects/:id/music',
    name: 'project-music',
    component: ProjectEditView,
  },
  {
    path: '/projects/:id/settings',
    name: 'project-settings',
    component: ProjectEditView,
  },
  {
    path: '/projects/:id/share',
    name: 'project-share',
    component: ProjectEditView,
  },
  {
    path: '/projects/:id/download',
    name: 'project-download',
    component: ProjectEditView,
  },
  {
    path: '/projects/:id/library/:order',
    name: 'project-library',
    component: ProjectEditView,
  },
  {
    path: '/projects/:id/library/:type/:order/:sceneid',
    name: 'project-library',
    component: ProjectEditView,
  },
  {
    path: '/account',
    name: 'payment',
    component: AccountView,
  },
  {
    path: '/congratulations',
    name: 'congratulations',
    component: Congratulations,
  },
  {
    path: '/ltd/buy/:tier?',
    name: 'buyltd',
    component: BuyLtdView,
  },
  {
    path: '/library',
    name: 'library',
    component: LibraryView,
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    component: VerifyEmail,
  },
]

export default privateRoutes
