<template>
  <div class="row mb-4">
    <div class="col-12 text-center" v-if="loading == index">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="col-sm-4 col-md-3 text-center" v-if="loading != index">
      <h3
        v-if="enableSelect && !menu"
        class="text-right text-primary"
        :class="{
          'text-success': selectedScenes.findIndex((x) => x === scene.id) >= 0,
        }"
      >
        <i
          :class="{
            'bi-square': selectedScenes.findIndex((x) => x === scene.id) < 0,
            'bi-check-square-fill': selectedScenes.findIndex((x) => x === scene.id) >= 0,
          }"
          style="cursor: pointer"
          @click.prevent="selectScene(scene.id, parseFloat(scene.trim_end) - parseFloat(scene.trim_start))"
        ></i>
      </h3>
      <div
        style="position: relative"
        class="text-center wrapper-thumbnail"
        :class="{
          'wrapper-thumbnail__removed': scene.visibility == 0,
        }"
      >
        <img
          :src="scene.thumbnail || project.thumbnailPath"
          :class="{
            'img-thumbnail': menu,
            'rounded border w-50': !menu,
          }"
        />
        <div class="scene-thumbnail-overlay text-center">
          <span v-if="scene.visibility === 0">{{ $t('project-edit.removed') }}</span>
          <span v-if="scene.visibility === 1 && !scene.thumbnail">{{ $t('project-edit.not-updated') }}</span>
        </div>
      </div>
    </div>
    <div class="col-sm-8 col-md-9" v-if="loading != index">
      <div class="row">
        <div class="col-sm-12 col-lg-5 mb-2" :class="{ 'text-center': !menu }">
          <span
            class="badge badge-primary"
            :class="{
              removed: scene.visibility == 0,
            }"
          >
            {{ $t('project-edit.overscene') }} {{ backgroundIndex + 1 }}
          </span>
          <span class="badge badge-dark ml-3"><i class="bi-clock"></i> {{ timestampToString(scene.end - scene.start) }}</span>
          <span class="badge badge-secondary ml-3" v-if="scene.type == 'intro'">{{ $t('project-edit.intro-scene') }}</span>
          <span class="badge badge-secondary ml-3" v-if="scene.type == 'outro'">{{ $t('project-edit.outro-scene') }}</span>
        </div>
        <div class="col-sm-12 col-lg-7" :class="{ 'text-right': menu, 'text-center': !menu }">
          <h5 v-if="enableDrag" class="text-secondary">
            <i class="bi-grip-vertical"></i>
          </h5>
          <h3
            v-if="enableSelect && menu"
            class="text-right text-primary"
            :class="{
              'text-success': selectedScenes.findIndex((x) => x === scene.id) >= 0,
            }"
          >
            <i
              :class="{
                'bi-square': selectedScenes.findIndex((x) => x === scene.id) < 0,
                'bi-check-square-fill': selectedScenes.findIndex((x) => x === scene.id) >= 0,
              }"
              style="cursor: pointer"
              @click.prevent="selectScene(scene.id, parseFloat(scene.trim_end) - parseFloat(scene.trim_start))"
            ></i>
          </h3>
          <div class="btn-group btn-group-storyboard" v-if="editing != index && scene.visibility == 1 && !enableDrag && !enableSelect && !showPlayer">
            <button
              type="button"
              class="btn btn-secondary"
              :class="{ 'btn-sm': menu }"
              :title="`${$t('project-edit.tools-overscene')}`"
              v-if="overscening != index && scene.type == 'overscene'"
              @click.prevent="overscening = index"
            >
              <span class="material-icons-round md-18">settings</span>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              :class="{ 'btn-sm': menu }"
              :title="`${$t('project-edit.tools-overscene')}`"
              v-if="overscening == index && scene.type == 'overscene'"
              @click.prevent="overscening = null"
            >
              <span class="material-icons-round md-18">settings</span>
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              :class="{ 'btn-sm': menu }"
              v-if="sliding != index"
              :title="`${$t('project-edit.tools-trim')}`"
              @click.prevent="sliding = index"
            >
              <span class="material-icons-round md-18">compare_arrows</span>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              :class="{ 'btn-sm': menu }"
              v-if="sliding == index"
              :title="`${$t('project-edit.tools-trim')}`"
              @click.prevent="sliding = null"
            >
              <span class="material-icons-round md-18">compare_arrows</span>
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              :class="{ 'btn-sm': menu }"
              v-if="!hasText && splitting != index"
              :title="`${$t('project-edit.tools-split')}`"
              @click.prevent="
                splitting = index
                previewTrims
              "
            >
              <span class="material-icons-round md-18">compare</span>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              :class="{ 'btn-sm': menu }"
              :title="`${$t('project-edit.tools-split')}`"
              v-if="!hasText && splitting == index"
              @click.prevent="
                splitting = null
                previewTrims
              "
            >
              <span class="material-icons-round md-18">compare</span>
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              :class="{ 'btn-sm': menu }"
              :title="`${$t('project-edit.tools-volume')}`"
              v-if="voluming != index && !project.mute_videos"
              @click.prevent="voluming = index"
            >
              <span class="material-icons-round md-18">volume_up</span>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              :class="{ 'btn-sm': menu }"
              :title="`${$t('project-edit.tools-volume')}`"
              v-if="voluming == index && !project.mute_videos"
              @click.prevent="voluming = null"
            >
              <span class="material-icons-round md-18">volume_up</span>
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              :class="{ 'btn-sm': menu }"
              :title="`${$t('project-edit.duplicate-scene')}`"
              @click.prevent="tryDuplicate(scene, index)"
            >
              <span class="material-icons-round md-18">content_copy</span>
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              :class="{ 'btn-sm': menu }"
              v-if="hasText && scene.text.length > 1 && scissorsScene !== index"
              @click.prevent="enableScissors(index)"
              :title="`${$t('project-edit.tools-scissors')}`"
            >
              <span class="material-icons-round md-18">content_cut</span>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              :class="{ 'btn-sm': menu }"
              v-if="hasText && scene.text.length > 1 && scissorsScene === index"
              @click.prevent="disableScissors"
              :title="`${$t('project-edit.tools-scissors')}`"
            >
              <span class="material-icons-round md-18">content_cut</span>
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              :class="{ 'btn-sm': menu }"
              @click.prevent="tryHide(scene, index)"
              :title="`${$t('project-edit.tools-trash')}`"
            >
              <span class="material-icons-round md-18">delete</span>
            </button>
          </div>
          <div class="btn-group" v-if="editing == index">
            <button type="button" class="btn btn-secondary" :class="{ 'btn-sm': menu }" @click.prevent="saveScene(scene, index)">
              <i class="bi-check-circle"></i>
            </button>
            <button type="button" class="btn btn-secondary" :class="{ 'btn-sm': menu }" @click.prevent="editing = null">
              <i class="bi-x-circle"></i>
            </button>
          </div>
          <div class="btn-group" v-if="scene.visibility == 0 && !enableDrag && !enableSelect">
            <button
              type="button"
              class="btn btn-secondary"
              :class="{ 'btn-sm': menu }"
              @click.prevent="tryShow(scene, index)"
              :title="`${$t('project-edit.tools-restore')}`"
            >
              <SvgIcon name="arrow-restore" stroke="#ffffff" />
            </button>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <div v-if="sliding == index">
          <div class="form-group">
            <label for="sceneTrimStart"
              ><small>{{ $t('project-edit.starts-at') }}: {{ timestampToString(scene.trim_start) }}</small></label
            >
            <br />
            <label for="sceneTrimEnd"
              ><small>{{ $t('project-edit.ends-at') }}: {{ timestampToString(scene.trim_end) }}</small></label
            >
          </div>
          <div class="form-group text-center">
            <Trimmer :scene="scene" v-on:current-position="onCurrentPosition" v-on:preview="previewTrims" />
          </div>
          <div class="text-center">
            <div class="btn-group">
              <button type="button" class="btn btn-sm btn-secondary" @click.prevent="previewTrims">
                <i class="bi-play-circle"></i> {{ $t('project-edit.preview') }}
              </button>
              <button type="button" class="btn btn-sm btn-secondary" v-if="!isDefaultTrims" @click.prevent="defaultTrims">
                <SvgIcon name="arrow-restore" stroke="#ffffff" /> {{ $t('project-edit.restore') }}
              </button>
              <button type="button" class="btn btn-sm btn-primary" v-if="changed" @click.prevent="saveTrims(index)">
                <i class="bi-check-circle"></i> {{ $t('project-edit.save') }}
              </button>
            </div>
          </div>
        </div>
        <div v-if="!hasText && splitting == index">
          <div class="form-group">
            <label for="sceneSplit"
              ><small
                >{{ $t('project-edit.split-at') }}:
                {{ timestampToString((splitTime / 100) * (parseFloat(scene.end) - parseFloat(scene.start))) }}</small
              ></label
            >
            <Splitter
              :scene="scene"
              v-model="splitTime"
              :changeSplitTime="changeSplitTime"
              v-on:current-position="onCurrentPosition"
              v-on:preview="previewTrims"
            />
          </div>
          <div class="text-center">
            <div class="btn-group">
              <button type="button" class="btn btn-sm btn-secondary" @click.prevent="previewSplit">
                <i class="bi-play-circle"></i> {{ $t('project-edit.preview') }}
              </button>
              <button type="button" class="btn btn-sm btn-primary" @click.prevent="trySplitByPosition(scene, index)">
                {{ $t('project-edit.split') }}
              </button>
            </div>
          </div>
        </div>
        <div v-if="voluming == index && !project.mute_videos">
          <div class="card bg-dark mb-2">
            <div class="card-body">
              <label
                ><b class="text-primary">{{ $t('project-edit.media-volume') }}</b></label
              >
              <div class="row justify-content-center">
                <div class="col-2">
                  <button
                    type="button"
                    class="btn btn-link p-0 m-1"
                    :class="{ 'text-success': scene.mediaAudio == 'mute' }"
                    @click.prevent="changeMediaAudio('mute')"
                  >
                    <i
                      :class="{
                        'bi-circle': scene.mediaAudio != 'mute',
                        'bi-check-circle-fill': scene.mediaAudio == 'mute',
                      }"
                    ></i
                    ><br />
                    {{ $t('project-edit.mute') }}
                  </button>
                </div>
                <div class="col-2">
                  <button
                    type="button"
                    class="btn btn-link p-0 m-1"
                    :class="{ 'text-success': scene.mediaAudio == 'low' }"
                    @click.prevent="changeMediaAudio('low')"
                  >
                    <i
                      :class="{
                        'bi-circle': scene.mediaAudio != 'low',
                        'bi-check-circle-fill': scene.mediaAudio == 'low',
                      }"
                    ></i
                    ><br />
                    {{ $t('project-edit.low') }}
                  </button>
                </div>
                <div class="col-2">
                  <button
                    type="button"
                    class="btn btn-link p-0 m-1"
                    :class="{ 'text-success': scene.mediaAudio == 'standard' }"
                    @click.prevent="changeMediaAudio('standard')"
                  >
                    <i
                      :class="{
                        'bi-circle': scene.mediaAudio != 'standard',
                        'bi-check-circle-fill': scene.mediaAudio == 'standard',
                      }"
                    ></i
                    ><br />
                    {{ $t('project-edit.medium') }}
                  </button>
                </div>
                <div class="col-2">
                  <button
                    type="button"
                    class="btn btn-link p-0 m-1"
                    :class="{ 'text-success': scene.mediaAudio == 'loud' }"
                    @click.prevent="changeMediaAudio('loud')"
                  >
                    <i
                      :class="{
                        'bi-circle': scene.mediaAudio != 'loud',
                        'bi-check-circle-fill': scene.mediaAudio == 'loud',
                      }"
                    ></i
                    ><br />
                    {{ $t('project-edit.high') }}
                  </button>
                </div>
                <div class="col-2">
                  <button
                    type="button"
                    class="btn btn-link p-0 m-1"
                    :class="{ 'text-success': scene.mediaAudio == 'extraloud' }"
                    @click.prevent="changeMediaAudio('extraloud')"
                  >
                    <i
                      :class="{
                        'bi-circle': scene.mediaAudio != 'extraloud',
                        'bi-check-circle-fill': scene.mediaAudio == 'extraloud',
                      }"
                    ></i
                    ><br />
                    {{ $t('project-edit.full') }}
                  </button>
                </div>
              </div>
              <hr class="border-primary" />
              <button type="button" class="btn btn-block btn-sm btn-primary" @click.prevent="saveScene(scene, index)">
                {{ $t('project-edit.save') }}
              </button>
            </div>
          </div>
        </div>
        <div v-if="overscening == index && scene.type == 'overscene'">
          <div class="card bg-dark mb-2">
            <div class="card-body">
              <label
                ><b class="text-primary">{{ $t('project-edit.overscene-type') }}</b></label
              >
              <div class="row justify-content-center">
                <div class="col-12">
                  <div class="form-group">
                    <select class="form-control mb-3" v-model="scene.overscene_type" @change="changeOversceneType">
                      <option value="fullscrn">{{ $t('project-edit.overscene-type-fullscreen') }}</option>
                      <option value="autozoom">{{ $t('project-edit.overscene-type-autozoom') }}</option>
                      <option value="pip_tl">{{ $t('project-edit.overscene-type-pip-tl') }}</option>
                      <option value="pip_tr">{{ $t('project-edit.overscene-type-pip-tr') }}</option>
                      <option value="pip_bl">{{ $t('project-edit.overscene-type-pip-bl') }}</option>
                      <option value="pip_br">{{ $t('project-edit.overscene-type-pip-br') }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <hr class="border-primary" />

              <div class="row m-0 justify-content-between">
                <div class="custom-control custom-switch mt-4 text-left col-12">
                  <input type="checkbox" class="custom-control-input" id="toggle-overscene-fade" v-model="scene.overscene_fade" />
                  <label class="custom-control-label" for="toggle-overscene-fade">{{ $t('project-edit.overscene-fade') }}</label>
                </div>
                <div class="custom-control custom-switch mt-4 text-left col-12">
                  <input type="checkbox" class="custom-control-input" id="toggle-overscene-overflow" v-model="scene.overscene_overflow" />
                  <label class="custom-control-label" for="toggle-overscene-overflow">{{ $t('project-edit.overscene-overflow') }}</label>
                </div>
                <div class="custom-control custom-switch mt-4 text-left col-12">
                  <input type="checkbox" class="custom-control-input" id="toggle-overscene-bg" v-model="scene.overscene_bg" />
                  <label class="custom-control-label" for="toggle-overscene-bg">{{ $t('project-edit.overscene-bg') }}</label>
                </div>
              </div>
              <hr class="border-primary" />

              <div class="overscene-preview">
                <p class="thumb-warning" v-if="scene.overscene_type == 'autozoom'">{{ $t('project-edit.overscene-thumb-warning') }}</p>
                <img class="img-thumbnail thumb-background" v-bind:class="backsceneThumb" :src="backgroundScene.thumbnail || project.thumbnailPath" />
                <img class="img-thumbnail" v-bind:class="oversceneThumb" :src="scene.thumbnail" />
              </div>
              <hr class="border-primary" />

              <button type="button" class="btn btn-block btn-sm btn-primary" @click.prevent="saveScene(scene, index)">
                {{ $t('project-edit.save') }}
              </button>
            </div>
          </div>
        </div>
        <div v-for="(line, idx) in scene.text" :key="idx">
          <p
            class="mb-1 removed"
            :class="{
              'text-secondary':
                line.start - scene.start + parseFloat(scene.trim_start_default) + parseFloat(scene.media_file.raw_preview_offset) < currentTime &&
                line.end - scene.start + parseFloat(scene.trim_start_default) + parseFloat(scene.media_file.raw_preview_offset) > currentTime,
              'text-center': !menu,
              'border-scissors': scissorsScene == index && scissorsLine == idx,
              'removed-line-start': line.end < parseFloat(scene.trim_start - parseFloat(scene.trim_start_default) + parseFloat(scene.text[0].start)),
              'removed-line-end': line.start > parseFloat(scene.text[scene.text.length - 1].end) - (scene.trim_end_default - scene.trim_end),
            }"
            v-if="editing != index"
          >
            <button
              type="button"
              class="btn btn-link m-0 p-0"
              @click.prevent="trySplitByLine(scene, idx, index)"
              v-if="hasText && idx + 1 < scene.text.length && scissorsScene == index"
              @mouseover="overScissors(idx, index)"
              @mouseout="outScissors(idx, index)"
            >
              <i class="bi-scissors"></i>
            </button>
            <span>{{ line.text }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Trimmer from './Trimmer.vue'
import Splitter from './Splitter.vue'
import SvgIcon from "@/components/icon/SvgIcon.vue";

export default {
  name: 'overscene',

  props: [
    'scene',
    'backgroundScene',
    'index',
    'backgroundIndex',
    'playScene',
    'playFullVideo',
    'project',
    'currentTime',
    'reloadPlayer',
    'enableDrag',
    'enableSelect',
    'scissorsScene',
    'enableScissors',
    'disableScissors',
    'showPlayer',
    'checkNotRendered',
    'selectScene',
    'selectedScenes',
  ],

  components: {
    Trimmer,
    Splitter,
    SvgIcon
  },

  data() {
    return {
      editing: null,
      sliding: null,
      splitting: null,
      voluming: null,
      overscening: null,
      loading: null,
      scissorsLine: null,
      trimStart: '0',
      trimEnd: '100',
      splitTime: 0,
      hasText: false,
      changed: false,
      isDefaultTrims: true,
    }
  },

  computed: {
    ...mapGetters({
      menu: 'common/menu',
    }),

    backsceneThumb: function () {
      return {
        'thumb-background-hidden': this.scene.overscene_bg,
        'thumb-pip': this.scene.overscene_type.startsWith('pip_'),
        'thumb-pip-tl': this.scene.overscene_type == 'pip_tl',
        'thumb-pip-tr': this.scene.overscene_type == 'pip_tr',
        'thumb-pip-bl': this.scene.overscene_type == 'pip_bl',
        'thumb-pip-br': this.scene.overscene_type == 'pip_br',
      }
    },

    oversceneThumb: function () {
      return {
        'thumb-fullscreen': this.scene.overscene_type == 'fullscrn',
        'thumb-autozoom': this.scene.overscene_type == 'autozoom',
      }
    },
  },

  mounted() {
    this.hasText = this.scene.text && this.scene.text.length ? true : false
    this.trimStart = parseInt((this.scene.trim_start * 100) / this.scene.trim_end_default)
    this.trimEnd = parseInt((this.scene.trim_end * 100) / this.scene.trim_end_default)
  },

  methods: {
    ...mapActions({
      updateById: 'scene/UPDATE_BY_ID',
      splitByLine: 'scene/SPLIT_BY_LINE',
      splitByPosition: 'scene/SPLIT_BY_POSITION',
      changeType: 'scene/CHANGE_TYPE',
      show: 'scene/SHOW',
      hide: 'scene/HIDE',
      trim: 'scene/TRIM',
      duplicate: 'scene/DUPLICATE',
    }),

    onCurrentPosition(val) {
      this.$emit('current-position', parseFloat(val) + parseFloat(this.scene.media_file.raw_preview_offset))
    },

    overScissors(idx) {
      this.scissorsLine = idx
    },

    outScissors() {
      this.scissorsLine = null
    },

    changeSplitTime() {
      this.changeTrims()
      // this.previewTrims();
    },

    changeTrims() {
      if (parseInt(this.trimStart) > parseInt(this.trimEnd)) {
        this.trimStart = this.trimEnd
      }
    },

    defaultTrims() {
      this.scene.trim_start = this.scene.trim_start_default
      this.scene.trim_end = this.scene.trim_end_default
    },

    changeMusicType(param) {
      this.scene.musicType = param
    },

    changeMediaAudio(param) {
      this.scene.mediaAudio = param
    },

    previewTrims() {
      const trim_start_raw = parseFloat(this.scene.trim_start) + parseFloat(this.scene.media_file.raw_preview_offset)
      const trim_end_raw = parseFloat(this.scene.trim_end) + parseFloat(this.scene.media_file.raw_preview_offset)
      this.playScene({ trim_start_raw, trim_end_raw })
    },

    previewSplit() {
      const trim_start_raw = parseFloat(
        (this.splitTime / 100) * (this.scene.trim_end_default - this.scene.trim_start_default) +
          parseFloat(this.scene.trim_start_default) +
          parseFloat(this.scene.media_file.raw_preview_offset)
      )
      const trim_end_raw = parseFloat(this.scene.trim_end) + parseFloat(this.scene.media_file.raw_preview_offset)
      this.playScene({ trim_start_raw, trim_end_raw })
    },

    timestampToString(seconds) {
      return new Date(seconds * 1000).toISOString().substr(11, 8)
    },

    async tryChangeType(scene, type, index) {
      this.loading = index
      const sceneId = await this.changeType({
        scene,
        type,
      })
      if (type == '/intro') {
        window.scrollTo(0, 0)
      }
      if (type == '/outro') {
        window.scrollTo(0, document.body.scrollHeight)
      }
      localStorage.setItem(`needsRender_${this.scene.project_id}`, 'true')
      this.checkNotRendered([scene])
      this.$emit('update-storyboard', [sceneId])
      // this.loading = null;
    },

    async saveTrims(index) {
      let scene = this.scene

      this.loading = index
      await this.trim(scene)
      this.changed = false
      this.editing = null
      this.sliding = null
      this.loading = null
      localStorage.setItem(`needsRender_${this.scene.project_id}`, 'true')
      this.checkNotRendered([scene])
      this.$bvToast.toast(this.$t('project-edit.settings-updated'), {
        variant: 'success',
        title: this.$t('project-edit.update'),
        toaster: 'b-toaster-top-center',
        solid: true,
        autoHideDelay: 2500,
      })
      this.reloadPlayer(scene)
      this.$emit('update-render-length')
    },

    async trySplitByLine(scene, idx, index) {
      this.loading = index
      const scenesIds = await this.splitByLine({
        scene,
        line: idx,
      })
      window.analytics.track('Project Scene Split', {
        sceneId: scene.id,
        projectId: scene.project_id,
        oldStart: scene.start,
        oldEnd: scene.end,
      })
      this.scissorsLine = null
      this.disableScissors()
      localStorage.setItem(`needsRender_${this.scene.project_id}`, 'true')
      this.checkNotRendered([scene])
      // this.$bvToast.toast(this.$t("project-edit.settings-updated"), {
      //   variant: "success",
      //   title: this.$t("project-edit.update"),
      //   toaster: "b-toaster-top-center",
      //   solid: true,
      //   autoHideDelay: 2500,
      // });
      this.$emit('update-storyboard', scenesIds, true)
      // this.loading = null;
    },

    async trySplitByPosition(scene, index) {
      this.loading = index
      const position = (this.splitTime / 100) * (parseFloat(scene.end) - parseFloat(scene.start)) + parseFloat(scene.start)
      const scenesIds = await this.splitByPosition({
        scene,
        position,
      })
      this.splitting = null
      localStorage.setItem(`needsRender_${this.scene.project_id}`, 'true')
      this.checkNotRendered([scene])
      this.$bvToast.toast(this.$t('project-edit.settings-updated'), {
        variant: 'success',
        title: this.$t('project-edit.update'),
        toaster: 'b-toaster-top-center',
        solid: true,
        autoHideDelay: 2500,
      })
      this.$emit('update-storyboard', scenesIds, true)
      // this.loading = null;
    },

    async tryShow(scene, index) {
      // this.loading = index;
      this.scene.visibility = 1
      await this.show(scene)
      localStorage.setItem(`needsRender_${this.scene.project_id}`, 'true')
      this.checkNotRendered([scene])
      this.$bvToast.toast(this.$t('project-edit.settings-updated'), {
        variant: 'success',
        title: this.$t('project-edit.update'),
        toaster: 'b-toaster-top-center',
        solid: true,
        autoHideDelay: 2500,
      })
      this.$emit('update-render-length')
      this.loading = null
    },

    async tryHide(scene, index) {
      // this.loading = index;
      this.scene.visibility = 0
      await this.hide(scene)
      localStorage.setItem(`needsRender_${this.scene.project_id}`, 'true')
      this.checkNotRendered([scene])
      this.$bvToast.toast(this.$t('project-edit.settings-updated'), {
        variant: 'success',
        title: this.$t('project-edit.update'),
        toaster: 'b-toaster-top-center',
        solid: true,
        autoHideDelay: 2500,
      })
      this.$emit('update-render-length')
      this.loading = null
    },

    resetSceneEditing() {
      this.editing = null
      this.sliding = null
      this.splitting = null
      this.voluming = null
      this.facing = null
    },

    async saveScene(scene, index) {
      // this.loading = index;
      this.resetSceneEditing()
      const result = await this.updateById(scene)
      if (result) {
        this.scene = result
      }
      localStorage.setItem(`needsRender_${this.scene.project_id}`, 'true')
      this.checkNotRendered([scene])
      this.$bvToast.toast(this.$t('project-edit.settings-updated'), {
        variant: 'success',
        title: this.$t('project-edit.update'),
        toaster: 'b-toaster-top-center',
        solid: true,
        autoHideDelay: 2500,
      })
      this.loading = null
      this.reloadPlayer(scene)
    },

    async tryDuplicate(scene, index) {
      this.loading = index
      await this.duplicate(scene)
      localStorage.setItem(`needsRender_${this.scene.project_id}`, 'true')
      this.checkNotRendered()
      this.$bvToast.toast(this.$t('project-edit.settings-updated'), {
        variant: 'success',
        title: this.$t('project-edit.update'),
        toaster: 'b-toaster-top-center',
        solid: true,
        autoHideDelay: 2500,
      })
      this.loading = null
    },

    changeOversceneType() {
      if (this.scene.overscene_type == 'autozoom' || this.scene.overscene_type == 'fullscrn') this.scene.overscene_bg = 1
      else this.scene.overscene_bg = 0
    },
  },

  watch: {
    scene: {
      handler: function handler() {
        this.changed = true

        if (this.scene.trim_start == this.scene.trim_start_default && this.scene.trim_end == this.scene.trim_end_default) this.isDefaultTrims = true
        else this.isDefaultTrims = false

        this.loading = null
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';
.removed-line-start {
  text-decoration: line-through;
}

.removed-line-end {
  text-decoration: line-through;
}

.play-button {
  background-color: rgba(28, 23, 77, 0.7);
  position: absolute;
  width: 68px;
  height: 68px;
  top: calc(50% - 34px);
  left: calc(50% - 34px);
}

.border-scissors {
  border-bottom: 2px dashed $primary;
}

.overscene-preview {
  position: relative;
  background-color: black;
}

.thumb-background {
  border: 0;
  border-radius: 0;
  padding: 0;
}
.wrapper-thumbnail {
  overflow: hidden;
  .img-thumbnail {
    border-radius: 10px;
    background: transparent;
  }
  &__removed {
    .img-thumbnail {
      opacity: 0.2;
    }
    .scene-thumbnail-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
    }
  }
}
.thumb-background-hidden {
  visibility: hidden;
}

.thumb-pip {
  position: absolute;
  border: 0;
  border-radius: 0;
  padding: 0;
  background: 0;
  max-width: 30%;
  max-height: 30%;
}

.thumb-pip-tl {
  top: 15px;
  left: 15px;
}

.thumb-pip-tr {
  top: 15px;
  right: 15px;
}

.thumb-pip-bl {
  bottom: 15px;
  left: 15px;
}

.thumb-pip-br {
  bottom: 15px;
  right: 15px;
}

.thumb-fullscreen,
.thumb-autozoom {
  position: absolute;
  border: 0;
  border-radius: 0;
  padding: 0;
  background: 0;
  max-width: 100%;
  max-height: 100%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.thumb-autozoom {
  width: 100%;
  height: 100%;
}

.thumb-warning {
  position: absolute;
  z-index: 1;
  color: white;
  text-align: center;
  margin-top: 45%;
  text-shadow: 1px 1px black;
  font-weight: 600;
}
</style>
