import { responseHandle } from './handle'
import fetch from './fetch'

export default {
  getPlans(interval) {
    const endpoint = `/payment/plans?interval=${interval}`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },
  getFreePlan(interval) {
    const endpoint = `/payment/freeplan`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },
  getCoupon(code, planId) {
    const endpoint = `/payment/coupon?code=${code}&plan=${planId}`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },
}
