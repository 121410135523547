<template>
  <div id="pricing">
    <TopBar />
    <div v-if="loading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <!-- <div class="promo-banner text-center p-2" v-if="!loading && (!me || (!me.ltd_tier))">
      <a href="https://www.wisecut.video/ltd">{{ $t('pricing.checkout-ltd') }}</a>
    </div> -->
    <div class="container mt-3" v-if="!loading">
      <h3 class="text-center mb-3">
        <b>{{ $t("pricing.pricing-plan") }}</b>
      </h3>
      <div>
        <div class="interval-switch">
          <div class="custom-control custom-switch">
            <span :class="{'text-primary':interval == 'month'}" class="off-value">{{ $t('pricing.monthly') }}</span>
            <input
              type="checkbox"
              class="custom-control-input"
              id="is-annual"
              v-model="isAnnual"
            />
            <label class="custom-control-label" for="is-annual"></label>
            <span :class="{'text-primary':interval == 'year'}">{{ $t('pricing.annually') }}</span>
          </div>
          <p>{{ $t('pricing.save-money-annually') }}</p>
          <p class="banner">{{ $t('pricing.more-hours-annually') }}</p>
        </div>
        <!-- <div class="form-group">
          <select class="form-control mb-3" v-model="interval">
            <option value="year">Annual</option>
            <option value="month">Monthly</option>
          </select>
        </div> -->
      </div>
      <table class="table table-borderless text-dark text-center mt-3">
        <tbody>
          <tr class="bg-light">
            <th scope="row"></th>
            <td
              v-for="plan in plans"
              :key="plan.id"
              class="p-0"
            >
              <CardPlan :plan="plan" :subscription="subscription" />
            </td>
          </tr>
          <tr>
            <th scope="row" class="text-uppercase border-top bg-gray">
              {{ $t("pricing.minutes") }}
            </th>
            <td
              v-for="(plan, idx) in plans"
              :key="plan.id"
              class="border-left border-right border-top"
              :class="{'bg-gray': idx % 2 != 0}"
            >
              <b v-if="plan.metadata.monthly_minutes >= 120" :class="{'text-primary':interval == 'year'}">{{ (plan.metadata.monthly_minutes / 60).toFixed(0) }}</b>
              <b v-else>{{ plan.metadata.monthly_minutes }}</b>
              <span v-if="plan.metadata.monthly_minutes >= 120" :class="{'text-primary':interval == 'year'}"> {{ $t('pricing.hours') }} / {{ $t("pricing.month") }}</span>
              <span v-else> {{ $t("pricing.minutes") }} / {{ $t("pricing.month") }}</span>
            </td>
          </tr>
          <tr>
            <th scope="row" class="text-uppercase bg-gray">
              {{ $t("pricing.resolution") }}
            </th>
            <td
              v-for="(plan, idx) in plans"
              :key="plan.id"
              class="border-left border-right"
              :class="{'bg-gray': idx % 2 != 0}"
            >
              <b>{{ plan.metadata.video_tier == 2 ? 1080 : 720 }}p</b>
              <span> Max</span>
            </td>
          </tr>
          <tr>
            <th scope="row" class="text-uppercase bg-gray">
              {{ $t("pricing.storage") }}
            </th>
            <td
              v-for="(plan, idx) in plans"
              :key="plan.id"
              class="border-left border-right"
              :class="{'bg-gray': idx % 2 != 0}"
            >
              <b>{{ plan.metadata.storage_gb }}</b>
              <span> GB</span>
            </td>
          </tr>
          <tr>
            <th scope="row" class="text-uppercase bg-gray">
              {{ $t("pricing.file-length") }}
            </th>
            <td
              v-for="(plan, idx) in plans"
              :key="plan.id"
              class="border-left border-right"
              :class="{'bg-gray': idx % 2 != 0}"
            >
              <b>{{ plan.metadata.upload_max_length_minutes }}</b>
              <span> Min / {{ $t("pricing.file") }}</span>
            </td>
          </tr>
          <tr>
            <th scope="row" class="text-uppercase bg-gray">
              {{ $t("pricing.file-size") }}
            </th>
            <td
              v-for="(plan, idx) in plans"
              :key="plan.id"
              class="border-left border-right"
              :class="{'bg-gray': idx % 2 != 0}"
            >
              <b>{{ plan.metadata.upload_max_size_mb / 1024 }}</b>
              <span> GB / {{ $t("pricing.file") }}</span>
            </td>
          </tr>
          <tr>
            <th scope="row" class="text-uppercase border-bottom bg-gray">
              <hr class="mt-0">
              {{ $t("pricing.included-in-plan") }}
            </th>
            <td
              v-for="(plan, idx) in plans"
              :key="plan.id"
              class="border-left border-right border-bottom"
              :class="{'bg-gray': idx % 2 != 0}"
            ><hr class="mt-0">
              <Included :plan="plan" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import TopBar from "./components/TopBar";
import CardPlan from "./components/CardPlan";
import Included from './components/Included';

export default {
  name: "pricing",

  components: {
    TopBar,
    CardPlan,
    Included
  },

  data() {
    return {
      loading: true,
      plans: null,
      isAnnual: true,
      interval: 'year',
    }
  },

  computed: {
    ...mapGetters({
      monthlyPlans: "payment/monthlyPlans",
      yearlyPlans: "payment/yearlyPlans",
      subscription: 'user/subscription',
      me: 'user/me'
    }),
  },

  async mounted() {
    await this.getYearlyPlans();
    await this.getMonthlyPlans();
    this.setPlans();
    await this.getMe();
    this.loading = false;
    if(this.me) {
      this.getSubscription();
    }
  },

  methods: {
    ...mapActions({
      getMonthlyPlans: "payment/GET_MONTHLY_PLANS",
      getYearlyPlans: "payment/GET_YEARLY_PLANS",
      getSubscription: 'user/GET_SUBSCRIPTION',
      getMe: "user/GET_ME"
    }),
    setPlans() {
      if(this.interval == 'year')
        this.plans = this.yearlyPlans;
      else if(this.interval == 'month')
        this.plans = this.monthlyPlans;
    }
  },

  watch: {
    me(val) {
      this.getSubscription();
    },
    isAnnual(val) {
      if(this.isAnnual)
        this.interval = 'year';
      else
        this.interval = 'month';
    },
    interval(val) {
      this.setPlans();
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/colors.scss";

#pricing {
  padding-top: 90px;
  background-color: $light;
  min-height: 1000px;
  color: $black;

  .bg-gray {
    background-color: $gray-200
  }
}

.promo-banner {
  background-color: $yellow;

  a:link, a:visited {
    color: $lpurple;
    text-decoration: underline;
  }

  a:hover {
    color: $vlpurple
  }
}

.interval-switch {
  text-align: center;
  width: 100%;

  .custom-switch {
    padding: 0;
  }

  .off-value {
  margin-right: 45px;
  }
}

.banner {
  font-size: 1.4em;
  font-weight: 400;
  padding: 5px;
  background-color: $yellow;
  border-radius: 5px;
  box-shadow: 3px 3px 7px 0px #00000080;
  margin-top: 50px;
  margin-bottom: 50px;
}
</style>