<template>
  <div class="list-group-item__list">
    <img class="img-thumbnail" :src="project.thumbnailPath || '/img/thumbnail.png'" />

    <div class="list-group-item__content">
      <div class="list-group-item__head">
        <h5 class="list-group-item__title">{{ project.name }}</h5>
        <stro class="text-default font-weight-bold">
          {{ $t('open-project.creatingProject') }} {{ ((project.working_progress + project.working_progress_raw) / 2).toFixed(1) }}%
        </stro>
      </div>

      <p class="text-primary creating-project">
        {{ $t('project-open.creating-project') }}
      </p>
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/icon/SvgIcon.vue'

export default {
  name: 'rendering',

  components: {
    SvgIcon,
  },

  props: ['project'],
}
</script>

<style lang="scss" scoped>

  .creating-project{
    max-width: 450px;
    font-weight: 600;
  }
</style>