<template>
  <div>
    <p>
      <span class="badge badge-info"
        >{{ $t("library.from-library") }}: {{ files.length }}</span
      >
    </p>
    <div class="row">
      <div
        class="col-sm-3"
        v-for="(file, index) in files"
        :key="`project_from_library_${index}`"
      >
        <div class="text-right">
          <a href="#" class="m-0 p-0" @click.prevent="removeFromLibrary(file.id)"
            ><i class="bi-trash"></i
          ></a>
        </div>
        <video
          class="rounded border border-dark"
          style="width: 100%; max-height: 90px"
          :id="`from_library_${index}`"
          :src="file.export_raw_url"
          muted
          @mouseover.prevent="playVideo(index)"
          @mouseout.prevent="pauseVideo(index)"
        >
          Your browser does not support the video tag.
        </video>
        <p class="text-center text-truncate mb-0">
          <small>{{ file.name }}</small>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "library-videos-component",

  props: ["files", "removeFromLibrary"],

  data() {
    return {
      URL: URL,
    };
  },

  mounted() {
    setTimeout(() => {
      if (this.files.length) {
        for (let index in this.files) {
          let video = document.querySelector(`#from_library_${index}`);
          let duration = video.duration;
          video.currentTime = duration * 0.1;
          document.getElementById(`videoDuration_${index}`).innerHTML =
            this.timestampToString(duration);
        }
      }
    }, 1000);
  },

  methods: {
    timestampToString(seconds) {
      return new Date(seconds * 1000).toISOString().substr(11, 8);
    },

    playVideo(index) {
      let player = document.querySelector(`#from_library_${index}`);
      player.play();
    },

    pauseVideo(index) {
      let player = document.querySelector(`#from_library_${index}`);
      player.pause();
      player.currentTime = player.duration * 0.1;
    },
  },
};
</script>
