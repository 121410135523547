<template>
  <nav class="navbar navbar-expand-lg navbar-dark">
    <div class="container">
      <a class="navbar-brand" href="#">
        <img src="@/assets/images/logo.svg" height="28" />
      </a>

      <ul class="navbar-nav ml-auto"></ul>
      <router-link to="/login" class="btn btn-sm btn-outline-light">{{ $t('create-account.sign-in') }}</router-link>
    </div>
  </nav>
</template>


<script>
export default {
  name: 'login-topbar',

  mounted() {
    window.ire('identify', { customerId: '', customerEmail: '' });
  }
}
</script>
<style lang="scss" scoped>
.navbar {
  box-shadow: 0 0 50px 7px #000000;
}
</style>
