import api from "@/api"

const getters = {
  me: state => {
    return state.me
  },

  youtubeUrl: state => {
    return state.youtubeUrl
  },

  stripeSession: state => {
    return state.stripeSession
  },

  subscription: state => {
    return state.subscription
  },

  paymentMethods: state => {
    return state.paymentMethods
  },

  intentToken: state => {
    return state.intentToken
  },

  limits: state => {
    return state.limits
  },

  quotas: state => {
    return state.quotas
  },

  tawktoHash: state => {
    return state.tawktoHash
  },

  impactHash: state => {
    return state.impacttoHash
  },

  upgradeLink: state => {
    return state.upgradeLink
  }
}

const mutations = {
  SET_AUTH(state, result) {
    localStorage.setItem("user", JSON.stringify(result))
  },

  CLEAR_AUTH() {
    localStorage.setItem("user", null)
  },

  SET_ME(state, result) {
    if (!result.error) {
      localStorage.setItem("user", JSON.stringify(result))
      state.me = result
    }
  },

  SET_YOUTUBE_URL(state, result) {
    state.youtubeUrl = result
  },

  SET_LOCALE(state, locale) {
    let user = JSON.parse(localStorage.getItem('user'))
    user.locale = locale
    localStorage.setItem("user", JSON.stringify(user))
    state.me = user
  },

  SET_RENDER_NOTIFICATIONS(state, status) {
    let user = JSON.parse(localStorage.getItem('user'))
    user.render_notifications = status
    localStorage.setItem("user", JSON.stringify(user))
    state.me = user
  },

  SET_STRIPE_SESSION: (state, result) => {
    state.stripeSession = result
  },

  SET_SUBSCRIPTION(state, result) {
    state.subscription = result
  },

  SET_PAYMENT_METHODS(state, result) {
    state.paymentMethods = result
  },

  SET_INTENT_TOKEN(state, result) {
    state.intentToken = result
  },

  SET_LIMITS(state, result) {
    state.limits = result
  },

  SET_QUOTAS(state, result) {
    state.quotas = result
  },

  SET_TAWKTO_HASH(state, result) {
    state.tawktoHash = result.hash
  },

  SET_IMPACT_HASH(state, result) {
    state.impacttoHash = result.hash
  },

  SET_UPGRADE_LINK(state, result) {
    state.upgradeLink = result
  }
}

const actions = {
  REGISTER: ({ }, payload) => {
    return new Promise(resolve => {
      return api.user
        .register(payload)
        .then(result => {
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  RESEND: () => {
    return new Promise(resolve => {
      return api.user
        .resend()
        .then(result => {
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  LOGIN: ({ commit }, payload) => {
    commit("CLEAR_AUTH")
    return new Promise(resolve => {
      return api.user
        .login(payload)
        .then(result => {
          commit("SET_AUTH", result)
          commit("SET_ME", result)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  LOGOUT: ({ commit }) => {
    return new Promise(resolve => {
      return api.user
        .logout()
        .then(result => {
          commit("CLEAR_AUTH")
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  GET_ME: ({ commit }) => {
    return new Promise(resolve => {
      return api.user
        .getMe()
        .then(result => {
          commit('SET_ME', result)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  CHANGE_LOCALE: ({ commit }, locale) => {
    return new Promise(resolve => {
      return api.user
        .locale(locale)
        .then(result => {
          commit('SET_LOCALE', locale)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  CHANGE_RENDER_NOTIFICATIONS: ({ commit }, status) => {
    return new Promise(resolve => {
      return api.user
        .renderNotifications(status)
        .then(result => {
          commit('SET_RENDER_NOTIFICATIONS', status)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  GET_YOUTUBE_URL: ({ commit }) => {
    return new Promise(resolve => {
      return api.user
        .getYoutubeUrl()
        .then(result => {
          commit('SET_YOUTUBE_URL', result)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  DISCONNECT_FROM_YOUTUBE: ({ commit }) => {
    return new Promise(resolve => {
      return api.user
        .disconnectFromYoutube()
        .then(result => {
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  GET_STRIPE_LTD_SESSION: ({ commit }, tier) => {
    return new Promise(resolve => {
      return api.user
        .getStripeLtdSession(tier)
        .then(result => {
          commit('SET_STRIPE_SESSION', result)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  GET_SUBSCRIPTION: ({ commit }) => {
    return new Promise(resolve => {
      return api.user
        .getSubscription()
        .then(result => {
          commit('SET_SUBSCRIPTION', result)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  UPDATE_SUBSCRIPTION: ({ dispatch }, payload) => {
    return new Promise(resolve => {
      return api.user
        .updateSubscription(payload)
        .then(async result => {
          await dispatch('GET_SUBSCRIPTION')
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  GET_PAYMENT_METHODS: ({ commit }) => {
    return new Promise(resolve => {
      return api.user
        .getPaymentMethods()
        .then(result => {
          commit('SET_PAYMENT_METHODS', result)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  GET_INTENT_TOKEN: ({ commit }) => {
    return new Promise(resolve => {
      return api.user
        .getIntentToken()
        .then(result => {
          commit('SET_INTENT_TOKEN', result)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  SAVE_PAYMENT_METHOD: ({ }, payload) => {
    return new Promise(resolve => {
      return api.user
        .savePaymentMethod(payload)
        .then(result => {
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  REMOVE_PAYMENT_METHOD: ({ }, id) => {
    return new Promise(resolve => {
      return api.user
        .removePaymentMethod(id)
        .then(result => {
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  GET_LIMITS: ({ commit }) => {
    return new Promise(resolve => {
      return api.user
        .getLimits()
        .then(result => {
          commit('SET_LIMITS', result)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  GET_QUOTAS: ({ commit }) => {
    return new Promise(resolve => {
      return api.user
        .getQuotas()
        .then(result => {
          commit('SET_QUOTAS', result)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  GET_TAWKTO_HASH: ({ commit }) => {
    return new Promise(resolve => {
      return api.user
        .getTawktoHash()
        .then(result => {
          commit('SET_TAWKTO_HASH', result)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  GET_IMPACT_HASH: ({ commit }) => {
    return new Promise(resolve => {
      return api.user
        .getImpacttoHash()
        .then(result => {
          commit('SET_IMPACT_HASH', result)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  GET_UPGRADE_LINK: ({ commit }) => {
    return new Promise(resolve => {
      return api.user
        .getAppsumoUpgradeLink()
        .then(result => {
          commit('SET_UPGRADE_LINK', result)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  RENAME_USER: ({ }, payload) => {
    return new Promise(resolve => {
      return api.user
        .renameUser(payload)
        .then(result => {
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  TOGGLE_WIZARD: ({ }, status) => {
    return new Promise(resolve => {
      return api.user
        .toggleWizard(status)
        .then(result => {
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },
}

const state = {
  me: null,
  youtubeUrl: null,
  subscription: null,
  paymentMethods: null,
  intentToken: null,
  limits: null,
  quotas: null,
  tawktoHash: null,
  impactHash: null,
  upgradeLink: null
}

const namespaced = true

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
}
