import Vue from 'vue'
import VueRouter from 'vue-router'

import privateRoutes from './private'
import publicRoutes from './public'

import { checkUrl } from './guards'

Vue.use(VueRouter)

const routes = [
  ...publicRoutes,
  ...privateRoutes,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(checkUrl)

export default router
