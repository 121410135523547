<template>
    <div class="timeline" ref="timeline" @mousedown="seekToCursor($event)" @mousemove="drag($event)" @touchmove="drag($event)" @mouseup="endDrag" @touchend="endDrag" @mouseleave="endDrag">
        <div :title="$t('trimmer.drag-playback')" ref="playhead" class="playhead" @mousedown="startDrag($event, 'playhead')" @touchstart="startDrag($event, 'playhead')" :style="{left: `0%`}"></div>
        <div :title="$t('trimmer.drag-trim-start')" ref="start" class="marker marker_left" @click="seekToMarker('start')" @mousedown="startDrag($event, 'start')" @touchstart="startDrag($event, 'start')" :style="{left: `calc(${((this.start - this.scene.trim_start_default) * 100.0) / (scene.trim_end_default - scene.trim_start_default)}%)`}">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="48" fill="currentColor" class="bi bi-grip-vertical" viewBox="0 0 16 16">
            <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
          </svg>
        </div>
        <div :title="$t('trimmer.drag-trim-end')" ref="end" class="marker marker_right" @click="seekToMarker('end')" @mousedown="startDrag($event, 'end')" @touchstart="startDrag($event, 'end')" :style="{left: `calc(${((this.end - scene.trim_start_default) * 100.0) / (scene.trim_end_default - scene.trim_start_default) - marker_width_percent}%)`}">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="48" fill="currentColor" class="bi bi-grip-vertical" viewBox="3 0 16 16">
            <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
          </svg>
        </div>
        <div ref="clip" class="clip" :class="clipSizeClass" :style="{left: `calc(${((this.start - this.scene.trim_start_default) * 100.0) / (scene.trim_end_default - scene.trim_start_default)}%)`, width: `calc(${((this.end - this.start) * 100.0) / (scene.trim_end_default - scene.trim_start_default)}%)`}">
          {{ (this.end - this.start).toFixed(1) }} {{ $t('trimmer.seconds') }}
        </div>
      </div>

</template>

<script>

export default {
    name: "trimmer",

    props: ["scene"],

    data() {
        return {
            dragging_marker: null,
            marker_width: 12,
            marker_width_percent: 0.0,
            clipSizeClass: "",
            start: 0,
            end: 100,
            cursor: 0
        }
  },

  mounted() {
    this.start = this.scene.trim_start;
    this.end = this.scene.trim_end;
    this.$refs.playhead.style.left = parseFloat(this.scene.trim_start);
    this.$emit('preview');
  },


    methods: {
        checkClipSize() {
            if (this.$refs.clip && this.$refs.clip.clientWidth < 80) {
                this.clipSizeClass = "clip-too-small"
            } else {
                this.clipSizeClass = ""
            }
        },
        startDrag(e, marker) {
            if (e) {
                e.preventDefault()
                e.stopPropagation()
        }
        if (this.$refs.video) {
            this.$refs.video.pause()
        }
        this.dragging_marker = marker
        },
        endDrag() {
            this.dragging_marker = null
        },
        seekToCursor(e) {
            if (!this.dragging_marker) {
                let percent_x = this.getCursorPosition(e)
                if (this.$refs.video) {
                this.$refs.video.currentTime = percent_x * this.$refs.video.duration
                }
                return false
            }
        },
        seekToMarker(marker) {
            if (this.$refs.video) {
                if (marker == 'start') {
                this.$refs.video.currentTime = this.preview.start * this.$refs.video.duration
                } else if (marker == 'end') {
                this.$refs.video.currentTime = this.preview.end * this.$refs.video.duration
                }
            }
        },
        drag(e) {
            this.checkClipSize()
            if (this.dragging_marker == 'start') {
                this.setPreview({start: Math.min((this.getCursorPosition(e) * (this.scene.trim_end_default - this.scene.trim_start_default) + parseFloat(this.scene.trim_start_default)), this.end),
                                        end: this.end})
            } else if (this.dragging_marker == 'end') {
                this.setPreview({start: this.start,
                                        end: Math.max((this.getCursorPosition(e) * (this.scene.trim_end_default - this.scene.trim_start_default) + parseFloat(this.scene.trim_start_default)), this.start)})
            } else if (this.dragging_marker == 'playhead') {
                let percent_x = this.getCursorPosition(e)
                this.cursor = this.getCursorPosition(e) * (this.scene.trim_end_default - this.scene.trim_start_default) + parseFloat(this.scene.trim_start_default)
                this.$refs.playhead.style.left = `${percent_x * 100}%`
                this.$emit('current-position', this.cursor);
            }
        },
        setPreview(position) {
            this.start = parseFloat(position.start);
            this.end = parseFloat(position.end);

            this.scene.trim_start = this.start;
            this.scene.trim_end = this.end;
        },
        getMarkerWidth() {
            if (this.$refs.timeline) {
                return this.marker_width / this.$refs.timeline.clientWidth + 2
            } else {
                return 0.0
            }
        },
        getCursorPosition(e, x_offset=0) {
            let timeline_bounds = this.$refs.timeline.getBoundingClientRect()
            let x = x_offset

            // Get X coordinate
            if (e.type == 'touchstart' || e.type == 'touchmove' || e.type == 'touchend' || e.type == 'touchcancel') {
                let touch = e.touches[0] || e.changedTouches[0];
                x += touch.clientX;
            } else if (e.type == 'click' || e.type == 'mousedown' || e.type == 'mouseup' || e.type == 'mousemove' || e.type == 'mouseover' || e.type == 'mouseout' || e.type == 'mouseenter' || e.type == 'mouseleave') {
                x += e.clientX;
            }

            // Clamp to parent bounds
            if (x < timeline_bounds.left) {
                x = timeline_bounds.left
            } else if (x + 12 - x_offset > timeline_bounds.right) {
                x = timeline_bounds.right - 12 + x_offset
            }

            // Convert position to percentage of timeline width
            let relative_x = x - timeline_bounds.left
            let percent_x = (relative_x / timeline_bounds.width)
            this.cursor = percent_x
            return percent_x
        }
    },

    watch: {
        'start': function() {
            this.$emit('current-position', this.start);
        },
        'end': function() {
            this.$emit('current-position', this.end);
        },
        'scene': {
            handler: function handler() {
                this.start = this.scene.trim_start;
                this.end = this.scene.trim_end;
            },
            deep: true
        }
    },

    updated() {
        this.marker_width_percent = this.getMarkerWidth()
    }
};
</script>

<style scoped>
    video {
        background-color: #000000;
        width: 100%;
    }
    .timeline {
        background-color: #000000;
        height: 48px;
        position: relative;
        border-radius: 4px;
        flex-grow: 1;
    }
    .marker {
        color: #ffffff;
        background-color: #2d285e;
        height:48px;
        width: 12px;
        border-radius: 4px;
        position: absolute;
        cursor: ew-resize;
        z-index: 10;
    }
    .clip {
        background-color: #6b60f6;
        height:48px;
        position: absolute;
        z-index: 0;
        color: #ffffff;
        font-size: 0.7em;
        text-align: center;
        padding-top: 15px;
        border-radius: 5px;
    }
    .clip-too-small {
        color: #0d6efd!important;
        padding-top: 47px!important;
    }
    .marker_left {
        border-left: #2d285e solid 1px;
    }
    .marker_right {
        border-right: #2d285e solid 1px;
    }
    .playhead {
        background-color: #ffffff;
        width: 3px;
        height: 48px;
        position: absolute;
        cursor: grab;
        z-index: 100;
        opacity: 0.8;
    }
    .timeline-btn {
        height: 48px;
        width: 100%;
        padding: 0.15em;
    }
    .img-thumbnail {
        width: 100%!important;
    }
    .spinner-border {
        margin-top: 100px;
        width: 4em;
        height: 4em;
    }
    .spinner-container {
        min-height: 300px;
    }
</style>
