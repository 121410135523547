var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isVlog && _vm.model.locale == '')?_c('div',{staticClass:"alert alert-danger text-center",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.$t("new-project.select-language"))+" ")]):(_vm.video_style)?_c('div',{staticClass:"alert alert-dark text-center text-success d-flex justify-content-between",attrs:{"role":"alert"}},[_c('b',[_c('i',{staticClass:"bi-check-circle-fill"}),_vm._v(" "+_vm._s(_vm.$t('new-project.good-work')))]),_c('button',{staticClass:"btn btn-default m-0 p-0",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.changeNav('music')}}},[_vm._v(" "+_vm._s(_vm.$t("new-project.next"))+" "),_c('i',{staticClass:"bi-arrow-right-circle"})])]):_vm._e(),_c('h5',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("new-project.audio-type"))),_c('br'),_c('small',[_vm._v(_vm._s(_vm.$t("new-project.select-one")))])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4 text-center"},[_c('button',{staticClass:"btn btn-block border-primary",class:{
          'btn-primary': _vm.video_style == 'vlog',
          'btn-outline-primary': _vm.video_style != 'vlog',
        },attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.changeStyle('vlog')}}},[_vm._m(0),_c('h4',{staticClass:"text-light"},[_vm._v(_vm._s(_vm.$t("new-project.someone-is-talking")))])]),(_vm.isVlog)?_c('div',{staticClass:"form-group mt-3"},[_c('label',[_vm._v(_vm._s(_vm.$t("new-project.spoken-language")))]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.locale),expression:"model.locale"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "locale", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}}),_c('option',{attrs:{"value":"en-US"}},[_vm._v("English")]),_c('option',{attrs:{"value":"pt-BR"}},[_vm._v("Português")]),_c('option',{attrs:{"value":"de-DE"}},[_vm._v("Deutsch")]),_c('option',{attrs:{"value":"es-ES"}},[_vm._v("Español")]),_c('option',{attrs:{"value":"fr-FR"}},[_vm._v("Français")]),_c('option',{attrs:{"value":"it-IT"}},[_vm._v("Italiano")]),_c('option',{attrs:{"value":"ar-SA"}},[_vm._v("عربى")]),_c('option',{attrs:{"value":"fil-PH"}},[_vm._v("Filipino")]),_c('option',{attrs:{"value":"hi-IN"}},[_vm._v("Hindi")])])]):_vm._e()]),_c('div',{staticClass:"col-sm-4 text-center"},[_c('button',{staticClass:"btn btn-block border-primary",class:{
          'btn-primary': _vm.video_style == 'no_speech',
          'btn-outline-primary': _vm.video_style != 'no_speech',
        },attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.changeStyle('no_speech')}}},[_vm._m(1),_c('h4',{staticClass:"text-light"},[_vm._v(_vm._s(_vm.$t("new-project.no-voice")))])])]),_c('div',{staticClass:"col-sm-4 text-center"},[_c('button',{staticClass:"btn btn-block border-primary",class:{
          'btn-primary': _vm.video_style == 'mute_videos',
          'btn-outline-primary': _vm.video_style != 'mute_videos',
        },attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.changeStyle('mute_videos')}}},[_vm._m(2),_c('h4',{staticClass:"text-light"},[_vm._v(_vm._s(_vm.$t("new-project.no-sound")))])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"p-0 m-0"},[_c('i',{staticClass:"bi-mic-fill",staticStyle:{"font-size":"6rem"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"p-0 m-0"},[_c('i',{staticClass:"bi-volume-up",staticStyle:{"font-size":"6rem"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"p-0 m-0"},[_c('i',{staticClass:"bi-volume-mute",staticStyle:{"font-size":"6rem"}})])
}]

export { render, staticRenderFns }