import LoginView from '@/views/public/Login/LoginView'
import PricingView from '@/views/public/Pricing/PricingView'
import CreateAccountView from '@/views/public/CreateAccount/CreateAccountView'

const publicRoutes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: PricingView,
    beforeEnter(to, from, next) {
      window.location.href = "https://wisecut.video/pricing";
    }
  },
  {
    path: '/register',
    name: 'register',
    component: CreateAccountView
  },
  {
    path: '/register/:referral',
    name: 'register',
    component: CreateAccountView
  },
]

export default publicRoutes
