<template>
  <div>
    <p><span class="badge badge-info">{{$t('library.images')}}: {{files.length}}</span></p>
    <div class="row">
      <div class="col-sm-3" v-for="(file, index) in files" :key="`image_to_upload_${index}`">
        <div class="text-right">
          <a href="#" class="m-0 p-0" @click.prevent="removeImage(index)"><i class="bi-trash"></i></a>
        </div>
        <img  class="rounded border border-dark" :src="URL.createObjectURL(file.data)" style="max-width: 100%;" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'library-images-component',

  props: ["files", "removeImage"],

  data() {
    return {
      URL: URL,
    };
  },
}
</script>
