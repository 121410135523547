<template>
  <svg v-bind="icon.attributes" :width="width" :height="width">
    <template v-for="child in icon.children">
      <g
        v-if="child.name === 'g' && child.children"
        :key="child.attributes.id || child.attributes['data-name']"
        v-bind="child.attributes"
      >
        <path
          v-for="path in child.children.filter(c => c.name === 'path')"
          :key="path.attributes.d || path.attributes.id || path.attributes['data-name']"
          v-bind="path.attributes"
          :stroke="stroke || path.attributes.stroke"
        />
        <g
          v-for="subGroup in child.children.filter(c => c.name === 'g')"
          :key="subGroup.attributes.id || subGroup.attributes['data-name']"
          v-bind="subGroup.attributes"
        >
          <path
            v-for="subPath in subGroup.children"
            :key="subPath.attributes.d || subPath.attributes.id || subPath.attributes['data-name']"
            v-bind="subPath.attributes"
            :stroke="stroke || subPath.attributes.stroke"
          />
        </g>
      </g>
      <path
        v-if="child.name === 'path'"
        :key="child.attributes.d || child.attributes.id || child.attributes['data-name']"
        v-bind="child.attributes"
        :stroke="stroke || child.attributes.stroke"
      />
    </template>
  </svg>
</template>

<script>
import icons from "./icons.json";

export default {
  name: "SvgIcon",
  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: "24px",
    },
    stroke: {
      type: String,
      default: "",
    },
  },
  computed: {
    icon() {
      return icons[this.name];
    },
  },
};
</script>
