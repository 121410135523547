<template>
  <div class="text-left">
    <hr class="border-primary" />
    <div class="row">
      <div v-if="!this.isFreeUser" class="col-sm-auto">
        <a :href="`${url}/projects/${selected.project.id}/downloadrender`" class="btn btn-primary mb-3" download>
          <i class="bi-film"></i> {{ $t('project-edit.download-finished-video') }}
        </a>
      </div>
      <div class="col-sm-auto">
        <button type="button" @click.prevent="downloadSubtitles" class="btn btn-secondary">
          <i class="bi-badge-cc"></i>
          {{ $t('project-edit.download-subtitles') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'share-export-old',

  props: ['downloadSubtitles', 'tryReopen', 'selected'],

  data() {
    return {
      url: null,
    }
  },

  computed: {
    ...mapGetters({
      me: 'user/me',
    }),

    isFreeUser() {
      return this.me.video_tier < 1;
    },
  },

  mounted() {
    this.url = process.env.VUE_APP_WEB_URL
  },
}
</script>
