<template>
  <ul class="nav nav-tabs mb-3">
    <li class="nav-item">
      <a
        class="nav-link d-flex justify-content-between"
        :class="{ active: nav == 'upload', disabled: scheduled, 'text-success': files.length }"
        href="#"
        @click.prevent="changeNav('upload')"
      >
        <span>1. {{ $t('new-project.upload') }} <SvgIcon v-if="files.length" name="check" stroke="#20CE9E" /></span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link d-flex justify-content-between" :class="{ active: nav == 'create' }" href="#" @click.prevent="changeNav('create')">
        <span>2. {{ $t('new-project.settings') }}</span>
      </a>
    </li>
  </ul>
</template>

<script>
import SvgIcon from '@/components/icon/SvgIcon.vue'

export default {
  name: 'new-project-nav',

  props: ['nav', 'changeNav', 'scheduled', 'isVlog', 'locale', 'files'],

  components: {
    SvgIcon,
  },

  mounted() {
    if (this.scheduled) {
      this.changeNav('create')
    }
  },
}
</script>

<style lang="scss" scoped>
.nav-link {
  span {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
</style>
