<template>
  <div class="payment">
    <div class="card">
      <h5 class="card-title mb-3">
        {{ $t('account.payment-methods') }}
      </h5>

      <h6 class="card-title mb-3 text-default" v-if="!paymentMethods?.length">
        {{ $t('payment.no-payment-methods') }}
      </h6>

      <ul class="list-group" v-if="paymentMethods?.length">
        <li class="list-group-item rounded-lg credit-card" v-for="method in paymentMethods" :key="method.id">
          <div class="d-flex justify-content-between">
            <h3 class="p-0 m-0" :class="{ 'text-success': method.id == payment_method }">
              <small @click="selectMethod(method.id)" style="cursor: pointer">
                <i :class="{ 'bi-square': method.id != payment_method, 'bi-check-square-fill': method.id == payment_method }"></i>
              </small>
            </h3>
            <img src="@/assets/images/mastercard.png" v-if="method.brand == 'mastercard'" height="36" />
            <img src="@/assets/images/visa.png" v-if="method.brand == 'visa'" height="36" />
          </div>
          <div class="mt-3 ml-3 pl-3">
            <h2>
              <span class="rounded bg-warning text-dark pl-3 pr-3">
                <i class="bi-cpu"></i>
              </span>
            </h2>
          </div>
          <div class="text-center">
            <h2>**** **** **** {{ method.last_four }}</h2>
            <div class="d-flex justify-content-between">
              <span>Exp {{ method.exp_month }} / {{ method.exp_year }}</span
              ><button type="button" class="btn btn-lg btn-link p-0 m-0" :disabled="loading" @click="confirmRemovePayment(method.id)">
                <div class="spinner-border text-light" role="status" v-if="loading">
                  <span class="sr-only">Loading...</span>
                </div>
                <i class="bi-trash" v-if="!loading"></i>
              </button>
            </div>
          </div>
        </li>
      </ul>

      <div class="card-form mt-3" v-if="showForm">
        <div class="text-right">
          <a href="https://stripe.com/" target="_blank"><img src="@/assets/images/stripe.png" height="50" /></a>
        </div>
        <div class="form-group">
          <label>{{ $t('payment.name-on-card') }}</label>
          <input type="text" class="form-control" v-model="model.name" />
        </div>
        <div class="form-group">
          <label>{{ $t('payment.card-number') }}</label>
          <div id="card-element" class="form-control"></div>
        </div>
        <button type="button" class="btn btn-primary btn-block" @click="addCard" :disabled="loading">
          <div class="spinner-border text-light" role="status" v-if="loading">
            <span class="sr-only">Loading...</span>
          </div>
          <span v-if="!loading">{{ $t('payment.add-card') }}</span>
        </button>
        <button type="button" class="btn btn-sm btn-secondary btn-block mt-3 btn-cancel" v-if="!loading" @click="showForm = false">
          {{ $t('payment.cancel') }}
        </button>
      </div>

      <div class="mt-3 text-center" v-if="!showForm">
        <button type="button" class="btn btn-sm btn-outline w-fit btn-add-payment" @click="addPayment">
          {{ $t('payment.add-payment-method') }}
        </button>
      </div>
    </div>

    <b-modal id="modal-remove-payment" :title="$t('payment.confirm-remove-payment')" centered hide-footer>
      <template #modal-header="{ close }">
        {{ $t('payment.confirm-remove-payment') }}
        <button class="btn btn-link p-0 close" @click="close()">
          <SvgIcon name="cancel" stroke="#ffffff" width="35px" />
        </button>
      </template>

      <p>{{ $t('payment.confirm-remove-payment-text') }}</p>

      <div class="d-flex justify-content-between mt-5">
        <button type="button" class="btn btn-block btn-primary" @click.prevent="tryRemovePayment">
          {{ $t('payment.confirm') }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { loadStripe } from '@stripe/stripe-js'
import SvgIcon from '@/components/icon/SvgIcon.vue'

export default {
  name: 'account-payment',

  props: ['payment_method', 'selectMethod', 'isShowForm'],

  components: {
    SvgIcon,
  },

  data() {
    return {
      loading: false,
      loadingMethods: true,
      stripe: null,
      showForm: false,
      model: {
        name: '',
      },
      card: '',
      cardToRemove: null,
    }
  },

  computed: {
    ...mapGetters({
      paymentMethods: 'user/paymentMethods',
      intentToken: 'user/intentToken',
    }),
  },

  async mounted() {
    await this.getPaymentMethods()
    this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)
    this.loadingMethods = false

    if (this.isShowForm && !this.payment_method) {
      this.addPayment()
    }
  },

  methods: {
    ...mapActions({
      getPaymentMethods: 'user/GET_PAYMENT_METHODS',
      getIntentToken: 'user/GET_INTENT_TOKEN',
      savePaymentMethod: 'user/SAVE_PAYMENT_METHOD',
      removePaymentMethod: 'user/REMOVE_PAYMENT_METHOD',
    }),

    addPayment() {
      this.showForm = true
      setTimeout(() => {
        this.elements = this.stripe.elements()
        this.card = this.elements.create('card')
        this.card.mount('#card-element')
      }, 100)
    },

    confirmRemovePayment(id) {
      this.cardToRemove = id
      this.$bvModal.show('modal-remove-payment')
    },

    async tryRemovePayment() {
      this.selectMethod(null)
      this.$bvModal.hide('modal-remove-payment')
      this.loading = true
      await this.removePaymentMethod(this.cardToRemove)
      await this.getPaymentMethods()
      this.loading = false
    },

    async addCard() {
      this.loading = true
      await this.getIntentToken()
      this.stripe
        .confirmCardSetup(this.intentToken.client_secret, {
          payment_method: {
            card: this.card,
            billing_details: {
              name: this.name,
            },
          },
        })
        .then(async (result) => {
          if (result.error) {
            console.error(result.error)
          } else {
            await this.savePaymentMethod({
              payment_method: result.setupIntent.payment_method,
            })
          }
          await this.getPaymentMethods()
          this.card.clear()
          this.name = ''
          this.showForm = false
          this.loading = false
        })
    },
  },

  watch: {
    isShowForm(val) {
      this.addPayment()
    },
    deep: true,
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';
.payment {
  .card {
    .card-body {
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      .form-group {
        label {
          color: $light;
        }
      }
    }
  }
}

.form-control {
  background-color: $white !important;
  color: $gray-800;
  caret-color: $gray-800;
  &:focus {
    color: $gray-800;
  }
}

#card-element {
  &.form-control {
    background-color: transparent;
    display: flex;
    align-items: center;
    width: 100%;
    iframe {
      width: 100% !important;
    }
    > div {
      width: 100% !important;
    }
  }
}

.list-group-item {
  &.credit-card {
    background-image: linear-gradient(to bottom right, #0b063c, $dpurple);
  }
}
::v-deep {
  .__PrivateStripeElement {
    width: 100% !important;
  }
}
</style>
