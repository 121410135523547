<template>
  <div style="height: 90px" class="mb-3 text-center">
    <div class="text-right">
      <a href="#" class="m-0 p-0" @click.prevent="removeFile(file, index)"
        ><i class="bi-trash"></i
      ></a>
    </div>
      <img
        class="rounded border border-dark"
        :src="URL.createObjectURL(file.data)"
        style="max-width: 100%; max-height: 100%"
      />
    <p class="text-primary text-center text-truncate mb-0">
      <small :class="{'text-success': file.progress >= 100}"
        ><b>{{ file.progress }}%</b> - {{ file.data.name }}</small
      >
    </p>
    <div class="progress" style="height: 3px">
      <div
        class="progress-bar progress-bar-striped"
        :class="{'bg-success': file.progress >= 100}"
        role="progressbar"
        :style="`width: ${file.progress}%`"
        :aria-valuenow="file.progress"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "new-project-image-component",

  props: ["file", "index", "removeFile"],

  data() {
    return {
      URL: URL,
    };
  },
};
</script>
