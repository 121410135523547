<template>
  <div class="container-fluid p-0">
    <h5 class="title-page mb-3">
      {{ $t('account.my-account') }}
    </h5>

    <div class="row">
      <div class="col-md-12 col-lg-10">
        <Subscription :tryUpdateSubscription="tryUpdateSubscription" :payment_method="payment_method" :isLoading="loading" />
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-lg-5">
        <Quotas :tryUpdateSubscription="tryUpdateSubscription" />
      </div>
      <div class="col-md-6 col-lg-5">
        <CurrentPlan :tryUpdateSubscription="tryUpdateSubscription"/>
        <Settings :model="me" />
        <Payment :payment_method="payment_method" :selectMethod="selectMethod" />
      </div>
    </div>

    <AppSumoWelcome v-if="me" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { useSecureLink } from '@/composables/useSecureLink'

import Payment from './components/Payment'
import Subscription from './components/Subscription'
import Quotas from './components/Quotas'
import AppSumoWelcome from './components/AppSumoWelcome'
import Settings from './components/Settings'
import CurrentPlan from './components/CurrentPlan'

export default {
  name: 'account-view',

  components: {
    Payment,
    Subscription,
    Quotas,
    AppSumoWelcome,
    Settings,
    CurrentPlan,
  },

  data() {
    return {
      loading: true,
      payment_method: null,
    }
  },

  computed: {
    ...mapGetters({
      me: 'user/me',
      paymentMethods: 'user/paymentMethods',
    }),
  },

  async mounted() {
    await this.getMe()
    if (this.me.show_wizard && this.me.ltd_tier) {
      this.$bvModal.show('modal-appsumo')
    }
    this.loading = false
    this.checkUserStripeAndRedirect()
  },

  methods: {
    ...mapActions({
      getMe: 'user/GET_ME',
      updateSubscription: 'user/UPDATE_SUBSCRIPTION',
    }),

    selectMethod(id) {
      this.payment_method = id
    },

    checkUserStripeAndRedirect () {
      const redirectURlIA = this.useSecureLink().createLink(process.env.VUE_APP_IA_URL)
      if (!this.me.stripe_id) {
        window.location.href = redirectURlIA;
      }
    },

    async tryUpdateSubscription(plan) {
      if (!this.payment_method && plan.amount > 0) {
        this.$bvToast.toast(this.$t('payment.select-payment-method'), {
          variant: 'info',
          title: this.$t('payment.no-payment-method'),
          toaster: 'b-toaster-top-center',
          solid: true,
        })
        this.$router.push({ name: 'congratulations' })

      } else {
        this.loading = true
        await this.updateSubscription({
          plan: plan.id,
          payment: this.payment_method,
          coupon: plan.coupon ?? null,
        })
        this.loading = false
        window.location.reload()
        this.$router.push({ name: 'congratulations' })
      }
    },
    useSecureLink,
  },

  watch: {
    paymentMethods(val) {
      if (this.paymentMethods.length == 1) {
        this.payment_method = this.paymentMethods[0].id
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';

::v-deep {
  .card {
    padding: 20px;
    min-height: 168px;
    position: relative;
    width: 100%;
    margin-bottom: 40px;

    &--large {
      min-height: 274px;
    }
    &--minor {
      min-height: 117px;
    }
    .card-loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .card-title {
      font-size: 14px;
      margin-bottom: 8px;
    }
    .card-subtitle {
      font-size: 12px;
      margin-bottom: 15px;
    }
    .card-body {
      display: flex;
      align-items: center;
      justify-content: center;

      gap: 5px;
      p {
        margin-bottom: 0;
        color: $white;
        font-size: 20px;
      }
    }
    &__infos {
      font-size: 12px;
      margin-top: 15px;
    }
  }

  [class^='col-'] {
    padding: 0 20px;
  }

  .row {
    margin: 0 -20px;
  }

  .timer-wrapper {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #181818;
  }

  .timer-circle {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background: conic-gradient(#00ffc2 0% var(--percentage), #0044ff var(--percentage) 100%);
    transform: rotate(-90deg);
  }

  .timer-text {
    font-size: 1.5em;
    color: #fff;
    z-index: 1;
  }
}
</style>
