<template>
  <div class="container-fluid p-0">
    <div v-if="loading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else>
      <div v-if="latest">
        <div class="wrapper-latest">
          <h5>{{ $t('home.open-latest-project') }}</h5>

          <div class="card card-project">
            <h5 class="card-title">{{ latest.name }}</h5>

            <router-link :to="`/projects/${latest.id}/preview`">
              <img :src="latest.thumbnailPath || '/img/thumbnail.png'" class="card-img" />
            </router-link>

            <div class="card-body">
              <div class="card-title d-flex justify-content-between">
                <div class="spinner-border text-primary" role="status" v-if="latest.status == 'Working' || latest.status == 'Rendering'">
                  <span class="sr-only">Loading...</span>
                </div>
                <p v-else class="mt-1">
                  <SvgIcon name="clock" />
                  {{ timestampToString(latest.duration_out_seconds) }}
                </p>
              </div>

              <p class="text-primary ml-3" v-if="latest.status == 'Working' || latest.status == 'Rendering'">
                {{ $t('home.creating-project') }}
              </p>
              <router-link v-else :to="`/projects/${latest.id}/preview`" class="btn btn-outline">{{ $t('home.open') }}</router-link>
            </div>
          </div>
        </div>

        <hr class="border-dark" />

        <QuickTips />
      </div>

      <div v-else>
        <div class="row">
          <div class="col text-center">
            <h4>{{ $t('home.welcome') }}</h4>

            <p>{{ $t('home.welcome_tip') }}</p>
          </div>
        </div>

        <div class="row justify-content-center mt-4">
          <div class="col-sm-8 col-md-6 col-lg-4">
            <div class="card">
              <div class="card-body pt-0">
                <h1 class="text-center">
                  <i class="bi-plus-circle"></i>
                </h1>
                <router-link to="/newproject" class="btn btn-lg btn-block btn-primary">
                  {{ $t('home.create-new-project') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import QuickTips from './QuickTips.vue'
import SvgIcon from '@/components/icon/SvgIcon.vue'

export default {
  name: 'home-view',

  data() {
    return {
      loading: true,
    }
  },

  components: {
    QuickTips,
    SvgIcon,
  },

  computed: {
    ...mapGetters({
      latest: 'project/latest',
    }),
  },

  async mounted() {
    await this.getLatest()
    this.loading = false
    this.setInterval(
      setInterval(() => {
        this.getLatest()
      }, 60000)
    )
  },

  methods: {
    ...mapActions({
      getLatest: 'project/GET_LATEST',
      setInterval: 'common/SET_INTERVAL',
    }),

    timestampToString(seconds) {
      return new Date(seconds * 1000).toISOString().substr(11, 8)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';

.border-dark {
  margin-top: 13.25rem;
}

.card-project {
  max-width: 543px;
  width: max-content;
  @media screen and (max-width: 600px) {
    max-width: 100%;
  }
}

.card-img {
  max-height: 18.875rem;
  object-fit: contain;
}

.card-body{
  gap: 80px;
}
</style>
