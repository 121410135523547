<template>
  <div class="plan-details">
    <div class="text-center">
      <h5 class="card-title">
        {{ $t(`pricing.${selectedPlan.nickname.split('-')[0]}`) }}
      </h5>

      <h5 class="plan-details__price--discount" v-if="selectedPlan.amount != parseInt(selectedPlan.amount_decimal)" style="text-decoration-line: line-through">
        US$ {{ (parseInt(selectedPlan.amount_decimal) / 100).toFixed(2) }}
      </h5>
      <h1 class="plan-details__price" :style="{ color: textColor }">
        US$ {{ (selectedPlan.amount / 100).toFixed(2) }}
      </h1>

      <div v-if="selectedPlan.amount > 0">
        <small>
          {{ $t('pricing.billing-cycle') }}:
          <span v-if="selectedPlan.interval == 'year'">({{ $t('pricing.yearly') }})</span>
          <span v-if="selectedPlan.interval == 'month'">({{ $t('pricing.monthly') }})</span>
        </small>
        <br />
        <!-- <small v-if="me && !me.has_tried">{{ $t('pricing.charge-after-trial') }}</small> -->
        <small>{{ $t('pricing.charge-now') }}</small>
      </div>
    </div>

    <table class="table">
      <tbody>
        <tr>
          <th scope="row" class="text-uppercase">
            {{ $t('pricing.minutes') }}
          </th>
          <td>
            <b>{{ selectedPlan.metadata.monthly_minutes }}</b>
            <span> {{ $t('pricing.minutes') }} / {{ $t('pricing.month') }}</span>
          </td>
        </tr>
        <tr>
          <th scope="row" class="text-uppercase">
            {{ $t('pricing.resolution') }}
          </th>
          <td>
            <b v-if="selectedPlan.metadata.video_tier == 2">1080p</b>
            <b v-else-if="selectedPlan.metadata.video_tier == 4">4k</b>
            <b v-else>720p</b>
            <span> Max</span>
          </td>
        </tr>
        <tr>
          <th scope="row" class="text-uppercase">
            {{ $t('pricing.storage') }}
          </th>
          <td>
            <b>{{ selectedPlan.metadata.storage_gb }}</b>
            <span> GB</span>
          </td>
        </tr>
        <tr>
          <th scope="row" class="text-uppercase">
            {{ $t('pricing.file-length') }}
          </th>
          <td>
            <b>{{ selectedPlan.metadata.upload_max_length_minutes }}</b>
            <span> Min / {{ $t('pricing.file') }}</span>
          </td>
        </tr>
        <tr>
          <th scope="row" class="text-uppercase">
            {{ $t('pricing.file-size') }}
          </th>
          <td>
            <b>{{ selectedPlan.metadata.upload_max_size_mb / 1024 }}</b>
            <span> GB / {{ $t('pricing.file') }}</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-center mb-3 mt-3 more-info">
      <a href="/pricing" target="_blank"><i class="bi-info-circle mr-2"></i> {{ $t('payment.more-info') }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'plan-details',

  props: ['me', 'selectedPlan', 'textColor'],
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/colors.scss';

.plan-details {
  .card-title {
    font-weight: bold;
    font-size: 12px;
    color: $white;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  &__price {
    font-weight: normal;
    font-size: 30px;
    line-height: 30px;
    display: inline-block;
    &--discount{
      font-size: 16px;
      color: $gray-450;
    }
  }
}

.table {
  width: auto;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  color: $white;
  background-color: transparent;
  th,
  td {
    vertical-align: top;
    border: none;
    padding: 5px 0;
  }

  thead th {
    vertical-align: bottom;
    border-bottom: none;
  }

  tbody th {
    font-weight: normal;
    padding-right: 11px;
  }

  tbody td {
    font-weight: normal;
    color: $gray-450;
    padding-left: 11px;
    b {
      font-weight: normal;
    }
  }

  tbody + tbody {
    border-top: none;
  }
}
</style>
