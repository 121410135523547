<template>
  <section class="wrapper-upload">
    <div class="d-flex mb-4">
      <h5 class="title-page">
        {{ $t('library.send-new-media') }}
      </h5>

      <button @click="handleBackToList" class="btn btn-outline text-nowrap ml-auto back-list">
        <SvgIcon name="arrow-left" stroke="#ffffff" width="9px" />
        {{ $t('library.back-to-list') }}
      </button>

      <button @click="handleBackToList" class="btn btn-outline text-nowrap ml-auto back-list-modal">
        {{ $t('library.library') }}
      </button>
    </div>

    <div v-if="!isUploading">
      <div
        class="media-dropbox p-3 text-center"
        :class="{ dragging: isDragging }"
        @click.prevent="browseFiles"
        @dragenter="dragEnter"
        @dragover="dragOver"
        @dragleave="dragLeave"
        @drop="drop"
        style="cursor: pointer"
      >
        <p>{{ $t('library.drag-here') }}</p>
        <p>{{ $t('library.or') }}</p>
        <button type="button" class="btn btn-primary">
          {{ $t('library.browse-files') }}
        </button>
      </div>
      <div class="mt-3">
        <div class="spinner-border text-primary" role="status" v-if="loading">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <p class="mt-3" v-if="!loading">
        <small>
          {{ $t('library.max-upload-size') }}: <b class="text-light">{{ uploadMaxSizeBytes / 1024 ** 3 }} GB</b><br />{{
            $t('library.max-upload-length')
          }}:
          <b class="text-light">{{ timestampToString(uploadMaxLengthMinutes * 60) }}</b>
          <span v-if="!selectFiles"
            ><br />{{ $t('library.remaining-processing-minutes') }}: <b class="text-light">{{ timestampToString(processingMinutes * 60) }}</b
            ><br />{{ $t('library.remaining-storage') }}: <b class="text-light">{{ (storageBytes / 1024 ** 3).toFixed(1) }} GB</b></span
          ><br />
          <!-- <a href="#">{{ $t("library.upgrade-plan") }}</a> -->
        </small>
      </p>
      <div id="mediaRow">
        <FromLibrary v-if="from_library.length" :files="from_library" :removeFromLibrary="removeFromLibrary" />
        <Videos v-if="videos.length" :files="videos" :removeVideo="removeVideo" :selectFiles="selectFiles" />
        <Audios v-if="audios.length" :files="audios" :removeAudio="removeAudio" :selectFiles="selectFiles" />
        <Images v-if="images.length" :files="images" :removeImage="removeImage" />
      </div>
      <input id="browse-files" type="file" multiple accept="image/*,audio/*,video/*" style="display: none" @change="handleFiles($event)" />
      <hr class="border-dark" />

      <div class="d-flex justify-content-between">
        <small>
          <b class="text-default">{{ $t('library.upload-size') }}:</b> {{ (projectSize / 1024 ** 3).toFixed(1) }} GB <br />
          <span v-if="!selectFiles">
            <b class="text-default">{{ $t('library.upload-length') }}:</b>
            {{ timestampToString(projectLength) }}
          </span>
        </small>
        <button
          type="button"
          class="btn btn-secondary"
          :disabled="!from_library.length && !videos.length && !audios.length && !images.length"
          @click.prevent="checkLanguages"
        >
          {{ $t('library.upload') }}
          {{ from_library.length + videos.length + audios.length + images.length }}
          {{ $t('library.files') }}
        </button>
      </div>
    </div>
    <div v-if="isUploading">
      <div>
        <h5 class="text-secondary text-center">
          {{ $t('library.uploading-files') }}
        </h5>
      </div>
      <div class="row">
        <div class="col-sm-3" v-for="(file, index) in files" :key="`library_file_${index}`">
          <UploadFileVideo v-if="file.data.type.substr(0, 5) === 'video'" :file="file" :index="index" :removeFile="removeFile" />
          <UploadFileAudio v-if="file.data.type.substr(0, 5) === 'audio'" :file="file" :index="index" :removeFile="removeFile" />
          <UploadFileImage v-if="file.data.type.substr(0, 5) === 'image'" :file="file" :index="index" :removeFile="removeFile" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

import '@uppy/core/dist/style.css'

import SvgIcon from '@/components/icon/SvgIcon.vue'

import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'
import Videos from './Videos'
import Audios from './Audios'
import Images from './Images'
import FromLibrary from './FromLibrary'
import UploadFileVideo from './UploadFileVideo'
import UploadFileAudio from './UploadFileAudio'
import UploadFileImage from './UploadFileImage'

export default {
  name: 'library-upload-component',

  props: ['selectFiles', 'changeNav', 'from_library', 'removeFromLibrary'],

  components: {
    Videos,
    Audios,
    Images,
    FromLibrary,
    UploadFileVideo,
    UploadFileAudio,
    UploadFileImage,
    SvgIcon,
  },

  data() {
    return {
      isUploading: false,
      uploadMaxSizeBytes: 0,
      uploadMaxLengthMinutes: 0,
      processingMinutes: 0,
      storageBytes: 0,
      projectSize: 0,
      projectLength: 0,
      progress: 0,
      loading: true,
      isDragging: false,
      videos: [],
      audios: [],
      images: [],
      files: [],
      model: {
        media_files: [],
      },
      uppy: new Uppy().use(AwsS3, {
        getUploadParameters(file) {
          const url = process.env.NODE_ENV == 'development' ? '' : process.env.VUE_APP_API_URL
          const auth = JSON.parse(localStorage.getItem('user'))
          return fetch(`${url}/signeds3`, {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              'X-CSRF-TOKEN': process.env.VUE_APP_AWS_CSRF_TOKEN,
              Authorization: auth ? 'Bearer ' + auth.api_token : '',
            },
            body: JSON.stringify({
              extension: file.type.split('/').pop(),
            }),
          })
            .then((response) => {
              return response.json()
            })
            .then((data) => {
              return {
                method: 'POST',
                url: data.postEndpoint,
                fields: data.signature,
                headers: {},
              }
            })
        },
      }),
    }
  },

  computed: {
    ...mapGetters({
      quotas: 'user/quotas',
    }),
  },

  mixins: [validationMixin],

  validations: {
    videos: {
      $each: {
        locale: {
          required,
        },
      },
    },
    audios: {
      $each: {
        locale: {
          required,
        },
      },
    },
  },

  async mounted() {
    await this.getQuotas()
    if (this.quotas) {
      this.uploadMaxSizeBytes = this.quotas.uploadMaxSizeBytes
      this.uploadMaxLengthMinutes = this.quotas.uploadMaxLengthMinutes
      this.processingMinutes = this.quotas.processingMinutes
      this.storageBytes = this.quotas.storageBytes
      this.videos = []
    }
    this.loading = false
    this.uppy.cancelAll()
    this.uppy.reset()
    this.uppy.on('progress', (progress) => {
      this.progress = progress
    })

    this.uppy.on('restriction-failed', (file, error) => {
      this.$bvToast.toast(`${file.name}: ${this.$t('library.duplicated')}`, {
        variant: 'danger',
        title: this.$t('library.double-file'),
        toaster: 'b-toaster-top-center',
        solid: true,
        noAutoHide: true,
      })
    })
    this.uppy.on('upload-progress', (file, progress) => {
      const index = this.files.findIndex((x) => x.data.name == file.data.name)
      this.files[index].progress = ((progress.bytesUploaded * 100) / progress.bytesTotal).toFixed(1)
    })
    this.uppy.on('upload-success', async (file) => {
      const index = this.files.findIndex((x) => x.data.name == file.data.name)
      const result = await this.postLibrary({
        media_file: file.meta.key,
        name: file.name,
        locale: this.files[index].locale == 'no-speech' ? null : this.files[index].locale,
        process: 1,
        has_speech: this.files[index].locale == 'no-speech' ? 0 : 1,
      })
      if (result.status == 'success') {
        this.files.splice(index, 1)
        this.$bvToast.toast(`${file.name} ${this.$t('library.successfully-uploaded')}`, {
          variant: 'success',
          title: this.$t('library.file-uploaded'),
          toaster: 'b-toaster-top-center',
          solid: true,
          noAutoHide: true,
        })
      }
      if (!this.files.length) {
        setTimeout(() => {
          this.changeNav('library')
        }, 200)
      }
    })
    this.uppy.on('upload-error', (file, error, response) => {
      console.error('error', file)
    })
  },

  methods: {
    ...mapActions({
      getQuotas: 'user/GET_QUOTAS',
      postLibrary: 'library/POST_LIBRARY',
    }),

    timestampToString(seconds) {
      return new Date(seconds * 1000).toISOString().substr(11, 8)
    },

    browseFiles() {
      document.getElementById('browse-files').click()
    },

    handleFiles(e) {
      e.stopPropagation()
      e.preventDefault()
      let files = e.target.files
      this.handleUploads(files)
      document.getElementById('browse-files').value = ''
    },

    dragEnter(e) {
      e.stopPropagation()
      e.preventDefault()
      this.isDragging = true
    },

    dragOver(e) {
      e.stopPropagation()
      e.preventDefault()
      this.isDragging = true
    },

    dragLeave(e) {
      e.stopPropagation()
      e.preventDefault()
      this.isDragging = false
    },

    drop(e) {
      e.stopPropagation()
      e.preventDefault()
      let dt = e.dataTransfer
      let files = dt.files
      this.handleUploads(files)
      this.isDragging = false
    },

    handleUploads(files) {
      for (let file of files) {
        if (file.size > this.uploadMaxSizeBytes) {
          this.$bvToast.toast(`${file.name} ${this.$t('library.exceeds-size')}`, {
            variant: 'danger',
            title: this.$t('library.limit-single-file'),
            toaster: 'b-toaster-top-center',
            solid: true,
            noAutoHide: true,
          })
        } else {
          let fileToStorage = {
            locale: '',
            data: file,
          }
          switch (file.type.substr(0, 5)) {
            case 'video':
              this.videos.push(fileToStorage)
              break
            case 'audio':
              this.audios.push(fileToStorage)
              break
            case 'image':
              this.images.push(fileToStorage)
              break
            default:
              this.$bvToast.toast(`${file.data.name} ${this.$t('library.is-unsupported')}`, {
                variant: 'warning',
                title: this.$t('library.unsupported-format'),
                toaster: 'b-toaster-top-center',
                solid: true,
              })
              break
          }
        }
      }
    },

    removeVideo(index) {
      this.videos.splice(index, 1)
    },

    removeAudio(index) {
      this.audios.splice(index, 1)
    },

    removeImage(index) {
      this.images.splice(index, 1)
    },

    removeFile(file, index) {
      const uppyFiles = this.uppy.getFiles()
      const uppyFile = uppyFiles.find((x) => x.data.name == file.data.name)
      this.uppy.removeFile(uppyFile.id)
      this.files.splice(index, 1)
    },

    calcSizeLimits() {
      this.projectSize = 0
      if (this.videos.length) {
        for (let file of this.videos) {
          this.projectSize = this.projectSize + file.data.size
        }
      }
      if (this.audios.length) {
        for (let file of this.audios) {
          this.projectSize = this.projectSize + file.data.size
        }
      }
      if (this.images.length) {
        for (let file of this.audios) {
          this.projectSize = this.projectSize + file.data.size
        }
      }
    },

    checkLanguages() {
      if (this.selectFiles) {
        this.sendFiles()
      } else {
        this.$v.videos.$touch()
        this.$v.audios.$touch()
        if (this.$v.videos.$error) {
          this.$bvToast.toast(`${this.$t('library.select-languages-for-all-videos')}`, {
            variant: 'warning',
            title: this.$t('library.language-of-the-video'),
            toaster: 'b-toaster-top-center',
            solid: true,
            noAutoHide: true,
          })
          return
        }
        if (this.$v.audios.$error) {
          this.$bvToast.toast(`${this.$t('library.select-languages-for-all-audios')}`, {
            variant: 'warning',
            title: this.$t('library.language-of-the-audio'),
            toaster: 'b-toaster-top-center',
            solid: true,
          })
          return
        }
        this.sendFiles()
      }
    },

    sendFiles() {
      if (this.selectFiles) {
        const videos = []
        const audios = []
        const images = []
        const from_library = []
        for (let project of this.from_library) {
          from_library.push(project)
        }
        for (let video of this.videos) {
          videos.push(video.data)
        }
        for (let audio of this.audios) {
          audios.push(audio.data)
        }
        for (let image of this.images) {
          images.push(image.data)
        }
        this.selectFiles(from_library, videos, audios, images)
      } else {
        let audioPlayers = document.querySelectorAll('#mediaRow audio')
        let videoPlayers = document.querySelectorAll('#mediaRow video')
        for (let player of videoPlayers) {
          this.projectLength = this.projectLength + player.duration
        }
        for (let player of audioPlayers) {
          this.projectLength = this.projectLength + player.duration
        }
        if (this.projectLength / 60 > this.processingMinutes) {
          this.$bvToast.toast(`${this.$t('library.exceeds-processing-minutes')}`, {
            variant: 'danger',
            title: this.$t('library.limits-restriction'),
            toaster: 'b-toaster-top-center',
            solid: true,
            noAutoHide: true,
          })
        } else if (this.projectSize > this.storageBytes) {
          this.$bvToast.toast(`${this.$t('library.exceeds-storage-bytes')}`, {
            variant: 'danger',
            title: this.$t('library.limits-restriction'),
            toaster: 'b-toaster-top-center',
            solid: true,
            noAutoHide: true,
          })
        } else {
          window.scrollTo(0, 0)
          const files = this.videos.concat(this.audios, this.images)
          for (let file of files) {
            this.uppy.addFile({
              name: file.data.name,
              type: file.data.type,
              locale: file.locale,
              data: file.data,
            })
            this.files.push({
              data: file.data,
              progress: 0,
              locale: file.locale,
            })
          }
          this.uppy.upload()
        }
      }
    },

    handleBackToList() {
      this.changeNav('library')
    },
  },

  watch: {
    videos(val) {
      this.calcSizeLimits()
    },

    audios(val) {
      this.calcSizeLimits()
    },

    images(val) {
      this.calcSizeLimits()
    },

    files(val) {
      if (val.length) {
        this.isUploading = true
      } else {
        this.isUploading = false
      }
    },

    progress(val) {
      if (val >= 100) {
        const files = this.uppy.getFiles()
        setTimeout(() => {
          this.model.media_files = files.map((value) => {
            return value.meta.key
          })
        }, 2000)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';
.back-list-modal {
  display: none;
}

p {
  color: $gray-450;
}
.media-dropbox {
  border: 1px dashed $gray-600;
  border-radius: 5px;
  background: $gray-900;
  min-height: 291px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.dragging {
    border-color: $secondary;
    background-color: $dark;
  }
}
</style>
