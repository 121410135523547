import api from "@/api"

const getters = {
  all: state => {
    return state.all
  }
}

const mutations = {
  SET_ALL: (state, result) => {
    if(result && result.data.length) {
      state.all = result.data
    } else {
      state.all = null
    }
  }
}

const actions = {
  GET_ALL: ({ commit }) => {
    return new Promise(resolve => {
      return api.bgm
        .getAll()
        .then(result => {
          commit('SET_ALL', result)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  }
}

const state = {
  all: null
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
