<template>
  <div class="container-fluid m-0 p-0">
    <div class="row justify-content-start">
      <div class="col-sm-10 col-md-8 col-lg-6">
        <section>
          <div class="form-group">
            <label><b>{{ $t('project-edit.project-name') }}</b></label>
            <div class="input-group">
              <input type="text" class="form-control" v-model="model.name" maxlength="140" />
              <div class="input-group-append">
                <button class="btn btn-primary" type="button" @click.prevent="tryUpdate">
                  {{ $t('project-edit.save') }}
                </button>
              </div>
            </div>

            <div v-if="me && me.render_notifications" class="custom-control custom-switch mt-3 text-left">
              <input
                type="checkbox"
                class="custom-control-input"
                id="render-notifications"
                v-model="model.render_notifications"
                @change="tryUpdate"
              />
              <label class="custom-control-label" for="render-notifications">
                <p>{{ $t('project-edit.render-notifications') }}</p>
              </label>
            </div>
          </div>
        </section>

        <hr class="border-dark" />

        <section>
          <div class="d-flex justify-content-between">
            <label>
              {{ $t('project-edit.wisecut-features') }}
            </label>
          </div>
          <div class="custom-control custom-switch mb-2">
            <input type="checkbox" class="custom-control-input" id="automatic-music" v-model="model.autobgm" @change="tryUpdate" />
            <label class="custom-control-label" for="automatic-music">
              <p>{{ $t('project-edit.automatic-music') }}</p>
            </label>
            <SvgIcon name="info-octogon" stroke="#5E52F5" width="18px" id="info-autobgm" class="ml-2" />
            <b-popover target="info-autobgm" placement="right" :title="$t('project-edit.info-autobgm')" triggers="hover"></b-popover>
          </div>
          <div class="custom-control custom-switch mb-2">
            <input type="checkbox" class="custom-control-input" id="automatic-subtitles" v-model="model.render_subtitle" @change="tryUpdate" />
            <label class="custom-control-label" for="automatic-subtitles">
              <p>{{ $t('project-edit.automatic-subtitles') }}</p>
            </label>
            <SvgIcon name="info-octogon" stroke="#5E52F5" width="18px" id="info-render-subtitles" class="ml-2" />
            <b-popover target="info-render-subtitles" placement="right" :title="$t('project-edit.info-render-subtitle')" triggers="hover"></b-popover>
          </div>
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="autopunchin" v-model="model.autopunchin" @change="tryUpdate" />
            <label class="custom-control-label" for="autopunchin">
              <p>{{ $t('project-edit.auto-punch-in') }}</p>
            </label>
            <SvgIcon name="info-octogon" stroke="#5E52F5" width="18px" id="info-autopunchin" class="ml-2" />
            <b-popover target="info-autopunchin" placement="right" :title="$t('project-edit.info-autopunchin')" triggers="hover"></b-popover>
          </div>
          <button
            v-if="!model.cuts_project"
            type="button"
            class="btn btn-block btn-primary mt-3"
            @click.prevent="confirmResetStoryboard"
            :disabled="resetLoading"
          >
            <div class="spinner-border text-light" role="status" v-if="resetLoading">
              <span class="sr-only">Loading...</span>
            </div>
            <span v-if="!resetLoading">{{ $t('project-edit.autocut-settings') }}</span>
          </button>
        </section>

        <hr class="border-dark" />

        <section>
          <div>
            <label
              ><b>{{ $t('project-edit.footage-audio') }}</b></label
            >
            <div class="custom-control custom-switch mb-3">
              <input type="checkbox" class="custom-control-input" id="footage-audio-mute" v-model="model.mute_videos" @change="tryUpdate" />
              <label class="custom-control-label" for="footage-audio-mute">
                <p>{{ $t('project-edit.mute') }}</p>
              </label>
            </div>
          </div>
          <button
            type="button"
            class="btn btn-block btn-primary mt-3"
            v-if="!model.mute_videos"
            @click.prevent="confirmResetVolume"
            :disabled="resetLoading"
          >
            <div class="spinner-border text-light" role="status" v-if="resetLoading">
              <span class="sr-only">Loading...</span>
            </div>
            <span v-if="!resetLoading">{{ $t('project-edit.volume-settings') }}</span>
          </button>
        </section>

        <hr class="border-dark" />

        <section>
          <label>
            <b>{{ $t('project-edit.enhancers') }}</b>
          </label>
          <div class="custom-control custom-switch mb-2" v-if="!model.mute_videos">
            <input type="checkbox" class="custom-control-input" id="audio-enhancer" v-model="model.audio_preprocessor" @change="tryUpdate" />
            <label class="custom-control-label" for="audio-enhancer">
              <p>{{ $t('project-edit.audio-enhancer') }}</p>
            </label>
          </div>
        </section>

        <section>
          <div class="custom-control custom-switch" v-if="model.audio_preprocessor && !model.mute_videos">
            <input type="checkbox" class="custom-control-input" id="voice-booster" v-model="model.voice_booster" @change="tryUpdate" />
            <label class="custom-control-label" for="voice-booster">
              <p>{{ $t('new-project.noise-canceling') }}</p>
            </label>
            <i class="bi-info-circle text-info ml-2 mb-2" id="info-voice-booster"></i>
            <b-popover target="info-voice-booster" placement="right" :title="$t('new-project.voice-booster-info')" triggers="hover"></b-popover>
          </div>
        </section>

        <div v-if="model.render_subtitle">
          <hr class="border-dark" />
          <label>
            <b>{{ $t('project-edit.subtitles') }}</b>
          </label>
          <div class="row">
            <div class="col-sm-4">
              <label class="text-primary">{{ $t('project-edit.font-color') }}</label
              ><br />
              <v-swatches v-model="model.font.color" row-length="6" shapes="circles" show-border :swatches="swatches" @input="tryUpdate"></v-swatches
              ><br />{{ model.font.color }}
            </div>
            <div class="col-sm-4">
              <label class="text-primary">{{ $t('project-edit.shadow-color') }}</label
              ><br />
              <v-swatches
                v-model="model.font.border_color"
                row-length="6"
                shapes="circles"
                show-border
                :swatches="swatches"
                @input="tryUpdate"
              ></v-swatches
              ><br />{{ model.font.border_color }}
            </div>
            <div class="col-sm-4">
              <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="drop-shadow" v-model="model.font.dropshadow" @change="tryUpdate" />
                <label class="custom-control-label text-primary" for="drop-shadow">
                  <p>{{ $t('project-edit.drop-shadow') }}</p>
                </label>
              </div>
            </div>
          </div>

          <!-- preview videos -->
          <div class="subtitle-preview">
            <div class="subtitle-preview--horizontal" :style="getTextStyle(model.font, 'horizontal')">
              <img class="img-thumbnail" :src="selected.project.thumbnailPath" />
              <p :style="`color: ${model.font.color}; `">
                {{ $t('project-edit.subtitle-sample') }}
              </p>
            </div>

            <div class="subtitle-preview--vertical" :style="getTextStyle(model.font, 'vertical')">
              <img class="img-thumbnail" :src="selected.project.thumbnailPath" />
              <p :style="`color: ${model.font.color}; `">
                {{ $t('project-edit.subtitle-sample') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-confirm-reset-volume" :title="$t('project-edit.volume-settings')" centered hide-footer>
      <div v-if="model.autobgm" class="mb-2">
        <label class="text-primary"
          ><b>{{ $t('project-edit.music-volume') }}</b></label
        >
        <div class="d-flex justify-content-between">
          <button
            type="button"
            :class="{ 'text-success': model.musicType == 'bgmlow' }"
            class="btn btn-link p-0 m-1"
            @click.prevent="changeBgmVolume('bgmlow')"
          >
            <i
              :class="{
                'bi-circle': model.musicType != 'bgmlow',
                'bi-check-circle-fill': model.musicType == 'bgmlow',
              }"
            ></i>
            {{ $t('project-edit.low') }}
          </button>
          <button
            type="button"
            :class="{ 'text-success': model.musicType == 'bgmstandard' }"
            class="btn btn-link p-0 m-1"
            @click.prevent="changeBgmVolume('bgmstandard')"
          >
            <i
              :class="{
                'bi-circle': model.musicType != 'bgmstandard',
                'bi-check-circle-fill': model.musicType == 'bgmstandard',
              }"
            ></i>
            {{ $t('project-edit.medium') }}
          </button>
          <button
            type="button"
            :class="{ 'text-success': model.musicType == 'bgmloud' }"
            class="btn btn-link p-0 m-1"
            @click.prevent="changeBgmVolume('bgmloud')"
          >
            <i
              :class="{
                'bi-circle': model.musicType != 'bgmloud',
                'bi-check-circle-fill': model.musicType == 'bgmloud',
              }"
            ></i>
            {{ $t('project-edit.high') }}
          </button>
          <button
            type="button"
            :class="{ 'text-success': model.musicType == 'mainmusic' }"
            class="btn btn-link p-0 m-1"
            @click.prevent="changeBgmVolume('mainmusic')"
          >
            <i
              :class="{
                'bi-circle': model.musicType != 'mainmusic',
                'bi-check-circle-fill': model.musicType == 'mainmusic',
              }"
            ></i>
            {{ $t('project-edit.full') }}
          </button>
        </div>
      </div>
      <label
        ><b class="text-primary">{{ $t('project-edit.media-volume') }}</b></label
      >
      <div class="d-flex justify-content-between">
        <button type="button" class="btn btn-link p-0 m-1" :class="{ 'text-success': model.mediaAudio == 'low' }" @click="changeMediaVolume('low')">
          <i
            :class="{
              'bi-circle': model.mediaAudio != 'low',
              'bi-check-circle-fill': model.mediaAudio == 'low',
            }"
          ></i>
          {{ $t('project-edit.low') }}
        </button>
        <button
          type="button"
          class="btn btn-link p-0 m-1"
          :class="{
            'text-success': model.mediaAudio == 'standard',
          }"
          @click="changeMediaVolume('standard')"
        >
          <i
            :class="{
              'bi-circle': model.mediaAudio != 'standard',
              'bi-check-circle-fill': model.mediaAudio == 'standard',
            }"
          ></i>
          {{ $t('project-edit.medium') }}
        </button>
        <button type="button" class="btn btn-link p-0 m-1" :class="{ 'text-success': model.mediaAudio == 'loud' }" @click="changeMediaVolume('loud')">
          <i
            :class="{
              'bi-circle': model.mediaAudio != 'loud',
              'bi-check-circle-fill': model.mediaAudio == 'loud',
            }"
          ></i>
          {{ $t('project-edit.high') }}
        </button>
        <button
          type="button"
          class="btn btn-link p-0 m-1"
          :class="{
            'text-success': model.mediaAudio == 'extraloud',
          }"
          @click="changeMediaVolume('extraloud')"
        >
          <i
            :class="{
              'bi-circle': model.mediaAudio != 'extraloud',
              'bi-check-circle-fill': model.mediaAudio == 'extraloud',
            }"
          ></i>
          {{ $t('project-edit.full') }}
        </button>
      </div>
      <hr class="border-dark" />
      <div v-if="model.autobgm">
        <div class="row">
          <div class="col text-center">
            <button type="button" class="btn btn-link" :class="{ 'text-success': autoduck }" @click.prevent="autoduck = true">
              <i
                :class="{
                  'bi-circle': !autoduck,
                  'bi-check-circle-fill': autoduck,
                }"
              ></i
              ><br />{{ $t('project-edit.with-auto-duck') }}
            </button>
          </div>
          <div class="col text-center">
            <button type="button" class="btn btn-link" :class="{ 'text-success': !autoduck }" @click.prevent="autoduck = false">
              <i
                :class="{
                  'bi-circle': autoduck,
                  'bi-check-circle-fill': !autoduck,
                }"
              ></i
              ><br />{{ $t('project-edit.without-auto-duck') }}
            </button>
          </div>
        </div>
        <p class="text-center">
          <small>{{ $t('project-edit.auto-duck-tip') }}</small>
        </p>
        <hr class="border-dark" />
      </div>

      <button type="button" class="btn btn-block btn-primary mt-3" @click.prevent="tryResetVolume">
        {{ $t('project-edit.reset') }}
      </button>
    </b-modal>
    <b-modal id="modal-confirm-reset-storyboard" :title="$t('project-edit.autocut-settings')" centered hide-footer>
      <div class="custom-control custom-switch" v-if="model.export_subtitle">
        <input type="checkbox" class="custom-control-input" id="smooth-cuts" v-model="model.smoothcuts" />
        <label class="custom-control-label" for="smooth-cuts">{{ $t('new-project.smoothcuts') }}</label>
        <i class="bi-info-circle text-info ml-2" id="info-smoothcuts"></i>
        <b-popover target="info-smoothcuts" placement="right" :title="$t('project-edit.info-smoothcuts')" triggers="hover"></b-popover>
      </div>
      <div class="custom-control custom-switch" v-if="model.export_subtitle">
        <input type="checkbox" class="custom-control-input" id="auto-cut-scenes" v-model="model.autocutsilences" />
        <label class="custom-control-label" for="auto-cut-scenes">{{ $t('new-project.auto-cut-scenes') }}</label>
        <i class="bi-info-circle text-info ml-2" id="info-autocut"></i>
        <b-popover target="info-autocut" placement="right" :title="$t('project-edit.info-autocut')" triggers="hover"></b-popover>
      </div>
      <p class="mt-3 text-warning">{{ $t('project-edit.reset-storyboard-attention') }}</p>

      <hr class="border-dark" />
      <button type="button" class="btn btn-block btn-danger mt-3" @click.prevent="tryRecreateStoryboard">
        {{ $t('project-edit.restore-storyboard') }}
      </button>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import VSwatches from 'vue-swatches'
import SvgIcon from '@/components/icon/SvgIcon.vue'

export default {
  name: 'settings',

  props: ['model', 'tryUpdate', 'selected'],

  components: {
    VSwatches,
    SvgIcon,
  },

  data() {
    return {
      autoduck: true,
      resetLoading: false,
      swatches: ['#FFFFFF', '#DDDDDD', '#FFFF00', '#FF0000', '#00FF00', '#0000FF', '#00FFFF', '#FF00FF', '#444444', '#000000'],
    }
  },

  computed: {
    ...mapGetters({
      me: 'user/me',
    }),
  },

  mounted() {
    this.getTextStyle(this.model.font, 'horizontal')
    this.getTextStyle(this.model.font, 'vertical')
    this.model.render_subtitle = Boolean(this.model.render_subtitle);
  },

  methods: {
    ...mapActions({
      resetVolumes: 'project/RESET_VOLUMES',
      recreateStoryboard: 'project/RECREATE_STORYBOARD',
    }),

    checkRenderSubtitle() {
      this.model.render_subtitle = Boolean(this.model.render_subtitle);
    },

    getTextStyle(font, type) {
      const baseStyle = `color: ${font?.color};` || ''
      let shadowStyle = ''

      if (font && font.dropshadow) {
        const shadowColor = font.border_color || 'black'

        if (type === 'horizontal') {
          shadowStyle = `text-shadow: 3px 2px 2px ${shadowColor};`
        }

        if (type === 'vertical') {
          shadowStyle = `text-shadow: 4px 0px 0px ${shadowColor}, 3.9232px 0.7804px 0px ${shadowColor}, 3.6956px 1.5308px 0px ${shadowColor}, 3.326px 2.2224px 0px ${shadowColor}, 2.8284px 2.8284px 0px ${shadowColor}, 2.2224px 3.326px 0px ${shadowColor}, 1.5308px 3.6956px 0px ${shadowColor}, 0.7804px 3.9232px 0px ${shadowColor}, 0px 4px 0px ${shadowColor}, -0.7804px 3.9232px 0px ${shadowColor}, -1.5308px 3.6956px 0px ${shadowColor}, -2.2224px 3.326px 0px ${shadowColor}, -2.8284px 2.8284px 0px ${shadowColor}, -3.326px 2.2224px 0px ${shadowColor}, -3.6956px 1.5308px 0px ${shadowColor}, -3.9232px 0.7804px 0px ${shadowColor}, -4px 0px 0px ${shadowColor}, -2.2224px -3.326px 0px ${shadowColor}, -1.5308px -3.6956px 0px ${shadowColor}, -0.7804px -3.9232px 0px ${shadowColor}, 0px -4px 0px ${shadowColor}, 0.7804px -3.9232px 0px ${shadowColor}, 1.5308px -3.6956px 0px ${shadowColor}, 2.2224px -3.326px 0px ${shadowColor}, 2.8284px -2.8284px 0px ${shadowColor}, 3.326px -2.2224px 0px ${shadowColor}, 3.6956px -1.5308px 0px ${shadowColor}, 3.9232px -0.7804px 0px ${shadowColor};`
        }
      }

      return `${baseStyle} ${shadowStyle}`
    },

    changeBgmVolume(volume) {
      this.model.musicType = volume
      this.tryUpdate()
      window.analytics.track('New Project Style Selected', {
        videoStyle: volume,
      })
    },

    changeMediaVolume(volume) {
      this.model.mediaAudio = volume
      this.tryUpdate()
      window.analytics.track('New Project Media Audio Selected', {
        videoStyle: volume,
      })
    },

    confirmResetVolume() {
      this.$bvModal.show('modal-confirm-reset-volume')
    },

    confirmResetStoryboard() {
      this.$bvModal.show('modal-confirm-reset-storyboard')
    },

    async tryResetVolume() {
      this.$bvModal.hide('modal-confirm-reset-volume')
      this.resetLoading = true
      const result = await this.resetVolumes({
        id: this.model.id,
        param: this.autoduck ? 1 : 0,
      })
      localStorage.setItem(`needsRender_${this.model.id}`, 'true')
      this.resetLoading = false
      this.$emit('reload-storyboard')
    },

    async tryRecreateStoryboard() {
      this.$bvModal.hide('modal-confirm-reset-storyboard')
      this.resetLoading = true
      const result = await this.recreateStoryboard({
        id: this.model.id,
        payload: {
          autocutsilences: this.model.autocutsilences,
          smoothcuts: this.model.smoothcuts,
        },
      })
      this.$router.push('/projects/' + this.model.id + '/preview')
      this.resetLoading = false
    },
  },

  watch: {
    'model.render_subtitle': {
      handler(newValue) {
        this.model.render_subtitle = Boolean(newValue);
      },
      immediate: true
    }
  }

}
</script>

<style lang="scss" scoped>
.subtitle-preview {
  position: relative;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 20px;

  $max-height: 300px;

  &--horizontal,
  &--vertical {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    max-height: $max-height;

    img {
      object-fit: cover;
      height: 100%;
      width: auto;
    }

    p {
      margin: 0;
      font-weight: bold;
      text-transform: uppercase;
      color: #ffffff;
      font-size: 16px;
      text-align: center;
      position: absolute;
      bottom: 10px;
      width: 100%;
    }
  }

  &--horizontal {
    width: calc($max-height * 16 / 9);
  }

  &--vertical {
    width: calc($max-height * 9 / 16);
  }
}
</style>
