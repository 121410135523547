<template>
  <div class="text-center">
    <h1 class="text-secondary">{{ $t('create-account.almost-there') }}</h1>
    <p>{{ $t('create-account.complete-registration') }}</p>
    <button type="button" class="btn btn-primary" :disabled="loading" @click.prevent="tryResend">
      <div class="spinner-border" role="status" v-if="loading">
        <span class="sr-only">Loading...</span>
      </div>
      <span v-if="!loading">{{ $t('create-account.send-email-again') }}</span>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'verify-email',

  data() {
    return {
      interval: null,
      loading: false,
    }
  },

  computed: {
    ...mapGetters({
      me: 'user/me',
    }),
  },

  mounted() {
    this.interval = setInterval(() => {
      this.getMe()
    }, 10000)
  },

  methods: {
    ...mapActions({
      getMe: 'user/GET_ME',
      resend: 'user/RESEND',
    }),

    async tryResend() {
      this.loading = true
      const result = await this.resend()
      if (result.msg) {
        this.$bvToast.toast(this.$t('create-account.check-mailbox'), {
          variant: 'success',
          title: this.$t('create-account.email-sent'),
          toaster: 'b-toaster-top-center',
          solid: true,
        })
      }
      this.loading = false
    },
  },

  watch: {
    me(val) {
      if (val.email_verified_at) {
        clearInterval(this.interval)
        this.$router.push('/')
      }
    },
  },
}
</script>
