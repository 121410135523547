<template>
  <div class="music-player">
    <div class="d-flex justify-content-between w-100 pb-2">
      <span class="d-flex align-items-center">
        <button type="button" class="btn btn-lg btn-link p-0 m-0" v-if="!playing" @click.prevent="play">
          <SvgIcon name="play" stroke="#ffffff" width="18px" />
        </button>
        <button type="button" class="btn btn-lg btn-link p-0 m-0" v-if="playing" @click.prevent="pause">
          <SvgIcon name="pause" stroke="#ffffff" width="18px" />
        </button>
        <small class="text-primary mr-3 ml-3">{{ timestampToString(currentTime) }}</small>
        <span class="d-flex align-items-center">
          <b>{{ song.title }}</b>
          <small class="text-default ml-3">{{ song.author }}</small>
        </span>
      </span>
      <span class="text-right">
        <button type="button" class="btn btn-lg btn-link p-0 m-0" @click.prevent="closePlayer">
          <SvgIcon name="cancel" stroke="#ffffff" width="18px" />
        </button>
      </span>
    </div>
    <div class="progress mt-1" style="height: 5px; cursor: pointer" @click.prevent="clickProgress">
      <div
        class="progress-bar"
        role="progressbar"
        :style="`width: ${progress}%;`"
        :aria-valuenow="progress"
        aria-valuemin="0"
        :aria-valuemax="100"
      ></div>
    </div>
    <audio v-if="song" id="audio-player">
      <source :src="song.public_demo" type="audio/mp3" />
    </audio>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import SvgIcon from '@/components/icon/SvgIcon.vue'

export default {
  name: 'music-player',

  data() {
    return {
      audioPlayer: null,
      progress: 0,
      playing: false,
      currentTime: 0,
    }
  },

  components: {
    SvgIcon,
  },

  computed: {
    ...mapGetters({
      song: 'common/song',
    }),
  },

  mounted() {
    if (this.song) {
      this.audioPlayer = document.getElementById('audio-player')
      this.audioPlayer.ontimeupdate = this.onTimeUpdate
      this.audioPlayer.onplay = this.onPlay
      this.audioPlayer.onpause = this.onPause
      this.audioPlayer.play()
    }
  },

  methods: {
    ...mapMutations({
      toggleAudioPlayer: 'common/TOGGLE_AUDIO_PLAYER',
      setSong: 'common/SET_SONG',
    }),

    onTimeUpdate() {
      this.progress = (this.audioPlayer.currentTime * 100) / this.audioPlayer.duration
      this.currentTime = this.audioPlayer.currentTime
    },

    onPlay() {
      this.playing = true
    },

    onPause() {
      this.playing = false
    },

    timestampToString(seconds) {
      return new Date(seconds * 1000).toISOString().substr(11, 8)
    },

    play() {
      this.audioPlayer.play()
    },

    pause() {
      this.audioPlayer.pause()
    },

    clickProgress(e) {
      let width
      let percent
      if (e.target.className.indexOf('progress-bar') > -1) {
        width = e.target.parentElement.offsetWidth
      } else {
        width = e.target.offsetWidth
      }
      percent = (e.layerX * 100) / width
      this.audioPlayer.currentTime = (percent / 100) * this.audioPlayer.duration
    },

    closePlayer() {
      this.setSong(null)
      this.toggleAudioPlayer(false)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';
.drawer-open {
  .music-player {
    width: calc(100% - 16rem);
  }
}
.music-player {
  transition: 0.3s;
  position: fixed;
  width: calc(100% - 90px);
  bottom: 0;
  right: 0;
  z-index: 1040;
  background-color: $black;
  padding: 20px 26px;
  border-top: 1px solid $gray-900;
}
</style>
