import store from '../store'
import router from './index'

export const checkUrl = async (to, from, next) => {
  store.commit('common/CLEAR_INTERVAL')

  const urlLogout = to.query.logout
  const urlWisecutAi = process.env.VUE_APP_IA_URL.replace(/['"]/g, '')

  if (urlLogout) {
    localStorage.removeItem('user')
    store.commit('common/SET_UNLOGGED')
    window.open('https://web.wisecut.video/password/reset', '_blank')
    return
  }

  const urlToken = to.query.token
  let user = JSON.parse(localStorage.getItem('user'))

  window.scrollTo(0, 0)

  const checkUserLoggedIn = async (redirect) => {
    if (user && user.api_token) {
      store.commit('common/SET_LOGGED')
      return next(redirect)
    } else {
      store.commit('common/SET_UNLOGGED')
      return next()
    }
  }

  if (urlToken) {
    try {
      user = { api_token: urlToken }
      localStorage.setItem('user', JSON.stringify(user))
      store.commit('common/SET_LOGGED')

      let newQuery = { ...to.query }
      delete newQuery.token

      router.replace({ ...to, query: newQuery }).catch((err) => {
        console.error('Redirection error:', err)
      })

      return
    } catch (error) {
      if (process.env.NODE_ENV !== 'development') {
        window.analytics.track('FE: Login Page Viewed, Not Logged In', {
          page: to.path,
        })
        window.location.href = `${urlWisecutAi}/login`
      } else {
        return next('/login?redirect=' + to.fullPath)
      }
    }
  }

  if (to.path.indexOf('/register') === 0) {
    if (!user || !user.api_token) {
      if (process.env.NODE_ENV !== 'development') {
        window.analytics.track('FE: Register Page Viewed, Not Logged In', {
          page: to.path,
        })
        window.location.href = `${urlWisecutAi}/register`
      } else {
        return checkUserLoggedIn('/account')
      }
      return
    } else {
      return next('/account')
    }
  } else if (to.path.indexOf('/login') === 0) {
    if (!user || !user.api_token) {
      window.analytics.track('FE: Login Page Viewed, Not Logged In', {
        page: to.path,
      })
      if (process.env.NODE_ENV !== 'development') {
        window.location.href = `${urlWisecutAi}/login`
      } else {
        return checkUserLoggedIn('/login')
      }
      return
    } else {
      return next('/')
    }
  } else {
    switch (to.path) {
      case '/pricing':
        return next()
      default:
        if (to.path === '/newproject') {
          window.onbeforeunload = function () {
            return ''
          }
        } else {
          window.onbeforeunload = null
        }
        if (user && user.api_token) {
          store.commit('common/SET_LOGGED')
          if (to.path !== '/account' && user.show_wizard && user.ltd_tier) {
            return next('/account')
          } else {
            return next()
          }
        } else {
          store.commit('common/SET_UNLOGGED')
          if (process.env.NODE_ENV !== 'development') {
            window.analytics.track('FE: Register Page Viewed, Not Logged In', {
              page: to.path,
            })
            window.location.href = `${urlWisecutAi}/login`
          } else {
            store.commit('common/SET_UNLOGGED')
            return next('/login?redirect=' + to.path)
          }
          return
        }
    }
  }
}

export default {
  checkUrl,
}
