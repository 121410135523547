<template>
  <div class="py-3">
    <div v-if="loading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="accordion" role="tablist" v-if="!loading">
      <div v-for="(language, locale) in lines" :key="locale">
        <button
          type="button"
          class="btn btn-secondary btn-block mb-2"
          v-b-toggle="`accordion-translated-${locale}`"
        >
          {{ $t(`project-edit.${locale}`) }}
        </button>
        <b-collapse
          :id="`accordion-translated-${locale}`"
          accordion="my-accordion"
          role="tabpanel"
          class="mb-2"
        >
          <div class="p-3 bg-black">
            <div class="row">
              <div :class="{ 'col-10': menu, 'col-8': !menu }">
                <p
                  class="mb-3"
                  v-for="(line, index) in language"
                  :key="`line_${index}`"
                >
                  <small
                    class="text-secondary"
                    v-for="(ori, idx) in line.original"
                    :key="`original_${idx}`"
                    >{{ ori.text }}</small
                  ><br />
                  <span
                    v-for="(trans, idx) in line.translated"
                    :key="`translated_${idx}`"
                  >
                    <span v-if="editing != locale">{{ trans.text }}</span>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="trans.text"
                      v-if="editing == locale"
                    />
                  </span>
                </p>
              </div>
              <div
                class="text-right"
                :class="{ 'col-2': menu, 'col-4': !menu }"
              >
                <div
                  class="btn-group"
                  role="group"
                  aria-label="Basic example"
                  style="position: sticky; top: 90px"
                >
                  <button
                    type="button"
                    class="btn btn-secondary"
                    :class="{ 'btn-sm': !menu }"
                    v-if="editing != locale"
                    @click="changeEditing(locale)"
                  >
                    <i class="bi-pencil-square"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    :class="{ 'btn-sm': !menu }"
                    v-if="editing == locale"
                    :disabled="saveLoading"
                    @click="trySaveSubtitles(locale)"
                  >
                    <i class="bi-check-square" v-if="!saveLoading"></i>
                    <div
                      class="spinner-border text-light"
                      role="status"
                      v-if="saveLoading"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    :class="{ 'btn-sm': !menu }"
                    v-if="editing == locale"
                    :disabled="saveLoading"
                    @click="changeEditing(null)"
                  >
                    <i class="bi-x-square"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
      <div v-for="working in pending" :key="working.id">
        <button
          type="button"
          class="btn btn-secondary btn-block mb-2"
          v-b-toggle="`accordion-${working.locale}`"
        >
          {{ $t(`project-edit.${working.locale}`) }}
        </button>
        <b-collapse
          :id="`accordion-${working.locale}`"
          accordion="my-accordion"
          role="tabpanel"
          class="mb-2"
        >
          <div class="p-3 bg-black text-center">
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <p>{{ $t("project-edit.translating") }}</p>
            <p><small>{{ $t("project-edit.translating-tip") }}</small></p>
          </div>
        </b-collapse>
      </div>
      <div v-for="(obj, name) in translateOptions" :key="obj">
        <button
          type="button"
          class="btn btn-secondary btn-block mb-2"
          v-b-toggle="`accordion-${name}`"
        >
          {{ $t(`project-edit.${name}`) }}
        </button>
        <b-collapse
          :id="`accordion-${name}`"
          accordion="my-accordion"
          role="tabpanel"
          class="mb-2"
        >
          <div class="p-3 bg-black text-center">
            <button
              type="button"
              class="btn btn-primary"
              @click="tryTranslate(name)"
            >
              {{ $t("project-edit.translate") }}
            </button>
          </div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "translate",

  props: ["selected", "tryTranslate"],

  data() {
    return {
      loading: true,
      saveLoading: false,
      languages: null,
      pending: null,
      original: null,
      editing: null,
      lines: {},
    };
  },

  computed: {
    ...mapGetters({
      subtitles: "project/subtitles",
      translateOptions: "project/translateOptions",
      menu: "common/menu",
    }),
  },

  async mounted() {
    await this.getSubtitles({
              id: this.selected.project.id,
              ready: 0
              });
    await this.getTranslateOptions(this.selected.project.id);
    this.setInterval(
      setInterval(() => {
        this.getSubtitles({
              id: this.selected.project.id,
              ready: 0
              });
        this.getTranslateOptions(this.selected.project.id);
      }, 60000)
    );
    this.languages = this.subtitles.filter(
      (x) => x.locale != this.selected.project.locale && x.status == "ready"
    );
    this.pending = this.subtitles.filter((x) => x.status != "ready");
    this.original = this.subtitles.find(
      (x) => x.locale == this.selected.project.locale
    );
    this.assembleLanguages();
  },

  methods: {
    ...mapMutations({
      setInterval: "common/SET_INTERVAL",
      clearInterval: "common/CLEAR_INTERVAL",
    }),

    ...mapActions({
      getSubtitles: "project/GET_SUBTITLES",
      getTranslateOptions: "project/GET_TRANSLATE_OPTIONS",
      updateById: "subtitle/UPDATE_BY_ID",
    }),

    changeEditing(locale) {
      this.editing = locale;
      if (locale) {
        this.clearInterval();
      } else {
        this.setInterval(
          setInterval(() => {
            this.getSubtitles({
              id: this.selected.project.id,
              ready: 0
              });
            this.getTranslateOptions(this.selected.project.id);
          }, 60000)
        );
      }
    },

    async trySaveSubtitles(locale) {
      this.saveLoading = true;
      let subtitle = Object.assign(
        {},
        this.subtitles.find((x) => x.locale === locale)
      );
      await this.updateById({
        id: subtitle.id,
        payload: subtitle,
      });
      this.changeEditing(null);
      this.saveLoading = false;
    },

    assembleLanguages() {
      for (let language of this.languages) {
        this.lines[language.locale] = [];
        if (language.lines.length < this.original.lines.length) {
          for (let obj of this.original.lines) {
            let filtered = language.lines.filter(
              (x) =>
                parseFloat(x.start) >= parseFloat(obj.start) &&
                parseFloat(x.start) < parseFloat(obj.end)
            );
            this.lines[language.locale].push({
              original: [obj],
              translated: filtered,
            });
          }
        } else {
          for (let obj of language.lines) {
            let filtered = this.original.lines.filter(
              (x) =>
                parseFloat(x.start) >= parseFloat(obj.start) &&
                parseFloat(x.start) < parseFloat(obj.end)
            );
            this.lines[language.locale].push({
              original: filtered,
              translated: [obj],
            });
          }
        }
      }
      this.loading = false;
    },
  },
};
</script>
