<template>
  <div>
    <div v-if="loading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else>
      <!-- <a v-if="tier_id" href="#" @click.prevent="tiersList()"><i class="bi-chevron-left" style="font-size: 0.9rem"></i> {{ $t('ltd.back') }}</a> -->
      <LtdTier v-if="tier_id" :tier_id="tier_id" :tier_name="tier_name" />
      <div v-else class="card">
        <div class="card-body">
          <h5 class="card-title text-primary">LTD Tiers ({{ $t('ltd.plans') }})</h5>
          <div class="text-center mb-5">
            <button type="button" class="btn btn-success" @click.prevent="selectTier('Tier 1', 'ltd_tier1')">{{ $t('ltd.select') }} Tier 1</button>
          </div>

          <div class="text-center mb-5">
            <button type="button" class="btn btn-success" @click.prevent="selectTier('Tier 2', 'ltd_tier2')">{{ $t('ltd.select') }} Tier 2</button>
          </div>

          <div class="text-center mb-5">
            <button type="button" class="btn btn-success" @click.prevent="selectTier('Tier 3', 'ltd_tier3')">{{ $t('ltd.select') }} Tier 3</button>
          </div>

          <div class="text-center mb-5">
            <p class="text-info mb-0"><a href="https://www.wisecut.video/ltd" target="_blank">{{ $t('ltd.more-info') }}</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LtdTier from "./LtdTier.vue"

export default {
  name: 'tiers-list',

  components: {
    LtdTier,
  },

  data() {
    return {
      loading: true,
      publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
			stripeEnabled: process.env.VUE_APP_STRIPE_ENABLED,
      tier_name: null,
      tier_id: null
    }
  },

  computed: {
    ...mapGetters({
      stripeSession: "user/stripeSession",
    }),
  },

  async mounted() {
    // await this.getStripeLtdSession(this.tier_id);
    if(this.$route.params.tier) {
      this.selectTier('', this.$route.params.tier)
    }
    this.loading = false;
  },

  methods: {
      ...mapActions({
        getStripeLtdSession: "user/GET_STRIPE_LTD_SESSION",
    }),

    selectTier(tier_name, tier_id) {
      this.tier_name = tier_name;
      this.tier_id = tier_id;
    },

    tiersList() {
      this.tier_id = null;
      this.$router.push(`/ltd/buy`);
    }
  },
}
</script>