<template>
    <input
        type="range"
        class="form-control-range"
        id="sceneSplit"
        v-model="splitTime"
        @change="changeSplitTime"
    />
</template>

<script>

export default {
    name: "splitter",

    props: ["scene", "value", "changeSplitTime"],

    data() {
        return {
            splitTime: this.value,
        }
  },

  mounted() {
    this.$emit('preview');
  },


    methods: {

    },
    watch: {
       splitTime(val) {
            this.$emit('current-position', (this.splitTime / 100) * (this.scene.trim_end_default - this.scene.trim_start_default) + parseFloat(this.scene.trim_start_default));
            this.$emit('input', this.splitTime);
        }
    }
};
</script>
