<template>
  <div>
    <div v-if="loading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-if="!loading">
      <h5 class="title-page mb-4">
        {{ selected.project.name }}
      </h5>
      <div v-if="selected.project.status != 'Render Ready'">
        <Nav :nav="nav" v-if="nav" />
        <div
          v-if="!selectedProject.timeline.scenes.length || selectedProject.timeline.scenes.length < projectScenesTotal"
          class="m-2 scenes-loading-progress"
        >
          <small class="text-default">
            <b>
              {{ $t('project-edit.loading-scenes') }}
              <span v-if="projectScenesTotal"> - {{ ((selectedProject.timeline.scenes.length * 100) / projectScenesTotal).toFixed(0) }}%</span>
            </b>
          </small>
          <div class="progress" style="height: 5px">
            <div
              class="progress-bar bg-primary"
              role="progressbar"
              :style="`width: ${((selectedProject.timeline.scenes.length * 100) / projectScenesTotal).toFixed(0)}%`"
              :aria-valuenow="selectedProject.timeline.scenes.length"
              aria-valuemin="0"
              :aria-valuemax="projectScenesTotal"
            ></div>
          </div>
        </div>
        <div v-if="!selectedProject.timeline.scenes.length" class="text-center">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <StoryboardFull
          v-if="nav == 'storyboard' && selectedProject.timeline.scenes.length"
          :selected="selectedProject"
          :needsRender="needsRender"
          :tryRender="tryRender"
          :checkNotRendered="checkNotRendered"
          :tryRenderRaw="tryRenderRaw"
          :renderRawLoading="renderRawLoading"
          :exportLength="exportLength"
          v-on:update-storyboard="updateStoryboard"
          v-on:reload-storyboard="reloadStoryboard"
          v-on:update-render-length="countProjectSceneLengtth"
        />
        <Translate v-if="nav == 'translate'" :selected="selected" :tryTranslate="tryTranslate" />
        <Music v-if="nav == 'music'" :model="model" :tryUpdate="tryUpdate" />
        <Settings v-if="nav == 'settings'" :model="model" :selected="selected" :tryUpdate="tryUpdate" v-on:reload-storyboard="reloadStoryboard" />
      </div>
      <Share v-if="nav == 'share'" :needsRender="needsRender" :tryRender="tryRender" :model="model" :tryUpdate="tryUpdate" />
      <LibraryView v-if="nav == 'library'" :source="'storyboard'" :selectScenes="selectScenes" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

import Nav from './components/Nav'
import StoryboardMobile from './subviews/StoryboardMobile'
import StoryboardFull from './subviews/StoryboardFull'
import Music from './subviews/Music'
import Settings from './subviews/Settings'
import Share from './subviews/ShareOld'
import Translate from './subviews/Translate'
import LibraryView from '@/views/private/Library/LibraryView'

export default {
  name: 'project-edit-view',

  components: {
    Nav,
    StoryboardMobile,
    StoryboardFull,
    Settings,
    Music,
    Share,
    Translate,
    LibraryView,
  },

  data() {
    return {
      nav: null,
      loading: true,
      renderRawLoading: false,
      model: {
        audio_preprocessor: 0,
        autocutsilences: 0,
        smoothcuts: 0,
        autobgm: 0,
        autopunchin: 0,
        render_subtitle: 0,
        mute_videos: 0,
        musicType: '',
        mediaAudio: '',
        intro_mode: null,
        outro_mode: null,
        video_preprocessor: 0,
        voice_booster: 0,
      },
      needsRender: false,
      exportLength: 0,
      projectScenesTotal: 0,
    }
  },

  computed: {
    ...mapGetters({
      selected: 'project/selected',
      storyboard: 'project/storyboard',
      menu: 'common/menu',
    }),
    selectedProject: {
      get() {
        return this.$store.getters['project/selectedProject']
      },
      set(payload) {
        return this.$store.dispatch('project/SET_SELECTED_PROJECT', payload)
      },
    },
    storyboard: {
      get() {
        return this.$store.getters['project/storyboard']
      },
      set(payload) {
        return this.$store.dispatch('project/SET_STORYBOARD', payload)
      },
    },
  },

  async mounted() {
    this.toggleAudioPlayer(false)
    if (!this.selected || this.selected.project.id != this.$route.params.id) {
      await this.getById(this.$route.params.id)
    } else {
      this.selectedProject.project = this.selected.project
      this.getById(this.$route.params.id)
    }
    this.loadStoryboard()
    this.setInterval(
      setInterval(() => {
        if (
          this.nav &&
          this.nav == 'share' &&
          this.selected &&
          this.selected.project.status != 'Preview Ready' &&
          this.selected.project.status != 'Render Ready'
        )
          this.getById(this.$route.params.id)
      }, 60000)
    )

    if (this.selected && this.selected.project) {
      this.onLoadModel()
    }

    if (this.selected && this.storyboard) {
      switch (this.selected.project.status) {
        case 'Render Ready':
          const path = this.$route.path.split('/')
          this.nav = path[path.length - 1]
          if (this.nav != 'share') {
            this.$router.push(`/projects/${this.selected.project.id}/share`)
          }
          break
        default:
          if (this.selected.project.export_raw_url && this.selected.project.export_raw_url != '') {
            this.model = Object.assign(this.model, this.selected.project)
            this.checkNotRendered(this.storyboard.timeline.scenes)
          } else {
            this.$router.push(`/projects/${this.selected.project.id}/preview`)
          }
          break
      }
      window.analytics.track('Project Opened', {
        projectId: this.selected.project.id,
        name: this.selected.project.name,
        firstTimeOpened: this.selected.project.is_new,
      })
    }
    this.loading = false
    this.checkNav()
  },

  methods: {
    ...mapMutations({
      toggleAudioPlayer: 'common/TOGGLE_AUDIO_PLAYER',
      setInterval: 'common/SET_INTERVAL',
    }),

    ...mapActions({
      getById: 'project/GET_BY_ID',
      getStoryboard: 'project/GET_STORYBOARD',
      updateById: 'project/UPDATE_BY_ID',
      render: 'project/RENDER',
      translateSubtitles: 'project/TRANSLATE_SUBTITLES',
      insertScenes: 'project/INSERT_SCENES',
      insertOverlay: 'project/INSERT_OVERLAY',
      renderRaw: 'project/RENDER_RAW',
    }),

    onLoadModel() {
      if (this.selected.project.export_raw_url && this.selected.project.export_raw_url !== '') {
        this.model = Object.assign({}, this.model, this.selected.project)
      }
    },

    async loadStoryboard(scenesIds = []) {
      this.exportLength = 0
      let offset = 0
      let limit = 250

      if (this.storyboard) {
        if (this.storyboard.projectId == this.$route.params.id) {
          offset = this.storyboard.offset + this.storyboard.limit
          this.projectScenesTotal = this.storyboard.timeline.projectScenesTotal
        } else {
          this.storyboard = null
          this.selectedProject.timeline.scenes = []
        }
      }

      while (!this.storyboard || this.storyboard.hasMoreScenes) {
        if (!this.storyboard) limit = 25
        const storyboard = await this.getStoryboard({
          id: this.$route.params.id,
          offset: offset,
          limit: limit,
          projectScenesTotal: this.projectScenesTotal,
          scenesIds: scenesIds,
        })
        offset = this.storyboard.offset + limit
        limit = 250
        if (storyboard.timeline.projectScenesTotal > 0) this.projectScenesTotal = storyboard.timeline.projectScenesTotal

        this.checkNotRendered(storyboard.timeline.scenes)
        this.selectedProject.timeline.scenes = this.selectedProject.timeline.scenes.concat(storyboard.timeline.scenes)
      }

      if (this.selectedProject.timeline.scenes.length) this.countProjectSceneLengtth()
    },

    async updateStoryboard(scenesIds, incNextScenes = false, incValue = 1) {
      if (this.storyboard && this.selectedProject && this.selectedProject.timeline.scenes.length) {
        const storyboard = await this.getStoryboard({
          id: this.$route.params.id,
          projectScenesTotal: this.projectScenesTotal,
          scenesIds: scenesIds,
        })
        this.checkNotRendered(storyboard.timeline.scenes)

        scenesIds.forEach((sceneId) => {
          const i = this.selectedProject.timeline.scenes.findIndex((e) => e.id === sceneId)
          if (i > -1) {
            this.selectedProject.timeline.scenes.splice(i, 1)
          }
        })

        this.selectedProject.timeline.scenes = this.selectedProject.timeline.scenes.concat(storyboard.timeline.scenes)

        if (incNextScenes) {
          this.selectedProject.timeline.scenes.forEach((scene) => {
            if (scene.order >= storyboard.timeline.scenes[storyboard.timeline.scenes.length - 1].order && !scenesIds.includes(scene.id))
              scene.order = scene.order + incValue
          })
        }

        this.selectedProject.timeline.scenes.sort((a, b) => a.order - b.order)

        this.countProjectSceneLengtth()
      }
    },

    async reloadStoryboard() {
      this.storyboard = null
      this.selectedProject.timeline.scenes = []
      await this.loadStoryboard()
    },

    countProjectSceneLengtth() {
      this.exportLength = 0
      for (let scene of this.selectedProject.timeline.scenes) {
        if (scene.visibility === 1) {
          this.exportLength += scene.trim_end - scene.trim_start
        }
      }
    },

    checkNav() {
      const path = this.$route.path.split('/')
      if (path[path.length - 2] == 'library' || path[path.length - 3] == 'overlay') {
        this.nav = 'library'
      } else {
        this.nav = path[path.length - 1]
      }
    },

    checkNotRendered(scenes) {
      // const notRendered = this.storyboard.timeline.scenes.filter(
      //   (x) => x.visibility === 1 && !x.thumbnail
      // );
      const notRendered = scenes.filter((x) => x.visibility === 1 && !x.thumbnail)
      if (notRendered.length) {
        localStorage.setItem(`needsRender_${this.selected.project.id}`, 'true')
      }
      this.needsRender = localStorage.getItem(`needsRender_${this.selected.project.id}`)
    },

    async selectScenes(files) {
      const id = this.$route.params.id
      let order = this.$route.params.order
      let scenes = []
      for (let scene of files) {
        scenes.push(scene.id)
      }
      if (this.$route.params.type && this.$route.params.type == 'overlay') {
        let sceneid = this.$route.params.sceneid
        await this.insertOverlay({
          id,
          payload: {
            sceneid,
            scenes,
          },
        })
      } else {
        await this.insertScenes({
          id,
          payload: {
            order,
            scenes,
          },
        })
      }
      localStorage.setItem(`needsRender_${this.selected.project.id}`, 'true')
      this.$router.push(`/projects/${this.selected.project.id}/storyboard`)
      this.reloadStoryboard()
    },

    async tryUpdate() {
      if (!this.model.autobgm) {
        this.model.intro_mode = null
        this.model.outro_mode = null
      }
      if (this.model.mute_videos) {
        this.model.mute_videos = 1
      } else {
        this.model.mute_videos = 0
      }
      if (this.model.render_notifications) {
        this.model.render_notifications = 1
      } else {
        this.model.render_notifications = 0
      }

      this.$bvToast.toast(this.$t('project-edit.updating-settings'), {
        variant: 'info',
        title: this.$t('project-edit.update'),
        toaster: 'b-toaster-top-center',
        solid: true,
        autoHideDelay: 2500,
      })
      await this.updateById({
        id: this.model.id,
        payload: this.model,
      })
      this.$bvToast.toast(this.$t('project-edit.settings-updated'), {
        variant: 'success',
        title: this.$t('project-edit.update'),
        toaster: 'b-toaster-top-center',
        solid: true,
        autoHideDelay: 2500,
      })
      localStorage.setItem(`needsRender_${this.selected.project.id}`, 'true')
      this.needsRender = localStorage.getItem(`needsRender_${this.selected.project.id}`)
    },

    async tryRender() {
      this.loading = true
      await this.render({
        id: this.selected.project.id,
        payload: {
          final: false,
        },
      })
      localStorage.setItem(`needsRender_${this.selected.project.id}`, 'false')
      setTimeout(() => {
        window.scrollTo(0, 0)
        this.$router.push(`/projects/${this.selected.project.id}/preview`)
      }, 200)
    },

    async tryTranslate(locale) {
      this.loading = true
      await this.translateSubtitles({
        id: this.selected.project.id,
        payload: {
          locale,
        },
      })
      this.loading = false
    },

    async tryRenderRaw() {
      this.renderRawLoading = true
      await this.renderRaw(this.selected.project.id)
      this.renderRawLoading = false
      if (this.needsRender) {
        this.tryRender()
      }
    },
  },

  watch: {
    selected(val) {
      this.selectedProject.project = val.project
    },
  },
}
</script>

<style lang="scss">
.scenes-loading-progress {
  position: fixed;
  bottom: 10px;
  z-index: 1001;
}
</style>
