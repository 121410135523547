<template>
  <div>
    <div
      class="card text-center"
      :class="{
        'bg-light border-gray p-3 mt-3': isCommonPlan,
        'bg-highlight text-light border-light pb-3': !isCommonPlan,
      }"
    >
      <div class="card-body">
        <h5 class="card-title text-uppercase">
          {{ $t(`pricing.${planName}`) }}
        </h5>
        <p class="mt-0 mb-3">
          {{ $t(`pricing.${planName}-tip`) }}
        </p>
        <h1 :class="{ 'text-primary mb-3 pb-3': isCommonPlan }">
          <b v-if="!isFreePlan">US$ {{ (plan.metadata.original_amount / 100).toFixed(2) }}</b>
          <b v-else>US$ 0.00</b>
        </h1>
        <div class="mb-3" v-if="plan.amount > 0">
          <small>{{ $t("pricing.per-month") }}<span v-if="plan.interval == 'year'">{{ $t('pricing.billed-yearly') }}</span></small>
          <!-- <small v-if="!me || !me.has_tried"> | {{ $t("pricing.trial-period") }}: {{ $t("pricing.14-days") }}</small> -->
        </div>
        <h4 v-if="subscription && plan.id === subscription.stripe_plan">
          <span class="badge badge-warning">{{
            $t("pricing.my-current-plan")
          }}</span>
        </h4>
        <div v-else>
          <a
            :href="registerUrl"
            class="btn btn-sm btn-outline-primary"
            v-if="isFreePlan && !me"
          >
            {{ $t("pricing.sign-up-free") }}
          </a>
          <a
            :href="registerUrl"
            class="btn btn-light border-primary text-primary"
            v-if="!isFreePlan && !me"
          >
            <b>{{ $t("pricing.try-for-free") }}</b>
          </a>
          <router-link to="/account" class="btn btn-light border-primary text-primary" v-if="!isFreePlan && me"
            ><b>{{ $t("pricing.try-for-free") }}</b></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "card-plan",

  props: ["plan", "subscription"],

  data() {
    return {
      registerUrl: "",
      planName: "",
    };
  },

  computed: {
    ...mapGetters({
      me: "user/me",
    }),
    isFreePlan() {
      return ((this.planName == 'Basic') || (this.planName == 'free'))
    },
    isCommonPlan() {
      return (this.planName != 'professional')
    }
  },

  mounted() {
    this.registerUrl = "/register";
    this.planName = this.plan.nickname.split('-')[0];
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/colors.scss";

.bg-highlight {
  background-image: linear-gradient(to bottom, $primary, $dpink);
}

.border-gray {
  border-color: $gray-200;
}

.free-plan {
  padding-top: 0 !important;
  border-left: 0; 
}
</style>