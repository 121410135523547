import Vue from "vue";
import Vuex from "vuex";

import common from "./modules/common";
import user from "./modules/user";
import project from "./modules/project";
import scene from "./modules/scene";
import bgm from "./modules/bgm";
import payment from "./modules/payment";
import subtitle from "./modules/subtitle";
import library from "./modules/library";
import globals from "./modules/globals";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    common,
    user,
    project,
    scene,
    bgm,
    payment,
    subtitle,
    library,
    globals,
  },
});

export default store;
