<template>
  <div class="login">
    <TopBar />
    <div class="container mt-3">
      <div class="row">
        <div class="col-12 d-block d-sm-block d-md-none">
          <h5>Secure Login</h5>
        </div>
        <div class="col-lg-8 col-md-6 d-none d-md-block">
          <h3 class="text-white mb-3 mt-3">
            <b>{{ $t('login.call') }}</b>
          </h3>
          <h5 class="mb-3">{{ $t('login.p1') }}</h5>
          <p class="mt-3">
            <a href="https://wisecut.video" target="_blank">
              <b>{{ $t('login.learn-more') }} >></b>
            </a>
          </p>
          <small class="text-default">{{ $t('login.social-media') }}</small>
          <h3>
            <a href="https://www.facebook.com/wisecut/" target="_blank" class="text-light"> <i class="bi-facebook"></i> </a>&nbsp;
            <a href="https://www.instagram.com/wisecut.video/" target="_blank" class="text-light"> <i class="bi-instagram"></i> </a>&nbsp;
            <a href="https://www.youtube.com/channel/UCrBMH0uMe9Nt6BMgwytI5Sw" target="_blank" class="text-light"> <i class="bi-youtube"></i></a>
          </h3>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="card bg-light">
            <div class="card-body">
              <div class="form-group">
                <label class="text-dark">
                  <small>
                    <b>{{ $t('login.email-address') }}</b>
                  </small>
                </label>
                <input
                  type="email"
                  class="form-control"
                  maxlength="255"
                  :placeholder="$t('login.email-address-placeholder')"
                  :class="{ 'is-invalid': $v.model.email.$error }"
                  v-model="model.email"
                  @keyup.enter="checkInfo"
                />
                <small class="form-text text-muted">{{ $t('login.email-address-tip') }}</small>
              </div>
              <div class="form-group">
                <label class="text-dark">
                  <small>
                    <b>{{ $t('login.password') }}</b>
                  </small>
                </label>
                <input
                  type="password"
                  class="form-control"
                  maxlength="255"
                  :class="{ 'is-invalid': $v.model.password.$error }"
                  v-model="model.password"
                  @keyup.enter="checkInfo"
                />
                <small class="form-text text-muted">{{ $t('login.password-tip') }}</small>
              </div>
              <div class="row">
                <div class="col-6">
                  <a class="text-default" :href="resetPasswordUrl" target="_blank"
                    ><small>{{ $t('login.forgot-password') }}</small></a
                  >
                </div>
                <div class="col-6">
                  <button type="button" class="btn btn-block btn-primary" @click.prevent="checkInfo" :disabled="loading">
                    <b v-if="!loading">{{ $t('login.sign-in') }}</b>
                    <div class="spinner-border" role="status" v-if="loading">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mt-3 p-3">
      <small>
        © {{ new Date().getFullYear() }}
        <b class="text-default">Wisecut</b>
      </small>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

import TopBar from './components/TopBar'

export default {
  name: 'login-view',

  components: {
    TopBar,
  },

  data() {
    return {
      height: 470,
      resetPasswordUrl: '',
      model: {
        email: '',
        password: '',
      },
      loading: false,
    }
  },

  mixins: [validationMixin],

  validations: {
    model: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },

  mounted() {
    this.resetPasswordUrl = process.env.VUE_APP_WEB_URL + '/password/reset'
    this.height = window.innerHeight
    window.addEventListener('resize', () => {
      this.height = window.innerHeight
    })
    if (navigator.language == 'pt-BR') {
      this.$i18n.locale = 'pt-BR'
    } else {
      this.$i18n.locale = 'en'
    }
  },

  methods: {
    ...mapActions({
      login: 'user/LOGIN',
    }),

    checkInfo() {
      this.$v.model.$touch()
      if (this.$v.model.$error) return
      this.tryLogin()
    },

    async tryLogin() {
      this.loading = true
      const result = await this.login(this.model)
      if (result.error) {
        this.$bvToast.toast(this.$t('login.error'), {
          variant: 'danger',
          title: this.$t('login.title'),
          toaster: 'b-toaster-top-right',
          solid: true,
        })
      } else {
        this.$router.push(this.$route.query.redirect || '/')
      }
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';

.login {
  padding-top: 30px;
  height: calc(100dvh - 30px);
  background: url('/img/pattern-1.svg') no-repeat fixed;
  background-position: left bottom;
  background-size: cover;
}

.container {
  margin-top: 4rem !important;
}

.form-control {
  color: $black;
  background-color: $light;
}
</style>
