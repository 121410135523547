const getters = {
  isDrawerOpen: (state) => {
    return state.isDrawerOpen;
  },
  isPromotionBanner: (state) => {
    const promotionEndDate = new Date('2024-01-01T13:00:00');
    const today = new Date();

    return state.isPromotionBanner && today < promotionEndDate;
  },
};

const state = {
  isDrawerOpen: false,
  isPromotionBanner: true,
};

const mutations = {
  toggleDrawer(state) {
    state.isDrawerOpen = !state.isDrawerOpen;
  },
  toggleBFBanner(state) {
    state.isPromotionBanner = !state.isPromotionBanner;
  },
};

export default {
  state,
  getters,
  mutations,
};
