<template>
  <section>
    <div class="col-12 text-center" v-if="loading == index">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="wrapper-storyboard-scenes">
      <div v-if="loading != index">
        <h3
          v-if="enableSelect && !menu"
          class="text-right text-primary"
          :class="{
            'text-success': selectedScenes.findIndex((x) => x === scene.id) >= 0,
          }"
        >
          <i
            :class="{
              'bi-square': selectedScenes.findIndex((x) => x === scene.id) < 0,
              'bi-check-square-fill': selectedScenes.findIndex((x) => x === scene.id) >= 0,
            }"
            style="cursor: pointer"
            @click.stop.prevent="selectScene(scene.id, parseFloat(scene.trim_end) - parseFloat(scene.trim_start))"
          ></i>
        </h3>
        <div class="wrapper-thumbnail" :class="{ 'wrapper-thumbnail__removed': scene.visibility == 0 }">
          <img :src="scene.thumbnail || project.thumbnailPath" class="img-thumbnail" loading="lazy" />

          <span class="badge badge-dark wrapper-thumbnail__badge px-0">
            <SvgIcon name="clock" stroke="#9999B5" />
            {{ timestampToString(scene.end - scene.start) }}
          </span>

          <div class="scene-thumbnail-overlay text-center">
            <span v-if="scene.visibility === 0">{{ $t('project-edit.removed') }}</span>
            <span v-if="scene.visibility === 1 && !scene.thumbnail">{{ $t('project-edit.not-updated') }}</span>
          </div>
        </div>
      </div>
      <div class="wrapper-storyboard-scenes__controls" v-if="loading != index">
        <div class="d-flex">
          <div class="wrapper-storyboard-scenes__controls--badges-list" :class="{ 'text-center': !menu }">
            <span class="badge badge-black" :class="{ 'text-opacity-30': scene.visibility == 0 }">
              {{ $t('project-edit.scene') }} {{ index + 1 }}
            </span>
            <span class="badge badge-secondary ml-3" v-if="scene.type == 'intro'">
              {{ $t('project-edit.intro-scene') }}
            </span>
            <span class="badge badge-secondary ml-3" v-if="scene.type == 'outro'">
              {{ $t('project-edit.outro-scene') }}
            </span>
          </div>

          <div class="col text-right pr-0">
            <span v-if="enableDrag" :class="{ 'drag-handle': enableDrag }">
              <SvgIcon name="grip-vertical" stroke="#9999B5" width="22px" />
            </span>
            <h3
              v-if="enableSelect && menu"
              class="text-right text-primary"
              :class="{
                'text-success': selectedScenes.findIndex((x) => x === scene.id) >= 0,
              }"
            >
              <i
                :class="{
                  'bi-square': selectedScenes.findIndex((x) => x === scene.id) < 0,
                  'bi-check-square-fill': selectedScenes.findIndex((x) => x === scene.id) >= 0,
                }"
                style="cursor: pointer"
                @click.stop.prevent="selectScene(scene.id, parseFloat(scene.trim_end) - parseFloat(scene.trim_start))"
              ></i>
            </h3>

            <div
              class="btn-group btn-group-controls ml-auto"
              v-if="editing != index && scene.visibility == 1 && !enableDrag && !enableSelect && !showPlayer"
            >
              <button
                type="button"
                class="btn btn-link"
                :class="{ 'btn-sm': menu }"
                :title="`${$t('project-edit.tools-play')}`"
                @click.stop.prevent="playScene(scene, $event)"
              >
                <SvgIcon name="play" width="18px" />
              </button>

              <button
                type="button"
                class="btn btn-secondary hidden-xs visible-sm"
                :class="{ 'btn-sm': menu }"
                @click.stop.prevent="editing = index"
                v-if="hasText"
                :title="`${$t('project-edit.tools-edit')}`"
              >
                <SvgIcon name="pencil" width="20px" />
              </button>

              <button
                type="button"
                class="btn btn-secondary hidden-xs visible-sm"
                :class="{ 'btn-sm': menu }"
                @click.stop.prevent="tryHide(scene, index)"
                :title="`${$t('project-edit.tools-trash')}`"
              >
                <SvgIcon name="trash" width="20px" />
              </button>

              <b-dropdown right variant="secondary" no-caret>
                <template #button-content>
                  <SvgIcon name="dots-vertical" width="22px" />
                </template>

                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-group id="dropdown-group-1" :header="`${$t('project-edit.tools-type')}`">
                  <b-dropdown-item-button @click="tryChangeType(scene, '/intro', index)" :class="{ active: scene.type == 'intro' }">
                    <i class="bi-check" v-if="scene.type == 'intro'"></i> {{ $t('project-edit.intro-scene') }}
                  </b-dropdown-item-button>
                  <b-dropdown-item-button @click="tryChangeType(scene, '', index)" :class="{ active: !scene.type }">
                    <i class="bi-check" v-if="!scene.type"></i> {{ $t('project-edit.default-scene') }}
                  </b-dropdown-item-button>
                  <b-dropdown-item-button @click="tryChangeType(scene, '/outro', index)" :class="{ active: scene.type == 'outro' }">
                    <i class="bi-check" v-if="scene.type == 'outro'"></i> {{ $t('project-edit.outro-scene') }}
                  </b-dropdown-item-button>
                </b-dropdown-group>
                <b-dropdown-divider></b-dropdown-divider>

                <b-dropdown-item @click.stop.prevent="tryHide(scene, index)" class="visible-xs">
                  <SvgIcon name="trash" width="16px" />{{ $t('project-edit.tools-trash') }}
                </b-dropdown-item>

                <b-dropdown-item @click.stop.prevent="editing = index" class="visible-xs">
                  <SvgIcon name="pencil" width="16px" />{{ $t('project-edit.tools-edit') }}
                </b-dropdown-item>

                <b-dropdown-item @click.stop.prevent="playFullVideo(scene.order)">
                  <SvgIcon name="playlist" width="16px" />{{ $t('project-edit.tools-play-collection') }}
                </b-dropdown-item>

                <b-dropdown-item @click.stop.prevent="sliding = index" v-if="sliding != index">
                  <SvgIcon name="compare-arrows" width="18px" /> {{ $t('project-edit.tools-trim') }}
                </b-dropdown-item>

                <b-dropdown-item @click.stop.prevent="sliding = null" v-if="sliding == index" :class="{ active: sliding == index }">
                  <SvgIcon name="compare-arrows" width="18px" /> {{ $t('project-edit.tools-trim') }}
                </b-dropdown-item>

                <b-dropdown-item
                  @click.stop.prevent="
                    splitting = index
                    previewTrims
                  "
                  v-if="!hasText && splitting != index"
                >
                  <SvgIcon name="background" width="22px" /> {{ $t('project-edit.tools-split') }}
                </b-dropdown-item>

                <b-dropdown-item
                  @click.stop.prevent="
                    splitting = null
                    previewTrims
                  "
                  v-if="!hasText && splitting == index"
                  :class="{ active: splitting == index }"
                >
                  <SvgIcon name="background" width="22px" /> {{ $t('project-edit.tools-split') }}
                </b-dropdown-item>

                <b-dropdown-item
                  @click.stop.prevent="voluming = index"
                  v-if="voluming != index && !project.mute_videos"
                  :class="{ active: voluming == index }"
                >
                  <SvgIcon name="sound" width="22px" />{{ $t('project-edit.tools-volume') }}
                </b-dropdown-item>

                <b-dropdown-item
                  @click.stop.prevent="voluming = null"
                  v-if="voluming == index && !project.mute_videos"
                  :class="{ active: voluming == index }"
                >
                  <SvgIcon name="sound" width="22px" /> {{ $t('project-edit.tools-volume') }}
                </b-dropdown-item>

                <b-dropdown-item @click.stop.prevent="tryDuplicate(scene, index)" v-if="voluming == index && !project.mute_videos">
                  <SvgIcon name="folder" width="22px" />{{ $t('project-edit.duplicate-scene') }}
                </b-dropdown-item>

                <b-dropdown-item @click.stop.prevent="facing = facing === index ? null : index" :class="{ active: facing == index }">
                  <SvgIcon name="smile" width="22px" />{{ $t('project-edit.tools-select-faces') }}
                </b-dropdown-item>

                <b-dropdown-item @click.stop.prevent="addOverlay(index + 1)" v-if="!scene.overscene">
                  <SvgIcon name="library-add" width="20px" />{{ $t('project-edit.overscene-add') }}
                </b-dropdown-item>

                <b-dropdown-item @click.stop.prevent="scissorsScene === index ? disableScissors() : enableScissors(index)" v-if="!scene.overscene" :class="{ active: scissorsScene == index }">
                  <SvgIcon name="cut" width="20px" />{{ $t('project-edit.tools-scissors') }}
                </b-dropdown-item>
              </b-dropdown>
            </div>

            <div class="btn-group" v-if="editing == index">
              <button
                type="button"
                class="btn btn-secondary"
                :class="{ 'btn-sm': menu }"
                :title="`${$t('project-edit.tools-play')}`"
                @click.stop.prevent="playScene(scene, $event)"
              >
                <span class="material-icons-round md-18">play_circle</span>
              </button>
              <button type="button" class="btn btn-secondary" :class="{ 'btn-sm': menu }" @click.stop.prevent="saveScene(scene, index)">
                <i class="bi-check-circle"></i>
              </button>
              <button type="button" class="btn btn-secondary" :class="{ 'btn-sm': menu }" @click.stop.prevent="cancelSaveScene(scene)">
                <i class="bi-x-circle"></i>
              </button>
            </div>

            <div class="btn-group btn-restore" v-if="scene.visibility == 0 && !enableDrag && !enableSelect">
              <button type="button" class="btn-group-controls" @click.stop.prevent="tryShow(scene, index)" :title="`${$t('project-edit.tools-restore')}`">
                <SvgIcon name="arrow-restore" />
              </button>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <div v-if="sliding == index">
            <div class="form-group">
              <label for="sceneTrimStart">
                <small>{{ $t('project-edit.starts-at') }}: {{ timestampToString(scene.trim_start) }}</small>
              </label>
              <br />
              <label for="sceneTrimEnd">
                <small>{{ $t('project-edit.ends-at') }}: {{ timestampToString(scene.trim_end) }}</small>
              </label>
            </div>
            <div class="form-group text-center">
              <Trimmer :scene="scene" v-on:current-position="onCurrentPosition" v-on:preview="previewTrims" />
            </div>
            <div class="text-center">
              <div class="btn-group">
                <button type="button" class="btn btn-sm btn-secondary" @click.stop.prevent="previewTrims">
                  <i class="bi-play-circle"></i> {{ $t('project-edit.preview') }}
                </button>
                <button type="button" class="btn btn-sm btn-secondary" v-if="!isDefaultTrims" @click.stop.prevent="defaultTrims">
                  <SvgIcon name="arrow-restore" stroke="#ffffff" /> {{ $t('project-edit.restore') }}
                </button>
                <button type="button" class="btn btn-sm btn-primary" v-if="changed" @click.stop.prevent="saveTrims(index)">
                  <i class="bi-check-circle"></i> {{ $t('project-edit.save') }}
                </button>
              </div>
            </div>
          </div>
          <div v-if="!hasText && splitting == index">
            <div class="form-group">
              <label for="sceneSplit"
                ><small
                  >{{ $t('project-edit.split-at') }}:
                  {{ timestampToString((splitTime / 100) * (parseFloat(scene.end) - parseFloat(scene.start))) }}</small
                ></label
              >
              <Splitter
                :scene="scene"
                v-model="splitTime"
                :changeSplitTime="changeSplitTime"
                v-on:current-position="onCurrentPosition"
                v-on:preview="previewTrims"
              />
            </div>
            <div class="text-center">
              <div class="btn-group">
                <button type="button" class="btn btn-sm btn-secondary" @click.stop.prevent="previewSplit">
                  <i class="bi-play-circle"></i> {{ $t('project-edit.preview') }}
                </button>
                <button type="button" class="btn btn-sm btn-primary" @click.stop.prevent="trySplitByPosition(scene, index)">
                  {{ $t('project-edit.split') }}
                </button>
              </div>
            </div>
          </div>

          <div v-if="voluming == index && !project.mute_videos">
            <div class="card mb-2 p-0 pb-3">
              <div class="card-body p-0">
                <label
                  ><b class="text-primary">{{ $t('project-edit.music-volume') }}</b></label
                >
                <div class="row justify-content-center">
                  <div class="col-2">
                    <button
                      type="button"
                      class="btn btn-link p-0 m-1 inline-block"
                      :class="{ 'text-success': scene.musicType == 'bgmmute' }"
                      @click.stop.prevent="changeMusicType('bgmmute')"
                    >
                      <i
                        :class="{
                          'bi-circle': scene.musicType != 'bgmmute',
                          'bi-check-circle-fill': scene.musicType == 'bgmmute',
                        }"
                      ></i
                      ><br />
                      {{ $t('project-edit.mute') }}
                    </button>
                  </div>
                  <div class="col-2">
                    <button
                      type="button"
                      class="btn btn-link p-0 m-1 inline-block"
                      :class="{ 'text-success': scene.musicType == 'bgmlow' }"
                      @click.stop.prevent="changeMusicType('bgmlow')"
                    >
                      <i
                        :class="{
                          'bi-circle': scene.musicType != 'bgmlow',
                          'bi-check-circle-fill': scene.musicType == 'bgmlow',
                        }"
                      ></i
                      ><br />
                      {{ $t('project-edit.low') }}
                    </button>
                  </div>
                  <div class="col-2">
                    <button
                      type="button"
                      class="btn btn-link p-0 m-1 inline-block"
                      :class="{
                        'text-success': scene.musicType == 'bgmstandard',
                      }"
                      @click.stop.prevent="changeMusicType('bgmstandard')"
                    >
                      <i
                        :class="{
                          'bi-circle': scene.musicType != 'bgmstandard',
                          'bi-check-circle-fill': scene.musicType == 'bgmstandard',
                        }"
                      ></i
                      ><br />
                      {{ $t('project-edit.medium') }}
                    </button>
                  </div>
                  <div class="col-2">
                    <button
                      type="button"
                      class="btn btn-link p-0 m-1 inline-block"
                      :class="{ 'text-success': scene.musicType == 'bgmloud' }"
                      @click.stop.prevent="changeMusicType('bgmloud')"
                    >
                      <i
                        :class="{
                          'bi-circle': scene.musicType != 'bgmloud',
                          'bi-check-circle-fill': scene.musicType == 'bgmloud',
                        }"
                      ></i
                      ><br />
                      {{ $t('project-edit.high') }}
                    </button>
                  </div>
                  <div class="col-2">
                    <button
                      type="button"
                      class="btn btn-link p-0 m-1 inline-block"
                      :class="{ 'text-success': scene.musicType == 'mainmusic' }"
                      @click.stop.prevent="changeMusicType('mainmusic')"
                    >
                      <i
                        :class="{
                          'bi-circle': scene.musicType != 'mainmusic',
                          'bi-check-circle-fill': scene.musicType == 'mainmusic',
                        }"
                      ></i
                      ><br />
                      {{ $t('project-edit.full') }}
                    </button>
                  </div>
                </div>
                <hr class="border-primary" />
                <label
                  ><b class="text-primary">{{ $t('project-edit.media-volume') }}</b></label
                >
                <div class="row justify-content-center">
                  <div class="col-2">
                    <button
                      type="button"
                      class="btn btn-link p-0 m-1 inline-block"
                      :class="{ 'text-success': scene.mediaAudio == 'mute' }"
                      @click.stop.prevent="changeMediaAudio('mute')"
                    >
                      <i
                        :class="{
                          'bi-circle': scene.mediaAudio != 'mute',
                          'bi-check-circle-fill': scene.mediaAudio == 'mute',
                        }"
                      ></i
                      ><br />
                      {{ $t('project-edit.mute') }}
                    </button>
                  </div>
                  <div class="col-2">
                    <button
                      type="button"
                      class="btn btn-link p-0 m-1 inline-block"
                      :class="{ 'text-success': scene.mediaAudio == 'low' }"
                      @click.stop.prevent="changeMediaAudio('low')"
                    >
                      <i
                        :class="{
                          'bi-circle': scene.mediaAudio != 'low',
                          'bi-check-circle-fill': scene.mediaAudio == 'low',
                        }"
                      ></i
                      ><br />
                      {{ $t('project-edit.low') }}
                    </button>
                  </div>
                  <div class="col-2">
                    <button
                      type="button"
                      class="btn btn-link p-0 m-1 inline-block"
                      :class="{ 'text-success': scene.mediaAudio == 'standard' }"
                      @click.stop.prevent="changeMediaAudio('standard')"
                    >
                      <i
                        :class="{
                          'bi-circle': scene.mediaAudio != 'standard',
                          'bi-check-circle-fill': scene.mediaAudio == 'standard',
                        }"
                      ></i
                      ><br />
                      {{ $t('project-edit.medium') }}
                    </button>
                  </div>
                  <div class="col-2">
                    <button
                      type="button"
                      class="btn btn-link p-0 m-1 inline-block"
                      :class="{ 'text-success': scene.mediaAudio == 'loud' }"
                      @click.stop.prevent="changeMediaAudio('loud')"
                    >
                      <i
                        :class="{
                          'bi-circle': scene.mediaAudio != 'loud',
                          'bi-check-circle-fill': scene.mediaAudio == 'loud',
                        }"
                      ></i
                      ><br />
                      {{ $t('project-edit.high') }}
                    </button>
                  </div>
                  <div class="col-2">
                    <button
                      type="button"
                      class="btn btn-link p-0 m-1 inline-block"
                      :class="{ 'text-success': scene.mediaAudio == 'extraloud' }"
                      @click.stop.prevent="changeMediaAudio('extraloud')"
                    >
                      <i
                        :class="{
                          'bi-circle': scene.mediaAudio != 'extraloud',
                          'bi-check-circle-fill': scene.mediaAudio == 'extraloud',
                        }"
                      ></i
                      ><br />
                      {{ $t('project-edit.full') }}
                    </button>
                  </div>
                </div>
                <hr class="border-primary" />
                <button type="button" class="btn btn-block btn-sm btn-primary" @click.stop.prevent="saveScene(scene, index)">
                  {{ $t('project-edit.save') }}
                </button>
              </div>
            </div>
          </div>

          <div v-if="facing == index && scene.thumbnail && (project.autopunchin || project.autocrop)">
            <div class="card mb-2 p-0 pb-3">
              <div class="card-body p-0">
                <div v-if="scene.selected_faces && scene.selected_faces.length > 0">
                  <label
                    ><b class="text-primary">{{ $t('project-edit.selected-faces') }}</b></label
                  >
                  <div class="scene-preview mb-4">
                    <div v-for="(face, idf) in scene.selected_faces" :key="idf + 1">
                      <div
                        @click="toggleFaceVisibility(idf)"
                        class="scene-face d-flex align-items-center justify-content-center"
                        :style="
                          'height:' +
                          face.BoundingBox.Height * 100 +
                          '%;' +
                          'left:' +
                          face.BoundingBox.Left * 100 +
                          '%;' +
                          'top:' +
                          face.BoundingBox.Top * 100 +
                          '%;' +
                          'width:' +
                          face.BoundingBox.Width * 100 +
                          '%;'
                        "
                        :class="{
                          'scene-face-selected': face.visibility,
                          'scene-face-unselected': !face.visibility,
                        }"
                      >
                        <span class="material-icons-round icon-size btn-add-face">add</span>
                        <span class="material-icons-round icon-size btn-remove-face">remove</span>
                      </div>
                    </div>
                    <img class="img-thumbnail thumb-preview" :src="scene.thumbnail" loading="lazy" />
                  </div>
                </div>
                <div v-if="project.autopunchin" class="mb-4">
                  <label
                    ><b class="text-primary">{{ $t('project-edit.auto-punch-in') }}</b></label
                  >
                  <div class="row justify-content-center">
                    <div class="col-4 text-center default-punch-in">
                      <button
                        type="button"
                        class="btn btn-link p-0 m-1 inline-block"
                        :class="{ 'text-success': scene.punch_in == 1 }"
                        @click.stop.prevent="scene.punch_in = 1"
                      >
                        <i
                          :class="{
                            'bi-circle': scene.punch_in != 1,
                            'bi-check-circle-fill': scene.punch_in == 1,
                          }"
                        ></i
                        ><br />
                        {{ $t('project-edit.auto') }}:
                        <br />
                        <span v-if="scene.punch_in_status">{{ $t('project-edit.punch-in') }}</span>
                        <span v-else>{{ $t('project-edit.punch-out') }}</span>
                      </button>
                    </div>
                    <div class="col-4 text-center">
                      <button
                        type="button"
                        class="btn btn-link p-0 m-1 inline-block"
                        :class="{ 'text-success': scene.punch_in == 2 }"
                        @click.stop.prevent="scene.punch_in = 2"
                      >
                        <i
                          :class="{
                            'bi-circle': scene.punch_in != 2,
                            'bi-check-circle-fill': scene.punch_in == 2,
                          }"
                        ></i
                        ><br />
                        {{ $t('project-edit.force') }}: {{ $t('project-edit.punch-in') }}
                      </button>
                    </div>
                    <div class="col-4 text-center">
                      <button
                        type="button"
                        class="btn btn-link p-0 m-1 inline-block"
                        :class="{ 'text-success': scene.punch_in == 0 }"
                        @click.stop.prevent="scene.punch_in = 0"
                      >
                        <i
                          :class="{
                            'bi-circle': scene.punch_in != 0,
                            'bi-check-circle-fill': scene.punch_in == 0,
                          }"
                        ></i
                        ><br />
                        {{ $t('project-edit.force') }}: {{ $t('project-edit.punch-out') }}
                      </button>
                    </div>
                  </div>
                </div>
                <div v-if="project.autocrop" class="mb-4">
                  <label
                    ><b class="text-primary">{{ $t('project-edit.auto-crop') }}</b></label
                  >
                  <div>
                    <div>
                      <div class="custom-control custom-switch mt-3">
                        <input type="checkbox" class="custom-control-input" :id="'sceneAutocrop-' + scene.id" v-model="scene.crop" />
                        <label class="custom-control-label" :for="'sceneAutocrop-' + scene.id"
                          ><small> {{ $t('project-edit.auto-crop-scene') }}</small></label
                        >
                        <i class="bi-info-circle text-info ml-2" :id="'info-scene-autocrop-' + scene.id"></i>
                        <b-popover
                          :target="'info-scene-autocrop-' + scene.id"
                          placement="right"
                          :title="$t('project-edit.info-autocrop-scene')"
                          triggers="hover"
                        ></b-popover>
                      </div>
                    </div>
                  </div>
                </div>

                <hr class="border-primary" />
                <button type="button" class="btn btn-block btn-sm btn-primary" @click.stop.prevent="saveScene(scene, index)">
                  {{ $t('project-edit.save') }}
                </button>
              </div>
            </div>
          </div>
          <div v-for="(line, idx) in scene.text" :key="idx + 1" class="scene-texts">
            <p
              class="mb-1"
              :class="{
                'text-default':
                  line.start - scene.start + parseFloat(scene.trim_start_default) + parseFloat(scene.media_file.raw_preview_offset) < currentTime &&
                  line.end - scene.start + parseFloat(scene.trim_start_default) + parseFloat(scene.media_file.raw_preview_offset) > currentTime,
                'border-scissors': scissorsScene == index && scissorsLine == idx,
                'removed-line-start':
                  line.end < parseFloat(scene.trim_start - parseFloat(scene.trim_start_default) + parseFloat(scene.text[0].start)),
                'removed-line-end': line.start > parseFloat(scene.text[scene.text.length - 1].end) - (scene.trim_end_default - scene.trim_end),
                'd-flex': hasText && idx + 1 < scene.text.length && scissorsScene == index,
                removed: scene.visibility == 0,
              }"
              v-if="editing != index"
            >
              <button
                type="button"
                class="btn btn-link m-0 p-0 mr-2"
                @click.stop.prevent="trySplitByLine(scene, idx, index)"
                v-if="hasText && idx + 1 < scene.text.length && scissorsScene == index"
                @mouseover="overScissors(idx, index)"
                @mouseout="outScissors(idx, index)"
              >
                <i class="bi-scissors"></i>
              </button>
              <span style="cursor: pointer" @click.stop.prevent="playAtLine(line, !idx)">{{ line.text }}</span>
            </p>
            <input type="text" class="form-control form-control-sm mb-2" maxlength="35" v-model="line.text" v-if="editing == index" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Trimmer from './Trimmer.vue'
import Splitter from './Splitter.vue'
import SvgIcon from '@/components/icon/SvgIcon.vue'

export default {
  name: 'scene',

  props: [
    'scene',
    'index',
    'playScene',
    'playFullVideo',
    'project',
    'currentTime',
    'reloadPlayer',
    'enableDrag',
    'enableSelect',
    'scissorsScene',
    'enableScissors',
    'disableScissors',
    'showPlayer',
    'checkNotRendered',
    'selectScene',
    'selectedScenes',
  ],

  components: {
    Trimmer,
    Splitter,
    SvgIcon,
  },

  data() {
    return {
      selected: null,
      editing: null,
      sliding: null,
      splitting: null,
      voluming: null,
      facing: null,
      loading: null,
      scissorsLine: null,
      trimStart: '0',
      trimEnd: '100',
      splitTime: 0,
      hasText: false,
      changed: false,
      isDefaultTrims: true,
    }
  },

  computed: {
    ...mapGetters({
      menu: 'common/menu',
    }),
  },

  mounted() {
    this.hasText = this.scene.text && this.scene.text.length ? true : false
    this.trimStart = parseInt((this.scene.trim_start * 100) / this.scene.trim_end_default)
    this.trimEnd = parseInt((this.scene.trim_end * 100) / this.scene.trim_end_default)
  },

  methods: {
    ...mapActions({
      updateById: 'scene/UPDATE_BY_ID',
      splitByLine: 'scene/SPLIT_BY_LINE',
      splitByPosition: 'scene/SPLIT_BY_POSITION',
      changeType: 'scene/CHANGE_TYPE',
      show: 'scene/SHOW',
      hide: 'scene/HIDE',
      trim: 'scene/TRIM',
      duplicate: 'scene/DUPLICATE',
    }),

    handleDropdownClick(index) {
      this.facing = this.facing === index ? null : index
    },

    playAtLine(line, firstLine) {
      let position
      let trimDiff = parseFloat(this.scene.trim_start) - parseFloat(this.scene.trim_start_default)
      if (firstLine) position = parseFloat(this.scene.trim_start_default) + parseFloat(this.scene.media_file.raw_preview_offset)
      else if (trimDiff > line.start && trimDiff < line.end)
        position =
          line.start - trimDiff - this.scene.start + parseFloat(this.scene.trim_default) + parseFloat(this.scene.media_file.raw_preview_offset)
      else position = line.start - this.scene.start + parseFloat(this.scene.trim_start_default) + parseFloat(this.scene.media_file.raw_preview_offset)
      this.playFullVideo(this.scene.order, position)
    },

    toggleFaceVisibility(index) {
      this.scene.selected_faces[index].visibility = !this.scene.selected_faces[index].visibility
    },

    onCurrentPosition(val) {
      this.$emit('current-position', parseFloat(val) + parseFloat(this.scene.media_file.raw_preview_offset))
    },

    overScissors(idx) {
      this.scissorsLine = idx
    },

    outScissors() {
      this.scissorsLine = null
    },

    changeSplitTime() {
      this.changeTrims()
    },

    changeTrims() {
      if (parseInt(this.trimStart) > parseInt(this.trimEnd)) {
        this.trimStart = this.trimEnd
      }
    },

    defaultTrims() {
      this.scene.trim_start = this.scene.trim_start_default
      this.scene.trim_end = this.scene.trim_end_default
    },

    changeMusicType(param) {
      this.scene.musicType = param
    },

    changeMediaAudio(param) {
      this.scene.mediaAudio = param
    },

    previewTrims() {
      const trim_start_raw = parseFloat(this.scene.trim_start) + parseFloat(this.scene.media_file.raw_preview_offset)
      const trim_end_raw = parseFloat(this.scene.trim_end) + parseFloat(this.scene.media_file.raw_preview_offset)
      this.playScene({ trim_start_raw, trim_end_raw })
    },

    previewSplit() {
      const trim_start_raw = parseFloat(
        (this.splitTime / 100) * (this.scene.trim_end_default - this.scene.trim_start_default) +
          parseFloat(this.scene.trim_start_default) +
          parseFloat(this.scene.media_file.raw_preview_offset)
      )
      const trim_end_raw = parseFloat(this.scene.trim_end) + parseFloat(this.scene.media_file.raw_preview_offset)
      this.playScene({ trim_start_raw, trim_end_raw })
    },

    timestampToString(seconds) {
      return new Date(seconds * 1000).toISOString().substr(11, 8)
    },

    async tryChangeType(scene, type, index) {
      this.loading = index
      const sceneId = await this.changeType({
        scene,
        type,
      })

      if (type == '/intro') {
        window.scrollTo(0, 0)
      }
      if (type == '/outro') {
        window.scrollTo(0, document.body.scrollHeight)
      }
      localStorage.setItem(`needsRender_${this.scene.project_id}`, 'true')
      this.checkNotRendered([scene])
      this.$emit('update-storyboard', [sceneId])
    },

    async saveTrims(index) {
      let scene = this.scene

      this.loading = index
      await this.trim(scene)
      this.changed = false
      this.editing = null
      this.sliding = null
      this.loading = null
      localStorage.setItem(`needsRender_${this.scene.project_id}`, 'true')
      this.checkNotRendered([scene])
      this.$bvToast.toast(this.$t('project-edit.settings-updated'), {
        variant: 'success',
        title: this.$t('project-edit.update'),
        toaster: 'b-toaster-top-center',
        solid: true,
        autoHideDelay: 2500,
      })
      this.reloadPlayer(scene)
      this.$emit('update-render-length')
    },

    async trySplitByLine(scene, idx, index) {
      this.loading = index
      const scenesIds = await this.splitByLine({
        scene,
        line: idx,
      })
      window.analytics.track('Project Scene Split', {
        sceneId: scene.id,
        projectId: scene.project_id,
        oldStart: scene.start,
        oldEnd: scene.end,
      })
      this.scissorsLine = null
      this.disableScissors()
      localStorage.setItem(`needsRender_${this.scene.project_id}`, 'true')
      this.checkNotRendered([scene])
      this.$emit('update-storyboard', scenesIds, true)
    },

    async trySplitByPosition(scene, index) {
      this.loading = index
      const position = (this.splitTime / 100) * (parseFloat(scene.end) - parseFloat(scene.start)) + parseFloat(scene.start)
      const scenesIds = await this.splitByPosition({
        scene,
        position,
      })
      this.splitting = null
      localStorage.setItem(`needsRender_${this.scene.project_id}`, 'true')
      this.checkNotRendered([scene])
      this.$bvToast.toast(this.$t('project-edit.settings-updated'), {
        variant: 'success',
        title: this.$t('project-edit.update'),
        toaster: 'b-toaster-top-center',
        solid: true,
        autoHideDelay: 2500,
      })
      this.$emit('update-storyboard', scenesIds, true)
    },

    async tryShow(scene, index) {
      this.scene.visibility = 1
      await this.show(scene)
      localStorage.setItem(`needsRender_${this.scene.project_id}`, 'true')
      this.checkNotRendered([scene])
      this.$bvToast.toast(this.$t('project-edit.settings-updated'), {
        variant: 'success',
        title: this.$t('project-edit.update'),
        toaster: 'b-toaster-top-center',
        solid: true,
        autoHideDelay: 2500,
      })
      this.$emit('update-render-length')
      this.loading = null
    },

    async tryHide(scene, index) {
      this.scene.visibility = 0
      await this.hide(scene)
      localStorage.setItem(`needsRender_${this.scene.project_id}`, 'true')
      this.checkNotRendered([scene])
      this.$bvToast.toast(this.$t('project-edit.settings-updated'), {
        variant: 'success',
        title: this.$t('project-edit.update'),
        toaster: 'b-toaster-top-center',
        solid: true,
        autoHideDelay: 2500,
      })
      this.$emit('update-render-length')
      this.loading = null
    },

    resetSceneEditing() {
      this.editing = null
      this.sliding = null
      this.splitting = null
      this.voluming = null
      this.facing = null
    },

    cancelSaveScene(scene) {
      this.resetSceneEditing()
      this.$emit('update-storyboard', [scene.id])
    },

    async saveScene(scene, index) {
      // this.loading = index;
      this.resetSceneEditing()
      const result = await this.updateById(scene)
      if (result) {
        this.scene = result
      }
      localStorage.setItem(`needsRender_${this.scene.project_id}`, 'true')
      this.checkNotRendered([scene])
      this.$bvToast.toast(this.$t('project-edit.settings-updated'), {
        variant: 'success',
        title: this.$t('project-edit.update'),
        toaster: 'b-toaster-top-center',
        solid: true,
        autoHideDelay: 2500,
      })
      this.loading = null
      this.reloadPlayer(scene)
    },

    async tryDuplicate(scene, index) {
      this.loading = index
      const scenesIds = await this.duplicate(scene)
      localStorage.setItem(`needsRender_${this.scene.project_id}`, 'true')
      this.checkNotRendered([scene])
      this.$bvToast.toast(this.$t('project-edit.settings-updated'), {
        variant: 'success',
        title: this.$t('project-edit.update'),
        toaster: 'b-toaster-top-center',
        solid: true,
        autoHideDelay: 2500,
      })
      this.$emit('reload-storyboard', scenesIds)
      this.loading = null
    },

    addOverlay(order) {
      this.$router.push(`/projects/${this.$route.params.id}/library/overlay/${order}/${this.scene.id}`)
    },
  },

  watch: {
    scene: {
      handler: function handler() {
        this.changed = true

        if (this.scene.trim_start == this.scene.trim_start_default && this.scene.trim_end == this.scene.trim_end_default) this.isDefaultTrims = true
        else this.isDefaultTrims = false

        this.loading = null
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';

.wrapper-storyboard-scenes {
  display: flex;
  gap: 25px;
  flex-direction: row;
  @media (max-width: 600px) {
    gap: 16px;
  }
  .drag-handle {
    cursor: move;
  }
  .scene-texts {
    @media screen and (max-width: 600px) {
      p {
        font-size: 12px;
      }
    }
  }

  &__controls {
    width: 100%;
    .removed {
      opacity: 0.2;
    }
    .card {
      background-color: transparent;
    }
    &--badges-list {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      @media (max-width: 600px) {
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: flex-start;
        align-items: flex-start;
        .ml-3 {
          margin-left: 0px !important;
        }
      }
    }
  }
}

.removed-line-start {
  text-decoration: line-through;
}

.removed-line-end {
  text-decoration: line-through;
}

.wrapper-thumbnail {
  position: relative;
  max-width: 195px;
  overflow: hidden;
  .img-thumbnail {
    border-radius: 10px;
    background: transparent;
    margin-bottom: 0px;
    width: 100%;
    min-height: 108px;
    min-width: 195px;
    object-fit: cover;
    @media (max-width: 600px) {
      width: 98px;
      min-width: 98px;
    }
  }
  &__removed {
    .img-thumbnail {
      opacity: 0.2;
    }
    .scene-thumbnail-overlay {
      position: absolute;
      width: 100%;
      height: calc(100% - 36px);
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      @media (max-width: 600px) {
        font-size: 12px;
      }
    }
  }
  &__badge {
    color: $gray-450;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
    background: transparent;
    font-weight: 400;
  }
}

.play-button {
  background-color: rgba(28, 23, 77, 0.7);
  position: absolute;
  width: 68px;
  height: 68px;
  top: calc(50% - 34px);
  left: calc(50% - 34px);
}

.border-scissors {
  border-bottom: 2px dashed $primary;
}
.scene-preview {
  background-color: black;
  position: relative;
}

.thumb-preview {
  border: 0;
  border-radius: 0;
  padding: 0;
  background: 0;
  max-width: 100%;
  max-height: 100%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
}

.scene-face {
  border: 3px solid white;
  border-radius: 25px;
  position: absolute;
  opacity: 1;

  span {
    display: none;
  }

  &:hover {
    opacity: 0.75;
    cursor: pointer;
  }
}
.scene-face-selected {
  border-color: green;

  &:hover {
    background-color: red;
    .btn-remove-face {
      display: initial;
    }
  }
}

.scene-face-unselected {
  border-color: red;

  &:hover {
    background-color: green;
    .btn-add-face {
      display: initial;
    }
  }
}
.default-punch-in {
  border-right: 2px dotted;
}
</style>
