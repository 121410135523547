<template>
  <section>
    <div class="row pt-1" style="margin-bottom: 150px">
      <div class="col-md-4">
        <div class="full-video-player">
          <div class="video-player-overlay" v-if="showOverlay">
            <img :src="selected.project.thumbnailPath" class="img-thumbnail-overlay" />
            <button
              type="button"
              class="video-player-overlay__play"
              @click.prevent="playFullVideo(0)"
              v-if="selected.project.raw_preview_render == 1"
            >
              <SvgIcon name="play" stroke="#ffffff" />
            </button>
            <div class="needs_render p-3" v-else>
              <p>
                <b>{{ $t('project-edit.needs-raw-render') }}</b>
              </p>
              <button type="button" class="btn btn-sm btn-primary" @click.prevent="tryRenderRaw" :disabled="renderRawLoading">
                <div
                  class="spinner-border spinner-border-sm text-light"
                  role="status"
                  v-if="renderRawLoading || selected.project.working_progress_raw < 100"
                >
                  <span class="sr-only">Loading...</span>
                </div>
                <span v-if="!renderRawLoading && selected.project.working_progress_raw == 100">{{ $t('project-edit.render-now') }}</span>
                <span v-if="selected.project.working_progress_raw < 100">{{ $t('project-edit.rendering') }}</span>
              </button>
            </div>
          </div>
          <VideoPlayer
            :thumbnail="selected.project.thumbnailPath"
            :video="selected.project.export_raw_url"
            :start="start"
            :subtitles="selected.project.subtitles.vtt"
            :end="end"
            v-if="showPlayer"
          />
        </div>

        <div class="project-info">
          <p class="length-info mt-3">
            <SvgIcon name="clock" stroke="#9999B5" />
            {{ timestampToString(exportLength) }}
          </p>
          <small class="preview-info">
            {{ $t('project-edit.this-is-reference') }}
            <router-link :to="`/projects/${$route.params.id}/share`">
              {{ $t('project-edit.share') }}
            </router-link>
            {{ $t('project-edit.for-rendered-video') }}
          </small>
        </div>

        <div class="selected-scenes-toolbar py-4 text-center" v-if="enableSelect">
          <p>{{ selectedScenes.length }} {{ $t('project-edit.scenes-selected') }} ({{ timestampToString(selectedScenesLength) }})</p>
          <button type="button" class="btn btn-primary" @click.prevent="$bvModal.show('modal-create-clips-project')">
            {{ $t('project-edit.create-clips-project') }}
          </button>
        </div>
      </div>
      <div class="col-md-8">
        <ProjectTools
          :enableDrag.sync="enableDrag"
          :enableSelect.sync="enableSelect"
          :enableHideRemovedScenes.sync="enableHideRemovedScenes"
          @showModalInfo="handleShowModalInfo"
        />

        <div class="project-edit__tools-add">
          <a href="#" class="btn btn-plus" @click.prevent="addMoreFiles(1)" :title="`${$t('project-edit.tools-add')}`">
            <SvgIcon name="plus" stroke="#ffffff" />
          </a>
        </div>

        <draggable
          :list="selected.timeline.scenes"
          :disabled="!enableDrag"
          class="card list-group"
          @end="dragEnd"
          :scroll-sensitivity="400"
          v-bind="dragOptions"
        >
          <div v-for="(scene, index) in selected.timeline.scenes" :key="scene.id">
            <li
              v-if="scene.visibility || !enableHideRemovedScenes"
              class="list-group-item"
              :class="{
                'active-scene': scene.trim_start_raw < currentTime && scene.trim_end_raw > currentTime && scene.visibility == 1,
              }"
            >
              <Overscene
                v-if="scene.overscene"
                :scene="scene.overscene"
                :backgroundScene="scene"
                :index="index + '_overscene'"
                :backgroundIndex="index"
                :playScene="playScene"
                :playFullVideo="playFullVideo"
                :project="selected.project"
                :currentTime="currentTime"
                :reloadPlayer="reloadPlayer"
                :enableDrag="enableDrag"
                :scissorsScene="scissorsScene"
                :enableScissors="enableScissors"
                :disableScissors="disableScissors"
                :checkNotRendered="checkNotRendered"
                :enableSelect="enableSelect"
                :selectScene="selectScene"
                :selectedScenes="selectedScenes"
                v-on:current-position="(val) => (video.currentTime = val)"
                v-on="$listeners"
              />

              <Scene
                :scene="scene"
                :index="index"
                :playScene="playScene"
                :playFullVideo="playFullVideo"
                :project="selected.project"
                :currentTime="currentTime"
                :reloadPlayer="reloadPlayer"
                :enableDrag="enableDrag"
                :scissorsScene="scissorsScene"
                :enableScissors="enableScissors"
                :disableScissors="disableScissors"
                :checkNotRendered="checkNotRendered"
                :enableSelect="enableSelect"
                :selectScene="selectScene"
                :selectedScenes="selectedScenes"
                v-on:current-position="(val) => (video.currentTime = val)"
                v-on="$listeners"
              />

              <div class="project-edit__controls">
                <a href="#" class="btn btn-plus" @click.prevent="addMoreFiles(scene.order + 1)" :title="`${$t('project-edit.tools-add')}`">
                  <SvgIcon name="plus" stroke="#ffffff" />
                </a>
                <a
                  v-if="
                    mergingScenes.findIndex((x) => x === scene.id) < 0 &&
                    typeof selected.timeline.scenes[index + 1] !== 'undefined' &&
                    (selected.timeline.scenes[index + 1].visibility || !enableHideRemovedScenes) &&
                    scene.trim_end_default == selected.timeline.scenes[index + 1].trim_start_default &&
                    scene.media_file_id == selected.timeline.scenes[index + 1].media_file_id
                  "
                  href="#"
                  class="btn btn-plus--no-bg px-0"
                  @click.prevent="mergeScenes(scene, selected.timeline.scenes[index + 1])"
                  :title="`${$t('project-edit.merge-next-scene')}`"
                >
                  <SvgIcon name="arrow-collapse" stroke="#ffffff" width="17px" />
                </a>
                <span v-if="scene.visibility === 1" class="badge badge-gray">
                  <SvgIcon name="home" stroke="#ffffff" width="17px" />
                  <!-- <p>{{ timestampToString(scene.start) }}</p> -->
                  <p>
                    <template v-if="index < selected.timeline.scenes.length - 1">
                      {{ timestampToString(selected.timeline.scenes[index + 1].start) }}
                    </template>
                    <template v-else>
                      {{ timestampToString(scene.start) }}
                    </template>
                  </p>
                </span>

                <div v-if="mergingScenes.findIndex((x) => x === scene.id) >= 0" class="spinner-border text-primary" role="status"></div>
              </div>
            </li>
          </div>
        </draggable>
      </div>
    </div>
    <ModalInfo />
    <ModalClipsProject
      :selected="selected"
      :selectedScenes="selectedScenes"
      :selectedScenesLengthTimestamp="timestampToString(selectedScenesLength)"
    />
  </section>
</template>

<script>
import { mapActions } from 'vuex'

import draggable from 'vuedraggable'

import SvgIcon from '@/components/icon/SvgIcon.vue'
import VideoPlayer from '@/components/VideoPlayer'
import ModalInfo from '../components/ModalInfo'
import ModalClipsProject from '../components/ModalClipsProject'
import Scene from '../components/Scene'
import Overscene from '../components/Overscene.vue'
import ProjectTools from '../components/ProjectTools.vue'

export default {
  name: 'storyboard-full',

  props: ['selected', 'needsRender', 'tryRender', 'checkNotRendered', 'tryRenderRaw', 'renderRawLoading', 'exportLength'],

  components: {
    Scene,
    Overscene,
    VideoPlayer,
    draggable,
    ModalInfo,
    ModalClipsProject,
    SvgIcon,
    ProjectTools,
  },

  data() {
    return {
      dragEnabled: true,
      start: 0,
      end: null,
      autoplay: false,
      currentTime: 0,
      playing: 0,
      showOverlay: true,
      showPlayer: true,
      enableDrag: false,
      enableSelect: false,
      enableHideRemovedScenes: false,
      scissorsScene: null,
      video: null,
      scenes: null,
      selectedScenes: [],
      selectedScenesLength: 0,
      mergingScenes: [],
    }
  },

  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        ghostClass: 'ghost',
        scroll: true,
        forceFallback: true,
      }
    },
  },

  mounted() {
    this.scenes = this.selected.timeline.scenes.filter((x) => x.visibility == 1)
    this.defineStartEnd()
    this.video = document.getElementsByClassName('vjs-tech')[0]
    if (this.video) {
      this.video.ontimeupdate = () => {
        this.currentTime = this.video.currentTime
      }
    }
  },

  methods: {
    ...mapActions({
      changeOrder: 'scene/CHANGE_ORDER',
      mergeNextScene: 'scene/MERGE_NEXT_SCENE',
      render: 'project/RENDER',
    }),

    handleShowModalInfo() {
      this.$bvModal.show('modal-info')
    },

    async mergeScenes(scene, nextScene) {
      this.mergingScenes.push(scene.id)
      await this.mergeNextScene(scene)
      const index = this.mergingScenes.findIndex((x) => x === scene.id)
      this.mergingScenes.splice(index)
      this.$emit('update-storyboard', [scene.id, nextScene.id], true, -1)
    },

    defineStartEnd() {
      this.start = this.scenes[0].trim_start_raw
      this.end = this.scenes[this.scenes.length - 1].trim_end_raw
    },

    playScene(scene) {
      this.showOverlay = false
      this.playing = -1
      this.video.currentTime = scene.trim_start_raw
      this.start = scene.trim_start_raw
      this.end = scene.trim_end_raw
      this.video.play()
    },

    playFullVideo(order, line_start) {
      this.showOverlay = false
      this.playing = 0
      this.scenes = this.selected.timeline.scenes.filter((x) => x.visibility == 1 && x.order >= order)
      this.defineStartEnd()
      this.video.currentTime = line_start ? line_start : this.start
      this.video.play()
    },

    reloadPlayer(scene) {
      this.showPlayer = false
      setTimeout(() => {
        this.showPlayer = true
      }, 200)
      setTimeout(() => {
        this.video = document.getElementsByClassName('vjs-tech')[0]
        if (this.video) {
          this.video.ontimeupdate = () => {
            this.currentTime = this.video.currentTime
          }
        }
      }, 400)
    },

    dragEnd(e) {
      const scene = this.selected.timeline.scenes[e.newIndex]
      const order = e.newIndex + 1
      this.changeOrder({ scene, order })
    },

    enableScissors(index) {
      this.scissorsScene = index
    },

    disableScissors() {
      this.scissorsScene = -1
    },

    selectScene(id, length) {
      const index = this.selectedScenes.findIndex((x) => x === id)
      if (index >= 0) {
        this.selectedScenes.splice(index, 1)
        this.selectedScenesLength -= length
      } else {
        this.selectedScenes.push(id)
        this.selectedScenesLength += length
      }
    },

    timestampToString(seconds) {
      return new Date(seconds * 1000).toISOString().substr(11, 8)
    },

    addMoreFiles(order) {
      this.$router.push(`/projects/${this.$route.params.id}/library/${order}`)
    },
  },

  watch: {
    currentTime(val) {
      if (this.playing > -1) {
        if (this.playing < this.scenes.length - 1) {
          if (val >= parseFloat(this.scenes[this.playing].trim_end_raw)) {
            this.playing++
            this.video.currentTime = this.scenes[this.playing].trim_start_raw
            this.end = this.scenes[this.playing].trim_end_raw
            setTimeout(() => {
              const scene = document.getElementsByClassName('active-scene')[0]
              if (scene) {
                window.scrollTo(0, scene.offsetTop + 90)
              }
            }, 1000)
          }
        } else {
          if (val >= this.end) {
            this.video.pause()
          }
        }
      } else {
        if (val >= this.end) {
          this.video.pause()
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';

.drawer-open {
  .selected-scenes-toolbar {
    width: calc(100% - 16rem);
  }
}
.selected-scenes-toolbar {
  position: fixed;
  transition: 0.3s;
  width: calc(100% - 90px);
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1040;
  background-color: $black;
}

.full-video-player {
  position: sticky;
  height: 285px;
  overflow: hidden;
  border-radius: 10px;
  top: 112px;
  @media screen and (max-width: 1022px) {
    top: 0;
    position: relative;
  }
  ::v-deep .video-js {
    height: 285px;
  }
  .video-player-overlay {
    position: absolute;
    width: 100%;
    top: 0;
    max-height: 285px;
    left: 0;
    z-index: 995;
    &__play {
      opacity: 1;
      position: absolute;
      top: 0;
      left: 0;
      color: $light;
      border-radius: 10px;
      background-color: rgba(14, 14, 23, 0.6);
      width: 100%;
      height: 100%;
      box-shadow: none;
      border: none;
      margin: 0;
      transition: 0.3s;
      svg {
        width: 33px;
      }
    }
    .img-thumbnail-overlay {
      position: relative;
      max-width: 100%;
      height: 100%;
      height: 285px;
      border: none;
      width: 100%;
      object-fit: cover;
    }

    .needs_render {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      font-weight: 600;
      font-size: 1.125rem;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: $light;
      border-radius: 10px;
      background-color: rgba(14, 14, 23, 0.8);
    }
  }
}

.card {
  padding: 0;
  border-radius: 10px;
  .list-group-item {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    background-color: transparent;
    padding: 1.4375rem 1rem;
    @media screen and (min-width: 1450px) {
      padding: 1.4375rem 2.0625rem;
    }
  }
  div:last-child {
    .list-group-item {
      border-bottom: 0;
    }
  }
}
.project-info {
  position: sticky;
  top: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .preview-info {
    color: $gray-450;
    line-height: 24px;
    @media screen and (max-width: 1022px) {
      margin-bottom: 35px;
    }
  }
  .length-info {
    color: $gray-450;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.project-edit {
  &__tools-add {
    position: relative;
    top: 14px;
    z-index: 1;
    display: flex;
    justify-content: center;
  }
  &__controls {
    height: 32px;
    display: flex;
    gap: 20px;
    align-items: center;
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    bottom: -17px;
    z-index: 1;
  }
}
</style>
