<template>
  <div>
    <BannerTop class="text-white">
      <p>
        {{ $t('banner.title') }}
        <a :href="redirectURlIA" rel="noopener noreferrer">
          {{ $t('banner.link') }}
        </a>
      </p>
    </BannerTop>

    <div
      class="wrapper-app banner-wrapper"
      :class="{ logged: logged, 'drawer-open': isDrawerOpen, promotionBanner: isPromotionBanner && me && me.video_tier < 1 }"
    >
      <PromotionTopBanner v-if="logged && me && me.video_tier < 1 && isPromotionBanner" />
      <div
        class="app"
        :class="{
          logged: logged,
          'drawer-open': isDrawerOpen,
        }"
      >
        <TopBar v-if="logged && $route.path != '/pricing'" />
        <MenuLeft v-if="logged && menu && $route.path != '/pricing'" />
        <router-view :key="$route.fullPath" />
        <div :class="{ marginLeft: menu }" v-if="showAudioPlayer">
          <MusicPlayer />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import PromotionTopBanner from './components/PromotionTopBanner'
import TopBar from './components/TopBar'
import MenuLeft from './components/MenuLeft'
import MusicPlayer from './components/MusicPlayer'
import BannerTop from './components/BannerTop.vue'
import { useSecureLink } from '@/composables/useSecureLink'

import 'vue-swatches/dist/vue-swatches.css'

export default {
  name: 'app',

  components: {
    PromotionTopBanner,
    TopBar,
    MenuLeft,
    MusicPlayer,
    BannerTop,
  },

  data() {
    return {
      bg_project: false,
      height: 400,
      redirectURlIA: '',
    }
  },

  computed: {
    ...mapGetters({
      logged: 'common/logged',
      menu: 'common/menu',
      showAudioPlayer: 'common/showAudioPlayer',
      isDrawerOpen: 'isDrawerOpen',
      isPromotionBanner: 'isPromotionBanner',
      me: 'user/me',
    }),
  },

  watch: {
    logged(newVal) {
      this.redirectURlIA = this.useSecureLink().createLink(process.env.VUE_APP_IA_URL, newVal)
    },
  },

  mounted() {
    this.redirectURlIA = this.useSecureLink().createLink(process.env.VUE_APP_IA_URL)
    this.height = document.body.clientHeight
    this.handleMenu()
    window.addEventListener('resize', () => {
      this.height = document.body.clientHeight
      this.handleMenu()
    })
  },

  methods: {
    ...mapMutations({
      showMenu: 'common/SHOW_MENU',
      hideMenu: 'common/HIDE_MENU',
    }),

    handleMenu() {
      if (parseInt(window.innerWidth) < 1023) {
        this.hideMenu()
      } else {
        this.showMenu()
      }
    },

    useSecureLink,
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/style';

html,
body {
  scroll-behavior: smooth;
}

.promotionBanner.logged {
  .app.logged {
    margin-top: 36px;
    @media screen and (max-width: 768px) {
      margin-top: 120px;
    }
  }
  .top-bar {
    top: 30px;
    @media screen and (min-width: 769px) and (max-width: 1024px) {
      top: 50px;
    }
    @media screen and (max-width: 768px) {
      top: 98px;
    }
  }
  .navigation-drawer__button {
    top: 70px;
  }
  .list-group .list-group-item:first-child {
    margin-top: 32px;
  }
  &.drawer-open {
    .bf-top-banner {
      width: calc(100% - 256px);
      left: 256px;
      @media screen and (max-width: 1024px) {
        width: 100%;
        left: 0;
      }
    }
  }
}
.wrapper-app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app {
  transition: 0.3s;
  &.logged {
    padding-right: 40px;
    padding-bottom: 76px;
    padding-left: calc(5.625rem + 60px);
    padding-top: 153px;
    min-height: 100vh;
    @media screen and (min-width: 1025px) and (max-width: 1190px) {
      padding-right: 30px;
    }
    @media screen and (max-width: 1024px) {
      padding: 190px 30px;
    }
    &.drawer-open {
      padding-left: calc(256px + 60px);
      @media screen and (max-width: 1024px) {
        padding-left: 30px;
      }
      .top-bar {
        padding-left: calc(16rem + 40px);
        @media screen and (max-width: 1024px) {
          padding-left: 30px;
        }
        .upgrade-link {
          @media screen and (max-width: 1290px) {
            display: none;
          }
        }
        .badge-info {
          white-space: break-spaces;
          @media screen and (max-width: 1500px) {
            max-width: 108px;
          }
        }
      }
      .selected-toolbar {
        left: 256px;
        @media screen and (max-width: 1024px) {
          left: 0;
        }
      }
    }
    .top-bar {
      @media screen and (max-width: 1024px) {
        padding-left: 30px;
      }
    }
  }
}

.banner-wrapper {
  &.logged {
    .app {
      margin-top: 30px;
      min-height: calc(100vh - 30px);
    }
    .top-bar {
      top: 30px;
    }
  }
  .navigation-drawer {
    top: 30px;
    &__button {
      top: 70px;
    }
  }
}
</style>
