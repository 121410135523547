<template>
  <div class="container-fluid p-0">
    <h5 class="title-page mb-2">
      {{ $t('menu.project-list') }}
    </h5>

    <div v-if="loading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div v-else>
      <div v-if="!all" class="text-center mt-5">
        <h5>{{ $t('project-open.no-projects') }}</h5>
        <router-link to="/newproject" class="btn btn-primary mx-auto mt-4">{{ $t('project-open.create-project') }}</router-link>
      </div>
      <div v-else>
        <div class="row mb-4">
          <div class="col text-right">
            <StorageMeasure :usedStorage="usedStorage" :maxStorage="maxStorage" />
          </div>
        </div>

        <ul class="list-group list-group-flush">
          <li class="list-group-item" v-for="(project, idx) in all" :key="project.id">
            <Queued v-if="project.is_queued" :project="project" :idx="idx" :tryUnqueue="tryUnqueue" :me="me" />
            <NotStarted v-else-if="project.status == 'Not Started'" :project="project" :idx="idx" :tryRemove="tryRemove" :tryRender="tryRender" />
            <Rendering
              v-else-if="['new', 'Working', 'Rendering', 'Final Rendering', 'Rdg Request'].findIndex((x) => x == project.status) > -1"
              :project="project"
            />
            <PreviewReady
              v-else-if="['Preview Ready', 'Render Ready'].findIndex((x) => x == project.status) > -1"
              :project="project"
              :tryRemove="tryRemove"
              :idx="idx"
            />
            <Failed v-else-if="['Failed'].findIndex((x) => x == project.status) > -1" :project="project" :tryRemove="tryRemove" :idx="idx" />
          </li>
        </ul>

        <nav v-if="all && all.length > 5" class="mt-5">
          <ul class="pagination ml-auto">
            <li class="page-item" :class="{ disabled: page == 1 }">
              <a class="page-link" href="#" @click.prevent="prev"><i class="bi-chevron-left" style="font-size: 0.9rem"></i></a>
            </li>
            <li class="page-item disabled">
              <a class="page-link" href="#"
                >{{ page }} <span v-if="allPages"> {{ $t('project-open.of') }} {{ allPages }}</span></a
              >
            </li>
            <li class="page-item" :class="{ disabled: all && all.length < 20 }">
              <a class="page-link" href="#" @click.prevent="next"><i class="bi-chevron-right" style="font-size: 0.9rem"></i></a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import StorageMeasure from './components/StorageMeasure'
import Rendering from './components/Rendering'
import PreviewReady from './components/PreviewReady'
import Failed from './components/Failed.vue'
import Queued from './components/Queued.vue'
import NotStarted from './components/NotStarted.vue'

export default {
  name: 'project-open',

  components: {
    StorageMeasure,
    Rendering,
    PreviewReady,
    Failed,
    Queued,
    NotStarted,
  },

  data() {
    return {
      page: 1,
      loading: true,
    }
  },

  computed: {
    ...mapGetters({
      all: 'project/all',
      allPages: 'project/allPages',
      me: 'user/me',
    }),
  },

  mounted() {
    this.getAllProjects()
    this.setInterval(
      setInterval(() => {
        this.getAll(this.page)
      }, 60000)
    )
  },

  methods: {
    ...mapMutations({
      setInterval: 'common/SET_INTERVAL',
    }),

    ...mapActions({
      getAll: 'project/GET_ALL',
      deleteById: 'project/DELETE_BY_ID',
      removeFromQueue: 'project/REMOVE_FROM_QUEUE',
      render: 'project/RENDER',
    }),

    async getAllProjects() {
      this.loading = true
      await this.getAll(this.page)
      this.loading = false
    },

    async tryRemove(project) {
      await this.deleteById(project.id)
      window.analytics.track('Project Deleting Started', {
        projectId: project.id,
        name: project.name,
      })
      this.getAllProjects()
    },

    async tryUnqueue(project) {
      await this.removeFromQueue(project.id)
      window.analytics.track('Project Unqueue Started', {
        projectId: project.id,
        name: project.name,
      })
      this.getAllProjects()
    },

    async tryRender(project) {
      this.loading = true
      await this.render({
        id: project.id,
        payload: {
          final: false,
        },
      })
      this.getAllProjects()
    },

    usedStorage: function () {
      return this.me.total_used_storage
    },

    maxStorage: function () {
      return this.me.max_storage
    },

    prev() {
      this.page = this.page - 1
      if (this.page < 1) {
        this.page = 1
      }
      this.getAllProjects()
    },

    next() {
      this.page = this.page + 1
      this.getAllProjects()
    },
  },
}
</script>

<style lang="scss" scoped>
.pagination {
  justify-content: center;
  .page-link {
    border: 0;
  }
}

::v-deep .list-group {
  .list-group-item {
    padding: 0%;
    &:not(:last-child) {
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
    .img-thumbnail {
      min-width: 190px;
      height: 111px;
      border-radius: 10px;
      object-fit: cover;
      margin-bottom: 0;
      @media screen and (max-width: 445px) {
        min-width: 138px;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
    }
    &__head {
      display: flex;
      gap: 38px;
      justify-content: space-between;
    }
    &__list {
      display: flex;
      gap: 38px;
      @media screen and (max-width: 768px) {
        gap: 15px;
      }
    }
    &__title {
      font-size: 14px;
      margin-bottom: 0;
      text-transform: capitalize;
    }
    &__infos {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      > div {
        gap: 38px;
        display: flex;

        @media screen and (max-width: 768px) {
          gap: 15px;
        }
        @media screen and (max-width: 500px) {
          flex-direction: column;
          gap: 12px;
        }
      }
      &--actions {
        @media screen and (max-width: 768px) {
          gap: 15px;
          position: absolute;
          top: 0;
          right: 0;
        }
        @media screen and (max-width: 475px) {
          span {
            display: none;
          }
        }
      }
    }
  }
}
</style>
