<template>
  <div class="p-3">
    <div v-if="loading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-else>
      <div class="row">
          <div class="col-sm-12">
          <TiersList v-if="!me.ltd_tier"/>
        </div>
        <!--<div class="col-sm-4">
          <Payment
            :payment_method="payment_method"
            :selectMethod="selectMethod"
          />
        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

// import Payment from "../Account/components/Payment";
import TiersList from "./components/TiersList.vue"

export default {
  name: "buy-ltd-view",

  components: {
    // Payment,
    TiersList
  },

  data() {
    return {
      loading: true,
      payment_method: null,
    };
  },

  computed: {
    ...mapGetters({
      me: "user/me",
      paymentMethods: "user/paymentMethods",
    }),
  },

  async mounted() {
    await this.getMe();
    if(this.me.ltd_tier) {
      this.$router.push(`/account`);      
    }
    this.loading = false;
  },

  methods: {
    ...mapActions({
      getMe: "user/GET_ME"
    }),

    selectMethod(id) {
      this.payment_method = id;
    },
  },

  /*watch: {
    paymentMethods(val) {
      if (this.paymentMethods.length == 1) {
        this.payment_method = this.paymentMethods[0].id;
      }
    },
  },*/
};
</script>