export function useSecureLink() {
  const user = JSON.parse(localStorage.getItem('user'))

  const createLink = (url, secure = true) => {
    if (!url) return url
    if (secure && user && user.api_token) {
      let cleanUrl = url.replace(/[\?\}\{\[\]]/g, '')

      if (cleanUrl.endsWith('/')) {
        cleanUrl = cleanUrl.slice(0, -1)
      }

      const hasQuery = cleanUrl.includes('?')
      const separator = hasQuery ? '&' : '?'

      return `${cleanUrl}${separator}token=${encodeURIComponent(user.api_token)}`
    }
    return url
  }

  return { createLink }
}
