export const responseHandle = response => {
  if (!response.ok) {
    switch (response.status) {
      case 400:
        return { error: 'Bad request' }
      case 401:
        localStorage.removeItem('user')
        return { error: 'Please authenticate' }
      case 403:
        if (response.url.split('/')[3] == 'user') {
          localStorage.removeItem('user')
          return { error: 'Please authenticate' }
        }
      case 406:
        return response.json()
      case 500:
        return { error: 'Internal server error' }
    }
  }

  return response.json()
}

export default { responseHandle }
