<template>
  <div>
    <b-overlay :show="confirmUnqueue == idx" variant="dark" opacity="1" no-wrap>
      <template #overlay>
        <div class="text-center">
          <p>
            <b>{{ $t('project-open.unqueue-project-confirmation') }}</b>
          </p>
          <button type="button" class="btn btn-sm btn-light mr-1" @click.prevent="confirmUnqueue = null">
            {{ $t('project-open.cancel') }}
          </button>
          <button type="button" class="btn btn-sm btn-danger" @click.prevent="tryUnqueue(project)">
            {{ $t('project-open.confirm') }}
          </button>
        </div>
      </template>
    </b-overlay>

    <div class="list-group-item__list">
      <img class="img-thumbnail" :src="project.thumbnailPath || '/img/thumbnail.png'" />

      <div class="list-group-item__content">
        <div class="list-group-item__head">
          <h5 class="list-group-item__title">{{ project.name }}</h5>
          <b class="text-default">
            {{ $t('open-project.queuedProject') }}
          </b>
        </div>

        <div class="list-group-item__infos">
          <div>
            <span class="badge badge-transparent">
              <SvgIcon name="clock" stroke="#9999B5" />
              {{ timestampToString(project.duration_out_seconds) }}
            </span>
            <span class="badge badge-transparent">
              <SvgIcon name="computer" stroke="#9999B5" />
              {{ (project.total_filesize / 1024 ** 2).toFixed(1) }} MB
            </span>
          </div>

          <button type="button" class="badge badge-transparent text-white ml-auto" @click.prevent="confirmUnqueue = idx">
            <SvgIcon name="cancel" stroke="#ffffff" />
            {{ $t('open-project.cancel') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SvgIcon from '@/components/icon/SvgIcon.vue'

export default {
  name: 'queued',

  props: ['project', 'idx', 'tryUnqueue', 'me'],

  components: {
    SvgIcon,
  },

  data() {
    return {
      confirmUnqueue: null,
    }
  },

  computed: {
    ...mapGetters({
      menu: 'common/menu',
    }),
  },

  methods: {
    timestampToString(seconds) {
      return new Date(seconds * 1000).toISOString().substr(11, 8)
    },
  },
}
</script>
