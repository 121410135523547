<template>
  <b-modal id="modal-appsumo" :title="$t('ltd.welcome')" hide-footer size="xl" centered no-close-on-backdrop no-close-on-esc hide-header-close>
    <p>{{ $t('appsumo.features') }}:</p>
    <div class="row">
      <div class="col-sm-6">
        <p>
          <i class="bi-check text-success"></i>
          {{ $t('appsumo.150-gb-free-storage') }}
        </p>
      </div>
      <div class="col-sm-6">
        <p>
          <i class="bi-check text-success"></i>
          {{ $t('appsumo.autocut-silences') }}
        </p>
      </div>
      <div class="col-sm-6">
        <p>
          <i class="bi-check text-success"></i>
          {{ $t('appsumo.smart-background-music') }}
        </p>
      </div>
      <div class="col-sm-6">
        <p>
          <i class="bi-check text-success"></i>
          {{ $t('appsumo.auto-subtitles') }}
        </p>
      </div>
      <div class="col-sm-6">
        <p>
          <i class="bi-check text-success"></i>
          {{ $t('appsumo.ai-storyboard') }}
        </p>
      </div>
      <div class="col-sm-6">
        <p>
          <i class="bi-check text-success"></i>
          {{ $t('appsumo.facial-recognition-auto-punch-in') }}
        </p>
      </div>
      <div class="col-sm-6">
        <p>
          <i class="bi-check text-success"></i>
          {{ $t('appsumo.ai-powered-audio-ducking') }}
        </p>
      </div>
      <div class="col-sm-6">
        <p>
          <i class="bi-check text-success"></i>
          {{ $t('appsumo.ai-voice-booster') }}
        </p>
      </div>
      <div class="col-sm-6">
        <p>
          <i class="bi-check text-success"></i>
          {{ $t('appsumo.youtube-integration') }}
        </p>
      </div>
      <div class="col-sm-6">
        <p>
          <i class="bi-check text-success"></i>
          {{ $t('appsumo.subtitles-auto-translation') }}
        </p>
      </div>
      <!-- <div class="col-sm-6">
        <p>
          <i class="bi-check text-success"></i>
          {{ $t("appsumo.1080p-resolution") }}
        </p>
      </div> -->
      <div class="col-sm-6">
        <p>
          <i class="bi-check text-success"></i>
          {{ $t('appsumo.file-size-limit') }}
        </p>
      </div>
      <div class="col-sm-6">
        <p>
          <i class="bi-check text-success"></i>
          {{ $t('appsumo.file-size-length') }}
        </p>
      </div>
    </div>
    <div class="form-group">
      <label>{{ $t('appsumo.your-language') }}</label>
      <select class="form-control" @change="localeChange">
        <option value="en" :selected="me.locale == 'en'">English</option>
        <option value="pt-BR" :selected="me.locale == 'pt-BR'">Português</option>
        <option value="es" :selected="me.locale == 'es'">Español</option>
        <option value="it" :selected="me.locale == 'it'">Italiano</option>
        <option value="fr" :selected="me.locale == 'fr'">Français</option>
        <option value="de" :selected="me.locale == 'de'">Deutsch</option>
      </select>
    </div>
    <div class="form-group">
      <label>{{ $t('appsumo.first-name') }}</label>
      <input type="text" class="form-control" v-model="model.first_name" />
    </div>
    <div class="form-group">
      <label>{{ $t('appsumo.last-name') }}</label>
      <input type="text" class="form-control" v-model="model.last_name" />
    </div>
    <p class="text-center">
      <i class="bi-check-circle-fill text-success"></i> {{ $t('appsumo.terms-disclaimer') }}
      <a href="https://www.wisecut.video/terms" target="_blank">{{ $t('appsumo.terms-of-use') }}</a>
    </p>
    <button type="button" class="btn btn-block btn-primary" @click.prevent="startUsing">
      {{ $t('appsumo.start-using') }}
    </button>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'appsumo-welcome',

  data() {
    return {
      model: {
        first_name: '',
        last_name: '',
      },
    }
  },

  computed: {
    ...mapGetters({
      me: 'user/me',
    }),
  },

  methods: {
    ...mapActions({
      changeLocale: 'user/CHANGE_LOCALE',
      renameUser: 'user/RENAME_USER',
      toggleWizard: 'user/TOGGLE_WIZARD',
      getMe: 'user/GET_ME',
    }),

    localeChange(e) {
      this.tryChangeLocale(e.target.value)
    },

    async startUsing() {
      await this.renameUser(this.model)
      await this.toggleWizard(0)
      await this.getMe()
      this.$bvModal.hide('modal-appsumo')
    },

    async tryChangeLocale(locale) {
      await this.changeLocale(locale)
      this.$i18n.locale = locale
    },
  },
}
</script>
