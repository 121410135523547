<template>
  <div class="mb-3 text-center">
    <div class="text-right">
      <a href="#" class="m-0 p-0" @click.prevent="removeFile(file, index)" v-if="file.progress < 100"
        ><i class="bi-trash"></i
      ></a>
      <i class="bi-check-circle-fill text-success" v-if="file.progress >= 100"></i>
    </div>
      <img
        class="rounded border border-dark"
        :src="URL.createObjectURL(file.data)"
        style="max-width: 100%; max-height: 100%"
      />
    <p class="text-primary text-center text-truncate mb-0">
      <small
        ><b>{{ file.progress }}%</b> - {{ file.data.name }}</small
      >
    </p>
    <div>
    <div class="progress" style="height: 3px">
      <div
        class="progress-bar progress-bar-striped"
        role="progressbar"
        :style="`width: ${file.progress}%`"
        :aria-valuenow="file.progress"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "new-project-image-component",

  props: ["file", "index", "removeFile"],

  data() {
    return {
      URL: URL,
    };
  },
};
</script>
