<template>
  <div>
    <div class="row">
      <div class="col-sm-9 col-lg-7">
        <div class="config-empty">
          <h5>{{ $t('new-project.simple-steps') }}:</h5>
          <p>{{ $t('new-project.step2') }}</p>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="form-group">
              <input
                type="text"
                :placeholder="$t('new-project.project-name')"
                class="form-control"
                maxlength="140"
                :class="{ 'is-invalid': $v.model.project.name.$error }"
                v-model="model.project.name"
              />
              <small class="form-text text-danger" v-if="$v.model.project.name.$error">{{ $t('new-project.project-name-required') }}</small>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <select
                class="form-control"
                :class="{ 'is-invalid': $v.model.locale.$error }"
                :placeholder="$t('new-project.spoken-language')"
                v-model="model.locale"
                @change="checkLanguage"
              >
                <option value="">
                  {{ $t('new-project.spoken-language') }}
                </option>
                <option value="no_speech">{{ $t('new-project.no-voice-short') }}</option>
                <option value="en-US">English</option>
                <option value="pt-BR">Português</option>
                <option value="de-DE">Deutsch</option>
                <option value="es-ES">Español</option>
                <option value="fr-FR">Français</option>
                <option value="it-IT">Italiano</option>
                <option value="ar-SA">عربى</option>
                <option value="fil-PH">Filipino</option>
                <option value="hi-IN">Hindi</option>
                <option value="fa-IR">فارسی</option>
                <option value="nl-NL">Dutch</option>
                <option value="ko-KR">한국어</option>
                <option value="ja-JP">日本</option>
              </select>
              <small class="form-text text-danger" v-if="$v.model.locale.$error">{{ $t('new-project.project-locale-required') }}</small>
            </div>
          </div>
        </div>

        <div class="mt-4">
          <div class="custom-control custom-switch mb-2" v-if="isVlog">
            <input type="checkbox" class="custom-control-input" id="smooth-cuts" v-model="model.project.smoothcuts" />
            <label class="custom-control-label" for="smooth-cuts">
              <p>{{ $t('new-project.smoothcuts') }}</p>
            </label>
            <SvgIcon name="info-octogon" stroke="#5E52F5" width="18px" id="info-smoothcuts" class="ml-2" />
            <b-popover target="info-smoothcuts" placement="right" :title="$t('project-edit.info-smoothcuts')" triggers="hover"></b-popover>
          </div>

          <div class="custom-control custom-switch mb-2" v-if="isVlog">
            <input type="checkbox" class="custom-control-input" id="auto-cut-scenes" v-model="model.project.autocutsilences" />
            <label class="custom-control-label" for="auto-cut-scenes">
              <p>{{ $t('new-project.auto-cut-scenes') }}</p>
            </label>
            <SvgIcon name="info-octogon" stroke="#5E52F5" width="18px" id="info-autocut" class="ml-2" />
            <b-popover target="info-autocut" placement="right" :title="$t('project-edit.info-autocut')" triggers="hover"></b-popover>
          </div>

          <div class="custom-control custom-switch mb-2">
            <input type="checkbox" class="custom-control-input" id="automatic-music" v-model="model.project.autobgm" />
            <label class="custom-control-label" for="automatic-music">
              <p>{{ $t('new-project.automatic-music') }}</p>
            </label>
            <SvgIcon name="info-octogon" stroke="#5E52F5" width="18px" id="info-autobgm" class="ml-2" />
            <b-popover target="info-autobgm" placement="right" :title="$t('project-edit.info-autobgm')" triggers="hover"></b-popover>
          </div>

          <div class="custom-control custom-switch mb-2" v-if="model.project.autobgm && isVlog">
            <input type="checkbox" class="custom-control-input" id="autoduck" v-model="model.project.autoduck" />
            <label class="custom-control-label" for="autoduck">
              <p>{{ $t('project-edit.auto-duck') }}</p>
            </label>
            <SvgIcon name="info-octogon" stroke="#5E52F5" width="18px" id="info-autoduck" class="ml-2" />
            <b-popover target="info-autoduck" placement="right" :title="$t('project-edit.info-autoduck')" triggers="hover"></b-popover>
          </div>

          <div class="custom-control custom-switch mb-2" v-if="isVlog">
            <input type="checkbox" class="custom-control-input" id="autopunchin" v-model="model.project.autopunchin" />
            <label class="custom-control-label" for="autopunchin">
              <p>{{ $t('project-edit.auto-punch-in') }}</p>
            </label>
            <SvgIcon name="info-octogon" stroke="#5E52F5" width="18px" id="info-autopunchin" class="ml-2" />
            <b-popover target="info-autopunchin" placement="right" :title="$t('project-edit.info-autopunchin')" triggers="hover"></b-popover>
          </div>

          <div class="custom-control custom-switch mb-2" v-if="isVlog">
            <input type="checkbox" class="custom-control-input" id="render-subtitle" v-model="model.project.render_subtitle" />
            <label class="custom-control-label" for="render-subtitle">
              <p>{{ $t('new-project.render-subtitle') }}</p>
            </label>
            <SvgIcon name="info-octogon" stroke="#5E52F5" width="18px" id="info-render-subtitle" class="ml-2" />
            <b-popover target="info-render-subtitle" placement="right" :title="$t('project-edit.info-render-subtitle')" triggers="hover"></b-popover>
          </div>
        </div>

        <button type="button" class="btn btn-block btn-primary mt-3" @click.prevent="checkInfo" v-if="files.length && !scheduled && video_style">
          {{ $t('new-project.create-project') }}
        </button>

        <div v-if="files.length && scheduled" class="bg-black rounded border border-primary p-3 text-center mt-5">
          <SvgIcon name="exclamation-circle" stroke="#5E52F5" width="32px" class="mb-3" />
          <p>
            {{ $t('new-project.create-scheduled') }}
          </p>
          <button type="button" class="btn btn-outline btn-sm ml-auto mr-auto" @click.prevent="schedule(false)">
            {{ $t('new-project.cancel-schedule') }}
          </button>
        </div>
      </div>

      <div class="col-sm-3" v-if="limits && subscription && showLimits">
        <div class="card mt-3">
          <div class="card-body text-center">
            <Limits :subscription="subscription" :limits="limits" />
            <router-link to="/account" class="btn btn-sm btn-primary mt-2">{{ $t('payment.upgrade-plan') }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Limits from '../../Payment/components/Limits'
import SvgIcon from '@/components/icon/SvgIcon.vue'

import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

export default {
  name: 'new-project-create',

  props: [
    'files',
    'model',
    'checkFiles',
    'schedule',
    'scheduled',
    'progress',
    'updateProgress',
    'showLimits',
    'isVlog',
    'video_style',
    'changeStyle',
    'setIsVlog',
  ],

  components: {
    Limits,
    SvgIcon,
  },

  computed: {
    ...mapGetters({
      subscription: 'user/subscription',
      limits: 'user/limits',
    }),
  },

  mixins: [validationMixin],

  validations: {
    model: {
      project: {
        name: {
          required,
        },
      },
      locale: {
        required,
      },
    },
  },

  methods: {
    checkInfo() {
      this.$v.model.$touch()
      if (this.$v.model.$error) return
      if (this.progress < 100) {
        this.schedule(true)
        let increment = 0
        let library_files = this.files.filter((x) => x.utype == 'library')
        for (let file of library_files) {
          increment = increment + file.progress
        }
        if (increment > 0 && library_files.length == this.files.length) {
          let progress = increment / library_files.length
          this.updateProgress(progress)
        }
      } else {
        this.checkFiles()
      }
    },
    checkLanguage() {
      if (this.model.locale == 'no_speech') {
        this.setIsVlog(false)
        this.changeStyle('no_speech')
      } else {
        this.setIsVlog(true)
        this.changeStyle('vlog')
      }
    },
  },

  watch: {
    progress(val) {
      if (val >= 100 && this.scheduled) {
        this.checkFiles()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.config-empty {
  h5 {
    font-size: 1.125rem;
  }
  p {
    font-size: 14px;
    margin-bottom: 20px;
  }
}
</style>
