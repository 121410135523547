<template>
  <b-modal id="modal-create-clips-project" modal-class="modal-create-clips-project" no-close-on-backdrop no-close-on-esc hide-footer>
    <template #modal-header="{ close }">
      {{ $t('project-edit.create-clips-project') }}

      <button class="btn btn-link p-0" @click="close()">
        <SvgIcon name="cancel" stroke="#ffffff" />
      </button>
    </template>

    <div v-if="!selectedScenes.length">
      <p>{{ $t('project-edit.no-scenes-selected') }}</p>
    </div>
    <div v-if="selectedScenes.length">
      <p>
        <b>{{ $t('project-edit.scenes') }}</b
        >: {{ selectedScenes.length }} {{ $t('project-edit.scenes-selected') }} ({{ selectedScenesLengthTimestamp }})
      </p>
      <hr class="border-primary" />
      <div class="form-group">
        <label
          ><b>{{ $t('project-edit.project-name') }}</b></label
        >
        <input type="text" class="form-control" :class="{ 'is-invalid': $v.model.name.$error }" v-model="model.name" />
      </div>
      <div v-if="model.final" class="form-group">
        <label>{{ $t('project-edit.size') }}</label>
        <select class="form-control mb-3" v-model="model.aspect">
          <option value="">{{ $t('project-edit.original') }}</option>
          <option value="landscape">{{ $t('project-edit.landscape') }} (16x9)</option>
          <option value="portrait">{{ $t('project-edit.portrait') }} (9x16)</option>
          <option value="square">{{ $t('project-edit.square') }} (1x1)</option>
        </select>
      </div>
      <div v-if="model.aspect" class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" id="auto-crop" v-model="model.autocrop" />
        <label class="custom-control-label" for="auto-crop">{{ $t('project-edit.auto-crop') }}</label
        ><i class="bi-info-circle text-info ml-2" id="info-render-crop"></i>
        <b-popover target="info-render-crop" placement="right" :title="$t('project-edit.info-autocrop')" triggers="hover"></b-popover>
      </div>
      <div class="custom-control custom-switch mt-3">
        <input type="checkbox" class="custom-control-input" id="exportClipsProject" v-model="model.final" />
        <label class="custom-control-label pl-2" for="exportClipsProject"> {{ $t('project-edit.download-ready') }}</label
        ><i class="bi-info-circle text-info ml-2" id="info-download-ready"></i>
        <b-popover target="info-download-ready" placement="right" :title="$t('project-edit.info-download-ready')" triggers="hover"></b-popover>
      </div>
      <button type="button" class="btn btn-block btn-primary mt-3" @click.prevent="checkInfo" :disabled="loading">
        <div class="spinner-border text-light" role="status" v-if="loading">
          <span class="sr-only">Loading...</span>
        </div>
        <span v-if="!loading">{{ $t('project-open.create-project') }}</span>
      </button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import SvgIcon from '@/components/icon/SvgIcon.vue'

export default {
  name: 'modal-clips-project',

  props: ['selected', 'selectedScenes', 'selectedScenesLengthTimestamp'],

  components: {
    SvgIcon,
  },

  data() {
    return {
      loading: false,
      model: {
        name: '',
        final: true,
        scenes: null,
        aspect: '',
        autocrop: 1,
      },
    }
  },

  mixins: [validationMixin],

  validations: {
    model: {
      name: {
        required,
      },
    },
  },

  methods: {
    ...mapActions({
      createProjectCuts: 'project/CREATE_PROJECT_CUTS',
    }),

    checkInfo() {
      this.$v.model.$touch()
      if (this.$v.model.$error) return
      this.tryCreate()
    },

    async tryCreate() {
      this.model.scenes = this.selectedScenes
      this.model.final = this.model.final ? 1 : 0
      this.loading = true
      const result = await this.createProjectCuts({
        id: this.selected.project.id,
        payload: this.model,
      })
      if (result.status == 'success') {
        if (this.model.final) {
          this.$router.push('/open')
        } else {
          this.$router.push(`/projects/${result.cutsproject_id}/storyboard`)
        }
      }

      // temporary disabled error handling

      // else {
      //   this.$bvToast.toast(this.$t(`new-project.error-${result.errorCode}`), {
      //     variant: 'danger',
      //     title: this.$t('new-project.create-error'),
      //     toaster: 'b-toaster-top-center',
      //     solid: true,
      //     noAutoHide: true,
      //   })
      // }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .modal-create-clips-project {
    .modal-header {
      padding-bottom: 0;
    }
  }
}
</style>
