<template>
  <div class="project-preview-view">
    <div v-if="loading" class="text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div v-if="!loading">
      <PreviewReady
        v-if="
          ['Preview Ready', 'Render Ready'].findIndex(
            (x) => x == selected.project.status
          ) > -1
        "
        :selected="selected"
        :highlights="highlights"
        :preloadingStoryboard="preloadingStoryboard"
      />
      <Rendering
        v-else
        :selected="selected"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import PreviewReady from "./components/PreviewReady";
import Rendering from "./components/Rendering";

export default {
  name: "project-preview-view",

  components: {
    PreviewReady,
    Rendering,
  },

  data() {
    return {
      loading: true,
      preloadingStoryboard: true
    };
  },

  computed: {
    ...mapGetters({
      selected: "project/selected"
    }),
    selectedProject: {
      get() {
        return this.$store.getters["project/selectedProject"];
      },
      set(payload) {
        return this.$store.dispatch("project/SET_SELECTED_PROJECT", payload);
      }
    },
    storyboard: {
      get() {
        return this.$store.getters["project/storyboard"];
      },
      set(payload) {
        return this.$store.dispatch("project/SET_STORYBOARD", payload);
      }
    },
    highlights: {
      get() {
        return this.$store.getters["project/highlights"];
      },
      set(payload) {
        return this.$store.dispatch("project/SET_HIGHLIGHTS", payload);
      }
    }
  },

  async mounted() {
    this.storyboard = null;
    this.selectedProject.timeline.scenes = [];
    this.loadStoryboard();

    this.highlights = null;
    this.getHighlights(this.$route.params.id);

    await this.getById(this.$route.params.id);

    this.setInterval(
      setInterval(() => {
        this.getById(this.$route.params.id);
        this.getHighlights(this.$route.params.id);
      }, 60000)
    );
    if (this.selected.project.deleted_at) {
      this.$router.push("/open");
    } else {
      this.loading = false;
      this.checkProject();
    }
  },

  methods: {
    ...mapMutations({
      setInterval: "common/SET_INTERVAL",
    }),

    ...mapActions({
      getById: "project/GET_BY_ID",
      getHighlights: "project/GET_HIGHLIGHTS",
      getStoryboard: "project/GET_STORYBOARD",
    }),

    async loadStoryboard() {
      let offset = 0;
      let limit = 25;
      const storyboard = await this.getStoryboard({
        id: this.$route.params.id,
        offset: offset,
        limit: limit,
        projectScenesTotal: 0
        });
      this.selectedProject.timeline.scenes = storyboard.timeline.scenes;
      this.preloadingStoryboard = false;
    },

    checkProject() {
      if (
        ['Preview Ready', 'Render Ready'].findIndex(
          (x) => x == this.selected.project.status
        ) > -1
      ) {
        window.analytics.track("Project Preview Opened", {
          projectId: this.selected.project.id,
          name: this.selected.project.name,
        });
      }
    },
  },
};
</script>
