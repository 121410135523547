<template>
  <div>
    <div>
      <div class="text-center">
        <b class="text-info"
          ><i class="bi-clock"></i> {{ $t("payment.minutes") }}</b
        >
      </div>
      <h2 class="text-center">
        {{ timestampToString(quotas.processingMinutes) }}
      </h2>
      <div class="text-center">
        <small class="text-success"
          ><i class="bi-clock"></i> {{ $t("payment.renews-at") }}
          {{
            (subscription.stripe_subscription.current_period_end * 1000)
              | moment(me.locale == "pt-BR" ? "DD/MM/YYYY" : "MM/DD/YYYY")
          }}</small
        >
      </div>
    </div>
    <div class="mt-3">
      <div class="text-center">
        <b class="text-info"
          ><i class="bi-hdd"></i> {{ $t("payment.storage") }}</b
        >
      </div>
      <h2 class="text-center">
        {{ (quotas.storageBytes / 1024 ** 3).toFixed(1) }} GB
      </h2>
    </div>
    <hr class="border-primary" />
    <div class="text-center">
      <p>
        <b>{{ $t("payment.plan-limits-right-now") }}</b>
      </p>
      <ul class="list-group">
        <li class="list-group-item">
          <small
            ><b class="text-info">{{ $t("payment.max-length") }}:</b></small
          ><br />
          {{ timestampToString(quotas.uploadMaxLengthMinutes) }}
        </li>
        <li class="list-group-item">
          <small
            ><b class="text-info">{{ $t("payment.max-size") }}:</b></small
          ><br />
          {{ (quotas.uploadMaxSizeBytes / 1024 ** 3).toFixed(1) }}
          GB
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "limits",

  props: ["limits", "quotas", "subscription"],

  data() {
    return {
      plan: null,
    };
  },

  computed: {
    ...mapGetters({
      me: "user/me",
    }),
  },

  mounted() {
    this.plan = Object.assign(
      {},
      this.subscription.stripe_subscription.items.data[0].plan.metadata
    );
  },

  methods: {
    timestampToString(totalMinutes) {
      const minutes = Math.floor(totalMinutes % 60);
      const hours = Math.floor(totalMinutes / 60);
      const seconds = Math.floor(((totalMinutes % 60) - minutes) * 60);

      return `${this.padTo2Digits(hours)}:${this.padTo2Digits(minutes)}:${this.padTo2Digits(seconds)}`;
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    }
  },
};
</script>