import { responseHandle } from "./handle"
import fetch from "./fetch"

export default {
  getAll(page) {
    const endpoint = `/projects?page=${page}`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  getById(id) {
    const endpoint = `/projects/${id}`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  getHighlights(id) {
    const endpoint = `/projects/${id}/highlights`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  getStoryboard(id, offset, limit, projectScenesTotal, scenesIds) {
    const endpoint = `/projects/${id}/storyboard?offset=${offset}&limit=${limit}&projectScenesTotal=${projectScenesTotal}&limit=${limit}`
    return fetch
      .post(endpoint, {scenesIds: scenesIds})
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  getLatest() {
    const endpoint = `/projects?count=1`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  updateById(id, payload) {
    const endpoint = `/projects/${id}`
    return fetch
      .put(endpoint, payload)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  deleteById(id) {
    const endpoint = `/projects/${id}`
    return fetch
      .delete(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  removeFromQueue(id) {
    const endpoint = `/projects/${id}/queue`
    return fetch
      .delete(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  render(id, payload) {
    const endpoint = `/projects/${id}/render`
    return fetch
      .post(endpoint, payload)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  reopen(id) {
    const endpoint = `/projects/${id}/reopen`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  createProject(payload) {
    const endpoint = `/createproject`
    return fetch
      .post(endpoint, payload)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },
  
  createProjectCuts(id, payload) {
    const endpoint = `/projects/${id}/cuts`
    return fetch
      .post(endpoint, payload)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  insertScenes(id, payload) {
    const endpoint = `/projects/${id}/library/import`
    return fetch
      .post(endpoint, payload)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  insertOverlay(id, payload) {
    const endpoint = `/projects/${id}/library/import/overscene`
    return fetch
      .post(endpoint, payload)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  getAwsKey(extension) {
    const endpoint = `/signeds3?extension?=${extension}`
    return fetch
      .post(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  getStripeVideoSession(id) {
    const endpoint = `/stripe/checkout/video?project=${id}`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  shareYoutube(id, payload) {
    const endpoint = `/projects/${id}/share/youtube`
    return fetch
      .post(endpoint, payload)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  getSubtitles(id, ready) {
    const endpoint = `/projects/${id}/autosubtitles?ready=${ready}`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  getTranslateOptions(id) {
    const endpoint = `/projects/${id}/translate/options`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  translateSubtitles(id, payload) {
    const endpoint = `/projects/${id}/translate`
    return fetch
      .post(endpoint, payload)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  resetVolumes(id, param) {
    const endpoint = `/projects/${id}/reset/volumes?autoduck=${param}`
    return fetch
      .get(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  recreateStoryboard(id, payload) {
    const endpoint = `/projects/${id}/recreatestoryboard`
    return fetch
      .post(endpoint, payload)
      .then(responseHandle)
      .then(response => {
        return response
      })
  },

  renderRaw(id) {
    const endpoint = `/projects/${id}/renderraw`
    return fetch
      .post(endpoint)
      .then(responseHandle)
      .then(response => {
        return response
      })
  }
}
