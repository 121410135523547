<template>
  <div>
    <b-overlay :show="confirmRestart == idx" variant="dark" opacity="1" no-wrap>
      <template #overlay>
        <div class="text-center">
          <p>
            <b>{{ $t('project-open.restart-project-confirmation') }}</b>
          </p>
          <p class="text-info">
            {{ $t('project-open.restart-project-tip') }}
          </p>
          <button type="button" class="btn btn-sm btn-light mr-1" @click.prevent="confirmRestart = null">
            {{ $t('project-open.cancel') }}
          </button>
          <button type="button" class="btn btn-sm btn-danger" @click.prevent="tryRender(project)">
            {{ $t('project-open.confirm') }}
          </button>
        </div>
      </template>
    </b-overlay>
    <b-overlay :show="confirmDelete == idx" variant="dark" opacity="1" no-wrap>
      <template #overlay>
        <div class="text-center">
          <p>
            <b>{{ $t('project-open.remove-project-confirmation') }}</b>
          </p>
          <p class="text-info">
            {{ $t('project-open.remove-project-tip') }}
          </p>
          <button type="button" class="btn btn-sm btn-light mr-1" @click.prevent="confirmDelete = null">
            {{ $t('project-open.cancel') }}
          </button>
          <button type="button" class="btn btn-sm btn-danger" @click.prevent="tryRemove(project)">
            {{ $t('project-open.confirm') }}
          </button>
        </div>
      </template>
    </b-overlay>

    <div class="list-group-item__list">
      <img class="img-thumbnail" :src="project.thumbnailPath || '/img/thumbnail.png'" />

      <div class="list-group-item__content">
        <h5 class="list-group-item__title">{{ project.name }}</h5>

        <div class="list-group-item__infos">
          <div>
            <small class="text-default" v-if="project.deleted_at">
              {{ $t('project-open.project-deleted') }}
            </small>
            <small v-else class="text-default text-justify mt-3">{{ $t('project-open.not-started-project-info') }}</small>
          </div>

          <div v-if="!project.deleted_at">
            <button type="button" class="badge text-white badge-transparent" @click.prevent="confirmRestart = idx">
              <SvgIcon name="reload" stroke="#ffffff" width="18px" />
              {{ $t('open-project.reload') }}
            </button>
            <button type="button" class="badge text-white badge-transparent" @click.prevent="confirmDelete = idx">
              <SvgIcon name="trash" stroke="#ffffff" width="18px" />
              {{ $t('open-project.delete') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SvgIcon from '@/components/icon/SvgIcon.vue'

export default {
  name: 'not-started',

  props: ['project', 'idx', 'tryRemove', 'tryRender'],

  components: {
    SvgIcon,
  },

  data() {
    return {
      confirmRestart: null,
      confirmDelete: null,
    }
  },

  computed: {
    ...mapGetters({
      menu: 'common/menu',
    }),
  },
}
</script>
