import api from "@/api"

const getters = {
  latest: state => {
    return state.latest
  },

  selected: state => {
    return state.selected
  },

  selectedProject: state => {
    return state.selectedProject
  },

  highlights: state => {
    return state.highlights
  },

  storyboard: state => {
    return state.storyboard
  },

  all: state => {
    return state.all
  },

  allPages: state => {
    return state.allPages
  },

  awsKey: state => {
    return state.awsKey
  },

  stripeSession: state => {
    return state.stripeSession
  },

  subtitles: state => {
    return state.subtitles
  },

  translateOptions: state => {
    return state.translateOptions
  }
}

const mutations = {
  SET_AWS_KEY: (state, result) => {
    state.awsKey = result
  },

  SET_LATEST: (state, result) => {
    if (result && result.data.length) {
      state.latest = result.data[0]
    } else {
      state.latest = null
    }
  },

  SET_SELECTED: (state, result) => {
    state.selected = result
  },

  SET_SELECTED_PROJECT: (state, result) => {
    state.selectedProject = result
  },

  SET_HIGHLIGHTS: (state, result) => {
    state.highlights = result
  },

  SET_STORYBOARD: (state, result) => {
    state.storyboard = result
  },

  SET_ALL: (state, result) => {
    if (result && result.data.length) {
      state.all = result.data
      state.allPages = result.last_page
    } else {
      state.all = null
      state.allPages = null
    }
  },

  SET_STRIPE_VIDEO_SESSION: (state, result) => {
    state.stripeSession = result
  },

  SET_SUBTITLES: (state, result) => {
    state.subtitles = result
  },

  SET_TRANSLATE_OPTIONS: (state, result) => {
    state.translateOptions = result
  },
}

const actions = {
  GET_AWS_KEY: ({ commit }, extension) => {
    return new Promise(resolve => {
      return api.project
        .getAwsKey(extension)
        .then(result => {
          commit('SET_AWS_KEY', result)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  GET_LATEST: ({ commit }) => {
    return new Promise(resolve => {
      return api.project
        .getLatest()
        .then(result => {
          commit('SET_LATEST', result)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  GET_BY_ID: ({ commit }, id) => {
    return new Promise(resolve => {
      return api.project
        .getById(id)
        .then(result => {
          commit('SET_SELECTED', result)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  GET_HIGHLIGHTS: ({ commit }, id) => {
    return new Promise(resolve => {
      return api.project
        .getHighlights(id)
        .then(result => {
          commit('SET_HIGHLIGHTS', result)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  SET_HIGHLIGHTS: ({ commit }, payload) => {
    commit('SET_HIGHLIGHTS', payload)
  },

  GET_STORYBOARD: ({ commit }, {id, offset, limit, projectScenesTotal, scenesIds = []}) => {
    return new Promise(resolve => {
      return api.project
        .getStoryboard(id, offset, limit, projectScenesTotal, scenesIds)
        .then(result => {
          commit('SET_STORYBOARD', result)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  SET_STORYBOARD: ({ commit }, payload) => {
    commit('SET_STORYBOARD', payload)
  },

  GET_ALL: ({ commit }, page) => {
    return new Promise(resolve => {
      return api.project
        .getAll(page)
        .then(result => {
          commit('SET_ALL', result)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  UPDATE_BY_ID: ({ dispatch }, { id, payload }) => {
    return new Promise(resolve => {
      return api.project
        .updateById(id, payload)
        .then(async result => {
          await dispatch('GET_BY_ID', id)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  SET_SELECTED_PROJECT: ({ commit }, payload) => {
    commit('SET_SELECTED_PROJECT', payload)
  },

  DELETE_BY_ID: ({ dispatch }, id) => {
    return new Promise(resolve => {
      return api.project
        .deleteById(id)
        .then(async result => {
          await dispatch('GET_ALL', 1)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  REMOVE_FROM_QUEUE: ({ dispatch }, id) => {
    return new Promise(resolve => {
      return api.project
        .removeFromQueue(id)
        .then(async result => {
          await dispatch('GET_ALL', 1)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  CREATE_PROJECT: ({ }, payload) => {
    return new Promise(resolve => {
      return api.project
        .createProject(payload)
        .then(result => {
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  CREATE_PROJECT_CUTS: ({ }, { id, payload }) => {
    return new Promise(resolve => {
      return api.project
        .createProjectCuts(id, payload)
        .then(result => {
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  INSERT_SCENES: ({ dispatch }, { id, payload }) => {
    return new Promise(resolve => {
      return api.project
        .insertScenes(id, payload)
        .then(result => {
          dispatch('GET_BY_ID', id)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  INSERT_OVERLAY: ({ dispatch }, { id, payload }) => {
    return new Promise(resolve => {
      return api.project
        .insertOverlay(id, payload)
        .then(result => {
          dispatch('GET_BY_ID', id)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  RENDER: ({ dispatch }, { id, payload }) => {
    return new Promise(resolve => {
      return api.project
        .render(id, payload)
        .then(async result => {
          await dispatch('GET_BY_ID', id)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  REOPEN: ({ dispatch }, id) => {
    return new Promise(resolve => {
      return api.project
        .reopen(id)
        .then(async result => {
          await dispatch('GET_BY_ID', id)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  GET_STRIPE_VIDEO_SESSION: ({ commit }, id) => {
    return new Promise(resolve => {
      return api.project
        .getStripeVideoSession(id)
        .then(result => {
          commit('SET_STRIPE_VIDEO_SESSION', result)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  SHARE_YOUTUBE: ({ dispatch }, { id, payload }) => {
    return new Promise(resolve => {
      return api.project
        .shareYoutube(id, payload)
        .then(async result => {
          await dispatch('GET_BY_ID', id)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  GET_SUBTITLES: ({ commit }, { id, ready }) => {
    return new Promise(resolve => {
      return api.project
        .getSubtitles(id, ready)
        .then(result => {
          commit('SET_SUBTITLES', result)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  GET_TRANSLATE_OPTIONS: ({ commit }, id) => {
    return new Promise(resolve => {
      return api.project
        .getTranslateOptions(id)
        .then(result => {
          commit('SET_TRANSLATE_OPTIONS', result)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  TRANSLATE_SUBTITLES: ({ dispatch }, { id, payload }) => {
    return new Promise(resolve => {
      return api.project
        .translateSubtitles(id, payload)
        .then(async result => {
          await dispatch('GET_SUBTITLES', id, false)
          await dispatch('GET_TRANSLATE_OPTIONS', id)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  RESET_VOLUMES: ({ dispatch }, { id, param }) => {
    return new Promise(resolve => {
      return api.project
        .resetVolumes(id, param)
        .then(async result => {
          await dispatch('GET_BY_ID', id)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  RECREATE_STORYBOARD: ({ dispatch }, { id, payload }) => {
    return new Promise(resolve => {
      return api.project
        .recreateStoryboard(id, payload)
        .then(async result => {
          await dispatch('GET_BY_ID', id)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },

  RENDER_RAW: ({ dispatch }, id) => {
    return new Promise(resolve => {
      return api.project
        .renderRaw(id)
        .then(async result => {
          await dispatch('GET_BY_ID', id)
          resolve(result)
        })
        .catch(error => {
          resolve(error)
        })
    })
  }
}

const state = {
  latest: null,
  selected: null,
  selectedProject: {
    project: null,
    timeline: {scenes: []}
  },
  highlights: null,
  storyboard: null,
  all: null,
  allPages: null,
  awsKey: null,
  stripeSession: null,
  subtitles: null,
  translateOptions: null
}

const namespaced = true

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
}
